/* eslint-disable prettier/prettier */
import { useAuth0, User } from '@auth0/auth0-react';

import useGetUser from './useGetUser';
import { GetUserQuery } from '../generated/graphql';

export type AuthUser = User &
  GetUserQuery['getUser'] & { isSuperAdmin: boolean };

interface AuthUserHookReturn {
  user: AuthUser | null;
  error: { source: 'auth0' | 'server'; error: any } | null;
  isLoading: boolean;
  isPending: boolean;
  fetchStatus: string | undefined;
}

function useAuthUser(): AuthUserHookReturn {
  /**
   * wrapper function to use auth0 and postgresql user data in tandem
   */
  const { user, error, isLoading: isAuth0Loading } = useAuth0();

  const isSuperAdmin =
    user?.['https://kalicosports.com/roles']?.includes('SuperAdmin');

  const {
    data: pgUser,
    isLoading: pgIsLoading,
    error: pgError,
    isPending: pgIsPending,
    status,
  } = useGetUser(user?.sub as string);

  return {
    user:
      user && pgUser
        ? ({
            ...user,
            ...pgUser.getUser,
            isSuperAdmin: isSuperAdmin,
          } as AuthUser)
        : null,
    error: error
      ? { source: 'auth0', error }
      : pgError
      ? { source: 'server', error: pgError }
      : null,
    isLoading: isAuth0Loading || (pgIsLoading && !!user?.sub),
    isPending: pgIsPending,
    fetchStatus: status,
  };
}

export default useAuthUser;
