import { Capacitor } from '@capacitor/core';

export const serverConfig = {
  schemaPath:
    (Capacitor.getPlatform() === 'android' &&
    process.env.NEXT_PUBLIC_NODE_ENV == 'development'
      ? process.env.NEXT_PUBLIC_ANDROID_FULL_PATH
      : process.env.NEXT_PUBLIC_SCHEMA_PATH) ?? '',
  fullPath: process.env.NEXT_PUBLIC_FULL_PATH ?? '',
};
