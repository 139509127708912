import { useState, useEffect } from 'react';

import { useAuth0, OAuthError } from '@auth0/auth0-react';
import * as Sentry from '@sentry/nextjs';
import { GraphQLClient } from 'graphql-request';

import { serverConfig } from '../config';
import { getSdk, Sdk } from '../generated/graphql';

// initialize graphql-request client
const gqlClient = new GraphQLClient(serverConfig.schemaPath, {
  headers: {
    authorization: `Bearer MY_TOKEN`,
  },
});

function isTimestampExpired(unixTimestamp: number) {
  const currentUnixTimestamp = Math.floor(Date.now() / 1000);
  return unixTimestamp < currentUnixTimestamp;
}

function useGql() {
  /**
   * wrapper function to use auth0 and graphql-request in tandem
   */
  const {
    user,
    error,
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const [gqlSdk, setGqlSdk] = useState<Sdk | null>(null);
  const [gqlError, setGqlError] = useState<Error | null>(null);
  useEffect(() => {
    async function setHeadersAndSdk() {
      try {
        if (user && isAuthenticated) {
          // if user is loggged in, add access token to headers

          // get id token claims (which basically has the access token)
          const idTokenClaims = await getIdTokenClaims();
          if (idTokenClaims?.exp && isTimestampExpired(idTokenClaims?.exp)) {
            // refresh token if expired
            await getAccessTokenSilently({ cacheMode: 'off' });
          }

          gqlClient.setHeader(
            'authorization',
            `Bearer ${idTokenClaims?.__raw}`
          );
        } else {
          gqlClient.setHeader('authorization', '');
        }

        // create graphql-request sdk, which contains the graphql queries
        const sdk = getSdk(gqlClient);
        setGqlSdk(sdk);
      } catch (e) {
        if ((e as OAuthError).error === 'invalid_grant') {
          logout();
          return;
        }
        console.error('Auth Error:', e);
        Sentry.captureException(e);
        setGqlError(new Error('Unable to set headers and create SDK'));
      }
    }

    if (!isLoading) {
      setHeadersAndSdk();
    }
  }, [
    isLoading,
    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    user,
    logout,
  ]);

  return {
    gqlSdk,
    error: error ?? gqlError ?? null,
    isLoading: isLoading || (!gqlSdk && !gqlError),
  };
}

export default useGql;
