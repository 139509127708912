/* eslint-disable prettier/prettier */
import { useQuery } from '@tanstack/react-query';

import useGql from './useGql';

function useGetUser(userId: string) {
  const { gqlSdk } = useGql();

  const {
    data: pgUser,
    isLoading: pgIsLoading,
    error: pgError,
    isPending: pgIsPending,
    status,
  } = useQuery({
    queryKey: ['getUser', userId],
    queryFn: () =>
      gqlSdk?.getUser({
        where: {
          id: userId,
        },
      }),

    enabled: !!userId && !!gqlSdk,
  });

  return {
    data: pgUser,
    error: pgError,
    isLoading: pgIsLoading,
    isPending: pgIsPending,
    status: status,
  };
}

export default useGetUser;
