import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTimeISO: { input: any; output: any };
  Decimal: { input: any; output: any };
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int']['output'];
};

export type AggregateCourt = {
  __typename?: 'AggregateCourt';
  _count?: Maybe<CourtCountAggregate>;
  _max?: Maybe<CourtMaxAggregate>;
  _min?: Maybe<CourtMinAggregate>;
};

export type AggregateEvent = {
  __typename?: 'AggregateEvent';
  _avg?: Maybe<EventAvgAggregate>;
  _count?: Maybe<EventCountAggregate>;
  _max?: Maybe<EventMaxAggregate>;
  _min?: Maybe<EventMinAggregate>;
  _sum?: Maybe<EventSumAggregate>;
};

export type AggregateLocation = {
  __typename?: 'AggregateLocation';
  _avg?: Maybe<LocationAvgAggregate>;
  _count?: Maybe<LocationCountAggregate>;
  _max?: Maybe<LocationMaxAggregate>;
  _min?: Maybe<LocationMinAggregate>;
  _sum?: Maybe<LocationSumAggregate>;
};

export type AggregateMatch = {
  __typename?: 'AggregateMatch';
  _avg?: Maybe<MatchAvgAggregate>;
  _count?: Maybe<MatchCountAggregate>;
  _max?: Maybe<MatchMaxAggregate>;
  _min?: Maybe<MatchMinAggregate>;
  _sum?: Maybe<MatchSumAggregate>;
};

export type AggregateOrg = {
  __typename?: 'AggregateOrg';
  _count?: Maybe<OrgCountAggregate>;
  _max?: Maybe<OrgMaxAggregate>;
  _min?: Maybe<OrgMinAggregate>;
};

export type AggregateOrgLocation = {
  __typename?: 'AggregateOrgLocation';
  _count?: Maybe<OrgLocationCountAggregate>;
  _max?: Maybe<OrgLocationMaxAggregate>;
  _min?: Maybe<OrgLocationMinAggregate>;
};

export type AggregatePool = {
  __typename?: 'AggregatePool';
  _count?: Maybe<PoolCountAggregate>;
  _max?: Maybe<PoolMaxAggregate>;
  _min?: Maybe<PoolMinAggregate>;
};

export type AggregateRating = {
  __typename?: 'AggregateRating';
  _count?: Maybe<RatingCountAggregate>;
  _max?: Maybe<RatingMaxAggregate>;
  _min?: Maybe<RatingMinAggregate>;
};

export type AggregateRound = {
  __typename?: 'AggregateRound';
  _avg?: Maybe<RoundAvgAggregate>;
  _count?: Maybe<RoundCountAggregate>;
  _max?: Maybe<RoundMaxAggregate>;
  _min?: Maybe<RoundMinAggregate>;
  _sum?: Maybe<RoundSumAggregate>;
};

export type AggregateScore = {
  __typename?: 'AggregateScore';
  _avg?: Maybe<ScoreAvgAggregate>;
  _count?: Maybe<ScoreCountAggregate>;
  _max?: Maybe<ScoreMaxAggregate>;
  _min?: Maybe<ScoreMinAggregate>;
  _sum?: Maybe<ScoreSumAggregate>;
};

export type AggregateStage = {
  __typename?: 'AggregateStage';
  _count?: Maybe<StageCountAggregate>;
  _max?: Maybe<StageMaxAggregate>;
  _min?: Maybe<StageMinAggregate>;
};

export type AggregateTeam = {
  __typename?: 'AggregateTeam';
  _count?: Maybe<TeamCountAggregate>;
  _max?: Maybe<TeamMaxAggregate>;
  _min?: Maybe<TeamMinAggregate>;
};

export type AggregateTeamEvent = {
  __typename?: 'AggregateTeamEvent';
  _avg?: Maybe<TeamEventAvgAggregate>;
  _count?: Maybe<TeamEventCountAggregate>;
  _max?: Maybe<TeamEventMaxAggregate>;
  _min?: Maybe<TeamEventMinAggregate>;
  _sum?: Maybe<TeamEventSumAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
};

export type AggregateUserEvent = {
  __typename?: 'AggregateUserEvent';
  _avg?: Maybe<UserEventAvgAggregate>;
  _count?: Maybe<UserEventCountAggregate>;
  _max?: Maybe<UserEventMaxAggregate>;
  _min?: Maybe<UserEventMinAggregate>;
  _sum?: Maybe<UserEventSumAggregate>;
};

export type AggregateUserEventInvite = {
  __typename?: 'AggregateUserEventInvite';
  _count?: Maybe<UserEventInviteCountAggregate>;
  _max?: Maybe<UserEventInviteMaxAggregate>;
  _min?: Maybe<UserEventInviteMinAggregate>;
};

export type AggregateUserNotificationPreferences = {
  __typename?: 'AggregateUserNotificationPreferences';
  _avg?: Maybe<UserNotificationPreferencesAvgAggregate>;
  _count?: Maybe<UserNotificationPreferencesCountAggregate>;
  _max?: Maybe<UserNotificationPreferencesMaxAggregate>;
  _min?: Maybe<UserNotificationPreferencesMinAggregate>;
  _sum?: Maybe<UserNotificationPreferencesSumAggregate>;
};

export type AggregateUserRating = {
  __typename?: 'AggregateUserRating';
  _count?: Maybe<UserRatingCountAggregate>;
  _max?: Maybe<UserRatingMaxAggregate>;
  _min?: Maybe<UserRatingMinAggregate>;
};

export type AggregateUserTeam = {
  __typename?: 'AggregateUserTeam';
  _count?: Maybe<UserTeamCountAggregate>;
  _max?: Maybe<UserTeamMaxAggregate>;
  _min?: Maybe<UserTeamMinAggregate>;
};

export type AggregateUserTeamEvent = {
  __typename?: 'AggregateUserTeamEvent';
  _count?: Maybe<UserTeamEventCountAggregate>;
  _max?: Maybe<UserTeamEventMaxAggregate>;
  _min?: Maybe<UserTeamEventMinAggregate>;
};

export type AggregateUserTeamInvite = {
  __typename?: 'AggregateUserTeamInvite';
  _count?: Maybe<UserTeamInviteCountAggregate>;
  _max?: Maybe<UserTeamInviteMaxAggregate>;
  _min?: Maybe<UserTeamInviteMinAggregate>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type Court = {
  __typename?: 'Court';
  _count?: Maybe<CourtCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location: Location;
  locationId: Scalars['String']['output'];
  match: Array<Match>;
  name: Scalars['String']['output'];
  sport: Sport;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type CourtCreatedByUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type CourtMatchArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MatchWhereInput>;
};

export type CourtCount = {
  __typename?: 'CourtCount';
  match: Scalars['Int']['output'];
};

export type CourtCountMatchArgs = {
  where?: InputMaybe<MatchWhereInput>;
};

export type CourtCountAggregate = {
  __typename?: 'CourtCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  createdByUserId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  locationId: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  sport: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type CourtCountOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
};

export type CourtCreateInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCourtInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  location: LocationCreateNestedOneWithoutCourtInput;
  match?: InputMaybe<MatchCreateNestedManyWithoutCourtInput>;
  name: Scalars['String']['input'];
  sport: Sport;
};

export type CourtCreateManyCreatedByUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sport: Sport;
};

export type CourtCreateManyCreatedByUserInputEnvelope = {
  data: Array<CourtCreateManyCreatedByUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CourtCreateManyInput = {
  createdByUserId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sport: Sport;
};

export type CourtCreateManyLocationInput = {
  createdByUserId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sport: Sport;
};

export type CourtCreateManyLocationInputEnvelope = {
  data: Array<CourtCreateManyLocationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CourtCreateNestedManyWithoutCreatedByUserInput = {
  connect?: InputMaybe<Array<CourtWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CourtCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<CourtCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<CourtCreateManyCreatedByUserInputEnvelope>;
};

export type CourtCreateNestedManyWithoutLocationInput = {
  connect?: InputMaybe<Array<CourtWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CourtCreateOrConnectWithoutLocationInput>>;
  create?: InputMaybe<Array<CourtCreateWithoutLocationInput>>;
  createMany?: InputMaybe<CourtCreateManyLocationInputEnvelope>;
};

export type CourtCreateNestedOneWithoutMatchInput = {
  connect?: InputMaybe<CourtWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CourtCreateOrConnectWithoutMatchInput>;
  create?: InputMaybe<CourtCreateWithoutMatchInput>;
};

export type CourtCreateOrConnectWithoutCreatedByUserInput = {
  create: CourtCreateWithoutCreatedByUserInput;
  where: CourtWhereUniqueInput;
};

export type CourtCreateOrConnectWithoutLocationInput = {
  create: CourtCreateWithoutLocationInput;
  where: CourtWhereUniqueInput;
};

export type CourtCreateOrConnectWithoutMatchInput = {
  create: CourtCreateWithoutMatchInput;
  where: CourtWhereUniqueInput;
};

export type CourtCreateWithoutCreatedByUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  location: LocationCreateNestedOneWithoutCourtInput;
  match?: InputMaybe<MatchCreateNestedManyWithoutCourtInput>;
  name: Scalars['String']['input'];
  sport: Sport;
};

export type CourtCreateWithoutLocationInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCourtInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  match?: InputMaybe<MatchCreateNestedManyWithoutCourtInput>;
  name: Scalars['String']['input'];
  sport: Sport;
};

export type CourtCreateWithoutMatchInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCourtInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  location: LocationCreateNestedOneWithoutCourtInput;
  name: Scalars['String']['input'];
  sport: Sport;
};

export type CourtGroupBy = {
  __typename?: 'CourtGroupBy';
  _count?: Maybe<CourtCountAggregate>;
  _max?: Maybe<CourtMaxAggregate>;
  _min?: Maybe<CourtMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sport: Sport;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type CourtListRelationFilter = {
  every?: InputMaybe<CourtWhereInput>;
  none?: InputMaybe<CourtWhereInput>;
  some?: InputMaybe<CourtWhereInput>;
};

export type CourtMaxAggregate = {
  __typename?: 'CourtMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Sport>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CourtMaxOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
};

export type CourtMinAggregate = {
  __typename?: 'CourtMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Sport>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CourtMinOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
};

export type CourtNullableRelationFilter = {
  is?: InputMaybe<CourtWhereInput>;
  isNot?: InputMaybe<CourtWhereInput>;
};

export type CourtOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CourtOrderByWithAggregationInput = {
  _count?: InputMaybe<CourtCountOrderByAggregateInput>;
  _max?: InputMaybe<CourtMaxOrderByAggregateInput>;
  _min?: InputMaybe<CourtMinOrderByAggregateInput>;
  createdByUserId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
};

export type CourtOrderByWithRelationInput = {
  createdByUser?: InputMaybe<UserOrderByWithRelationInput>;
  createdByUserId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByWithRelationInput>;
  locationId?: InputMaybe<SortOrder>;
  match?: InputMaybe<MatchOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
};

export enum CourtScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  Id = 'id',
  LocationId = 'locationId',
  Name = 'name',
  Sport = 'sport',
  UpdatedAt = 'updatedAt',
}

export type CourtScalarWhereInput = {
  AND?: InputMaybe<Array<CourtScalarWhereInput>>;
  NOT?: InputMaybe<Array<CourtScalarWhereInput>>;
  OR?: InputMaybe<Array<CourtScalarWhereInput>>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  sport?: InputMaybe<EnumSportFilter>;
};

export type CourtScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CourtScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CourtScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CourtScalarWhereWithAggregatesInput>>;
  createdByUserId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  locationId?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  sport?: InputMaybe<EnumSportWithAggregatesFilter>;
};

export type CourtUpdateInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutCourtNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutCourtNestedInput>;
  match?: InputMaybe<MatchUpdateManyWithoutCourtNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
};

export type CourtUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
};

export type CourtUpdateManyWithWhereWithoutCreatedByUserInput = {
  data: CourtUpdateManyMutationInput;
  where: CourtScalarWhereInput;
};

export type CourtUpdateManyWithWhereWithoutLocationInput = {
  data: CourtUpdateManyMutationInput;
  where: CourtScalarWhereInput;
};

export type CourtUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: InputMaybe<Array<CourtWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CourtCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<CourtCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<CourtCreateManyCreatedByUserInputEnvelope>;
  delete?: InputMaybe<Array<CourtWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CourtScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CourtWhereUniqueInput>>;
  set?: InputMaybe<Array<CourtWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CourtUpdateWithWhereUniqueWithoutCreatedByUserInput>
  >;
  updateMany?: InputMaybe<
    Array<CourtUpdateManyWithWhereWithoutCreatedByUserInput>
  >;
  upsert?: InputMaybe<
    Array<CourtUpsertWithWhereUniqueWithoutCreatedByUserInput>
  >;
};

export type CourtUpdateManyWithoutLocationNestedInput = {
  connect?: InputMaybe<Array<CourtWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CourtCreateOrConnectWithoutLocationInput>>;
  create?: InputMaybe<Array<CourtCreateWithoutLocationInput>>;
  createMany?: InputMaybe<CourtCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<CourtWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CourtScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CourtWhereUniqueInput>>;
  set?: InputMaybe<Array<CourtWhereUniqueInput>>;
  update?: InputMaybe<Array<CourtUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: InputMaybe<Array<CourtUpdateManyWithWhereWithoutLocationInput>>;
  upsert?: InputMaybe<Array<CourtUpsertWithWhereUniqueWithoutLocationInput>>;
};

export type CourtUpdateOneWithoutMatchNestedInput = {
  connect?: InputMaybe<CourtWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CourtCreateOrConnectWithoutMatchInput>;
  create?: InputMaybe<CourtCreateWithoutMatchInput>;
  delete?: InputMaybe<CourtWhereInput>;
  disconnect?: InputMaybe<CourtWhereInput>;
  update?: InputMaybe<CourtUpdateToOneWithWhereWithoutMatchInput>;
  upsert?: InputMaybe<CourtUpsertWithoutMatchInput>;
};

export type CourtUpdateToOneWithWhereWithoutMatchInput = {
  data: CourtUpdateWithoutMatchInput;
  where?: InputMaybe<CourtWhereInput>;
};

export type CourtUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  data: CourtUpdateWithoutCreatedByUserInput;
  where: CourtWhereUniqueInput;
};

export type CourtUpdateWithWhereUniqueWithoutLocationInput = {
  data: CourtUpdateWithoutLocationInput;
  where: CourtWhereUniqueInput;
};

export type CourtUpdateWithoutCreatedByUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutCourtNestedInput>;
  match?: InputMaybe<MatchUpdateManyWithoutCourtNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CourtUpdateWithoutLocationInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutCourtNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  match?: InputMaybe<MatchUpdateManyWithoutCourtNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
};

export type CourtUpdateWithoutMatchInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutCourtNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutCourtNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
};

export type CourtUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  create: CourtCreateWithoutCreatedByUserInput;
  update: CourtUpdateWithoutCreatedByUserInput;
  where: CourtWhereUniqueInput;
};

export type CourtUpsertWithWhereUniqueWithoutLocationInput = {
  create: CourtCreateWithoutLocationInput;
  update: CourtUpdateWithoutLocationInput;
  where: CourtWhereUniqueInput;
};

export type CourtUpsertWithoutMatchInput = {
  create: CourtCreateWithoutMatchInput;
  update: CourtUpdateWithoutMatchInput;
  where?: InputMaybe<CourtWhereInput>;
};

export type CourtWhereInput = {
  AND?: InputMaybe<Array<CourtWhereInput>>;
  NOT?: InputMaybe<Array<CourtWhereInput>>;
  OR?: InputMaybe<Array<CourtWhereInput>>;
  createdByUser?: InputMaybe<UserNullableRelationFilter>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  locationId?: InputMaybe<StringFilter>;
  match?: InputMaybe<MatchListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  sport?: InputMaybe<EnumSportFilter>;
};

export type CourtWhereUniqueInput = {
  AND?: InputMaybe<Array<CourtWhereInput>>;
  NOT?: InputMaybe<Array<CourtWhereInput>>;
  OR?: InputMaybe<Array<CourtWhereInput>>;
  createdByUser?: InputMaybe<UserNullableRelationFilter>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationRelationFilter>;
  locationId?: InputMaybe<StringFilter>;
  match?: InputMaybe<MatchListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  sport?: InputMaybe<EnumSportFilter>;
};

export type CreateManyAndReturnCourt = {
  __typename?: 'CreateManyAndReturnCourt';
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location: Location;
  locationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sport: Sport;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type CreateManyAndReturnCourtCreatedByUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type CreateManyAndReturnEvent = {
  __typename?: 'CreateManyAndReturnEvent';
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  endDate: Scalars['DateTimeISO']['output'];
  gender: Gender;
  id: Scalars['String']['output'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']['output']>;
  maxTeams?: Maybe<Scalars['Int']['output']>;
  maxUsersGoing?: Maybe<Scalars['Int']['output']>;
  minTeams?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  org?: Maybe<Org>;
  orgId?: Maybe<Scalars['String']['output']>;
  privacy?: Maybe<EventPrivacy>;
  sport: Sport;
  startDate: Scalars['DateTimeISO']['output'];
  teamSize?: Maybe<Scalars['Int']['output']>;
  type: EventType;
  updatedAt: Scalars['DateTimeISO']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type CreateManyAndReturnEventCreatedByUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type CreateManyAndReturnEventLocationArgs = {
  where?: InputMaybe<LocationWhereInput>;
};

export type CreateManyAndReturnEventOrgArgs = {
  where?: InputMaybe<OrgWhereInput>;
};

export type CreateManyAndReturnLocation = {
  __typename?: 'CreateManyAndReturnLocation';
  address?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  latitude: Scalars['Decimal']['output'];
  longitude: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type CreateManyAndReturnLocationCreatedByUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type CreateManyAndReturnMatch = {
  __typename?: 'CreateManyAndReturnMatch';
  court?: Maybe<Court>;
  courtId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  endDate?: Maybe<Scalars['DateTimeISO']['output']>;
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  nextMatch?: Maybe<Match>;
  nextMatchId?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  playoffRound?: Maybe<Scalars['Int']['output']>;
  stage?: Maybe<Stage>;
  stageId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  winningTeam?: Maybe<Team>;
  winningTeamId?: Maybe<Scalars['String']['output']>;
};

export type CreateManyAndReturnMatchCourtArgs = {
  where?: InputMaybe<CourtWhereInput>;
};

export type CreateManyAndReturnMatchEventArgs = {
  where?: InputMaybe<EventWhereInput>;
};

export type CreateManyAndReturnMatchNextMatchArgs = {
  where?: InputMaybe<MatchWhereInput>;
};

export type CreateManyAndReturnMatchStageArgs = {
  where?: InputMaybe<StageWhereInput>;
};

export type CreateManyAndReturnMatchWinningTeamArgs = {
  where?: InputMaybe<TeamWhereInput>;
};

export type CreateManyAndReturnOrg = {
  __typename?: 'CreateManyAndReturnOrg';
  createdAt: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  scrapeUrls?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTimeISO']['output'];
  url: Scalars['String']['output'];
};

export type CreateManyAndReturnOrgLocation = {
  __typename?: 'CreateManyAndReturnOrgLocation';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  location: Location;
  locationId: Scalars['String']['output'];
  org: Org;
  orgId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type CreateManyAndReturnPool = {
  __typename?: 'CreateManyAndReturnPool';
  createdAt: Scalars['DateTimeISO']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type CreateManyAndReturnRating = {
  __typename?: 'CreateManyAndReturnRating';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  rating: Scalars['String']['output'];
  sport: Sport;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type CreateManyAndReturnRound = {
  __typename?: 'CreateManyAndReturnRound';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  match: Match;
  matchId: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  winningTeam?: Maybe<Team>;
  winningTeamId?: Maybe<Scalars['String']['output']>;
};

export type CreateManyAndReturnRoundWinningTeamArgs = {
  where?: InputMaybe<TeamWhereInput>;
};

export type CreateManyAndReturnScore = {
  __typename?: 'CreateManyAndReturnScore';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  round: Round;
  roundId: Scalars['String']['output'];
  team: Team;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  value: Scalars['Int']['output'];
};

export type CreateManyAndReturnStage = {
  __typename?: 'CreateManyAndReturnStage';
  createdAt: Scalars['DateTimeISO']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: StageType;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type CreateManyAndReturnTeam = {
  __typename?: 'CreateManyAndReturnTeam';
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sport: Sport;
  status: Status;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type CreateManyAndReturnTeamCreatedByUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type CreateManyAndReturnTeamEvent = {
  __typename?: 'CreateManyAndReturnTeamEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPaid: Scalars['Boolean']['output'];
  placement?: Maybe<Scalars['Int']['output']>;
  pool?: Maybe<Pool>;
  poolId?: Maybe<Scalars['String']['output']>;
  seed?: Maybe<Scalars['Int']['output']>;
  team: Team;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type CreateManyAndReturnTeamEventEventArgs = {
  where?: InputMaybe<EventWhereInput>;
};

export type CreateManyAndReturnTeamEventPoolArgs = {
  where?: InputMaybe<PoolWhereInput>;
};

export type CreateManyAndReturnUser = {
  __typename?: 'CreateManyAndReturnUser';
  createdAt: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  favoriteSports?: Maybe<Array<Sport>>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isPseudo: Scalars['Boolean']['output'];
  lastViewedFeed?: Maybe<Scalars['DateTimeISO']['output']>;
  name: Scalars['String']['output'];
  optIn: Scalars['Boolean']['output'];
  sport?: Maybe<Sport>;
  startDate: Scalars['DateTimeISO']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type CreateManyAndReturnUserEvent = {
  __typename?: 'CreateManyAndReturnUserEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  eventPosition?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  status: Status;
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type CreateManyAndReturnUserEventInvite = {
  __typename?: 'CreateManyAndReturnUserEventInvite';
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser: User;
  createdByUserId: Scalars['String']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: InviteStatus;
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type CreateManyAndReturnUserNotificationPreferences = {
  __typename?: 'CreateManyAndReturnUserNotificationPreferences';
  createdAt: Scalars['DateTimeISO']['output'];
  deviceId: Scalars['String']['output'];
  deviceName: Scalars['String']['output'];
  emailNotify: Scalars['Boolean']['output'];
  firebaseToken: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  pushNotify: Scalars['Boolean']['output'];
  smsNotify: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type CreateManyAndReturnUserRating = {
  __typename?: 'CreateManyAndReturnUserRating';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  rating: Rating;
  ratingId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type CreateManyAndReturnUserTeam = {
  __typename?: 'CreateManyAndReturnUserTeam';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  status: Status;
  team: Team;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type CreateManyAndReturnUserTeamEvent = {
  __typename?: 'CreateManyAndReturnUserTeamEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Status;
  team: Team;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type CreateManyAndReturnUserTeamInvite = {
  __typename?: 'CreateManyAndReturnUserTeamInvite';
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser: User;
  createdByUserId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: InviteStatus;
  team: Team;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Decimal']['input']>;
  divide?: InputMaybe<Scalars['Decimal']['input']>;
  increment?: InputMaybe<Scalars['Decimal']['input']>;
  multiply?: InputMaybe<Scalars['Decimal']['input']>;
  set?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type DecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type EnumEventPrivacyNullableFilter = {
  equals?: InputMaybe<EventPrivacy>;
  in?: InputMaybe<Array<EventPrivacy>>;
  not?: InputMaybe<NestedEnumEventPrivacyNullableFilter>;
  notIn?: InputMaybe<Array<EventPrivacy>>;
};

export type EnumEventPrivacyNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumEventPrivacyNullableFilter>;
  _min?: InputMaybe<NestedEnumEventPrivacyNullableFilter>;
  equals?: InputMaybe<EventPrivacy>;
  in?: InputMaybe<Array<EventPrivacy>>;
  not?: InputMaybe<NestedEnumEventPrivacyNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<EventPrivacy>>;
};

export type EnumEventTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<EventType>;
};

export type EnumEventTypeFilter = {
  equals?: InputMaybe<EventType>;
  in?: InputMaybe<Array<EventType>>;
  not?: InputMaybe<NestedEnumEventTypeFilter>;
  notIn?: InputMaybe<Array<EventType>>;
};

export type EnumEventTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumEventTypeFilter>;
  _min?: InputMaybe<NestedEnumEventTypeFilter>;
  equals?: InputMaybe<EventType>;
  in?: InputMaybe<Array<EventType>>;
  not?: InputMaybe<NestedEnumEventTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<EventType>>;
};

export type EnumGenderFieldUpdateOperationsInput = {
  set?: InputMaybe<Gender>;
};

export type EnumGenderFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  not?: InputMaybe<NestedEnumGenderFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type EnumGenderWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumGenderFilter>;
  _min?: InputMaybe<NestedEnumGenderFilter>;
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  not?: InputMaybe<NestedEnumGenderWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type EnumInviteStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<InviteStatus>;
};

export type EnumInviteStatusFilter = {
  equals?: InputMaybe<InviteStatus>;
  in?: InputMaybe<Array<InviteStatus>>;
  not?: InputMaybe<NestedEnumInviteStatusFilter>;
  notIn?: InputMaybe<Array<InviteStatus>>;
};

export type EnumInviteStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInviteStatusFilter>;
  _min?: InputMaybe<NestedEnumInviteStatusFilter>;
  equals?: InputMaybe<InviteStatus>;
  in?: InputMaybe<Array<InviteStatus>>;
  not?: InputMaybe<NestedEnumInviteStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InviteStatus>>;
};

export type EnumSportFieldUpdateOperationsInput = {
  set?: InputMaybe<Sport>;
};

export type EnumSportFilter = {
  equals?: InputMaybe<Sport>;
  in?: InputMaybe<Array<Sport>>;
  not?: InputMaybe<NestedEnumSportFilter>;
  notIn?: InputMaybe<Array<Sport>>;
};

export type EnumSportNullableFilter = {
  equals?: InputMaybe<Sport>;
  in?: InputMaybe<Array<Sport>>;
  not?: InputMaybe<NestedEnumSportNullableFilter>;
  notIn?: InputMaybe<Array<Sport>>;
};

export type EnumSportNullableListFilter = {
  equals?: InputMaybe<Array<Sport>>;
  has?: InputMaybe<Sport>;
  hasEvery?: InputMaybe<Array<Sport>>;
  hasSome?: InputMaybe<Array<Sport>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumSportNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumSportNullableFilter>;
  _min?: InputMaybe<NestedEnumSportNullableFilter>;
  equals?: InputMaybe<Sport>;
  in?: InputMaybe<Array<Sport>>;
  not?: InputMaybe<NestedEnumSportNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Sport>>;
};

export type EnumSportWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumSportFilter>;
  _min?: InputMaybe<NestedEnumSportFilter>;
  equals?: InputMaybe<Sport>;
  in?: InputMaybe<Array<Sport>>;
  not?: InputMaybe<NestedEnumSportWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Sport>>;
};

export type EnumStageTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<StageType>;
};

export type EnumStageTypeFilter = {
  equals?: InputMaybe<StageType>;
  in?: InputMaybe<Array<StageType>>;
  not?: InputMaybe<NestedEnumStageTypeFilter>;
  notIn?: InputMaybe<Array<StageType>>;
};

export type EnumStageTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStageTypeFilter>;
  _min?: InputMaybe<NestedEnumStageTypeFilter>;
  equals?: InputMaybe<StageType>;
  in?: InputMaybe<Array<StageType>>;
  not?: InputMaybe<NestedEnumStageTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StageType>>;
};

export type EnumStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<Status>;
};

export type EnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStatusFilter>;
  _min?: InputMaybe<NestedEnumStatusFilter>;
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type Event = {
  __typename?: 'Event';
  _count?: Maybe<EventCount>;
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  endDate: Scalars['DateTimeISO']['output'];
  eventUsers: Array<UserEvent>;
  gender: Gender;
  id: Scalars['String']['output'];
  invitedUsers: Array<UserEventInvite>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']['output']>;
  matches: Array<Match>;
  maxTeams?: Maybe<Scalars['Int']['output']>;
  maxUsersGoing?: Maybe<Scalars['Int']['output']>;
  minTeams?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  org?: Maybe<Org>;
  orgId?: Maybe<Scalars['String']['output']>;
  pools: Array<Pool>;
  privacy?: Maybe<EventPrivacy>;
  sport: Sport;
  stages: Array<Stage>;
  startDate: Scalars['DateTimeISO']['output'];
  teamEvents: Array<TeamEvent>;
  teamSize?: Maybe<Scalars['Int']['output']>;
  type: EventType;
  updatedAt: Scalars['DateTimeISO']['output'];
  url?: Maybe<Scalars['String']['output']>;
  userTeamEvents: Array<UserTeamEvent>;
};

export type EventCreatedByUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type EventEventUsersArgs = {
  cursor?: InputMaybe<UserEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventWhereInput>;
};

export type EventInvitedUsersArgs = {
  cursor?: InputMaybe<UserEventInviteWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserEventInviteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserEventInviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventInviteWhereInput>;
};

export type EventLocationArgs = {
  where?: InputMaybe<LocationWhereInput>;
};

export type EventMatchesArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MatchWhereInput>;
};

export type EventOrgArgs = {
  where?: InputMaybe<OrgWhereInput>;
};

export type EventPoolsArgs = {
  cursor?: InputMaybe<PoolWhereUniqueInput>;
  distinct?: InputMaybe<Array<PoolScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PoolOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PoolWhereInput>;
};

export type EventStagesArgs = {
  cursor?: InputMaybe<StageWhereUniqueInput>;
  distinct?: InputMaybe<Array<StageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StageWhereInput>;
};

export type EventTeamEventsArgs = {
  cursor?: InputMaybe<TeamEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamEventWhereInput>;
};

export type EventUserTeamEventsArgs = {
  cursor?: InputMaybe<UserTeamEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type EventAvgAggregate = {
  __typename?: 'EventAvgAggregate';
  cost?: Maybe<Scalars['Float']['output']>;
  maxTeams?: Maybe<Scalars['Float']['output']>;
  maxUsersGoing?: Maybe<Scalars['Float']['output']>;
  minTeams?: Maybe<Scalars['Float']['output']>;
  teamSize?: Maybe<Scalars['Float']['output']>;
};

export type EventAvgOrderByAggregateInput = {
  cost?: InputMaybe<SortOrder>;
  maxTeams?: InputMaybe<SortOrder>;
  maxUsersGoing?: InputMaybe<SortOrder>;
  minTeams?: InputMaybe<SortOrder>;
  teamSize?: InputMaybe<SortOrder>;
};

export type EventCount = {
  __typename?: 'EventCount';
  eventUsers: Scalars['Int']['output'];
  invitedUsers: Scalars['Int']['output'];
  matches: Scalars['Int']['output'];
  pools: Scalars['Int']['output'];
  stages: Scalars['Int']['output'];
  teamEvents: Scalars['Int']['output'];
  userTeamEvents: Scalars['Int']['output'];
};

export type EventCountEventUsersArgs = {
  where?: InputMaybe<UserEventWhereInput>;
};

export type EventCountInvitedUsersArgs = {
  where?: InputMaybe<UserEventInviteWhereInput>;
};

export type EventCountMatchesArgs = {
  where?: InputMaybe<MatchWhereInput>;
};

export type EventCountPoolsArgs = {
  where?: InputMaybe<PoolWhereInput>;
};

export type EventCountStagesArgs = {
  where?: InputMaybe<StageWhereInput>;
};

export type EventCountTeamEventsArgs = {
  where?: InputMaybe<TeamEventWhereInput>;
};

export type EventCountUserTeamEventsArgs = {
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type EventCountAggregate = {
  __typename?: 'EventCountAggregate';
  _all: Scalars['Int']['output'];
  cost: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  createdByUserId: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  endDate: Scalars['Int']['output'];
  gender: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  locationId: Scalars['Int']['output'];
  maxTeams: Scalars['Int']['output'];
  maxUsersGoing: Scalars['Int']['output'];
  minTeams: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  orgId: Scalars['Int']['output'];
  privacy: Scalars['Int']['output'];
  sport: Scalars['Int']['output'];
  startDate: Scalars['Int']['output'];
  teamSize: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type EventCountOrderByAggregateInput = {
  cost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdByUserId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  maxTeams?: InputMaybe<SortOrder>;
  maxUsersGoing?: InputMaybe<SortOrder>;
  minTeams?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orgId?: InputMaybe<SortOrder>;
  privacy?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  teamSize?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type EventCreateInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  eventUsers?: InputMaybe<UserEventCreateNestedManyWithoutEventInput>;
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserEventInviteCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutEventsInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutEventInput>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  org?: InputMaybe<OrgCreateNestedOneWithoutEventsInput>;
  pools?: InputMaybe<PoolCreateNestedManyWithoutEventInput>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  stages?: InputMaybe<StageCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTimeISO']['input'];
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutEventInput>;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutEventInput>;
};

export type EventCreateManyCreatedByUserInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  startDate: Scalars['DateTimeISO']['input'];
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type EventCreateManyCreatedByUserInputEnvelope = {
  data: Array<EventCreateManyCreatedByUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventCreateManyInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUserId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  startDate: Scalars['DateTimeISO']['input'];
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type EventCreateManyLocationInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUserId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  startDate: Scalars['DateTimeISO']['input'];
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type EventCreateManyLocationInputEnvelope = {
  data: Array<EventCreateManyLocationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventCreateManyOrgInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUserId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  startDate: Scalars['DateTimeISO']['input'];
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type EventCreateManyOrgInputEnvelope = {
  data: Array<EventCreateManyOrgInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventCreateNestedManyWithoutCreatedByUserInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EventCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<EventCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<EventCreateManyCreatedByUserInputEnvelope>;
};

export type EventCreateNestedManyWithoutLocationInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutLocationInput>>;
  create?: InputMaybe<Array<EventCreateWithoutLocationInput>>;
  createMany?: InputMaybe<EventCreateManyLocationInputEnvelope>;
};

export type EventCreateNestedManyWithoutOrgInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutOrgInput>>;
  create?: InputMaybe<Array<EventCreateWithoutOrgInput>>;
  createMany?: InputMaybe<EventCreateManyOrgInputEnvelope>;
};

export type EventCreateNestedOneWithoutEventUsersInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutEventUsersInput>;
  create?: InputMaybe<EventCreateWithoutEventUsersInput>;
};

export type EventCreateNestedOneWithoutInvitedUsersInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutInvitedUsersInput>;
  create?: InputMaybe<EventCreateWithoutInvitedUsersInput>;
};

export type EventCreateNestedOneWithoutMatchesInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutMatchesInput>;
  create?: InputMaybe<EventCreateWithoutMatchesInput>;
};

export type EventCreateNestedOneWithoutPoolsInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutPoolsInput>;
  create?: InputMaybe<EventCreateWithoutPoolsInput>;
};

export type EventCreateNestedOneWithoutStagesInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutStagesInput>;
  create?: InputMaybe<EventCreateWithoutStagesInput>;
};

export type EventCreateNestedOneWithoutTeamEventsInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutTeamEventsInput>;
  create?: InputMaybe<EventCreateWithoutTeamEventsInput>;
};

export type EventCreateNestedOneWithoutUserTeamEventsInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutUserTeamEventsInput>;
  create?: InputMaybe<EventCreateWithoutUserTeamEventsInput>;
};

export type EventCreateOrConnectWithoutCreatedByUserInput = {
  create: EventCreateWithoutCreatedByUserInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutEventUsersInput = {
  create: EventCreateWithoutEventUsersInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutInvitedUsersInput = {
  create: EventCreateWithoutInvitedUsersInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutLocationInput = {
  create: EventCreateWithoutLocationInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutMatchesInput = {
  create: EventCreateWithoutMatchesInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutOrgInput = {
  create: EventCreateWithoutOrgInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutPoolsInput = {
  create: EventCreateWithoutPoolsInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutStagesInput = {
  create: EventCreateWithoutStagesInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutTeamEventsInput = {
  create: EventCreateWithoutTeamEventsInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutUserTeamEventsInput = {
  create: EventCreateWithoutUserTeamEventsInput;
  where: EventWhereUniqueInput;
};

export type EventCreateWithoutCreatedByUserInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  eventUsers?: InputMaybe<UserEventCreateNestedManyWithoutEventInput>;
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserEventInviteCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutEventsInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutEventInput>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  org?: InputMaybe<OrgCreateNestedOneWithoutEventsInput>;
  pools?: InputMaybe<PoolCreateNestedManyWithoutEventInput>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  stages?: InputMaybe<StageCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTimeISO']['input'];
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutEventInput>;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutEventUsersInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserEventInviteCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutEventsInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutEventInput>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  org?: InputMaybe<OrgCreateNestedOneWithoutEventsInput>;
  pools?: InputMaybe<PoolCreateNestedManyWithoutEventInput>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  stages?: InputMaybe<StageCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTimeISO']['input'];
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutEventInput>;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutInvitedUsersInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  eventUsers?: InputMaybe<UserEventCreateNestedManyWithoutEventInput>;
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationCreateNestedOneWithoutEventsInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutEventInput>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  org?: InputMaybe<OrgCreateNestedOneWithoutEventsInput>;
  pools?: InputMaybe<PoolCreateNestedManyWithoutEventInput>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  stages?: InputMaybe<StageCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTimeISO']['input'];
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutEventInput>;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutLocationInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  eventUsers?: InputMaybe<UserEventCreateNestedManyWithoutEventInput>;
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserEventInviteCreateNestedManyWithoutEventInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutEventInput>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  org?: InputMaybe<OrgCreateNestedOneWithoutEventsInput>;
  pools?: InputMaybe<PoolCreateNestedManyWithoutEventInput>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  stages?: InputMaybe<StageCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTimeISO']['input'];
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutEventInput>;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutMatchesInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  eventUsers?: InputMaybe<UserEventCreateNestedManyWithoutEventInput>;
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserEventInviteCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutEventsInput>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  org?: InputMaybe<OrgCreateNestedOneWithoutEventsInput>;
  pools?: InputMaybe<PoolCreateNestedManyWithoutEventInput>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  stages?: InputMaybe<StageCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTimeISO']['input'];
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutEventInput>;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutOrgInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  eventUsers?: InputMaybe<UserEventCreateNestedManyWithoutEventInput>;
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserEventInviteCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutEventsInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutEventInput>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  pools?: InputMaybe<PoolCreateNestedManyWithoutEventInput>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  stages?: InputMaybe<StageCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTimeISO']['input'];
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutEventInput>;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutPoolsInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  eventUsers?: InputMaybe<UserEventCreateNestedManyWithoutEventInput>;
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserEventInviteCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutEventsInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutEventInput>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  org?: InputMaybe<OrgCreateNestedOneWithoutEventsInput>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  stages?: InputMaybe<StageCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTimeISO']['input'];
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutEventInput>;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutStagesInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  eventUsers?: InputMaybe<UserEventCreateNestedManyWithoutEventInput>;
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserEventInviteCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutEventsInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutEventInput>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  org?: InputMaybe<OrgCreateNestedOneWithoutEventsInput>;
  pools?: InputMaybe<PoolCreateNestedManyWithoutEventInput>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  startDate: Scalars['DateTimeISO']['input'];
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutEventInput>;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutTeamEventsInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  eventUsers?: InputMaybe<UserEventCreateNestedManyWithoutEventInput>;
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserEventInviteCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutEventsInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutEventInput>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  org?: InputMaybe<OrgCreateNestedOneWithoutEventsInput>;
  pools?: InputMaybe<PoolCreateNestedManyWithoutEventInput>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  stages?: InputMaybe<StageCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTimeISO']['input'];
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutUserTeamEventsInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTimeISO']['input'];
  eventUsers?: InputMaybe<UserEventCreateNestedManyWithoutEventInput>;
  gender: Gender;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserEventInviteCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutEventsInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutEventInput>;
  maxTeams?: InputMaybe<Scalars['Int']['input']>;
  maxUsersGoing?: InputMaybe<Scalars['Int']['input']>;
  minTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  org?: InputMaybe<OrgCreateNestedOneWithoutEventsInput>;
  pools?: InputMaybe<PoolCreateNestedManyWithoutEventInput>;
  privacy?: InputMaybe<EventPrivacy>;
  sport: Sport;
  stages?: InputMaybe<StageCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTimeISO']['input'];
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutEventInput>;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  type: EventType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type EventGroupBy = {
  __typename?: 'EventGroupBy';
  _avg?: Maybe<EventAvgAggregate>;
  _count?: Maybe<EventCountAggregate>;
  _max?: Maybe<EventMaxAggregate>;
  _min?: Maybe<EventMinAggregate>;
  _sum?: Maybe<EventSumAggregate>;
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUserId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  endDate: Scalars['DateTimeISO']['output'];
  gender: Gender;
  id: Scalars['String']['output'];
  locationId?: Maybe<Scalars['String']['output']>;
  maxTeams?: Maybe<Scalars['Int']['output']>;
  maxUsersGoing?: Maybe<Scalars['Int']['output']>;
  minTeams?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  privacy?: Maybe<EventPrivacy>;
  sport: Sport;
  startDate: Scalars['DateTimeISO']['output'];
  teamSize?: Maybe<Scalars['Int']['output']>;
  type: EventType;
  updatedAt: Scalars['DateTimeISO']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type EventListRelationFilter = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type EventMaxAggregate = {
  __typename?: 'EventMaxAggregate';
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTimeISO']['output']>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  maxTeams?: Maybe<Scalars['Int']['output']>;
  maxUsersGoing?: Maybe<Scalars['Int']['output']>;
  minTeams?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  privacy?: Maybe<EventPrivacy>;
  sport?: Maybe<Sport>;
  startDate?: Maybe<Scalars['DateTimeISO']['output']>;
  teamSize?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<EventType>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type EventMaxOrderByAggregateInput = {
  cost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdByUserId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  maxTeams?: InputMaybe<SortOrder>;
  maxUsersGoing?: InputMaybe<SortOrder>;
  minTeams?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orgId?: InputMaybe<SortOrder>;
  privacy?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  teamSize?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type EventMinAggregate = {
  __typename?: 'EventMinAggregate';
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTimeISO']['output']>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  maxTeams?: Maybe<Scalars['Int']['output']>;
  maxUsersGoing?: Maybe<Scalars['Int']['output']>;
  minTeams?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  privacy?: Maybe<EventPrivacy>;
  sport?: Maybe<Sport>;
  startDate?: Maybe<Scalars['DateTimeISO']['output']>;
  teamSize?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<EventType>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type EventMinOrderByAggregateInput = {
  cost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdByUserId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  maxTeams?: InputMaybe<SortOrder>;
  maxUsersGoing?: InputMaybe<SortOrder>;
  minTeams?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orgId?: InputMaybe<SortOrder>;
  privacy?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  teamSize?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type EventNullableRelationFilter = {
  is?: InputMaybe<EventWhereInput>;
  isNot?: InputMaybe<EventWhereInput>;
};

export type EventOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EventOrderByWithAggregationInput = {
  _avg?: InputMaybe<EventAvgOrderByAggregateInput>;
  _count?: InputMaybe<EventCountOrderByAggregateInput>;
  _max?: InputMaybe<EventMaxOrderByAggregateInput>;
  _min?: InputMaybe<EventMinOrderByAggregateInput>;
  _sum?: InputMaybe<EventSumOrderByAggregateInput>;
  cost?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdByUserId?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrderInput>;
  maxTeams?: InputMaybe<SortOrderInput>;
  maxUsersGoing?: InputMaybe<SortOrderInput>;
  minTeams?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  orgId?: InputMaybe<SortOrderInput>;
  privacy?: InputMaybe<SortOrderInput>;
  sport?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  teamSize?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
};

export type EventOrderByWithRelationInput = {
  cost?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdByUser?: InputMaybe<UserOrderByWithRelationInput>;
  createdByUserId?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  eventUsers?: InputMaybe<UserEventOrderByRelationAggregateInput>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invitedUsers?: InputMaybe<UserEventInviteOrderByRelationAggregateInput>;
  location?: InputMaybe<LocationOrderByWithRelationInput>;
  locationId?: InputMaybe<SortOrderInput>;
  matches?: InputMaybe<MatchOrderByRelationAggregateInput>;
  maxTeams?: InputMaybe<SortOrderInput>;
  maxUsersGoing?: InputMaybe<SortOrderInput>;
  minTeams?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  org?: InputMaybe<OrgOrderByWithRelationInput>;
  orgId?: InputMaybe<SortOrderInput>;
  pools?: InputMaybe<PoolOrderByRelationAggregateInput>;
  privacy?: InputMaybe<SortOrderInput>;
  sport?: InputMaybe<SortOrder>;
  stages?: InputMaybe<StageOrderByRelationAggregateInput>;
  startDate?: InputMaybe<SortOrder>;
  teamEvents?: InputMaybe<TeamEventOrderByRelationAggregateInput>;
  teamSize?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
  userTeamEvents?: InputMaybe<UserTeamEventOrderByRelationAggregateInput>;
};

export enum EventPrivacy {
  InviteOnlyAdmin = 'INVITE_ONLY_ADMIN',
  InviteOnlyAll = 'INVITE_ONLY_ALL',
  InviteOnlyAllWLink = 'INVITE_ONLY_ALL_W_LINK',
  Public = 'PUBLIC',
  ViewOnly = 'VIEW_ONLY',
}

export type EventRelationFilter = {
  is?: InputMaybe<EventWhereInput>;
  isNot?: InputMaybe<EventWhereInput>;
};

export enum EventScalarFieldEnum {
  Cost = 'cost',
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  Description = 'description',
  EndDate = 'endDate',
  Gender = 'gender',
  Id = 'id',
  LocationId = 'locationId',
  MaxTeams = 'maxTeams',
  MaxUsersGoing = 'maxUsersGoing',
  MinTeams = 'minTeams',
  Name = 'name',
  OrgId = 'orgId',
  Privacy = 'privacy',
  Sport = 'sport',
  StartDate = 'startDate',
  TeamSize = 'teamSize',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Url = 'url',
}

export type EventScalarWhereInput = {
  AND?: InputMaybe<Array<EventScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventScalarWhereInput>>;
  OR?: InputMaybe<Array<EventScalarWhereInput>>;
  cost?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  gender?: InputMaybe<EnumGenderFilter>;
  id?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<StringNullableFilter>;
  maxTeams?: InputMaybe<IntNullableFilter>;
  maxUsersGoing?: InputMaybe<IntNullableFilter>;
  minTeams?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  orgId?: InputMaybe<StringNullableFilter>;
  privacy?: InputMaybe<EnumEventPrivacyNullableFilter>;
  sport?: InputMaybe<EnumSportFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  teamSize?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<EnumEventTypeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type EventScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EventScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EventScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EventScalarWhereWithAggregatesInput>>;
  cost?: InputMaybe<IntNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  createdByUserId?: InputMaybe<StringNullableWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  endDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  gender?: InputMaybe<EnumGenderWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  locationId?: InputMaybe<StringNullableWithAggregatesFilter>;
  maxTeams?: InputMaybe<IntNullableWithAggregatesFilter>;
  maxUsersGoing?: InputMaybe<IntNullableWithAggregatesFilter>;
  minTeams?: InputMaybe<IntNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  orgId?: InputMaybe<StringNullableWithAggregatesFilter>;
  privacy?: InputMaybe<EnumEventPrivacyNullableWithAggregatesFilter>;
  sport?: InputMaybe<EnumSportWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  teamSize?: InputMaybe<IntNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumEventTypeWithAggregatesFilter>;
  url?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type EventSumAggregate = {
  __typename?: 'EventSumAggregate';
  cost?: Maybe<Scalars['Int']['output']>;
  maxTeams?: Maybe<Scalars['Int']['output']>;
  maxUsersGoing?: Maybe<Scalars['Int']['output']>;
  minTeams?: Maybe<Scalars['Int']['output']>;
  teamSize?: Maybe<Scalars['Int']['output']>;
};

export type EventSumOrderByAggregateInput = {
  cost?: InputMaybe<SortOrder>;
  maxTeams?: InputMaybe<SortOrder>;
  maxUsersGoing?: InputMaybe<SortOrder>;
  minTeams?: InputMaybe<SortOrder>;
  teamSize?: InputMaybe<SortOrder>;
};

export enum EventType {
  League = 'LEAGUE',
  Pickup = 'PICKUP',
  Tournament = 'TOURNAMENT',
}

export type EventUpdateInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutCreatedEventsNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventUsers?: InputMaybe<UserEventUpdateManyWithoutEventNestedInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserEventInviteUpdateManyWithoutEventNestedInput>;
  location?: InputMaybe<LocationUpdateOneWithoutEventsNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutEventNestedInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  org?: InputMaybe<OrgUpdateOneWithoutEventsNestedInput>;
  pools?: InputMaybe<PoolUpdateManyWithoutEventNestedInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  stages?: InputMaybe<StageUpdateManyWithoutEventNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutEventNestedInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateManyMutationInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateManyWithWhereWithoutCreatedByUserInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithWhereWithoutLocationInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithWhereWithoutOrgInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EventCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<EventCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<EventCreateManyCreatedByUserInputEnvelope>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<
    Array<EventUpdateWithWhereUniqueWithoutCreatedByUserInput>
  >;
  updateMany?: InputMaybe<
    Array<EventUpdateManyWithWhereWithoutCreatedByUserInput>
  >;
  upsert?: InputMaybe<
    Array<EventUpsertWithWhereUniqueWithoutCreatedByUserInput>
  >;
};

export type EventUpdateManyWithoutLocationNestedInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutLocationInput>>;
  create?: InputMaybe<Array<EventCreateWithoutLocationInput>>;
  createMany?: InputMaybe<EventCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutLocationInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutLocationInput>>;
};

export type EventUpdateManyWithoutOrgNestedInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutOrgInput>>;
  create?: InputMaybe<Array<EventCreateWithoutOrgInput>>;
  createMany?: InputMaybe<EventCreateManyOrgInputEnvelope>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutOrgInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutOrgInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutOrgInput>>;
};

export type EventUpdateOneRequiredWithoutEventUsersNestedInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutEventUsersInput>;
  create?: InputMaybe<EventCreateWithoutEventUsersInput>;
  update?: InputMaybe<EventUpdateToOneWithWhereWithoutEventUsersInput>;
  upsert?: InputMaybe<EventUpsertWithoutEventUsersInput>;
};

export type EventUpdateOneRequiredWithoutInvitedUsersNestedInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutInvitedUsersInput>;
  create?: InputMaybe<EventCreateWithoutInvitedUsersInput>;
  update?: InputMaybe<EventUpdateToOneWithWhereWithoutInvitedUsersInput>;
  upsert?: InputMaybe<EventUpsertWithoutInvitedUsersInput>;
};

export type EventUpdateOneRequiredWithoutPoolsNestedInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutPoolsInput>;
  create?: InputMaybe<EventCreateWithoutPoolsInput>;
  update?: InputMaybe<EventUpdateToOneWithWhereWithoutPoolsInput>;
  upsert?: InputMaybe<EventUpsertWithoutPoolsInput>;
};

export type EventUpdateOneRequiredWithoutStagesNestedInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutStagesInput>;
  create?: InputMaybe<EventCreateWithoutStagesInput>;
  update?: InputMaybe<EventUpdateToOneWithWhereWithoutStagesInput>;
  upsert?: InputMaybe<EventUpsertWithoutStagesInput>;
};

export type EventUpdateOneRequiredWithoutUserTeamEventsNestedInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutUserTeamEventsInput>;
  create?: InputMaybe<EventCreateWithoutUserTeamEventsInput>;
  update?: InputMaybe<EventUpdateToOneWithWhereWithoutUserTeamEventsInput>;
  upsert?: InputMaybe<EventUpsertWithoutUserTeamEventsInput>;
};

export type EventUpdateOneWithoutMatchesNestedInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutMatchesInput>;
  create?: InputMaybe<EventCreateWithoutMatchesInput>;
  delete?: InputMaybe<EventWhereInput>;
  disconnect?: InputMaybe<EventWhereInput>;
  update?: InputMaybe<EventUpdateToOneWithWhereWithoutMatchesInput>;
  upsert?: InputMaybe<EventUpsertWithoutMatchesInput>;
};

export type EventUpdateOneWithoutTeamEventsNestedInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutTeamEventsInput>;
  create?: InputMaybe<EventCreateWithoutTeamEventsInput>;
  delete?: InputMaybe<EventWhereInput>;
  disconnect?: InputMaybe<EventWhereInput>;
  update?: InputMaybe<EventUpdateToOneWithWhereWithoutTeamEventsInput>;
  upsert?: InputMaybe<EventUpsertWithoutTeamEventsInput>;
};

export type EventUpdateToOneWithWhereWithoutEventUsersInput = {
  data: EventUpdateWithoutEventUsersInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpdateToOneWithWhereWithoutInvitedUsersInput = {
  data: EventUpdateWithoutInvitedUsersInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpdateToOneWithWhereWithoutMatchesInput = {
  data: EventUpdateWithoutMatchesInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpdateToOneWithWhereWithoutPoolsInput = {
  data: EventUpdateWithoutPoolsInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpdateToOneWithWhereWithoutStagesInput = {
  data: EventUpdateWithoutStagesInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpdateToOneWithWhereWithoutTeamEventsInput = {
  data: EventUpdateWithoutTeamEventsInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpdateToOneWithWhereWithoutUserTeamEventsInput = {
  data: EventUpdateWithoutUserTeamEventsInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  data: EventUpdateWithoutCreatedByUserInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithWhereUniqueWithoutLocationInput = {
  data: EventUpdateWithoutLocationInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithWhereUniqueWithoutOrgInput = {
  data: EventUpdateWithoutOrgInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithoutCreatedByUserInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventUsers?: InputMaybe<UserEventUpdateManyWithoutEventNestedInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserEventInviteUpdateManyWithoutEventNestedInput>;
  location?: InputMaybe<LocationUpdateOneWithoutEventsNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutEventNestedInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  org?: InputMaybe<OrgUpdateOneWithoutEventsNestedInput>;
  pools?: InputMaybe<PoolUpdateManyWithoutEventNestedInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  stages?: InputMaybe<StageUpdateManyWithoutEventNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutEventNestedInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutEventUsersInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutCreatedEventsNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserEventInviteUpdateManyWithoutEventNestedInput>;
  location?: InputMaybe<LocationUpdateOneWithoutEventsNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutEventNestedInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  org?: InputMaybe<OrgUpdateOneWithoutEventsNestedInput>;
  pools?: InputMaybe<PoolUpdateManyWithoutEventNestedInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  stages?: InputMaybe<StageUpdateManyWithoutEventNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutEventNestedInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutInvitedUsersInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutCreatedEventsNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventUsers?: InputMaybe<UserEventUpdateManyWithoutEventNestedInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneWithoutEventsNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutEventNestedInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  org?: InputMaybe<OrgUpdateOneWithoutEventsNestedInput>;
  pools?: InputMaybe<PoolUpdateManyWithoutEventNestedInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  stages?: InputMaybe<StageUpdateManyWithoutEventNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutEventNestedInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutLocationInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutCreatedEventsNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventUsers?: InputMaybe<UserEventUpdateManyWithoutEventNestedInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserEventInviteUpdateManyWithoutEventNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutEventNestedInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  org?: InputMaybe<OrgUpdateOneWithoutEventsNestedInput>;
  pools?: InputMaybe<PoolUpdateManyWithoutEventNestedInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  stages?: InputMaybe<StageUpdateManyWithoutEventNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutEventNestedInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutMatchesInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutCreatedEventsNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventUsers?: InputMaybe<UserEventUpdateManyWithoutEventNestedInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserEventInviteUpdateManyWithoutEventNestedInput>;
  location?: InputMaybe<LocationUpdateOneWithoutEventsNestedInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  org?: InputMaybe<OrgUpdateOneWithoutEventsNestedInput>;
  pools?: InputMaybe<PoolUpdateManyWithoutEventNestedInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  stages?: InputMaybe<StageUpdateManyWithoutEventNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutEventNestedInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutOrgInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutCreatedEventsNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventUsers?: InputMaybe<UserEventUpdateManyWithoutEventNestedInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserEventInviteUpdateManyWithoutEventNestedInput>;
  location?: InputMaybe<LocationUpdateOneWithoutEventsNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutEventNestedInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  pools?: InputMaybe<PoolUpdateManyWithoutEventNestedInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  stages?: InputMaybe<StageUpdateManyWithoutEventNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutEventNestedInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutPoolsInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutCreatedEventsNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventUsers?: InputMaybe<UserEventUpdateManyWithoutEventNestedInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserEventInviteUpdateManyWithoutEventNestedInput>;
  location?: InputMaybe<LocationUpdateOneWithoutEventsNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutEventNestedInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  org?: InputMaybe<OrgUpdateOneWithoutEventsNestedInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  stages?: InputMaybe<StageUpdateManyWithoutEventNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutEventNestedInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutStagesInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutCreatedEventsNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventUsers?: InputMaybe<UserEventUpdateManyWithoutEventNestedInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserEventInviteUpdateManyWithoutEventNestedInput>;
  location?: InputMaybe<LocationUpdateOneWithoutEventsNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutEventNestedInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  org?: InputMaybe<OrgUpdateOneWithoutEventsNestedInput>;
  pools?: InputMaybe<PoolUpdateManyWithoutEventNestedInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutEventNestedInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutTeamEventsInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutCreatedEventsNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventUsers?: InputMaybe<UserEventUpdateManyWithoutEventNestedInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserEventInviteUpdateManyWithoutEventNestedInput>;
  location?: InputMaybe<LocationUpdateOneWithoutEventsNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutEventNestedInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  org?: InputMaybe<OrgUpdateOneWithoutEventsNestedInput>;
  pools?: InputMaybe<PoolUpdateManyWithoutEventNestedInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  stages?: InputMaybe<StageUpdateManyWithoutEventNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutUserTeamEventsInput = {
  cost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutCreatedEventsNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventUsers?: InputMaybe<UserEventUpdateManyWithoutEventNestedInput>;
  gender?: InputMaybe<EnumGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserEventInviteUpdateManyWithoutEventNestedInput>;
  location?: InputMaybe<LocationUpdateOneWithoutEventsNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutEventNestedInput>;
  maxTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUsersGoing?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minTeams?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  org?: InputMaybe<OrgUpdateOneWithoutEventsNestedInput>;
  pools?: InputMaybe<PoolUpdateManyWithoutEventNestedInput>;
  privacy?: InputMaybe<NullableEnumEventPrivacyFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  stages?: InputMaybe<StageUpdateManyWithoutEventNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutEventNestedInput>;
  teamSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEventTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  create: EventCreateWithoutCreatedByUserInput;
  update: EventUpdateWithoutCreatedByUserInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithWhereUniqueWithoutLocationInput = {
  create: EventCreateWithoutLocationInput;
  update: EventUpdateWithoutLocationInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithWhereUniqueWithoutOrgInput = {
  create: EventCreateWithoutOrgInput;
  update: EventUpdateWithoutOrgInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithoutEventUsersInput = {
  create: EventCreateWithoutEventUsersInput;
  update: EventUpdateWithoutEventUsersInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpsertWithoutInvitedUsersInput = {
  create: EventCreateWithoutInvitedUsersInput;
  update: EventUpdateWithoutInvitedUsersInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpsertWithoutMatchesInput = {
  create: EventCreateWithoutMatchesInput;
  update: EventUpdateWithoutMatchesInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpsertWithoutPoolsInput = {
  create: EventCreateWithoutPoolsInput;
  update: EventUpdateWithoutPoolsInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpsertWithoutStagesInput = {
  create: EventCreateWithoutStagesInput;
  update: EventUpdateWithoutStagesInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpsertWithoutTeamEventsInput = {
  create: EventCreateWithoutTeamEventsInput;
  update: EventUpdateWithoutTeamEventsInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventUpsertWithoutUserTeamEventsInput = {
  create: EventCreateWithoutUserTeamEventsInput;
  update: EventUpdateWithoutUserTeamEventsInput;
  where?: InputMaybe<EventWhereInput>;
};

export type EventWhereInput = {
  AND?: InputMaybe<Array<EventWhereInput>>;
  NOT?: InputMaybe<Array<EventWhereInput>>;
  OR?: InputMaybe<Array<EventWhereInput>>;
  cost?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUser?: InputMaybe<UserNullableRelationFilter>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  eventUsers?: InputMaybe<UserEventListRelationFilter>;
  gender?: InputMaybe<EnumGenderFilter>;
  id?: InputMaybe<StringFilter>;
  invitedUsers?: InputMaybe<UserEventInviteListRelationFilter>;
  location?: InputMaybe<LocationNullableRelationFilter>;
  locationId?: InputMaybe<StringNullableFilter>;
  matches?: InputMaybe<MatchListRelationFilter>;
  maxTeams?: InputMaybe<IntNullableFilter>;
  maxUsersGoing?: InputMaybe<IntNullableFilter>;
  minTeams?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  org?: InputMaybe<OrgNullableRelationFilter>;
  orgId?: InputMaybe<StringNullableFilter>;
  pools?: InputMaybe<PoolListRelationFilter>;
  privacy?: InputMaybe<EnumEventPrivacyNullableFilter>;
  sport?: InputMaybe<EnumSportFilter>;
  stages?: InputMaybe<StageListRelationFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  teamEvents?: InputMaybe<TeamEventListRelationFilter>;
  teamSize?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<EnumEventTypeFilter>;
  url?: InputMaybe<StringNullableFilter>;
  userTeamEvents?: InputMaybe<UserTeamEventListRelationFilter>;
};

export type EventWhereUniqueInput = {
  AND?: InputMaybe<Array<EventWhereInput>>;
  NOT?: InputMaybe<Array<EventWhereInput>>;
  OR?: InputMaybe<Array<EventWhereInput>>;
  cost?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUser?: InputMaybe<UserNullableRelationFilter>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  eventUsers?: InputMaybe<UserEventListRelationFilter>;
  gender?: InputMaybe<EnumGenderFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserEventInviteListRelationFilter>;
  location?: InputMaybe<LocationNullableRelationFilter>;
  locationId?: InputMaybe<StringNullableFilter>;
  matches?: InputMaybe<MatchListRelationFilter>;
  maxTeams?: InputMaybe<IntNullableFilter>;
  maxUsersGoing?: InputMaybe<IntNullableFilter>;
  minTeams?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  org?: InputMaybe<OrgNullableRelationFilter>;
  orgId?: InputMaybe<StringNullableFilter>;
  pools?: InputMaybe<PoolListRelationFilter>;
  privacy?: InputMaybe<EnumEventPrivacyNullableFilter>;
  sport?: InputMaybe<EnumSportFilter>;
  stages?: InputMaybe<StageListRelationFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  teamEvents?: InputMaybe<TeamEventListRelationFilter>;
  teamSize?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<EnumEventTypeFilter>;
  url?: InputMaybe<StringNullableFilter>;
  userTeamEvents?: InputMaybe<UserTeamEventListRelationFilter>;
};

export enum Gender {
  Coed = 'COED',
  Men = 'MEN',
  Women = 'WOMEN',
}

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum InviteStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type Location = {
  __typename?: 'Location';
  OrgLocations: Array<OrgLocation>;
  _count?: Maybe<LocationCount>;
  address?: Maybe<Scalars['String']['output']>;
  court: Array<Court>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  events: Array<Event>;
  id: Scalars['String']['output'];
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  latitude: Scalars['Decimal']['output'];
  longitude: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type LocationOrgLocationsArgs = {
  cursor?: InputMaybe<OrgLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrgLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrgLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgLocationWhereInput>;
};

export type LocationCourtArgs = {
  cursor?: InputMaybe<CourtWhereUniqueInput>;
  distinct?: InputMaybe<Array<CourtScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CourtOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CourtWhereInput>;
};

export type LocationCreatedByUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type LocationEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type LocationAvgAggregate = {
  __typename?: 'LocationAvgAggregate';
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

export type LocationAvgOrderByAggregateInput = {
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
};

export type LocationCount = {
  __typename?: 'LocationCount';
  OrgLocations: Scalars['Int']['output'];
  court: Scalars['Int']['output'];
  events: Scalars['Int']['output'];
};

export type LocationCountOrgLocationsArgs = {
  where?: InputMaybe<OrgLocationWhereInput>;
};

export type LocationCountCourtArgs = {
  where?: InputMaybe<CourtWhereInput>;
};

export type LocationCountEventsArgs = {
  where?: InputMaybe<EventWhereInput>;
};

export type LocationCountAggregate = {
  __typename?: 'LocationCountAggregate';
  _all: Scalars['Int']['output'];
  address: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  createdByUserId: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isVerified: Scalars['Int']['output'];
  latitude: Scalars['Int']['output'];
  longitude: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type LocationCountOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  createdByUserId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isVerified?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type LocationCreateInput = {
  OrgLocations?: InputMaybe<OrgLocationCreateNestedManyWithoutLocationInput>;
  address?: InputMaybe<Scalars['String']['input']>;
  court?: InputMaybe<CourtCreateNestedManyWithoutLocationInput>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutLocationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<EventCreateNestedManyWithoutLocationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LocationCreateManyCreatedByUserInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LocationCreateManyCreatedByUserInputEnvelope = {
  data: Array<LocationCreateManyCreatedByUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LocationCreateManyInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  createdByUserId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LocationCreateNestedManyWithoutCreatedByUserInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LocationCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<LocationCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<LocationCreateManyCreatedByUserInputEnvelope>;
};

export type LocationCreateNestedOneWithoutCourtInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutCourtInput>;
  create?: InputMaybe<LocationCreateWithoutCourtInput>;
};

export type LocationCreateNestedOneWithoutEventsInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutEventsInput>;
  create?: InputMaybe<LocationCreateWithoutEventsInput>;
};

export type LocationCreateNestedOneWithoutOrgLocationsInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutOrgLocationsInput>;
  create?: InputMaybe<LocationCreateWithoutOrgLocationsInput>;
};

export type LocationCreateOrConnectWithoutCourtInput = {
  create: LocationCreateWithoutCourtInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutCreatedByUserInput = {
  create: LocationCreateWithoutCreatedByUserInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutEventsInput = {
  create: LocationCreateWithoutEventsInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutOrgLocationsInput = {
  create: LocationCreateWithoutOrgLocationsInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateWithoutCourtInput = {
  OrgLocations?: InputMaybe<OrgLocationCreateNestedManyWithoutLocationInput>;
  address?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutLocationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<EventCreateNestedManyWithoutLocationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LocationCreateWithoutCreatedByUserInput = {
  OrgLocations?: InputMaybe<OrgLocationCreateNestedManyWithoutLocationInput>;
  address?: InputMaybe<Scalars['String']['input']>;
  court?: InputMaybe<CourtCreateNestedManyWithoutLocationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<EventCreateNestedManyWithoutLocationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LocationCreateWithoutEventsInput = {
  OrgLocations?: InputMaybe<OrgLocationCreateNestedManyWithoutLocationInput>;
  address?: InputMaybe<Scalars['String']['input']>;
  court?: InputMaybe<CourtCreateNestedManyWithoutLocationInput>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutLocationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LocationCreateWithoutOrgLocationsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  court?: InputMaybe<CourtCreateNestedManyWithoutLocationInput>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutLocationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<EventCreateNestedManyWithoutLocationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LocationGroupBy = {
  __typename?: 'LocationGroupBy';
  _avg?: Maybe<LocationAvgAggregate>;
  _count?: Maybe<LocationCountAggregate>;
  _max?: Maybe<LocationMaxAggregate>;
  _min?: Maybe<LocationMinAggregate>;
  _sum?: Maybe<LocationSumAggregate>;
  address?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUserId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  latitude: Scalars['Decimal']['output'];
  longitude: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type LocationListRelationFilter = {
  every?: InputMaybe<LocationWhereInput>;
  none?: InputMaybe<LocationWhereInput>;
  some?: InputMaybe<LocationWhereInput>;
};

export type LocationLongitudeLatitudeCompoundUniqueInput = {
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
};

export type LocationMaxAggregate = {
  __typename?: 'LocationMaxAggregate';
  address?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LocationMaxOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  createdByUserId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isVerified?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type LocationMinAggregate = {
  __typename?: 'LocationMinAggregate';
  address?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LocationMinOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  createdByUserId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isVerified?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type LocationNullableRelationFilter = {
  is?: InputMaybe<LocationWhereInput>;
  isNot?: InputMaybe<LocationWhereInput>;
};

export type LocationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LocationOrderByWithAggregationInput = {
  _avg?: InputMaybe<LocationAvgOrderByAggregateInput>;
  _count?: InputMaybe<LocationCountOrderByAggregateInput>;
  _max?: InputMaybe<LocationMaxOrderByAggregateInput>;
  _min?: InputMaybe<LocationMinOrderByAggregateInput>;
  _sum?: InputMaybe<LocationSumOrderByAggregateInput>;
  address?: InputMaybe<SortOrderInput>;
  createdByUserId?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isVerified?: InputMaybe<SortOrderInput>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
};

export type LocationOrderByWithRelationInput = {
  OrgLocations?: InputMaybe<OrgLocationOrderByRelationAggregateInput>;
  address?: InputMaybe<SortOrderInput>;
  court?: InputMaybe<CourtOrderByRelationAggregateInput>;
  createdByUser?: InputMaybe<UserOrderByWithRelationInput>;
  createdByUserId?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  events?: InputMaybe<EventOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isVerified?: InputMaybe<SortOrderInput>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
};

export type LocationRelationFilter = {
  is?: InputMaybe<LocationWhereInput>;
  isNot?: InputMaybe<LocationWhereInput>;
};

export enum LocationScalarFieldEnum {
  Address = 'address',
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  Description = 'description',
  Id = 'id',
  IsVerified = 'isVerified',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Url = 'url',
}

export type LocationScalarWhereInput = {
  AND?: InputMaybe<Array<LocationScalarWhereInput>>;
  NOT?: InputMaybe<Array<LocationScalarWhereInput>>;
  OR?: InputMaybe<Array<LocationScalarWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isVerified?: InputMaybe<BoolNullableFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type LocationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LocationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LocationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LocationScalarWhereWithAggregatesInput>>;
  address?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdByUserId?: InputMaybe<StringNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isVerified?: InputMaybe<BoolNullableWithAggregatesFilter>;
  latitude?: InputMaybe<DecimalWithAggregatesFilter>;
  longitude?: InputMaybe<DecimalWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  url?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type LocationSumAggregate = {
  __typename?: 'LocationSumAggregate';
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

export type LocationSumOrderByAggregateInput = {
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
};

export type LocationUpdateInput = {
  OrgLocations?: InputMaybe<OrgLocationUpdateManyWithoutLocationNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  court?: InputMaybe<CourtUpdateManyWithoutLocationNestedInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutLocationNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutLocationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateManyMutationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateManyWithWhereWithoutCreatedByUserInput = {
  data: LocationUpdateManyMutationInput;
  where: LocationScalarWhereInput;
};

export type LocationUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LocationCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<LocationCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<LocationCreateManyCreatedByUserInputEnvelope>;
  delete?: InputMaybe<Array<LocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  set?: InputMaybe<Array<LocationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LocationUpdateWithWhereUniqueWithoutCreatedByUserInput>
  >;
  updateMany?: InputMaybe<
    Array<LocationUpdateManyWithWhereWithoutCreatedByUserInput>
  >;
  upsert?: InputMaybe<
    Array<LocationUpsertWithWhereUniqueWithoutCreatedByUserInput>
  >;
};

export type LocationUpdateOneRequiredWithoutCourtNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutCourtInput>;
  create?: InputMaybe<LocationCreateWithoutCourtInput>;
  update?: InputMaybe<LocationUpdateToOneWithWhereWithoutCourtInput>;
  upsert?: InputMaybe<LocationUpsertWithoutCourtInput>;
};

export type LocationUpdateOneRequiredWithoutOrgLocationsNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutOrgLocationsInput>;
  create?: InputMaybe<LocationCreateWithoutOrgLocationsInput>;
  update?: InputMaybe<LocationUpdateToOneWithWhereWithoutOrgLocationsInput>;
  upsert?: InputMaybe<LocationUpsertWithoutOrgLocationsInput>;
};

export type LocationUpdateOneWithoutEventsNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutEventsInput>;
  create?: InputMaybe<LocationCreateWithoutEventsInput>;
  delete?: InputMaybe<LocationWhereInput>;
  disconnect?: InputMaybe<LocationWhereInput>;
  update?: InputMaybe<LocationUpdateToOneWithWhereWithoutEventsInput>;
  upsert?: InputMaybe<LocationUpsertWithoutEventsInput>;
};

export type LocationUpdateToOneWithWhereWithoutCourtInput = {
  data: LocationUpdateWithoutCourtInput;
  where?: InputMaybe<LocationWhereInput>;
};

export type LocationUpdateToOneWithWhereWithoutEventsInput = {
  data: LocationUpdateWithoutEventsInput;
  where?: InputMaybe<LocationWhereInput>;
};

export type LocationUpdateToOneWithWhereWithoutOrgLocationsInput = {
  data: LocationUpdateWithoutOrgLocationsInput;
  where?: InputMaybe<LocationWhereInput>;
};

export type LocationUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  data: LocationUpdateWithoutCreatedByUserInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpdateWithoutCourtInput = {
  OrgLocations?: InputMaybe<OrgLocationUpdateManyWithoutLocationNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutLocationNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutLocationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutCreatedByUserInput = {
  OrgLocations?: InputMaybe<OrgLocationUpdateManyWithoutLocationNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  court?: InputMaybe<CourtUpdateManyWithoutLocationNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutLocationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutEventsInput = {
  OrgLocations?: InputMaybe<OrgLocationUpdateManyWithoutLocationNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  court?: InputMaybe<CourtUpdateManyWithoutLocationNestedInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutLocationNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutOrgLocationsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  court?: InputMaybe<CourtUpdateManyWithoutLocationNestedInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutLocationNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutLocationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isVerified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LocationUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  create: LocationCreateWithoutCreatedByUserInput;
  update: LocationUpdateWithoutCreatedByUserInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpsertWithoutCourtInput = {
  create: LocationCreateWithoutCourtInput;
  update: LocationUpdateWithoutCourtInput;
  where?: InputMaybe<LocationWhereInput>;
};

export type LocationUpsertWithoutEventsInput = {
  create: LocationCreateWithoutEventsInput;
  update: LocationUpdateWithoutEventsInput;
  where?: InputMaybe<LocationWhereInput>;
};

export type LocationUpsertWithoutOrgLocationsInput = {
  create: LocationCreateWithoutOrgLocationsInput;
  update: LocationUpdateWithoutOrgLocationsInput;
  where?: InputMaybe<LocationWhereInput>;
};

export type LocationWhereInput = {
  AND?: InputMaybe<Array<LocationWhereInput>>;
  NOT?: InputMaybe<Array<LocationWhereInput>>;
  OR?: InputMaybe<Array<LocationWhereInput>>;
  OrgLocations?: InputMaybe<OrgLocationListRelationFilter>;
  address?: InputMaybe<StringNullableFilter>;
  court?: InputMaybe<CourtListRelationFilter>;
  createdByUser?: InputMaybe<UserNullableRelationFilter>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  events?: InputMaybe<EventListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isVerified?: InputMaybe<BoolNullableFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type LocationWhereUniqueInput = {
  AND?: InputMaybe<Array<LocationWhereInput>>;
  NOT?: InputMaybe<Array<LocationWhereInput>>;
  OR?: InputMaybe<Array<LocationWhereInput>>;
  OrgLocations?: InputMaybe<OrgLocationListRelationFilter>;
  address?: InputMaybe<StringNullableFilter>;
  court?: InputMaybe<CourtListRelationFilter>;
  createdByUser?: InputMaybe<UserNullableRelationFilter>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  events?: InputMaybe<EventListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isVerified?: InputMaybe<BoolNullableFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  longitude_latitude?: InputMaybe<LocationLongitudeLatitudeCompoundUniqueInput>;
  name?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type Match = {
  __typename?: 'Match';
  _count?: Maybe<MatchCount>;
  court?: Maybe<Court>;
  courtId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  endDate?: Maybe<Scalars['DateTimeISO']['output']>;
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  nextMatch?: Maybe<Match>;
  nextMatchId?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  playoffRound?: Maybe<Scalars['Int']['output']>;
  previousMatches: Array<Match>;
  rounds: Array<Round>;
  stage?: Maybe<Stage>;
  stageId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTimeISO']['output']>;
  teams: Array<Team>;
  updatedAt: Scalars['DateTimeISO']['output'];
  winningTeam?: Maybe<Team>;
  winningTeamId?: Maybe<Scalars['String']['output']>;
};

export type MatchCourtArgs = {
  where?: InputMaybe<CourtWhereInput>;
};

export type MatchEventArgs = {
  where?: InputMaybe<EventWhereInput>;
};

export type MatchNextMatchArgs = {
  where?: InputMaybe<MatchWhereInput>;
};

export type MatchPreviousMatchesArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MatchWhereInput>;
};

export type MatchRoundsArgs = {
  cursor?: InputMaybe<RoundWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoundWhereInput>;
};

export type MatchStageArgs = {
  where?: InputMaybe<StageWhereInput>;
};

export type MatchTeamsArgs = {
  cursor?: InputMaybe<TeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamWhereInput>;
};

export type MatchWinningTeamArgs = {
  where?: InputMaybe<TeamWhereInput>;
};

export type MatchAvgAggregate = {
  __typename?: 'MatchAvgAggregate';
  order?: Maybe<Scalars['Float']['output']>;
  playoffRound?: Maybe<Scalars['Float']['output']>;
};

export type MatchAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
  playoffRound?: InputMaybe<SortOrder>;
};

export type MatchCount = {
  __typename?: 'MatchCount';
  previousMatches: Scalars['Int']['output'];
  rounds: Scalars['Int']['output'];
  teams: Scalars['Int']['output'];
};

export type MatchCountPreviousMatchesArgs = {
  where?: InputMaybe<MatchWhereInput>;
};

export type MatchCountRoundsArgs = {
  where?: InputMaybe<RoundWhereInput>;
};

export type MatchCountTeamsArgs = {
  where?: InputMaybe<TeamWhereInput>;
};

export type MatchCountAggregate = {
  __typename?: 'MatchCountAggregate';
  _all: Scalars['Int']['output'];
  courtId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  endDate: Scalars['Int']['output'];
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nextMatchId: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  playoffRound: Scalars['Int']['output'];
  stageId: Scalars['Int']['output'];
  startDate: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  winningTeamId: Scalars['Int']['output'];
};

export type MatchCountOrderByAggregateInput = {
  courtId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  nextMatchId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  playoffRound?: InputMaybe<SortOrder>;
  stageId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  winningTeamId?: InputMaybe<SortOrder>;
};

export type MatchCreateInput = {
  court?: InputMaybe<CourtCreateNestedOneWithoutMatchInput>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event?: InputMaybe<EventCreateNestedOneWithoutMatchesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatch?: InputMaybe<MatchCreateNestedOneWithoutPreviousMatchesInput>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  previousMatches?: InputMaybe<MatchCreateNestedManyWithoutNextMatchInput>;
  rounds?: InputMaybe<RoundCreateNestedManyWithoutMatchInput>;
  stage?: InputMaybe<StageCreateNestedOneWithoutMatchesInput>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMatchesInput>;
  winningTeam?: InputMaybe<TeamCreateNestedOneWithoutWonMatchesInput>;
};

export type MatchCreateManyCourtInput = {
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatchId?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  stageId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  winningTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type MatchCreateManyCourtInputEnvelope = {
  data: Array<MatchCreateManyCourtInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MatchCreateManyEventInput = {
  courtId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatchId?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  stageId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  winningTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type MatchCreateManyEventInputEnvelope = {
  data: Array<MatchCreateManyEventInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MatchCreateManyInput = {
  courtId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatchId?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  stageId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  winningTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type MatchCreateManyNextMatchInput = {
  courtId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  stageId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  winningTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type MatchCreateManyNextMatchInputEnvelope = {
  data: Array<MatchCreateManyNextMatchInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MatchCreateManyStageInput = {
  courtId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatchId?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  winningTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type MatchCreateManyStageInputEnvelope = {
  data: Array<MatchCreateManyStageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MatchCreateManyWinningTeamInput = {
  courtId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatchId?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  stageId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type MatchCreateManyWinningTeamInputEnvelope = {
  data: Array<MatchCreateManyWinningTeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MatchCreateNestedManyWithoutCourtInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchCreateOrConnectWithoutCourtInput>>;
  create?: InputMaybe<Array<MatchCreateWithoutCourtInput>>;
  createMany?: InputMaybe<MatchCreateManyCourtInputEnvelope>;
};

export type MatchCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<MatchCreateWithoutEventInput>>;
  createMany?: InputMaybe<MatchCreateManyEventInputEnvelope>;
};

export type MatchCreateNestedManyWithoutNextMatchInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MatchCreateOrConnectWithoutNextMatchInput>
  >;
  create?: InputMaybe<Array<MatchCreateWithoutNextMatchInput>>;
  createMany?: InputMaybe<MatchCreateManyNextMatchInputEnvelope>;
};

export type MatchCreateNestedManyWithoutStageInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchCreateOrConnectWithoutStageInput>>;
  create?: InputMaybe<Array<MatchCreateWithoutStageInput>>;
  createMany?: InputMaybe<MatchCreateManyStageInputEnvelope>;
};

export type MatchCreateNestedManyWithoutTeamsInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchCreateOrConnectWithoutTeamsInput>>;
  create?: InputMaybe<Array<MatchCreateWithoutTeamsInput>>;
};

export type MatchCreateNestedManyWithoutWinningTeamInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MatchCreateOrConnectWithoutWinningTeamInput>
  >;
  create?: InputMaybe<Array<MatchCreateWithoutWinningTeamInput>>;
  createMany?: InputMaybe<MatchCreateManyWinningTeamInputEnvelope>;
};

export type MatchCreateNestedOneWithoutPreviousMatchesInput = {
  connect?: InputMaybe<MatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MatchCreateOrConnectWithoutPreviousMatchesInput>;
  create?: InputMaybe<MatchCreateWithoutPreviousMatchesInput>;
};

export type MatchCreateNestedOneWithoutRoundsInput = {
  connect?: InputMaybe<MatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MatchCreateOrConnectWithoutRoundsInput>;
  create?: InputMaybe<MatchCreateWithoutRoundsInput>;
};

export type MatchCreateOrConnectWithoutCourtInput = {
  create: MatchCreateWithoutCourtInput;
  where: MatchWhereUniqueInput;
};

export type MatchCreateOrConnectWithoutEventInput = {
  create: MatchCreateWithoutEventInput;
  where: MatchWhereUniqueInput;
};

export type MatchCreateOrConnectWithoutNextMatchInput = {
  create: MatchCreateWithoutNextMatchInput;
  where: MatchWhereUniqueInput;
};

export type MatchCreateOrConnectWithoutPreviousMatchesInput = {
  create: MatchCreateWithoutPreviousMatchesInput;
  where: MatchWhereUniqueInput;
};

export type MatchCreateOrConnectWithoutRoundsInput = {
  create: MatchCreateWithoutRoundsInput;
  where: MatchWhereUniqueInput;
};

export type MatchCreateOrConnectWithoutStageInput = {
  create: MatchCreateWithoutStageInput;
  where: MatchWhereUniqueInput;
};

export type MatchCreateOrConnectWithoutTeamsInput = {
  create: MatchCreateWithoutTeamsInput;
  where: MatchWhereUniqueInput;
};

export type MatchCreateOrConnectWithoutWinningTeamInput = {
  create: MatchCreateWithoutWinningTeamInput;
  where: MatchWhereUniqueInput;
};

export type MatchCreateWithoutCourtInput = {
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event?: InputMaybe<EventCreateNestedOneWithoutMatchesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatch?: InputMaybe<MatchCreateNestedOneWithoutPreviousMatchesInput>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  previousMatches?: InputMaybe<MatchCreateNestedManyWithoutNextMatchInput>;
  rounds?: InputMaybe<RoundCreateNestedManyWithoutMatchInput>;
  stage?: InputMaybe<StageCreateNestedOneWithoutMatchesInput>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMatchesInput>;
  winningTeam?: InputMaybe<TeamCreateNestedOneWithoutWonMatchesInput>;
};

export type MatchCreateWithoutEventInput = {
  court?: InputMaybe<CourtCreateNestedOneWithoutMatchInput>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatch?: InputMaybe<MatchCreateNestedOneWithoutPreviousMatchesInput>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  previousMatches?: InputMaybe<MatchCreateNestedManyWithoutNextMatchInput>;
  rounds?: InputMaybe<RoundCreateNestedManyWithoutMatchInput>;
  stage?: InputMaybe<StageCreateNestedOneWithoutMatchesInput>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMatchesInput>;
  winningTeam?: InputMaybe<TeamCreateNestedOneWithoutWonMatchesInput>;
};

export type MatchCreateWithoutNextMatchInput = {
  court?: InputMaybe<CourtCreateNestedOneWithoutMatchInput>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event?: InputMaybe<EventCreateNestedOneWithoutMatchesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  previousMatches?: InputMaybe<MatchCreateNestedManyWithoutNextMatchInput>;
  rounds?: InputMaybe<RoundCreateNestedManyWithoutMatchInput>;
  stage?: InputMaybe<StageCreateNestedOneWithoutMatchesInput>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMatchesInput>;
  winningTeam?: InputMaybe<TeamCreateNestedOneWithoutWonMatchesInput>;
};

export type MatchCreateWithoutPreviousMatchesInput = {
  court?: InputMaybe<CourtCreateNestedOneWithoutMatchInput>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event?: InputMaybe<EventCreateNestedOneWithoutMatchesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatch?: InputMaybe<MatchCreateNestedOneWithoutPreviousMatchesInput>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  rounds?: InputMaybe<RoundCreateNestedManyWithoutMatchInput>;
  stage?: InputMaybe<StageCreateNestedOneWithoutMatchesInput>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMatchesInput>;
  winningTeam?: InputMaybe<TeamCreateNestedOneWithoutWonMatchesInput>;
};

export type MatchCreateWithoutRoundsInput = {
  court?: InputMaybe<CourtCreateNestedOneWithoutMatchInput>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event?: InputMaybe<EventCreateNestedOneWithoutMatchesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatch?: InputMaybe<MatchCreateNestedOneWithoutPreviousMatchesInput>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  previousMatches?: InputMaybe<MatchCreateNestedManyWithoutNextMatchInput>;
  stage?: InputMaybe<StageCreateNestedOneWithoutMatchesInput>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMatchesInput>;
  winningTeam?: InputMaybe<TeamCreateNestedOneWithoutWonMatchesInput>;
};

export type MatchCreateWithoutStageInput = {
  court?: InputMaybe<CourtCreateNestedOneWithoutMatchInput>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event?: InputMaybe<EventCreateNestedOneWithoutMatchesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatch?: InputMaybe<MatchCreateNestedOneWithoutPreviousMatchesInput>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  previousMatches?: InputMaybe<MatchCreateNestedManyWithoutNextMatchInput>;
  rounds?: InputMaybe<RoundCreateNestedManyWithoutMatchInput>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMatchesInput>;
  winningTeam?: InputMaybe<TeamCreateNestedOneWithoutWonMatchesInput>;
};

export type MatchCreateWithoutTeamsInput = {
  court?: InputMaybe<CourtCreateNestedOneWithoutMatchInput>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event?: InputMaybe<EventCreateNestedOneWithoutMatchesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatch?: InputMaybe<MatchCreateNestedOneWithoutPreviousMatchesInput>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  previousMatches?: InputMaybe<MatchCreateNestedManyWithoutNextMatchInput>;
  rounds?: InputMaybe<RoundCreateNestedManyWithoutMatchInput>;
  stage?: InputMaybe<StageCreateNestedOneWithoutMatchesInput>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  winningTeam?: InputMaybe<TeamCreateNestedOneWithoutWonMatchesInput>;
};

export type MatchCreateWithoutWinningTeamInput = {
  court?: InputMaybe<CourtCreateNestedOneWithoutMatchInput>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  event?: InputMaybe<EventCreateNestedOneWithoutMatchesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatch?: InputMaybe<MatchCreateNestedOneWithoutPreviousMatchesInput>;
  order: Scalars['Int']['input'];
  playoffRound?: InputMaybe<Scalars['Int']['input']>;
  previousMatches?: InputMaybe<MatchCreateNestedManyWithoutNextMatchInput>;
  rounds?: InputMaybe<RoundCreateNestedManyWithoutMatchInput>;
  stage?: InputMaybe<StageCreateNestedOneWithoutMatchesInput>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMatchesInput>;
};

export type MatchGroupBy = {
  __typename?: 'MatchGroupBy';
  _avg?: Maybe<MatchAvgAggregate>;
  _count?: Maybe<MatchCountAggregate>;
  _max?: Maybe<MatchMaxAggregate>;
  _min?: Maybe<MatchMinAggregate>;
  _sum?: Maybe<MatchSumAggregate>;
  courtId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  endDate?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  nextMatchId?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  playoffRound?: Maybe<Scalars['Int']['output']>;
  stageId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  winningTeamId?: Maybe<Scalars['String']['output']>;
};

export type MatchListRelationFilter = {
  every?: InputMaybe<MatchWhereInput>;
  none?: InputMaybe<MatchWhereInput>;
  some?: InputMaybe<MatchWhereInput>;
};

export type MatchMaxAggregate = {
  __typename?: 'MatchMaxAggregate';
  courtId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  endDate?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  nextMatchId?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  playoffRound?: Maybe<Scalars['Int']['output']>;
  stageId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  winningTeamId?: Maybe<Scalars['String']['output']>;
};

export type MatchMaxOrderByAggregateInput = {
  courtId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  nextMatchId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  playoffRound?: InputMaybe<SortOrder>;
  stageId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  winningTeamId?: InputMaybe<SortOrder>;
};

export type MatchMinAggregate = {
  __typename?: 'MatchMinAggregate';
  courtId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  endDate?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  nextMatchId?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  playoffRound?: Maybe<Scalars['Int']['output']>;
  stageId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  winningTeamId?: Maybe<Scalars['String']['output']>;
};

export type MatchMinOrderByAggregateInput = {
  courtId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  nextMatchId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  playoffRound?: InputMaybe<SortOrder>;
  stageId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  winningTeamId?: InputMaybe<SortOrder>;
};

export type MatchNullableRelationFilter = {
  is?: InputMaybe<MatchWhereInput>;
  isNot?: InputMaybe<MatchWhereInput>;
};

export type MatchOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MatchOrderByWithAggregationInput = {
  _avg?: InputMaybe<MatchAvgOrderByAggregateInput>;
  _count?: InputMaybe<MatchCountOrderByAggregateInput>;
  _max?: InputMaybe<MatchMaxOrderByAggregateInput>;
  _min?: InputMaybe<MatchMinOrderByAggregateInput>;
  _sum?: InputMaybe<MatchSumOrderByAggregateInput>;
  courtId?: InputMaybe<SortOrderInput>;
  endDate?: InputMaybe<SortOrderInput>;
  eventId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  nextMatchId?: InputMaybe<SortOrderInput>;
  order?: InputMaybe<SortOrder>;
  playoffRound?: InputMaybe<SortOrderInput>;
  stageId?: InputMaybe<SortOrderInput>;
  startDate?: InputMaybe<SortOrderInput>;
  winningTeamId?: InputMaybe<SortOrderInput>;
};

export type MatchOrderByWithRelationInput = {
  court?: InputMaybe<CourtOrderByWithRelationInput>;
  courtId?: InputMaybe<SortOrderInput>;
  endDate?: InputMaybe<SortOrderInput>;
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  nextMatch?: InputMaybe<MatchOrderByWithRelationInput>;
  nextMatchId?: InputMaybe<SortOrderInput>;
  order?: InputMaybe<SortOrder>;
  playoffRound?: InputMaybe<SortOrderInput>;
  previousMatches?: InputMaybe<MatchOrderByRelationAggregateInput>;
  rounds?: InputMaybe<RoundOrderByRelationAggregateInput>;
  stage?: InputMaybe<StageOrderByWithRelationInput>;
  stageId?: InputMaybe<SortOrderInput>;
  startDate?: InputMaybe<SortOrderInput>;
  teams?: InputMaybe<TeamOrderByRelationAggregateInput>;
  winningTeam?: InputMaybe<TeamOrderByWithRelationInput>;
  winningTeamId?: InputMaybe<SortOrderInput>;
};

export type MatchRelationFilter = {
  is?: InputMaybe<MatchWhereInput>;
  isNot?: InputMaybe<MatchWhereInput>;
};

export enum MatchScalarFieldEnum {
  CourtId = 'courtId',
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  EventId = 'eventId',
  Id = 'id',
  NextMatchId = 'nextMatchId',
  Order = 'order',
  PlayoffRound = 'playoffRound',
  StageId = 'stageId',
  StartDate = 'startDate',
  UpdatedAt = 'updatedAt',
  WinningTeamId = 'winningTeamId',
}

export type MatchScalarWhereInput = {
  AND?: InputMaybe<Array<MatchScalarWhereInput>>;
  NOT?: InputMaybe<Array<MatchScalarWhereInput>>;
  OR?: InputMaybe<Array<MatchScalarWhereInput>>;
  courtId?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  nextMatchId?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<IntFilter>;
  playoffRound?: InputMaybe<IntNullableFilter>;
  stageId?: InputMaybe<StringNullableFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  winningTeamId?: InputMaybe<StringNullableFilter>;
};

export type MatchScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MatchScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MatchScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MatchScalarWhereWithAggregatesInput>>;
  courtId?: InputMaybe<StringNullableWithAggregatesFilter>;
  endDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  eventId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  nextMatchId?: InputMaybe<StringNullableWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  playoffRound?: InputMaybe<IntNullableWithAggregatesFilter>;
  stageId?: InputMaybe<StringNullableWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  winningTeamId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type MatchStageIdOrderCompoundUniqueInput = {
  order: Scalars['Int']['input'];
  stageId: Scalars['String']['input'];
};

export type MatchSumAggregate = {
  __typename?: 'MatchSumAggregate';
  order?: Maybe<Scalars['Int']['output']>;
  playoffRound?: Maybe<Scalars['Int']['output']>;
};

export type MatchSumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
  playoffRound?: InputMaybe<SortOrder>;
};

export type MatchUpdateInput = {
  court?: InputMaybe<CourtUpdateOneWithoutMatchNestedInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutMatchesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nextMatch?: InputMaybe<MatchUpdateOneWithoutPreviousMatchesNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  playoffRound?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  previousMatches?: InputMaybe<MatchUpdateManyWithoutNextMatchNestedInput>;
  rounds?: InputMaybe<RoundUpdateManyWithoutMatchNestedInput>;
  stage?: InputMaybe<StageUpdateOneWithoutMatchesNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMatchesNestedInput>;
  winningTeam?: InputMaybe<TeamUpdateOneWithoutWonMatchesNestedInput>;
};

export type MatchUpdateManyMutationInput = {
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  playoffRound?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type MatchUpdateManyWithWhereWithoutCourtInput = {
  data: MatchUpdateManyMutationInput;
  where: MatchScalarWhereInput;
};

export type MatchUpdateManyWithWhereWithoutEventInput = {
  data: MatchUpdateManyMutationInput;
  where: MatchScalarWhereInput;
};

export type MatchUpdateManyWithWhereWithoutNextMatchInput = {
  data: MatchUpdateManyMutationInput;
  where: MatchScalarWhereInput;
};

export type MatchUpdateManyWithWhereWithoutStageInput = {
  data: MatchUpdateManyMutationInput;
  where: MatchScalarWhereInput;
};

export type MatchUpdateManyWithWhereWithoutTeamsInput = {
  data: MatchUpdateManyMutationInput;
  where: MatchScalarWhereInput;
};

export type MatchUpdateManyWithWhereWithoutWinningTeamInput = {
  data: MatchUpdateManyMutationInput;
  where: MatchScalarWhereInput;
};

export type MatchUpdateManyWithoutCourtNestedInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchCreateOrConnectWithoutCourtInput>>;
  create?: InputMaybe<Array<MatchCreateWithoutCourtInput>>;
  createMany?: InputMaybe<MatchCreateManyCourtInputEnvelope>;
  delete?: InputMaybe<Array<MatchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MatchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  set?: InputMaybe<Array<MatchWhereUniqueInput>>;
  update?: InputMaybe<Array<MatchUpdateWithWhereUniqueWithoutCourtInput>>;
  updateMany?: InputMaybe<Array<MatchUpdateManyWithWhereWithoutCourtInput>>;
  upsert?: InputMaybe<Array<MatchUpsertWithWhereUniqueWithoutCourtInput>>;
};

export type MatchUpdateManyWithoutEventNestedInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<MatchCreateWithoutEventInput>>;
  createMany?: InputMaybe<MatchCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<MatchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MatchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  set?: InputMaybe<Array<MatchWhereUniqueInput>>;
  update?: InputMaybe<Array<MatchUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<MatchUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<MatchUpsertWithWhereUniqueWithoutEventInput>>;
};

export type MatchUpdateManyWithoutNextMatchNestedInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MatchCreateOrConnectWithoutNextMatchInput>
  >;
  create?: InputMaybe<Array<MatchCreateWithoutNextMatchInput>>;
  createMany?: InputMaybe<MatchCreateManyNextMatchInputEnvelope>;
  delete?: InputMaybe<Array<MatchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MatchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  set?: InputMaybe<Array<MatchWhereUniqueInput>>;
  update?: InputMaybe<Array<MatchUpdateWithWhereUniqueWithoutNextMatchInput>>;
  updateMany?: InputMaybe<Array<MatchUpdateManyWithWhereWithoutNextMatchInput>>;
  upsert?: InputMaybe<Array<MatchUpsertWithWhereUniqueWithoutNextMatchInput>>;
};

export type MatchUpdateManyWithoutStageNestedInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchCreateOrConnectWithoutStageInput>>;
  create?: InputMaybe<Array<MatchCreateWithoutStageInput>>;
  createMany?: InputMaybe<MatchCreateManyStageInputEnvelope>;
  delete?: InputMaybe<Array<MatchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MatchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  set?: InputMaybe<Array<MatchWhereUniqueInput>>;
  update?: InputMaybe<Array<MatchUpdateWithWhereUniqueWithoutStageInput>>;
  updateMany?: InputMaybe<Array<MatchUpdateManyWithWhereWithoutStageInput>>;
  upsert?: InputMaybe<Array<MatchUpsertWithWhereUniqueWithoutStageInput>>;
};

export type MatchUpdateManyWithoutTeamsNestedInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchCreateOrConnectWithoutTeamsInput>>;
  create?: InputMaybe<Array<MatchCreateWithoutTeamsInput>>;
  delete?: InputMaybe<Array<MatchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MatchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  set?: InputMaybe<Array<MatchWhereUniqueInput>>;
  update?: InputMaybe<Array<MatchUpdateWithWhereUniqueWithoutTeamsInput>>;
  updateMany?: InputMaybe<Array<MatchUpdateManyWithWhereWithoutTeamsInput>>;
  upsert?: InputMaybe<Array<MatchUpsertWithWhereUniqueWithoutTeamsInput>>;
};

export type MatchUpdateManyWithoutWinningTeamNestedInput = {
  connect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MatchCreateOrConnectWithoutWinningTeamInput>
  >;
  create?: InputMaybe<Array<MatchCreateWithoutWinningTeamInput>>;
  createMany?: InputMaybe<MatchCreateManyWinningTeamInputEnvelope>;
  delete?: InputMaybe<Array<MatchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MatchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MatchWhereUniqueInput>>;
  set?: InputMaybe<Array<MatchWhereUniqueInput>>;
  update?: InputMaybe<Array<MatchUpdateWithWhereUniqueWithoutWinningTeamInput>>;
  updateMany?: InputMaybe<
    Array<MatchUpdateManyWithWhereWithoutWinningTeamInput>
  >;
  upsert?: InputMaybe<Array<MatchUpsertWithWhereUniqueWithoutWinningTeamInput>>;
};

export type MatchUpdateOneRequiredWithoutRoundsNestedInput = {
  connect?: InputMaybe<MatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MatchCreateOrConnectWithoutRoundsInput>;
  create?: InputMaybe<MatchCreateWithoutRoundsInput>;
  update?: InputMaybe<MatchUpdateToOneWithWhereWithoutRoundsInput>;
  upsert?: InputMaybe<MatchUpsertWithoutRoundsInput>;
};

export type MatchUpdateOneWithoutPreviousMatchesNestedInput = {
  connect?: InputMaybe<MatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MatchCreateOrConnectWithoutPreviousMatchesInput>;
  create?: InputMaybe<MatchCreateWithoutPreviousMatchesInput>;
  delete?: InputMaybe<MatchWhereInput>;
  disconnect?: InputMaybe<MatchWhereInput>;
  update?: InputMaybe<MatchUpdateToOneWithWhereWithoutPreviousMatchesInput>;
  upsert?: InputMaybe<MatchUpsertWithoutPreviousMatchesInput>;
};

export type MatchUpdateToOneWithWhereWithoutPreviousMatchesInput = {
  data: MatchUpdateWithoutPreviousMatchesInput;
  where?: InputMaybe<MatchWhereInput>;
};

export type MatchUpdateToOneWithWhereWithoutRoundsInput = {
  data: MatchUpdateWithoutRoundsInput;
  where?: InputMaybe<MatchWhereInput>;
};

export type MatchUpdateWithWhereUniqueWithoutCourtInput = {
  data: MatchUpdateWithoutCourtInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpdateWithWhereUniqueWithoutEventInput = {
  data: MatchUpdateWithoutEventInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpdateWithWhereUniqueWithoutNextMatchInput = {
  data: MatchUpdateWithoutNextMatchInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpdateWithWhereUniqueWithoutStageInput = {
  data: MatchUpdateWithoutStageInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpdateWithWhereUniqueWithoutTeamsInput = {
  data: MatchUpdateWithoutTeamsInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpdateWithWhereUniqueWithoutWinningTeamInput = {
  data: MatchUpdateWithoutWinningTeamInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpdateWithoutCourtInput = {
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutMatchesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nextMatch?: InputMaybe<MatchUpdateOneWithoutPreviousMatchesNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  playoffRound?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  previousMatches?: InputMaybe<MatchUpdateManyWithoutNextMatchNestedInput>;
  rounds?: InputMaybe<RoundUpdateManyWithoutMatchNestedInput>;
  stage?: InputMaybe<StageUpdateOneWithoutMatchesNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMatchesNestedInput>;
  winningTeam?: InputMaybe<TeamUpdateOneWithoutWonMatchesNestedInput>;
};

export type MatchUpdateWithoutEventInput = {
  court?: InputMaybe<CourtUpdateOneWithoutMatchNestedInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nextMatch?: InputMaybe<MatchUpdateOneWithoutPreviousMatchesNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  playoffRound?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  previousMatches?: InputMaybe<MatchUpdateManyWithoutNextMatchNestedInput>;
  rounds?: InputMaybe<RoundUpdateManyWithoutMatchNestedInput>;
  stage?: InputMaybe<StageUpdateOneWithoutMatchesNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMatchesNestedInput>;
  winningTeam?: InputMaybe<TeamUpdateOneWithoutWonMatchesNestedInput>;
};

export type MatchUpdateWithoutNextMatchInput = {
  court?: InputMaybe<CourtUpdateOneWithoutMatchNestedInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutMatchesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  playoffRound?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  previousMatches?: InputMaybe<MatchUpdateManyWithoutNextMatchNestedInput>;
  rounds?: InputMaybe<RoundUpdateManyWithoutMatchNestedInput>;
  stage?: InputMaybe<StageUpdateOneWithoutMatchesNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMatchesNestedInput>;
  winningTeam?: InputMaybe<TeamUpdateOneWithoutWonMatchesNestedInput>;
};

export type MatchUpdateWithoutPreviousMatchesInput = {
  court?: InputMaybe<CourtUpdateOneWithoutMatchNestedInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutMatchesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nextMatch?: InputMaybe<MatchUpdateOneWithoutPreviousMatchesNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  playoffRound?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rounds?: InputMaybe<RoundUpdateManyWithoutMatchNestedInput>;
  stage?: InputMaybe<StageUpdateOneWithoutMatchesNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMatchesNestedInput>;
  winningTeam?: InputMaybe<TeamUpdateOneWithoutWonMatchesNestedInput>;
};

export type MatchUpdateWithoutRoundsInput = {
  court?: InputMaybe<CourtUpdateOneWithoutMatchNestedInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutMatchesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nextMatch?: InputMaybe<MatchUpdateOneWithoutPreviousMatchesNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  playoffRound?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  previousMatches?: InputMaybe<MatchUpdateManyWithoutNextMatchNestedInput>;
  stage?: InputMaybe<StageUpdateOneWithoutMatchesNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMatchesNestedInput>;
  winningTeam?: InputMaybe<TeamUpdateOneWithoutWonMatchesNestedInput>;
};

export type MatchUpdateWithoutStageInput = {
  court?: InputMaybe<CourtUpdateOneWithoutMatchNestedInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutMatchesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nextMatch?: InputMaybe<MatchUpdateOneWithoutPreviousMatchesNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  playoffRound?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  previousMatches?: InputMaybe<MatchUpdateManyWithoutNextMatchNestedInput>;
  rounds?: InputMaybe<RoundUpdateManyWithoutMatchNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMatchesNestedInput>;
  winningTeam?: InputMaybe<TeamUpdateOneWithoutWonMatchesNestedInput>;
};

export type MatchUpdateWithoutTeamsInput = {
  court?: InputMaybe<CourtUpdateOneWithoutMatchNestedInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutMatchesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nextMatch?: InputMaybe<MatchUpdateOneWithoutPreviousMatchesNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  playoffRound?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  previousMatches?: InputMaybe<MatchUpdateManyWithoutNextMatchNestedInput>;
  rounds?: InputMaybe<RoundUpdateManyWithoutMatchNestedInput>;
  stage?: InputMaybe<StageUpdateOneWithoutMatchesNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  winningTeam?: InputMaybe<TeamUpdateOneWithoutWonMatchesNestedInput>;
};

export type MatchUpdateWithoutWinningTeamInput = {
  court?: InputMaybe<CourtUpdateOneWithoutMatchNestedInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutMatchesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nextMatch?: InputMaybe<MatchUpdateOneWithoutPreviousMatchesNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  playoffRound?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  previousMatches?: InputMaybe<MatchUpdateManyWithoutNextMatchNestedInput>;
  rounds?: InputMaybe<RoundUpdateManyWithoutMatchNestedInput>;
  stage?: InputMaybe<StageUpdateOneWithoutMatchesNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMatchesNestedInput>;
};

export type MatchUpsertWithWhereUniqueWithoutCourtInput = {
  create: MatchCreateWithoutCourtInput;
  update: MatchUpdateWithoutCourtInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpsertWithWhereUniqueWithoutEventInput = {
  create: MatchCreateWithoutEventInput;
  update: MatchUpdateWithoutEventInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpsertWithWhereUniqueWithoutNextMatchInput = {
  create: MatchCreateWithoutNextMatchInput;
  update: MatchUpdateWithoutNextMatchInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpsertWithWhereUniqueWithoutStageInput = {
  create: MatchCreateWithoutStageInput;
  update: MatchUpdateWithoutStageInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpsertWithWhereUniqueWithoutTeamsInput = {
  create: MatchCreateWithoutTeamsInput;
  update: MatchUpdateWithoutTeamsInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpsertWithWhereUniqueWithoutWinningTeamInput = {
  create: MatchCreateWithoutWinningTeamInput;
  update: MatchUpdateWithoutWinningTeamInput;
  where: MatchWhereUniqueInput;
};

export type MatchUpsertWithoutPreviousMatchesInput = {
  create: MatchCreateWithoutPreviousMatchesInput;
  update: MatchUpdateWithoutPreviousMatchesInput;
  where?: InputMaybe<MatchWhereInput>;
};

export type MatchUpsertWithoutRoundsInput = {
  create: MatchCreateWithoutRoundsInput;
  update: MatchUpdateWithoutRoundsInput;
  where?: InputMaybe<MatchWhereInput>;
};

export type MatchWhereInput = {
  AND?: InputMaybe<Array<MatchWhereInput>>;
  NOT?: InputMaybe<Array<MatchWhereInput>>;
  OR?: InputMaybe<Array<MatchWhereInput>>;
  court?: InputMaybe<CourtNullableRelationFilter>;
  courtId?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  event?: InputMaybe<EventNullableRelationFilter>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  nextMatch?: InputMaybe<MatchNullableRelationFilter>;
  nextMatchId?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<IntFilter>;
  playoffRound?: InputMaybe<IntNullableFilter>;
  previousMatches?: InputMaybe<MatchListRelationFilter>;
  rounds?: InputMaybe<RoundListRelationFilter>;
  stage?: InputMaybe<StageNullableRelationFilter>;
  stageId?: InputMaybe<StringNullableFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  teams?: InputMaybe<TeamListRelationFilter>;
  winningTeam?: InputMaybe<TeamNullableRelationFilter>;
  winningTeamId?: InputMaybe<StringNullableFilter>;
};

export type MatchWhereUniqueInput = {
  AND?: InputMaybe<Array<MatchWhereInput>>;
  NOT?: InputMaybe<Array<MatchWhereInput>>;
  OR?: InputMaybe<Array<MatchWhereInput>>;
  court?: InputMaybe<CourtNullableRelationFilter>;
  courtId?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  event?: InputMaybe<EventNullableRelationFilter>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  nextMatch?: InputMaybe<MatchNullableRelationFilter>;
  nextMatchId?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<IntFilter>;
  playoffRound?: InputMaybe<IntNullableFilter>;
  previousMatches?: InputMaybe<MatchListRelationFilter>;
  rounds?: InputMaybe<RoundListRelationFilter>;
  stage?: InputMaybe<StageNullableRelationFilter>;
  stageId?: InputMaybe<StringNullableFilter>;
  stageId_order?: InputMaybe<MatchStageIdOrderCompoundUniqueInput>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  teams?: InputMaybe<TeamListRelationFilter>;
  winningTeam?: InputMaybe<TeamNullableRelationFilter>;
  winningTeamId?: InputMaybe<StringNullableFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createManyAndReturnCourt: Array<CreateManyAndReturnCourt>;
  createManyAndReturnEvent: Array<CreateManyAndReturnEvent>;
  createManyAndReturnLocation: Array<CreateManyAndReturnLocation>;
  createManyAndReturnMatch: Array<CreateManyAndReturnMatch>;
  createManyAndReturnOrg: Array<CreateManyAndReturnOrg>;
  createManyAndReturnOrgLocation: Array<CreateManyAndReturnOrgLocation>;
  createManyAndReturnPool: Array<CreateManyAndReturnPool>;
  createManyAndReturnRating: Array<CreateManyAndReturnRating>;
  createManyAndReturnRound: Array<CreateManyAndReturnRound>;
  createManyAndReturnScore: Array<CreateManyAndReturnScore>;
  createManyAndReturnStage: Array<CreateManyAndReturnStage>;
  createManyAndReturnTeam: Array<CreateManyAndReturnTeam>;
  createManyAndReturnTeamEvent: Array<CreateManyAndReturnTeamEvent>;
  createManyAndReturnUser: Array<CreateManyAndReturnUser>;
  createManyAndReturnUserEvent: Array<CreateManyAndReturnUserEvent>;
  createManyAndReturnUserEventInvite: Array<CreateManyAndReturnUserEventInvite>;
  createManyAndReturnUserNotificationPreferences: Array<CreateManyAndReturnUserNotificationPreferences>;
  createManyAndReturnUserRating: Array<CreateManyAndReturnUserRating>;
  createManyAndReturnUserTeam: Array<CreateManyAndReturnUserTeam>;
  createManyAndReturnUserTeamEvent: Array<CreateManyAndReturnUserTeamEvent>;
  createManyAndReturnUserTeamInvite: Array<CreateManyAndReturnUserTeamInvite>;
  createManyCourt: AffectedRowsOutput;
  createManyEvent: AffectedRowsOutput;
  createManyLocation: AffectedRowsOutput;
  createManyMatch: AffectedRowsOutput;
  createManyOrg: AffectedRowsOutput;
  createManyOrgLocation: AffectedRowsOutput;
  createManyPool: AffectedRowsOutput;
  createManyRating: AffectedRowsOutput;
  createManyRound: AffectedRowsOutput;
  createManyScore: AffectedRowsOutput;
  createManyStage: AffectedRowsOutput;
  createManyTeam: AffectedRowsOutput;
  createManyTeamEvent: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createManyUserEvent: AffectedRowsOutput;
  createManyUserEventInvite: AffectedRowsOutput;
  createManyUserNotificationPreferences: AffectedRowsOutput;
  createManyUserRating: AffectedRowsOutput;
  createManyUserTeam: AffectedRowsOutput;
  createManyUserTeamEvent: AffectedRowsOutput;
  createManyUserTeamInvite: AffectedRowsOutput;
  createOneCourt: Court;
  createOneEvent: Event;
  createOneLocation: Location;
  createOneMatch: Match;
  createOneOrg: Org;
  createOneOrgLocation: OrgLocation;
  createOnePool: Pool;
  createOneRating: Rating;
  createOneRound: Round;
  createOneScore: Score;
  createOneStage: Stage;
  createOneTeam: Team;
  createOneTeamEvent: TeamEvent;
  createOneUser: User;
  createOneUserEvent: UserEvent;
  createOneUserEventInvite: UserEventInvite;
  createOneUserNotificationPreferences: UserNotificationPreferences;
  createOneUserRating: UserRating;
  createOneUserTeam: UserTeam;
  createOneUserTeamEvent: UserTeamEvent;
  createOneUserTeamInvite: UserTeamInvite;
  createPlayoffMatches: Scalars['Float']['output'];
  deleteAuth0User: Scalars['Boolean']['output'];
  deleteManyCourt: AffectedRowsOutput;
  deleteManyEvent: AffectedRowsOutput;
  deleteManyLocation: AffectedRowsOutput;
  deleteManyMatch: AffectedRowsOutput;
  deleteManyOrg: AffectedRowsOutput;
  deleteManyOrgLocation: AffectedRowsOutput;
  deleteManyPool: AffectedRowsOutput;
  deleteManyRating: AffectedRowsOutput;
  deleteManyRound: AffectedRowsOutput;
  deleteManyScore: AffectedRowsOutput;
  deleteManyStage: AffectedRowsOutput;
  deleteManyTeam: AffectedRowsOutput;
  deleteManyTeamEvent: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteManyUserEvent: AffectedRowsOutput;
  deleteManyUserEventInvite: AffectedRowsOutput;
  deleteManyUserNotificationPreferences: AffectedRowsOutput;
  deleteManyUserRating: AffectedRowsOutput;
  deleteManyUserTeam: AffectedRowsOutput;
  deleteManyUserTeamEvent: AffectedRowsOutput;
  deleteManyUserTeamInvite: AffectedRowsOutput;
  deleteOneCourt?: Maybe<Court>;
  deleteOneEvent?: Maybe<Event>;
  deleteOneLocation?: Maybe<Location>;
  deleteOneMatch?: Maybe<Match>;
  deleteOneOrg?: Maybe<Org>;
  deleteOneOrgLocation?: Maybe<OrgLocation>;
  deleteOnePool?: Maybe<Pool>;
  deleteOneRating?: Maybe<Rating>;
  deleteOneRound?: Maybe<Round>;
  deleteOneScore?: Maybe<Score>;
  deleteOneStage?: Maybe<Stage>;
  deleteOneTeam?: Maybe<Team>;
  deleteOneTeamEvent?: Maybe<TeamEvent>;
  deleteOneUser?: Maybe<User>;
  deleteOneUserEvent?: Maybe<UserEvent>;
  deleteOneUserEventInvite?: Maybe<UserEventInvite>;
  deleteOneUserNotificationPreferences?: Maybe<UserNotificationPreferences>;
  deleteOneUserRating?: Maybe<UserRating>;
  deleteOneUserTeam?: Maybe<UserTeam>;
  deleteOneUserTeamEvent?: Maybe<UserTeamEvent>;
  deleteOneUserTeamInvite?: Maybe<UserTeamInvite>;
  emailInvite: Scalars['Boolean']['output'];
  generateSeeds: Scalars['Boolean']['output'];
  moveUserIdsToEndofQueue: Scalars['Boolean']['output'];
  moveUserIndexesInQueue: Scalars['Boolean']['output'];
  updateManyCourt: AffectedRowsOutput;
  updateManyEvent: AffectedRowsOutput;
  updateManyLocation: AffectedRowsOutput;
  updateManyMatch: AffectedRowsOutput;
  updateManyOrg: AffectedRowsOutput;
  updateManyOrgLocation: AffectedRowsOutput;
  updateManyPool: AffectedRowsOutput;
  updateManyRating: AffectedRowsOutput;
  updateManyRound: AffectedRowsOutput;
  updateManyScore: AffectedRowsOutput;
  updateManyStage: AffectedRowsOutput;
  updateManyTeam: AffectedRowsOutput;
  updateManyTeamEvent: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateManyUserEvent: AffectedRowsOutput;
  updateManyUserEventInvite: AffectedRowsOutput;
  updateManyUserNotificationPreferences: AffectedRowsOutput;
  updateManyUserRating: AffectedRowsOutput;
  updateManyUserTeam: AffectedRowsOutput;
  updateManyUserTeamEvent: AffectedRowsOutput;
  updateManyUserTeamInvite: AffectedRowsOutput;
  updateOneCourt?: Maybe<Court>;
  updateOneEvent?: Maybe<Event>;
  updateOneLocation?: Maybe<Location>;
  updateOneMatch?: Maybe<Match>;
  updateOneOrg?: Maybe<Org>;
  updateOneOrgLocation?: Maybe<OrgLocation>;
  updateOnePool?: Maybe<Pool>;
  updateOneRating?: Maybe<Rating>;
  updateOneRound?: Maybe<Round>;
  updateOneScore?: Maybe<Score>;
  updateOneStage?: Maybe<Stage>;
  updateOneTeam?: Maybe<Team>;
  updateOneTeamEvent?: Maybe<TeamEvent>;
  updateOneUser?: Maybe<User>;
  updateOneUserEvent?: Maybe<UserEvent>;
  updateOneUserEventInvite?: Maybe<UserEventInvite>;
  updateOneUserNotificationPreferences?: Maybe<UserNotificationPreferences>;
  updateOneUserRating?: Maybe<UserRating>;
  updateOneUserTeam?: Maybe<UserTeam>;
  updateOneUserTeamEvent?: Maybe<UserTeamEvent>;
  updateOneUserTeamInvite?: Maybe<UserTeamInvite>;
  updateSeeds: Scalars['Boolean']['output'];
  upsertOneCourt: Court;
  upsertOneEvent: Event;
  upsertOneLocation: Location;
  upsertOneMatch: Match;
  upsertOneOrg: Org;
  upsertOneOrgLocation: OrgLocation;
  upsertOnePool: Pool;
  upsertOneRating: Rating;
  upsertOneRound: Round;
  upsertOneScore: Score;
  upsertOneStage: Stage;
  upsertOneTeam: Team;
  upsertOneTeamEvent: TeamEvent;
  upsertOneUser: User;
  upsertOneUserEvent: UserEvent;
  upsertOneUserEventInvite: UserEventInvite;
  upsertOneUserNotificationPreferences: UserNotificationPreferences;
  upsertOneUserRating: UserRating;
  upsertOneUserTeam: UserTeam;
  upsertOneUserTeamEvent: UserTeamEvent;
  upsertOneUserTeamInvite: UserTeamInvite;
};

export type MutationCreateManyAndReturnCourtArgs = {
  data: Array<CourtCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnEventArgs = {
  data: Array<EventCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnLocationArgs = {
  data: Array<LocationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnMatchArgs = {
  data: Array<MatchCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnOrgArgs = {
  data: Array<OrgCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnOrgLocationArgs = {
  data: Array<OrgLocationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnPoolArgs = {
  data: Array<PoolCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnRatingArgs = {
  data: Array<RatingCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnRoundArgs = {
  data: Array<RoundCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnScoreArgs = {
  data: Array<ScoreCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnStageArgs = {
  data: Array<StageCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnTeamArgs = {
  data: Array<TeamCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnTeamEventArgs = {
  data: Array<TeamEventCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnUserEventArgs = {
  data: Array<UserEventCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnUserEventInviteArgs = {
  data: Array<UserEventInviteCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnUserNotificationPreferencesArgs = {
  data: Array<UserNotificationPreferencesCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnUserRatingArgs = {
  data: Array<UserRatingCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnUserTeamArgs = {
  data: Array<UserTeamCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnUserTeamEventArgs = {
  data: Array<UserTeamEventCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyAndReturnUserTeamInviteArgs = {
  data: Array<UserTeamInviteCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyCourtArgs = {
  data: Array<CourtCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyEventArgs = {
  data: Array<EventCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyLocationArgs = {
  data: Array<LocationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyMatchArgs = {
  data: Array<MatchCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyOrgArgs = {
  data: Array<OrgCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyOrgLocationArgs = {
  data: Array<OrgLocationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyPoolArgs = {
  data: Array<PoolCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyRatingArgs = {
  data: Array<RatingCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyRoundArgs = {
  data: Array<RoundCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyScoreArgs = {
  data: Array<ScoreCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyStageArgs = {
  data: Array<StageCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyTeamArgs = {
  data: Array<TeamCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyTeamEventArgs = {
  data: Array<TeamEventCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyUserEventArgs = {
  data: Array<UserEventCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyUserEventInviteArgs = {
  data: Array<UserEventInviteCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyUserNotificationPreferencesArgs = {
  data: Array<UserNotificationPreferencesCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyUserRatingArgs = {
  data: Array<UserRatingCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyUserTeamArgs = {
  data: Array<UserTeamCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyUserTeamEventArgs = {
  data: Array<UserTeamEventCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateManyUserTeamInviteArgs = {
  data: Array<UserTeamInviteCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateOneCourtArgs = {
  data: CourtCreateInput;
};

export type MutationCreateOneEventArgs = {
  data: EventCreateInput;
};

export type MutationCreateOneLocationArgs = {
  data: LocationCreateInput;
};

export type MutationCreateOneMatchArgs = {
  data: MatchCreateInput;
};

export type MutationCreateOneOrgArgs = {
  data: OrgCreateInput;
};

export type MutationCreateOneOrgLocationArgs = {
  data: OrgLocationCreateInput;
};

export type MutationCreateOnePoolArgs = {
  data: PoolCreateInput;
};

export type MutationCreateOneRatingArgs = {
  data: RatingCreateInput;
};

export type MutationCreateOneRoundArgs = {
  data: RoundCreateInput;
};

export type MutationCreateOneScoreArgs = {
  data: ScoreCreateInput;
};

export type MutationCreateOneStageArgs = {
  data: StageCreateInput;
};

export type MutationCreateOneTeamArgs = {
  data: TeamCreateInput;
};

export type MutationCreateOneTeamEventArgs = {
  data: TeamEventCreateInput;
};

export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};

export type MutationCreateOneUserEventArgs = {
  data: UserEventCreateInput;
};

export type MutationCreateOneUserEventInviteArgs = {
  data: UserEventInviteCreateInput;
};

export type MutationCreateOneUserNotificationPreferencesArgs = {
  data: UserNotificationPreferencesCreateInput;
};

export type MutationCreateOneUserRatingArgs = {
  data: UserRatingCreateInput;
};

export type MutationCreateOneUserTeamArgs = {
  data: UserTeamCreateInput;
};

export type MutationCreateOneUserTeamEventArgs = {
  data: UserTeamEventCreateInput;
};

export type MutationCreateOneUserTeamInviteArgs = {
  data: UserTeamInviteCreateInput;
};

export type MutationCreatePlayoffMatchesArgs = {
  eventId: Scalars['String']['input'];
};

export type MutationDeleteManyCourtArgs = {
  where?: InputMaybe<CourtWhereInput>;
};

export type MutationDeleteManyEventArgs = {
  where?: InputMaybe<EventWhereInput>;
};

export type MutationDeleteManyLocationArgs = {
  where?: InputMaybe<LocationWhereInput>;
};

export type MutationDeleteManyMatchArgs = {
  where?: InputMaybe<MatchWhereInput>;
};

export type MutationDeleteManyOrgArgs = {
  where?: InputMaybe<OrgWhereInput>;
};

export type MutationDeleteManyOrgLocationArgs = {
  where?: InputMaybe<OrgLocationWhereInput>;
};

export type MutationDeleteManyPoolArgs = {
  where?: InputMaybe<PoolWhereInput>;
};

export type MutationDeleteManyRatingArgs = {
  where?: InputMaybe<RatingWhereInput>;
};

export type MutationDeleteManyRoundArgs = {
  where?: InputMaybe<RoundWhereInput>;
};

export type MutationDeleteManyScoreArgs = {
  where?: InputMaybe<ScoreWhereInput>;
};

export type MutationDeleteManyStageArgs = {
  where?: InputMaybe<StageWhereInput>;
};

export type MutationDeleteManyTeamArgs = {
  where?: InputMaybe<TeamWhereInput>;
};

export type MutationDeleteManyTeamEventArgs = {
  where?: InputMaybe<TeamEventWhereInput>;
};

export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type MutationDeleteManyUserEventArgs = {
  where?: InputMaybe<UserEventWhereInput>;
};

export type MutationDeleteManyUserEventInviteArgs = {
  where?: InputMaybe<UserEventInviteWhereInput>;
};

export type MutationDeleteManyUserNotificationPreferencesArgs = {
  where?: InputMaybe<UserNotificationPreferencesWhereInput>;
};

export type MutationDeleteManyUserRatingArgs = {
  where?: InputMaybe<UserRatingWhereInput>;
};

export type MutationDeleteManyUserTeamArgs = {
  where?: InputMaybe<UserTeamWhereInput>;
};

export type MutationDeleteManyUserTeamEventArgs = {
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type MutationDeleteManyUserTeamInviteArgs = {
  where?: InputMaybe<UserTeamInviteWhereInput>;
};

export type MutationDeleteOneCourtArgs = {
  where: CourtWhereUniqueInput;
};

export type MutationDeleteOneEventArgs = {
  where: EventWhereUniqueInput;
};

export type MutationDeleteOneLocationArgs = {
  where: LocationWhereUniqueInput;
};

export type MutationDeleteOneMatchArgs = {
  where: MatchWhereUniqueInput;
};

export type MutationDeleteOneOrgArgs = {
  where: OrgWhereUniqueInput;
};

export type MutationDeleteOneOrgLocationArgs = {
  where: OrgLocationWhereUniqueInput;
};

export type MutationDeleteOnePoolArgs = {
  where: PoolWhereUniqueInput;
};

export type MutationDeleteOneRatingArgs = {
  where: RatingWhereUniqueInput;
};

export type MutationDeleteOneRoundArgs = {
  where: RoundWhereUniqueInput;
};

export type MutationDeleteOneScoreArgs = {
  where: ScoreWhereUniqueInput;
};

export type MutationDeleteOneStageArgs = {
  where: StageWhereUniqueInput;
};

export type MutationDeleteOneTeamArgs = {
  where: TeamWhereUniqueInput;
};

export type MutationDeleteOneTeamEventArgs = {
  where: TeamEventWhereUniqueInput;
};

export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};

export type MutationDeleteOneUserEventArgs = {
  where: UserEventWhereUniqueInput;
};

export type MutationDeleteOneUserEventInviteArgs = {
  where: UserEventInviteWhereUniqueInput;
};

export type MutationDeleteOneUserNotificationPreferencesArgs = {
  where: UserNotificationPreferencesWhereUniqueInput;
};

export type MutationDeleteOneUserRatingArgs = {
  where: UserRatingWhereUniqueInput;
};

export type MutationDeleteOneUserTeamArgs = {
  where: UserTeamWhereUniqueInput;
};

export type MutationDeleteOneUserTeamEventArgs = {
  where: UserTeamEventWhereUniqueInput;
};

export type MutationDeleteOneUserTeamInviteArgs = {
  where: UserTeamInviteWhereUniqueInput;
};

export type MutationEmailInviteArgs = {
  emails: Array<Scalars['ID']['input']>;
  eventStartDate: Scalars['String']['input'];
  id: Scalars['String']['input'];
  inviteType: Scalars['String']['input'];
  invitedBy: Scalars['String']['input'];
  invitedByID: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationGenerateSeedsArgs = {
  eventId: Scalars['String']['input'];
};

export type MutationMoveUserIdsToEndofQueueArgs = {
  eventId: Scalars['String']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export type MutationMoveUserIndexesInQueueArgs = {
  eventId: Scalars['String']['input'];
  index1: Scalars['Float']['input'];
  index2: Scalars['Float']['input'];
};

export type MutationUpdateManyCourtArgs = {
  data: CourtUpdateManyMutationInput;
  where?: InputMaybe<CourtWhereInput>;
};

export type MutationUpdateManyEventArgs = {
  data: EventUpdateManyMutationInput;
  where?: InputMaybe<EventWhereInput>;
};

export type MutationUpdateManyLocationArgs = {
  data: LocationUpdateManyMutationInput;
  where?: InputMaybe<LocationWhereInput>;
};

export type MutationUpdateManyMatchArgs = {
  data: MatchUpdateManyMutationInput;
  where?: InputMaybe<MatchWhereInput>;
};

export type MutationUpdateManyOrgArgs = {
  data: OrgUpdateManyMutationInput;
  where?: InputMaybe<OrgWhereInput>;
};

export type MutationUpdateManyOrgLocationArgs = {
  data: OrgLocationUpdateManyMutationInput;
  where?: InputMaybe<OrgLocationWhereInput>;
};

export type MutationUpdateManyPoolArgs = {
  data: PoolUpdateManyMutationInput;
  where?: InputMaybe<PoolWhereInput>;
};

export type MutationUpdateManyRatingArgs = {
  data: RatingUpdateManyMutationInput;
  where?: InputMaybe<RatingWhereInput>;
};

export type MutationUpdateManyRoundArgs = {
  data: RoundUpdateManyMutationInput;
  where?: InputMaybe<RoundWhereInput>;
};

export type MutationUpdateManyScoreArgs = {
  data: ScoreUpdateManyMutationInput;
  where?: InputMaybe<ScoreWhereInput>;
};

export type MutationUpdateManyStageArgs = {
  data: StageUpdateManyMutationInput;
  where?: InputMaybe<StageWhereInput>;
};

export type MutationUpdateManyTeamArgs = {
  data: TeamUpdateManyMutationInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type MutationUpdateManyTeamEventArgs = {
  data: TeamEventUpdateManyMutationInput;
  where?: InputMaybe<TeamEventWhereInput>;
};

export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};

export type MutationUpdateManyUserEventArgs = {
  data: UserEventUpdateManyMutationInput;
  where?: InputMaybe<UserEventWhereInput>;
};

export type MutationUpdateManyUserEventInviteArgs = {
  data: UserEventInviteUpdateManyMutationInput;
  where?: InputMaybe<UserEventInviteWhereInput>;
};

export type MutationUpdateManyUserNotificationPreferencesArgs = {
  data: UserNotificationPreferencesUpdateManyMutationInput;
  where?: InputMaybe<UserNotificationPreferencesWhereInput>;
};

export type MutationUpdateManyUserRatingArgs = {
  data: UserRatingUpdateManyMutationInput;
  where?: InputMaybe<UserRatingWhereInput>;
};

export type MutationUpdateManyUserTeamArgs = {
  data: UserTeamUpdateManyMutationInput;
  where?: InputMaybe<UserTeamWhereInput>;
};

export type MutationUpdateManyUserTeamEventArgs = {
  data: UserTeamEventUpdateManyMutationInput;
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type MutationUpdateManyUserTeamInviteArgs = {
  data: UserTeamInviteUpdateManyMutationInput;
  where?: InputMaybe<UserTeamInviteWhereInput>;
};

export type MutationUpdateOneCourtArgs = {
  data: CourtUpdateInput;
  where: CourtWhereUniqueInput;
};

export type MutationUpdateOneEventArgs = {
  data: EventUpdateInput;
  where: EventWhereUniqueInput;
};

export type MutationUpdateOneLocationArgs = {
  data: LocationUpdateInput;
  where: LocationWhereUniqueInput;
};

export type MutationUpdateOneMatchArgs = {
  data: MatchUpdateInput;
  where: MatchWhereUniqueInput;
};

export type MutationUpdateOneOrgArgs = {
  data: OrgUpdateInput;
  where: OrgWhereUniqueInput;
};

export type MutationUpdateOneOrgLocationArgs = {
  data: OrgLocationUpdateInput;
  where: OrgLocationWhereUniqueInput;
};

export type MutationUpdateOnePoolArgs = {
  data: PoolUpdateInput;
  where: PoolWhereUniqueInput;
};

export type MutationUpdateOneRatingArgs = {
  data: RatingUpdateInput;
  where: RatingWhereUniqueInput;
};

export type MutationUpdateOneRoundArgs = {
  data: RoundUpdateInput;
  where: RoundWhereUniqueInput;
};

export type MutationUpdateOneScoreArgs = {
  data: ScoreUpdateInput;
  where: ScoreWhereUniqueInput;
};

export type MutationUpdateOneStageArgs = {
  data: StageUpdateInput;
  where: StageWhereUniqueInput;
};

export type MutationUpdateOneTeamArgs = {
  data: TeamUpdateInput;
  where: TeamWhereUniqueInput;
};

export type MutationUpdateOneTeamEventArgs = {
  data: TeamEventUpdateInput;
  where: TeamEventWhereUniqueInput;
};

export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationUpdateOneUserEventArgs = {
  data: UserEventUpdateInput;
  where: UserEventWhereUniqueInput;
};

export type MutationUpdateOneUserEventInviteArgs = {
  data: UserEventInviteUpdateInput;
  where: UserEventInviteWhereUniqueInput;
};

export type MutationUpdateOneUserNotificationPreferencesArgs = {
  data: UserNotificationPreferencesUpdateInput;
  where: UserNotificationPreferencesWhereUniqueInput;
};

export type MutationUpdateOneUserRatingArgs = {
  data: UserRatingUpdateInput;
  where: UserRatingWhereUniqueInput;
};

export type MutationUpdateOneUserTeamArgs = {
  data: UserTeamUpdateInput;
  where: UserTeamWhereUniqueInput;
};

export type MutationUpdateOneUserTeamEventArgs = {
  data: UserTeamEventUpdateInput;
  where: UserTeamEventWhereUniqueInput;
};

export type MutationUpdateOneUserTeamInviteArgs = {
  data: UserTeamInviteUpdateInput;
  where: UserTeamInviteWhereUniqueInput;
};

export type MutationUpdateSeedsArgs = {
  eventId: Scalars['String']['input'];
  teams: Array<Scalars['ID']['input']>;
};

export type MutationUpsertOneCourtArgs = {
  create: CourtCreateInput;
  update: CourtUpdateInput;
  where: CourtWhereUniqueInput;
};

export type MutationUpsertOneEventArgs = {
  create: EventCreateInput;
  update: EventUpdateInput;
  where: EventWhereUniqueInput;
};

export type MutationUpsertOneLocationArgs = {
  create: LocationCreateInput;
  update: LocationUpdateInput;
  where: LocationWhereUniqueInput;
};

export type MutationUpsertOneMatchArgs = {
  create: MatchCreateInput;
  update: MatchUpdateInput;
  where: MatchWhereUniqueInput;
};

export type MutationUpsertOneOrgArgs = {
  create: OrgCreateInput;
  update: OrgUpdateInput;
  where: OrgWhereUniqueInput;
};

export type MutationUpsertOneOrgLocationArgs = {
  create: OrgLocationCreateInput;
  update: OrgLocationUpdateInput;
  where: OrgLocationWhereUniqueInput;
};

export type MutationUpsertOnePoolArgs = {
  create: PoolCreateInput;
  update: PoolUpdateInput;
  where: PoolWhereUniqueInput;
};

export type MutationUpsertOneRatingArgs = {
  create: RatingCreateInput;
  update: RatingUpdateInput;
  where: RatingWhereUniqueInput;
};

export type MutationUpsertOneRoundArgs = {
  create: RoundCreateInput;
  update: RoundUpdateInput;
  where: RoundWhereUniqueInput;
};

export type MutationUpsertOneScoreArgs = {
  create: ScoreCreateInput;
  update: ScoreUpdateInput;
  where: ScoreWhereUniqueInput;
};

export type MutationUpsertOneStageArgs = {
  create: StageCreateInput;
  update: StageUpdateInput;
  where: StageWhereUniqueInput;
};

export type MutationUpsertOneTeamArgs = {
  create: TeamCreateInput;
  update: TeamUpdateInput;
  where: TeamWhereUniqueInput;
};

export type MutationUpsertOneTeamEventArgs = {
  create: TeamEventCreateInput;
  update: TeamEventUpdateInput;
  where: TeamEventWhereUniqueInput;
};

export type MutationUpsertOneUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationUpsertOneUserEventArgs = {
  create: UserEventCreateInput;
  update: UserEventUpdateInput;
  where: UserEventWhereUniqueInput;
};

export type MutationUpsertOneUserEventInviteArgs = {
  create: UserEventInviteCreateInput;
  update: UserEventInviteUpdateInput;
  where: UserEventInviteWhereUniqueInput;
};

export type MutationUpsertOneUserNotificationPreferencesArgs = {
  create: UserNotificationPreferencesCreateInput;
  update: UserNotificationPreferencesUpdateInput;
  where: UserNotificationPreferencesWhereUniqueInput;
};

export type MutationUpsertOneUserRatingArgs = {
  create: UserRatingCreateInput;
  update: UserRatingUpdateInput;
  where: UserRatingWhereUniqueInput;
};

export type MutationUpsertOneUserTeamArgs = {
  create: UserTeamCreateInput;
  update: UserTeamUpdateInput;
  where: UserTeamWhereUniqueInput;
};

export type MutationUpsertOneUserTeamEventArgs = {
  create: UserTeamEventCreateInput;
  update: UserTeamEventUpdateInput;
  where: UserTeamEventWhereUniqueInput;
};

export type MutationUpsertOneUserTeamInviteArgs = {
  create: UserTeamInviteCreateInput;
  update: UserTeamInviteUpdateInput;
  where: UserTeamInviteWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedDecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedEnumEventPrivacyNullableFilter = {
  equals?: InputMaybe<EventPrivacy>;
  in?: InputMaybe<Array<EventPrivacy>>;
  not?: InputMaybe<NestedEnumEventPrivacyNullableFilter>;
  notIn?: InputMaybe<Array<EventPrivacy>>;
};

export type NestedEnumEventPrivacyNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumEventPrivacyNullableFilter>;
  _min?: InputMaybe<NestedEnumEventPrivacyNullableFilter>;
  equals?: InputMaybe<EventPrivacy>;
  in?: InputMaybe<Array<EventPrivacy>>;
  not?: InputMaybe<NestedEnumEventPrivacyNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<EventPrivacy>>;
};

export type NestedEnumEventTypeFilter = {
  equals?: InputMaybe<EventType>;
  in?: InputMaybe<Array<EventType>>;
  not?: InputMaybe<NestedEnumEventTypeFilter>;
  notIn?: InputMaybe<Array<EventType>>;
};

export type NestedEnumEventTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumEventTypeFilter>;
  _min?: InputMaybe<NestedEnumEventTypeFilter>;
  equals?: InputMaybe<EventType>;
  in?: InputMaybe<Array<EventType>>;
  not?: InputMaybe<NestedEnumEventTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<EventType>>;
};

export type NestedEnumGenderFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  not?: InputMaybe<NestedEnumGenderFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type NestedEnumGenderWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumGenderFilter>;
  _min?: InputMaybe<NestedEnumGenderFilter>;
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  not?: InputMaybe<NestedEnumGenderWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type NestedEnumInviteStatusFilter = {
  equals?: InputMaybe<InviteStatus>;
  in?: InputMaybe<Array<InviteStatus>>;
  not?: InputMaybe<NestedEnumInviteStatusFilter>;
  notIn?: InputMaybe<Array<InviteStatus>>;
};

export type NestedEnumInviteStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInviteStatusFilter>;
  _min?: InputMaybe<NestedEnumInviteStatusFilter>;
  equals?: InputMaybe<InviteStatus>;
  in?: InputMaybe<Array<InviteStatus>>;
  not?: InputMaybe<NestedEnumInviteStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InviteStatus>>;
};

export type NestedEnumSportFilter = {
  equals?: InputMaybe<Sport>;
  in?: InputMaybe<Array<Sport>>;
  not?: InputMaybe<NestedEnumSportFilter>;
  notIn?: InputMaybe<Array<Sport>>;
};

export type NestedEnumSportNullableFilter = {
  equals?: InputMaybe<Sport>;
  in?: InputMaybe<Array<Sport>>;
  not?: InputMaybe<NestedEnumSportNullableFilter>;
  notIn?: InputMaybe<Array<Sport>>;
};

export type NestedEnumSportNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumSportNullableFilter>;
  _min?: InputMaybe<NestedEnumSportNullableFilter>;
  equals?: InputMaybe<Sport>;
  in?: InputMaybe<Array<Sport>>;
  not?: InputMaybe<NestedEnumSportNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Sport>>;
};

export type NestedEnumSportWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumSportFilter>;
  _min?: InputMaybe<NestedEnumSportFilter>;
  equals?: InputMaybe<Sport>;
  in?: InputMaybe<Array<Sport>>;
  not?: InputMaybe<NestedEnumSportWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Sport>>;
};

export type NestedEnumStageTypeFilter = {
  equals?: InputMaybe<StageType>;
  in?: InputMaybe<Array<StageType>>;
  not?: InputMaybe<NestedEnumStageTypeFilter>;
  notIn?: InputMaybe<Array<StageType>>;
};

export type NestedEnumStageTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStageTypeFilter>;
  _min?: InputMaybe<NestedEnumStageTypeFilter>;
  equals?: InputMaybe<StageType>;
  in?: InputMaybe<Array<StageType>>;
  not?: InputMaybe<NestedEnumStageTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StageType>>;
};

export type NestedEnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStatusFilter>;
  _min?: InputMaybe<NestedEnumStatusFilter>;
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type NullableEnumEventPrivacyFieldUpdateOperationsInput = {
  set?: InputMaybe<EventPrivacy>;
};

export type NullableEnumSportFieldUpdateOperationsInput = {
  set?: InputMaybe<Sport>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last',
}

export type Org = {
  __typename?: 'Org';
  OrgLocations: Array<OrgLocation>;
  _count?: Maybe<OrgCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  events: Array<Event>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  scrapeUrls: Array<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  url: Scalars['String']['output'];
};

export type OrgOrgLocationsArgs = {
  cursor?: InputMaybe<OrgLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrgLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrgLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgLocationWhereInput>;
};

export type OrgEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type OrgCount = {
  __typename?: 'OrgCount';
  OrgLocations: Scalars['Int']['output'];
  events: Scalars['Int']['output'];
};

export type OrgCountOrgLocationsArgs = {
  where?: InputMaybe<OrgLocationWhereInput>;
};

export type OrgCountEventsArgs = {
  where?: InputMaybe<EventWhereInput>;
};

export type OrgCountAggregate = {
  __typename?: 'OrgCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  scrapeUrls: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type OrgCountOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  scrapeUrls?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type OrgCreateInput = {
  OrgLocations?: InputMaybe<OrgLocationCreateNestedManyWithoutOrgInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<EventCreateNestedManyWithoutOrgInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scrapeUrls?: InputMaybe<OrgCreatescrapeUrlsInput>;
  url: Scalars['String']['input'];
};

export type OrgCreateManyInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scrapeUrls?: InputMaybe<OrgCreatescrapeUrlsInput>;
  url: Scalars['String']['input'];
};

export type OrgCreateNestedOneWithoutEventsInput = {
  connect?: InputMaybe<OrgWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrgCreateOrConnectWithoutEventsInput>;
  create?: InputMaybe<OrgCreateWithoutEventsInput>;
};

export type OrgCreateNestedOneWithoutOrgLocationsInput = {
  connect?: InputMaybe<OrgWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrgCreateOrConnectWithoutOrgLocationsInput>;
  create?: InputMaybe<OrgCreateWithoutOrgLocationsInput>;
};

export type OrgCreateOrConnectWithoutEventsInput = {
  create: OrgCreateWithoutEventsInput;
  where: OrgWhereUniqueInput;
};

export type OrgCreateOrConnectWithoutOrgLocationsInput = {
  create: OrgCreateWithoutOrgLocationsInput;
  where: OrgWhereUniqueInput;
};

export type OrgCreateWithoutEventsInput = {
  OrgLocations?: InputMaybe<OrgLocationCreateNestedManyWithoutOrgInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scrapeUrls?: InputMaybe<OrgCreatescrapeUrlsInput>;
  url: Scalars['String']['input'];
};

export type OrgCreateWithoutOrgLocationsInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<EventCreateNestedManyWithoutOrgInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scrapeUrls?: InputMaybe<OrgCreatescrapeUrlsInput>;
  url: Scalars['String']['input'];
};

export type OrgCreatescrapeUrlsInput = {
  set: Array<Scalars['String']['input']>;
};

export type OrgGroupBy = {
  __typename?: 'OrgGroupBy';
  _count?: Maybe<OrgCountAggregate>;
  _max?: Maybe<OrgMaxAggregate>;
  _min?: Maybe<OrgMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  scrapeUrls?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTimeISO']['output'];
  url: Scalars['String']['output'];
};

export type OrgLocation = {
  __typename?: 'OrgLocation';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  location: Location;
  locationId: Scalars['String']['output'];
  org: Org;
  orgId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type OrgLocationCountAggregate = {
  __typename?: 'OrgLocationCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  locationId: Scalars['Int']['output'];
  orgId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type OrgLocationCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  orgId?: InputMaybe<SortOrder>;
};

export type OrgLocationCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  location: LocationCreateNestedOneWithoutOrgLocationsInput;
  org: OrgCreateNestedOneWithoutOrgLocationsInput;
};

export type OrgLocationCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};

export type OrgLocationCreateManyLocationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
};

export type OrgLocationCreateManyLocationInputEnvelope = {
  data: Array<OrgLocationCreateManyLocationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrgLocationCreateManyOrgInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
};

export type OrgLocationCreateManyOrgInputEnvelope = {
  data: Array<OrgLocationCreateManyOrgInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrgLocationCreateNestedManyWithoutLocationInput = {
  connect?: InputMaybe<Array<OrgLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OrgLocationCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<OrgLocationCreateWithoutLocationInput>>;
  createMany?: InputMaybe<OrgLocationCreateManyLocationInputEnvelope>;
};

export type OrgLocationCreateNestedManyWithoutOrgInput = {
  connect?: InputMaybe<Array<OrgLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OrgLocationCreateOrConnectWithoutOrgInput>
  >;
  create?: InputMaybe<Array<OrgLocationCreateWithoutOrgInput>>;
  createMany?: InputMaybe<OrgLocationCreateManyOrgInputEnvelope>;
};

export type OrgLocationCreateOrConnectWithoutLocationInput = {
  create: OrgLocationCreateWithoutLocationInput;
  where: OrgLocationWhereUniqueInput;
};

export type OrgLocationCreateOrConnectWithoutOrgInput = {
  create: OrgLocationCreateWithoutOrgInput;
  where: OrgLocationWhereUniqueInput;
};

export type OrgLocationCreateWithoutLocationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  org: OrgCreateNestedOneWithoutOrgLocationsInput;
};

export type OrgLocationCreateWithoutOrgInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  location: LocationCreateNestedOneWithoutOrgLocationsInput;
};

export type OrgLocationGroupBy = {
  __typename?: 'OrgLocationGroupBy';
  _count?: Maybe<OrgLocationCountAggregate>;
  _max?: Maybe<OrgLocationMaxAggregate>;
  _min?: Maybe<OrgLocationMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type OrgLocationListRelationFilter = {
  every?: InputMaybe<OrgLocationWhereInput>;
  none?: InputMaybe<OrgLocationWhereInput>;
  some?: InputMaybe<OrgLocationWhereInput>;
};

export type OrgLocationMaxAggregate = {
  __typename?: 'OrgLocationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type OrgLocationMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  orgId?: InputMaybe<SortOrder>;
};

export type OrgLocationMinAggregate = {
  __typename?: 'OrgLocationMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type OrgLocationMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  orgId?: InputMaybe<SortOrder>;
};

export type OrgLocationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrgLocationOrderByWithAggregationInput = {
  _count?: InputMaybe<OrgLocationCountOrderByAggregateInput>;
  _max?: InputMaybe<OrgLocationMaxOrderByAggregateInput>;
  _min?: InputMaybe<OrgLocationMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  orgId?: InputMaybe<SortOrder>;
};

export type OrgLocationOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByWithRelationInput>;
  locationId?: InputMaybe<SortOrder>;
  org?: InputMaybe<OrgOrderByWithRelationInput>;
  orgId?: InputMaybe<SortOrder>;
};

export type OrgLocationOrgIdLocationIdCompoundUniqueInput = {
  locationId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};

export enum OrgLocationScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  LocationId = 'locationId',
  OrgId = 'orgId',
  UpdatedAt = 'updatedAt',
}

export type OrgLocationScalarWhereInput = {
  AND?: InputMaybe<Array<OrgLocationScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrgLocationScalarWhereInput>>;
  OR?: InputMaybe<Array<OrgLocationScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<StringFilter>;
  orgId?: InputMaybe<StringFilter>;
};

export type OrgLocationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<OrgLocationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<OrgLocationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<OrgLocationScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  locationId?: InputMaybe<StringWithAggregatesFilter>;
  orgId?: InputMaybe<StringWithAggregatesFilter>;
};

export type OrgLocationUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutOrgLocationsNestedInput>;
  org?: InputMaybe<OrgUpdateOneRequiredWithoutOrgLocationsNestedInput>;
};

export type OrgLocationUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OrgLocationUpdateManyWithWhereWithoutLocationInput = {
  data: OrgLocationUpdateManyMutationInput;
  where: OrgLocationScalarWhereInput;
};

export type OrgLocationUpdateManyWithWhereWithoutOrgInput = {
  data: OrgLocationUpdateManyMutationInput;
  where: OrgLocationScalarWhereInput;
};

export type OrgLocationUpdateManyWithoutLocationNestedInput = {
  connect?: InputMaybe<Array<OrgLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OrgLocationCreateOrConnectWithoutLocationInput>
  >;
  create?: InputMaybe<Array<OrgLocationCreateWithoutLocationInput>>;
  createMany?: InputMaybe<OrgLocationCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<OrgLocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrgLocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrgLocationWhereUniqueInput>>;
  set?: InputMaybe<Array<OrgLocationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<OrgLocationUpdateWithWhereUniqueWithoutLocationInput>
  >;
  updateMany?: InputMaybe<
    Array<OrgLocationUpdateManyWithWhereWithoutLocationInput>
  >;
  upsert?: InputMaybe<
    Array<OrgLocationUpsertWithWhereUniqueWithoutLocationInput>
  >;
};

export type OrgLocationUpdateManyWithoutOrgNestedInput = {
  connect?: InputMaybe<Array<OrgLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OrgLocationCreateOrConnectWithoutOrgInput>
  >;
  create?: InputMaybe<Array<OrgLocationCreateWithoutOrgInput>>;
  createMany?: InputMaybe<OrgLocationCreateManyOrgInputEnvelope>;
  delete?: InputMaybe<Array<OrgLocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrgLocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrgLocationWhereUniqueInput>>;
  set?: InputMaybe<Array<OrgLocationWhereUniqueInput>>;
  update?: InputMaybe<Array<OrgLocationUpdateWithWhereUniqueWithoutOrgInput>>;
  updateMany?: InputMaybe<Array<OrgLocationUpdateManyWithWhereWithoutOrgInput>>;
  upsert?: InputMaybe<Array<OrgLocationUpsertWithWhereUniqueWithoutOrgInput>>;
};

export type OrgLocationUpdateWithWhereUniqueWithoutLocationInput = {
  data: OrgLocationUpdateWithoutLocationInput;
  where: OrgLocationWhereUniqueInput;
};

export type OrgLocationUpdateWithWhereUniqueWithoutOrgInput = {
  data: OrgLocationUpdateWithoutOrgInput;
  where: OrgLocationWhereUniqueInput;
};

export type OrgLocationUpdateWithoutLocationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  org?: InputMaybe<OrgUpdateOneRequiredWithoutOrgLocationsNestedInput>;
};

export type OrgLocationUpdateWithoutOrgInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutOrgLocationsNestedInput>;
};

export type OrgLocationUpsertWithWhereUniqueWithoutLocationInput = {
  create: OrgLocationCreateWithoutLocationInput;
  update: OrgLocationUpdateWithoutLocationInput;
  where: OrgLocationWhereUniqueInput;
};

export type OrgLocationUpsertWithWhereUniqueWithoutOrgInput = {
  create: OrgLocationCreateWithoutOrgInput;
  update: OrgLocationUpdateWithoutOrgInput;
  where: OrgLocationWhereUniqueInput;
};

export type OrgLocationWhereInput = {
  AND?: InputMaybe<Array<OrgLocationWhereInput>>;
  NOT?: InputMaybe<Array<OrgLocationWhereInput>>;
  OR?: InputMaybe<Array<OrgLocationWhereInput>>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  locationId?: InputMaybe<StringFilter>;
  org?: InputMaybe<OrgRelationFilter>;
  orgId?: InputMaybe<StringFilter>;
};

export type OrgLocationWhereUniqueInput = {
  AND?: InputMaybe<Array<OrgLocationWhereInput>>;
  NOT?: InputMaybe<Array<OrgLocationWhereInput>>;
  OR?: InputMaybe<Array<OrgLocationWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationRelationFilter>;
  locationId?: InputMaybe<StringFilter>;
  org?: InputMaybe<OrgRelationFilter>;
  orgId?: InputMaybe<StringFilter>;
  orgId_locationId?: InputMaybe<OrgLocationOrgIdLocationIdCompoundUniqueInput>;
};

export type OrgMaxAggregate = {
  __typename?: 'OrgMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type OrgMaxOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type OrgMinAggregate = {
  __typename?: 'OrgMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type OrgMinOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type OrgNullableRelationFilter = {
  is?: InputMaybe<OrgWhereInput>;
  isNot?: InputMaybe<OrgWhereInput>;
};

export type OrgOrderByWithAggregationInput = {
  _count?: InputMaybe<OrgCountOrderByAggregateInput>;
  _max?: InputMaybe<OrgMaxOrderByAggregateInput>;
  _min?: InputMaybe<OrgMinOrderByAggregateInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  scrapeUrls?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type OrgOrderByWithRelationInput = {
  OrgLocations?: InputMaybe<OrgLocationOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrderInput>;
  events?: InputMaybe<EventOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  scrapeUrls?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type OrgRelationFilter = {
  is?: InputMaybe<OrgWhereInput>;
  isNot?: InputMaybe<OrgWhereInput>;
};

export enum OrgScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  ScrapeUrls = 'scrapeUrls',
  UpdatedAt = 'updatedAt',
  Url = 'url',
}

export type OrgScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<OrgScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<OrgScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<OrgScalarWhereWithAggregatesInput>>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  scrapeUrls?: InputMaybe<StringNullableListFilter>;
  url?: InputMaybe<StringWithAggregatesFilter>;
};

export type OrgUpdateInput = {
  OrgLocations?: InputMaybe<OrgLocationUpdateManyWithoutOrgNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutOrgNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scrapeUrls?: InputMaybe<OrgUpdatescrapeUrlsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OrgUpdateManyMutationInput = {
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scrapeUrls?: InputMaybe<OrgUpdatescrapeUrlsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OrgUpdateOneRequiredWithoutOrgLocationsNestedInput = {
  connect?: InputMaybe<OrgWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrgCreateOrConnectWithoutOrgLocationsInput>;
  create?: InputMaybe<OrgCreateWithoutOrgLocationsInput>;
  update?: InputMaybe<OrgUpdateToOneWithWhereWithoutOrgLocationsInput>;
  upsert?: InputMaybe<OrgUpsertWithoutOrgLocationsInput>;
};

export type OrgUpdateOneWithoutEventsNestedInput = {
  connect?: InputMaybe<OrgWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrgCreateOrConnectWithoutEventsInput>;
  create?: InputMaybe<OrgCreateWithoutEventsInput>;
  delete?: InputMaybe<OrgWhereInput>;
  disconnect?: InputMaybe<OrgWhereInput>;
  update?: InputMaybe<OrgUpdateToOneWithWhereWithoutEventsInput>;
  upsert?: InputMaybe<OrgUpsertWithoutEventsInput>;
};

export type OrgUpdateToOneWithWhereWithoutEventsInput = {
  data: OrgUpdateWithoutEventsInput;
  where?: InputMaybe<OrgWhereInput>;
};

export type OrgUpdateToOneWithWhereWithoutOrgLocationsInput = {
  data: OrgUpdateWithoutOrgLocationsInput;
  where?: InputMaybe<OrgWhereInput>;
};

export type OrgUpdateWithoutEventsInput = {
  OrgLocations?: InputMaybe<OrgLocationUpdateManyWithoutOrgNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scrapeUrls?: InputMaybe<OrgUpdatescrapeUrlsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OrgUpdateWithoutOrgLocationsInput = {
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutOrgNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scrapeUrls?: InputMaybe<OrgUpdatescrapeUrlsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OrgUpdatescrapeUrlsInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrgUpsertWithoutEventsInput = {
  create: OrgCreateWithoutEventsInput;
  update: OrgUpdateWithoutEventsInput;
  where?: InputMaybe<OrgWhereInput>;
};

export type OrgUpsertWithoutOrgLocationsInput = {
  create: OrgCreateWithoutOrgLocationsInput;
  update: OrgUpdateWithoutOrgLocationsInput;
  where?: InputMaybe<OrgWhereInput>;
};

export type OrgWhereInput = {
  AND?: InputMaybe<Array<OrgWhereInput>>;
  NOT?: InputMaybe<Array<OrgWhereInput>>;
  OR?: InputMaybe<Array<OrgWhereInput>>;
  OrgLocations?: InputMaybe<OrgLocationListRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  events?: InputMaybe<EventListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  scrapeUrls?: InputMaybe<StringNullableListFilter>;
  url?: InputMaybe<StringFilter>;
};

export type OrgWhereUniqueInput = {
  AND?: InputMaybe<Array<OrgWhereInput>>;
  NOT?: InputMaybe<Array<OrgWhereInput>>;
  OR?: InputMaybe<Array<OrgWhereInput>>;
  OrgLocations?: InputMaybe<OrgLocationListRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  events?: InputMaybe<EventListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  scrapeUrls?: InputMaybe<StringNullableListFilter>;
  url?: InputMaybe<StringFilter>;
};

export type Pool = {
  __typename?: 'Pool';
  _count?: Maybe<PoolCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  teamEvent: Array<TeamEvent>;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type PoolTeamEventArgs = {
  cursor?: InputMaybe<TeamEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamEventWhereInput>;
};

export type PoolCount = {
  __typename?: 'PoolCount';
  teamEvent: Scalars['Int']['output'];
};

export type PoolCountTeamEventArgs = {
  where?: InputMaybe<TeamEventWhereInput>;
};

export type PoolCountAggregate = {
  __typename?: 'PoolCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PoolCountOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type PoolCreateInput = {
  event: EventCreateNestedOneWithoutPoolsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  teamEvent?: InputMaybe<TeamEventCreateNestedManyWithoutPoolInput>;
};

export type PoolCreateManyEventInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type PoolCreateManyEventInputEnvelope = {
  data: Array<PoolCreateManyEventInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PoolCreateManyInput = {
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type PoolCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<PoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PoolCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<PoolCreateWithoutEventInput>>;
  createMany?: InputMaybe<PoolCreateManyEventInputEnvelope>;
};

export type PoolCreateNestedOneWithoutTeamEventInput = {
  connect?: InputMaybe<PoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PoolCreateOrConnectWithoutTeamEventInput>;
  create?: InputMaybe<PoolCreateWithoutTeamEventInput>;
};

export type PoolCreateOrConnectWithoutEventInput = {
  create: PoolCreateWithoutEventInput;
  where: PoolWhereUniqueInput;
};

export type PoolCreateOrConnectWithoutTeamEventInput = {
  create: PoolCreateWithoutTeamEventInput;
  where: PoolWhereUniqueInput;
};

export type PoolCreateWithoutEventInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  teamEvent?: InputMaybe<TeamEventCreateNestedManyWithoutPoolInput>;
};

export type PoolCreateWithoutTeamEventInput = {
  event: EventCreateNestedOneWithoutPoolsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type PoolEventIdNameCompoundUniqueInput = {
  eventId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type PoolGroupBy = {
  __typename?: 'PoolGroupBy';
  _count?: Maybe<PoolCountAggregate>;
  _max?: Maybe<PoolMaxAggregate>;
  _min?: Maybe<PoolMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type PoolListRelationFilter = {
  every?: InputMaybe<PoolWhereInput>;
  none?: InputMaybe<PoolWhereInput>;
  some?: InputMaybe<PoolWhereInput>;
};

export type PoolMaxAggregate = {
  __typename?: 'PoolMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type PoolMaxOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type PoolMinAggregate = {
  __typename?: 'PoolMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type PoolMinOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type PoolNullableRelationFilter = {
  is?: InputMaybe<PoolWhereInput>;
  isNot?: InputMaybe<PoolWhereInput>;
};

export type PoolOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PoolOrderByWithAggregationInput = {
  _count?: InputMaybe<PoolCountOrderByAggregateInput>;
  _max?: InputMaybe<PoolMaxOrderByAggregateInput>;
  _min?: InputMaybe<PoolMinOrderByAggregateInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type PoolOrderByWithRelationInput = {
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  teamEvent?: InputMaybe<TeamEventOrderByRelationAggregateInput>;
};

export enum PoolScalarFieldEnum {
  CreatedAt = 'createdAt',
  EventId = 'eventId',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export type PoolScalarWhereInput = {
  AND?: InputMaybe<Array<PoolScalarWhereInput>>;
  NOT?: InputMaybe<Array<PoolScalarWhereInput>>;
  OR?: InputMaybe<Array<PoolScalarWhereInput>>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type PoolScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PoolScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PoolScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PoolScalarWhereWithAggregatesInput>>;
  eventId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
};

export type PoolUpdateInput = {
  event?: InputMaybe<EventUpdateOneRequiredWithoutPoolsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  teamEvent?: InputMaybe<TeamEventUpdateManyWithoutPoolNestedInput>;
};

export type PoolUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PoolUpdateManyWithWhereWithoutEventInput = {
  data: PoolUpdateManyMutationInput;
  where: PoolScalarWhereInput;
};

export type PoolUpdateManyWithoutEventNestedInput = {
  connect?: InputMaybe<Array<PoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PoolCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<PoolCreateWithoutEventInput>>;
  createMany?: InputMaybe<PoolCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<PoolWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PoolScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PoolWhereUniqueInput>>;
  set?: InputMaybe<Array<PoolWhereUniqueInput>>;
  update?: InputMaybe<Array<PoolUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<PoolUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<PoolUpsertWithWhereUniqueWithoutEventInput>>;
};

export type PoolUpdateOneWithoutTeamEventNestedInput = {
  connect?: InputMaybe<PoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PoolCreateOrConnectWithoutTeamEventInput>;
  create?: InputMaybe<PoolCreateWithoutTeamEventInput>;
  delete?: InputMaybe<PoolWhereInput>;
  disconnect?: InputMaybe<PoolWhereInput>;
  update?: InputMaybe<PoolUpdateToOneWithWhereWithoutTeamEventInput>;
  upsert?: InputMaybe<PoolUpsertWithoutTeamEventInput>;
};

export type PoolUpdateToOneWithWhereWithoutTeamEventInput = {
  data: PoolUpdateWithoutTeamEventInput;
  where?: InputMaybe<PoolWhereInput>;
};

export type PoolUpdateWithWhereUniqueWithoutEventInput = {
  data: PoolUpdateWithoutEventInput;
  where: PoolWhereUniqueInput;
};

export type PoolUpdateWithoutEventInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  teamEvent?: InputMaybe<TeamEventUpdateManyWithoutPoolNestedInput>;
};

export type PoolUpdateWithoutTeamEventInput = {
  event?: InputMaybe<EventUpdateOneRequiredWithoutPoolsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PoolUpsertWithWhereUniqueWithoutEventInput = {
  create: PoolCreateWithoutEventInput;
  update: PoolUpdateWithoutEventInput;
  where: PoolWhereUniqueInput;
};

export type PoolUpsertWithoutTeamEventInput = {
  create: PoolCreateWithoutTeamEventInput;
  update: PoolUpdateWithoutTeamEventInput;
  where?: InputMaybe<PoolWhereInput>;
};

export type PoolWhereInput = {
  AND?: InputMaybe<Array<PoolWhereInput>>;
  NOT?: InputMaybe<Array<PoolWhereInput>>;
  OR?: InputMaybe<Array<PoolWhereInput>>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  teamEvent?: InputMaybe<TeamEventListRelationFilter>;
};

export type PoolWhereUniqueInput = {
  AND?: InputMaybe<Array<PoolWhereInput>>;
  NOT?: InputMaybe<Array<PoolWhereInput>>;
  OR?: InputMaybe<Array<PoolWhereInput>>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  eventId_name?: InputMaybe<PoolEventIdNameCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  teamEvent?: InputMaybe<TeamEventListRelationFilter>;
};

export type Query = {
  __typename?: 'Query';
  aggregateCourt: AggregateCourt;
  aggregateEvent: AggregateEvent;
  aggregateLocation: AggregateLocation;
  aggregateMatch: AggregateMatch;
  aggregateOrg: AggregateOrg;
  aggregateOrgLocation: AggregateOrgLocation;
  aggregatePool: AggregatePool;
  aggregateRating: AggregateRating;
  aggregateRound: AggregateRound;
  aggregateScore: AggregateScore;
  aggregateStage: AggregateStage;
  aggregateTeam: AggregateTeam;
  aggregateTeamEvent: AggregateTeamEvent;
  aggregateUser: AggregateUser;
  aggregateUserEvent: AggregateUserEvent;
  aggregateUserEventInvite: AggregateUserEventInvite;
  aggregateUserNotificationPreferences: AggregateUserNotificationPreferences;
  aggregateUserRating: AggregateUserRating;
  aggregateUserTeam: AggregateUserTeam;
  aggregateUserTeamEvent: AggregateUserTeamEvent;
  aggregateUserTeamInvite: AggregateUserTeamInvite;
  court?: Maybe<Court>;
  courts: Array<Court>;
  event?: Maybe<Event>;
  events: Array<Event>;
  findFirstCourt?: Maybe<Court>;
  findFirstCourtOrThrow?: Maybe<Court>;
  findFirstEvent?: Maybe<Event>;
  findFirstEventOrThrow?: Maybe<Event>;
  findFirstLocation?: Maybe<Location>;
  findFirstLocationOrThrow?: Maybe<Location>;
  findFirstMatch?: Maybe<Match>;
  findFirstMatchOrThrow?: Maybe<Match>;
  findFirstOrg?: Maybe<Org>;
  findFirstOrgLocation?: Maybe<OrgLocation>;
  findFirstOrgLocationOrThrow?: Maybe<OrgLocation>;
  findFirstOrgOrThrow?: Maybe<Org>;
  findFirstPool?: Maybe<Pool>;
  findFirstPoolOrThrow?: Maybe<Pool>;
  findFirstRating?: Maybe<Rating>;
  findFirstRatingOrThrow?: Maybe<Rating>;
  findFirstRound?: Maybe<Round>;
  findFirstRoundOrThrow?: Maybe<Round>;
  findFirstScore?: Maybe<Score>;
  findFirstScoreOrThrow?: Maybe<Score>;
  findFirstStage?: Maybe<Stage>;
  findFirstStageOrThrow?: Maybe<Stage>;
  findFirstTeam?: Maybe<Team>;
  findFirstTeamEvent?: Maybe<TeamEvent>;
  findFirstTeamEventOrThrow?: Maybe<TeamEvent>;
  findFirstTeamOrThrow?: Maybe<Team>;
  findFirstUser?: Maybe<User>;
  findFirstUserEvent?: Maybe<UserEvent>;
  findFirstUserEventInvite?: Maybe<UserEventInvite>;
  findFirstUserEventInviteOrThrow?: Maybe<UserEventInvite>;
  findFirstUserEventOrThrow?: Maybe<UserEvent>;
  findFirstUserNotificationPreferences?: Maybe<UserNotificationPreferences>;
  findFirstUserNotificationPreferencesOrThrow?: Maybe<UserNotificationPreferences>;
  findFirstUserOrThrow?: Maybe<User>;
  findFirstUserRating?: Maybe<UserRating>;
  findFirstUserRatingOrThrow?: Maybe<UserRating>;
  findFirstUserTeam?: Maybe<UserTeam>;
  findFirstUserTeamEvent?: Maybe<UserTeamEvent>;
  findFirstUserTeamEventOrThrow?: Maybe<UserTeamEvent>;
  findFirstUserTeamInvite?: Maybe<UserTeamInvite>;
  findFirstUserTeamInviteOrThrow?: Maybe<UserTeamInvite>;
  findFirstUserTeamOrThrow?: Maybe<UserTeam>;
  findManyUserNotificationPreferences: Array<UserNotificationPreferences>;
  findUniqueUserNotificationPreferences?: Maybe<UserNotificationPreferences>;
  findUniqueUserNotificationPreferencesOrThrow?: Maybe<UserNotificationPreferences>;
  getCourt?: Maybe<Court>;
  getEvent?: Maybe<Event>;
  getLocation?: Maybe<Location>;
  getMatch?: Maybe<Match>;
  getOrg?: Maybe<Org>;
  getOrgLocation?: Maybe<OrgLocation>;
  getPool?: Maybe<Pool>;
  getRating?: Maybe<Rating>;
  getRound?: Maybe<Round>;
  getScore?: Maybe<Score>;
  getStage?: Maybe<Stage>;
  getTeam?: Maybe<Team>;
  getTeamEvent?: Maybe<TeamEvent>;
  getUser?: Maybe<User>;
  getUserEvent?: Maybe<UserEvent>;
  getUserEventInvite?: Maybe<UserEventInvite>;
  getUserRating?: Maybe<UserRating>;
  getUserTeam?: Maybe<UserTeam>;
  getUserTeamEvent?: Maybe<UserTeamEvent>;
  getUserTeamInvite?: Maybe<UserTeamInvite>;
  getUsersInQueue: Array<UserEvent>;
  groupByCourt: Array<CourtGroupBy>;
  groupByEvent: Array<EventGroupBy>;
  groupByLocation: Array<LocationGroupBy>;
  groupByMatch: Array<MatchGroupBy>;
  groupByOrg: Array<OrgGroupBy>;
  groupByOrgLocation: Array<OrgLocationGroupBy>;
  groupByPool: Array<PoolGroupBy>;
  groupByRating: Array<RatingGroupBy>;
  groupByRound: Array<RoundGroupBy>;
  groupByScore: Array<ScoreGroupBy>;
  groupByStage: Array<StageGroupBy>;
  groupByTeam: Array<TeamGroupBy>;
  groupByTeamEvent: Array<TeamEventGroupBy>;
  groupByUser: Array<UserGroupBy>;
  groupByUserEvent: Array<UserEventGroupBy>;
  groupByUserEventInvite: Array<UserEventInviteGroupBy>;
  groupByUserNotificationPreferences: Array<UserNotificationPreferencesGroupBy>;
  groupByUserRating: Array<UserRatingGroupBy>;
  groupByUserTeam: Array<UserTeamGroupBy>;
  groupByUserTeamEvent: Array<UserTeamEventGroupBy>;
  groupByUserTeamInvite: Array<UserTeamInviteGroupBy>;
  location?: Maybe<Location>;
  locations: Array<Location>;
  match?: Maybe<Match>;
  matches: Array<Match>;
  org?: Maybe<Org>;
  orgLocation?: Maybe<OrgLocation>;
  orgLocations: Array<OrgLocation>;
  orgs: Array<Org>;
  pool?: Maybe<Pool>;
  pools: Array<Pool>;
  rating?: Maybe<Rating>;
  ratings: Array<Rating>;
  round?: Maybe<Round>;
  rounds: Array<Round>;
  score?: Maybe<Score>;
  scores: Array<Score>;
  stage?: Maybe<Stage>;
  stages: Array<Stage>;
  team?: Maybe<Team>;
  teamEvent?: Maybe<TeamEvent>;
  teamEvents: Array<TeamEvent>;
  teams: Array<Team>;
  user?: Maybe<User>;
  userEvent?: Maybe<UserEvent>;
  userEventInvite?: Maybe<UserEventInvite>;
  userEventInvites: Array<UserEventInvite>;
  userEvents: Array<UserEvent>;
  userRating?: Maybe<UserRating>;
  userRatings: Array<UserRating>;
  userTeam?: Maybe<UserTeam>;
  userTeamEvent?: Maybe<UserTeamEvent>;
  userTeamEvents: Array<UserTeamEvent>;
  userTeamInvite?: Maybe<UserTeamInvite>;
  userTeamInvites: Array<UserTeamInvite>;
  userTeams: Array<UserTeam>;
  users: Array<User>;
};

export type QueryAggregateCourtArgs = {
  cursor?: InputMaybe<CourtWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CourtOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CourtWhereInput>;
};

export type QueryAggregateEventArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type QueryAggregateLocationArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryAggregateMatchArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MatchWhereInput>;
};

export type QueryAggregateOrgArgs = {
  cursor?: InputMaybe<OrgWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrgOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgWhereInput>;
};

export type QueryAggregateOrgLocationArgs = {
  cursor?: InputMaybe<OrgLocationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrgLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgLocationWhereInput>;
};

export type QueryAggregatePoolArgs = {
  cursor?: InputMaybe<PoolWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PoolOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PoolWhereInput>;
};

export type QueryAggregateRatingArgs = {
  cursor?: InputMaybe<RatingWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RatingWhereInput>;
};

export type QueryAggregateRoundArgs = {
  cursor?: InputMaybe<RoundWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RoundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoundWhereInput>;
};

export type QueryAggregateScoreArgs = {
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};

export type QueryAggregateStageArgs = {
  cursor?: InputMaybe<StageWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StageWhereInput>;
};

export type QueryAggregateTeamArgs = {
  cursor?: InputMaybe<TeamWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamWhereInput>;
};

export type QueryAggregateTeamEventArgs = {
  cursor?: InputMaybe<TeamEventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamEventWhereInput>;
};

export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryAggregateUserEventArgs = {
  cursor?: InputMaybe<UserEventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventWhereInput>;
};

export type QueryAggregateUserEventInviteArgs = {
  cursor?: InputMaybe<UserEventInviteWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserEventInviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventInviteWhereInput>;
};

export type QueryAggregateUserNotificationPreferencesArgs = {
  cursor?: InputMaybe<UserNotificationPreferencesWhereUniqueInput>;
  orderBy?: InputMaybe<
    Array<UserNotificationPreferencesOrderByWithRelationInput>
  >;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserNotificationPreferencesWhereInput>;
};

export type QueryAggregateUserRatingArgs = {
  cursor?: InputMaybe<UserRatingWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserRatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserRatingWhereInput>;
};

export type QueryAggregateUserTeamArgs = {
  cursor?: InputMaybe<UserTeamWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserTeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamWhereInput>;
};

export type QueryAggregateUserTeamEventArgs = {
  cursor?: InputMaybe<UserTeamEventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserTeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type QueryAggregateUserTeamInviteArgs = {
  cursor?: InputMaybe<UserTeamInviteWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserTeamInviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamInviteWhereInput>;
};

export type QueryCourtArgs = {
  where: CourtWhereUniqueInput;
};

export type QueryCourtsArgs = {
  cursor?: InputMaybe<CourtWhereUniqueInput>;
  distinct?: InputMaybe<Array<CourtScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CourtOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CourtWhereInput>;
};

export type QueryEventArgs = {
  where: EventWhereUniqueInput;
};

export type QueryEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type QueryFindFirstCourtArgs = {
  cursor?: InputMaybe<CourtWhereUniqueInput>;
  distinct?: InputMaybe<Array<CourtScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CourtOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CourtWhereInput>;
};

export type QueryFindFirstCourtOrThrowArgs = {
  cursor?: InputMaybe<CourtWhereUniqueInput>;
  distinct?: InputMaybe<Array<CourtScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CourtOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CourtWhereInput>;
};

export type QueryFindFirstEventArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type QueryFindFirstEventOrThrowArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type QueryFindFirstLocationArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryFindFirstLocationOrThrowArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryFindFirstMatchArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MatchWhereInput>;
};

export type QueryFindFirstMatchOrThrowArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MatchWhereInput>;
};

export type QueryFindFirstOrgArgs = {
  cursor?: InputMaybe<OrgWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrgScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrgOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgWhereInput>;
};

export type QueryFindFirstOrgLocationArgs = {
  cursor?: InputMaybe<OrgLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrgLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrgLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgLocationWhereInput>;
};

export type QueryFindFirstOrgLocationOrThrowArgs = {
  cursor?: InputMaybe<OrgLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrgLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrgLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgLocationWhereInput>;
};

export type QueryFindFirstOrgOrThrowArgs = {
  cursor?: InputMaybe<OrgWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrgScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrgOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgWhereInput>;
};

export type QueryFindFirstPoolArgs = {
  cursor?: InputMaybe<PoolWhereUniqueInput>;
  distinct?: InputMaybe<Array<PoolScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PoolOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PoolWhereInput>;
};

export type QueryFindFirstPoolOrThrowArgs = {
  cursor?: InputMaybe<PoolWhereUniqueInput>;
  distinct?: InputMaybe<Array<PoolScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PoolOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PoolWhereInput>;
};

export type QueryFindFirstRatingArgs = {
  cursor?: InputMaybe<RatingWhereUniqueInput>;
  distinct?: InputMaybe<Array<RatingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RatingWhereInput>;
};

export type QueryFindFirstRatingOrThrowArgs = {
  cursor?: InputMaybe<RatingWhereUniqueInput>;
  distinct?: InputMaybe<Array<RatingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RatingWhereInput>;
};

export type QueryFindFirstRoundArgs = {
  cursor?: InputMaybe<RoundWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoundWhereInput>;
};

export type QueryFindFirstRoundOrThrowArgs = {
  cursor?: InputMaybe<RoundWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoundWhereInput>;
};

export type QueryFindFirstScoreArgs = {
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};

export type QueryFindFirstScoreOrThrowArgs = {
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};

export type QueryFindFirstStageArgs = {
  cursor?: InputMaybe<StageWhereUniqueInput>;
  distinct?: InputMaybe<Array<StageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StageWhereInput>;
};

export type QueryFindFirstStageOrThrowArgs = {
  cursor?: InputMaybe<StageWhereUniqueInput>;
  distinct?: InputMaybe<Array<StageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StageWhereInput>;
};

export type QueryFindFirstTeamArgs = {
  cursor?: InputMaybe<TeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamWhereInput>;
};

export type QueryFindFirstTeamEventArgs = {
  cursor?: InputMaybe<TeamEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamEventWhereInput>;
};

export type QueryFindFirstTeamEventOrThrowArgs = {
  cursor?: InputMaybe<TeamEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamEventWhereInput>;
};

export type QueryFindFirstTeamOrThrowArgs = {
  cursor?: InputMaybe<TeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamWhereInput>;
};

export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryFindFirstUserEventArgs = {
  cursor?: InputMaybe<UserEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventWhereInput>;
};

export type QueryFindFirstUserEventInviteArgs = {
  cursor?: InputMaybe<UserEventInviteWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserEventInviteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserEventInviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventInviteWhereInput>;
};

export type QueryFindFirstUserEventInviteOrThrowArgs = {
  cursor?: InputMaybe<UserEventInviteWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserEventInviteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserEventInviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventInviteWhereInput>;
};

export type QueryFindFirstUserEventOrThrowArgs = {
  cursor?: InputMaybe<UserEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventWhereInput>;
};

export type QueryFindFirstUserNotificationPreferencesArgs = {
  cursor?: InputMaybe<UserNotificationPreferencesWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserNotificationPreferencesScalarFieldEnum>>;
  orderBy?: InputMaybe<
    Array<UserNotificationPreferencesOrderByWithRelationInput>
  >;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserNotificationPreferencesWhereInput>;
};

export type QueryFindFirstUserNotificationPreferencesOrThrowArgs = {
  cursor?: InputMaybe<UserNotificationPreferencesWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserNotificationPreferencesScalarFieldEnum>>;
  orderBy?: InputMaybe<
    Array<UserNotificationPreferencesOrderByWithRelationInput>
  >;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserNotificationPreferencesWhereInput>;
};

export type QueryFindFirstUserOrThrowArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryFindFirstUserRatingArgs = {
  cursor?: InputMaybe<UserRatingWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserRatingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserRatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserRatingWhereInput>;
};

export type QueryFindFirstUserRatingOrThrowArgs = {
  cursor?: InputMaybe<UserRatingWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserRatingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserRatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserRatingWhereInput>;
};

export type QueryFindFirstUserTeamArgs = {
  cursor?: InputMaybe<UserTeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamWhereInput>;
};

export type QueryFindFirstUserTeamEventArgs = {
  cursor?: InputMaybe<UserTeamEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type QueryFindFirstUserTeamEventOrThrowArgs = {
  cursor?: InputMaybe<UserTeamEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type QueryFindFirstUserTeamInviteArgs = {
  cursor?: InputMaybe<UserTeamInviteWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamInviteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamInviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamInviteWhereInput>;
};

export type QueryFindFirstUserTeamInviteOrThrowArgs = {
  cursor?: InputMaybe<UserTeamInviteWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamInviteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamInviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamInviteWhereInput>;
};

export type QueryFindFirstUserTeamOrThrowArgs = {
  cursor?: InputMaybe<UserTeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamWhereInput>;
};

export type QueryFindManyUserNotificationPreferencesArgs = {
  cursor?: InputMaybe<UserNotificationPreferencesWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserNotificationPreferencesScalarFieldEnum>>;
  orderBy?: InputMaybe<
    Array<UserNotificationPreferencesOrderByWithRelationInput>
  >;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserNotificationPreferencesWhereInput>;
};

export type QueryFindUniqueUserNotificationPreferencesArgs = {
  where: UserNotificationPreferencesWhereUniqueInput;
};

export type QueryFindUniqueUserNotificationPreferencesOrThrowArgs = {
  where: UserNotificationPreferencesWhereUniqueInput;
};

export type QueryGetCourtArgs = {
  where: CourtWhereUniqueInput;
};

export type QueryGetEventArgs = {
  where: EventWhereUniqueInput;
};

export type QueryGetLocationArgs = {
  where: LocationWhereUniqueInput;
};

export type QueryGetMatchArgs = {
  where: MatchWhereUniqueInput;
};

export type QueryGetOrgArgs = {
  where: OrgWhereUniqueInput;
};

export type QueryGetOrgLocationArgs = {
  where: OrgLocationWhereUniqueInput;
};

export type QueryGetPoolArgs = {
  where: PoolWhereUniqueInput;
};

export type QueryGetRatingArgs = {
  where: RatingWhereUniqueInput;
};

export type QueryGetRoundArgs = {
  where: RoundWhereUniqueInput;
};

export type QueryGetScoreArgs = {
  where: ScoreWhereUniqueInput;
};

export type QueryGetStageArgs = {
  where: StageWhereUniqueInput;
};

export type QueryGetTeamArgs = {
  where: TeamWhereUniqueInput;
};

export type QueryGetTeamEventArgs = {
  where: TeamEventWhereUniqueInput;
};

export type QueryGetUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryGetUserEventArgs = {
  where: UserEventWhereUniqueInput;
};

export type QueryGetUserEventInviteArgs = {
  where: UserEventInviteWhereUniqueInput;
};

export type QueryGetUserRatingArgs = {
  where: UserRatingWhereUniqueInput;
};

export type QueryGetUserTeamArgs = {
  where: UserTeamWhereUniqueInput;
};

export type QueryGetUserTeamEventArgs = {
  where: UserTeamEventWhereUniqueInput;
};

export type QueryGetUserTeamInviteArgs = {
  where: UserTeamInviteWhereUniqueInput;
};

export type QueryGetUsersInQueueArgs = {
  eventId: Scalars['String']['input'];
};

export type QueryGroupByCourtArgs = {
  by: Array<CourtScalarFieldEnum>;
  having?: InputMaybe<CourtScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CourtOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CourtWhereInput>;
};

export type QueryGroupByEventArgs = {
  by: Array<EventScalarFieldEnum>;
  having?: InputMaybe<EventScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EventOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventWhereInput>;
};

export type QueryGroupByLocationArgs = {
  by: Array<LocationScalarFieldEnum>;
  having?: InputMaybe<LocationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LocationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryGroupByMatchArgs = {
  by: Array<MatchScalarFieldEnum>;
  having?: InputMaybe<MatchScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MatchOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MatchWhereInput>;
};

export type QueryGroupByOrgArgs = {
  by: Array<OrgScalarFieldEnum>;
  having?: InputMaybe<OrgScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<OrgOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgWhereInput>;
};

export type QueryGroupByOrgLocationArgs = {
  by: Array<OrgLocationScalarFieldEnum>;
  having?: InputMaybe<OrgLocationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<OrgLocationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgLocationWhereInput>;
};

export type QueryGroupByPoolArgs = {
  by: Array<PoolScalarFieldEnum>;
  having?: InputMaybe<PoolScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PoolOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PoolWhereInput>;
};

export type QueryGroupByRatingArgs = {
  by: Array<RatingScalarFieldEnum>;
  having?: InputMaybe<RatingScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RatingOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RatingWhereInput>;
};

export type QueryGroupByRoundArgs = {
  by: Array<RoundScalarFieldEnum>;
  having?: InputMaybe<RoundScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RoundOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoundWhereInput>;
};

export type QueryGroupByScoreArgs = {
  by: Array<ScoreScalarFieldEnum>;
  having?: InputMaybe<ScoreScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};

export type QueryGroupByStageArgs = {
  by: Array<StageScalarFieldEnum>;
  having?: InputMaybe<StageScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StageOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StageWhereInput>;
};

export type QueryGroupByTeamArgs = {
  by: Array<TeamScalarFieldEnum>;
  having?: InputMaybe<TeamScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TeamOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamWhereInput>;
};

export type QueryGroupByTeamEventArgs = {
  by: Array<TeamEventScalarFieldEnum>;
  having?: InputMaybe<TeamEventScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TeamEventOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamEventWhereInput>;
};

export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryGroupByUserEventArgs = {
  by: Array<UserEventScalarFieldEnum>;
  having?: InputMaybe<UserEventScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserEventOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventWhereInput>;
};

export type QueryGroupByUserEventInviteArgs = {
  by: Array<UserEventInviteScalarFieldEnum>;
  having?: InputMaybe<UserEventInviteScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserEventInviteOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventInviteWhereInput>;
};

export type QueryGroupByUserNotificationPreferencesArgs = {
  by: Array<UserNotificationPreferencesScalarFieldEnum>;
  having?: InputMaybe<UserNotificationPreferencesScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<
    Array<UserNotificationPreferencesOrderByWithAggregationInput>
  >;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserNotificationPreferencesWhereInput>;
};

export type QueryGroupByUserRatingArgs = {
  by: Array<UserRatingScalarFieldEnum>;
  having?: InputMaybe<UserRatingScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserRatingOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserRatingWhereInput>;
};

export type QueryGroupByUserTeamArgs = {
  by: Array<UserTeamScalarFieldEnum>;
  having?: InputMaybe<UserTeamScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserTeamOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamWhereInput>;
};

export type QueryGroupByUserTeamEventArgs = {
  by: Array<UserTeamEventScalarFieldEnum>;
  having?: InputMaybe<UserTeamEventScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserTeamEventOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type QueryGroupByUserTeamInviteArgs = {
  by: Array<UserTeamInviteScalarFieldEnum>;
  having?: InputMaybe<UserTeamInviteScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserTeamInviteOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamInviteWhereInput>;
};

export type QueryLocationArgs = {
  where: LocationWhereUniqueInput;
};

export type QueryLocationsArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryMatchArgs = {
  where: MatchWhereUniqueInput;
};

export type QueryMatchesArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MatchWhereInput>;
};

export type QueryOrgArgs = {
  where: OrgWhereUniqueInput;
};

export type QueryOrgLocationArgs = {
  where: OrgLocationWhereUniqueInput;
};

export type QueryOrgLocationsArgs = {
  cursor?: InputMaybe<OrgLocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrgLocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrgLocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgLocationWhereInput>;
};

export type QueryOrgsArgs = {
  cursor?: InputMaybe<OrgWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrgScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrgOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrgWhereInput>;
};

export type QueryPoolArgs = {
  where: PoolWhereUniqueInput;
};

export type QueryPoolsArgs = {
  cursor?: InputMaybe<PoolWhereUniqueInput>;
  distinct?: InputMaybe<Array<PoolScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PoolOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PoolWhereInput>;
};

export type QueryRatingArgs = {
  where: RatingWhereUniqueInput;
};

export type QueryRatingsArgs = {
  cursor?: InputMaybe<RatingWhereUniqueInput>;
  distinct?: InputMaybe<Array<RatingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RatingWhereInput>;
};

export type QueryRoundArgs = {
  where: RoundWhereUniqueInput;
};

export type QueryRoundsArgs = {
  cursor?: InputMaybe<RoundWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoundWhereInput>;
};

export type QueryScoreArgs = {
  where: ScoreWhereUniqueInput;
};

export type QueryScoresArgs = {
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};

export type QueryStageArgs = {
  where: StageWhereUniqueInput;
};

export type QueryStagesArgs = {
  cursor?: InputMaybe<StageWhereUniqueInput>;
  distinct?: InputMaybe<Array<StageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StageWhereInput>;
};

export type QueryTeamArgs = {
  where: TeamWhereUniqueInput;
};

export type QueryTeamEventArgs = {
  where: TeamEventWhereUniqueInput;
};

export type QueryTeamEventsArgs = {
  cursor?: InputMaybe<TeamEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamEventWhereInput>;
};

export type QueryTeamsArgs = {
  cursor?: InputMaybe<TeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamWhereInput>;
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryUserEventArgs = {
  where: UserEventWhereUniqueInput;
};

export type QueryUserEventInviteArgs = {
  where: UserEventInviteWhereUniqueInput;
};

export type QueryUserEventInvitesArgs = {
  cursor?: InputMaybe<UserEventInviteWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserEventInviteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserEventInviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventInviteWhereInput>;
};

export type QueryUserEventsArgs = {
  cursor?: InputMaybe<UserEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserEventWhereInput>;
};

export type QueryUserRatingArgs = {
  where: UserRatingWhereUniqueInput;
};

export type QueryUserRatingsArgs = {
  cursor?: InputMaybe<UserRatingWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserRatingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserRatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserRatingWhereInput>;
};

export type QueryUserTeamArgs = {
  where: UserTeamWhereUniqueInput;
};

export type QueryUserTeamEventArgs = {
  where: UserTeamEventWhereUniqueInput;
};

export type QueryUserTeamEventsArgs = {
  cursor?: InputMaybe<UserTeamEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type QueryUserTeamInviteArgs = {
  where: UserTeamInviteWhereUniqueInput;
};

export type QueryUserTeamInvitesArgs = {
  cursor?: InputMaybe<UserTeamInviteWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamInviteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamInviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamInviteWhereInput>;
};

export type QueryUserTeamsArgs = {
  cursor?: InputMaybe<UserTeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamWhereInput>;
};

export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export type Rating = {
  __typename?: 'Rating';
  _count?: Maybe<RatingCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  rating: Scalars['String']['output'];
  sport: Sport;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type RatingCount = {
  __typename?: 'RatingCount';
  userRating: Scalars['Int']['output'];
};

export type RatingCountUserRatingArgs = {
  where?: InputMaybe<UserRatingWhereInput>;
};

export type RatingCountAggregate = {
  __typename?: 'RatingCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
  sport: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type RatingCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
};

export type RatingCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['String']['input'];
  sport: Sport;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutRatingInput>;
};

export type RatingCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['String']['input'];
  sport: Sport;
};

export type RatingCreateNestedOneWithoutUserRatingInput = {
  connect?: InputMaybe<RatingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RatingCreateOrConnectWithoutUserRatingInput>;
  create?: InputMaybe<RatingCreateWithoutUserRatingInput>;
};

export type RatingCreateOrConnectWithoutUserRatingInput = {
  create: RatingCreateWithoutUserRatingInput;
  where: RatingWhereUniqueInput;
};

export type RatingCreateWithoutUserRatingInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['String']['input'];
  sport: Sport;
};

export type RatingGroupBy = {
  __typename?: 'RatingGroupBy';
  _count?: Maybe<RatingCountAggregate>;
  _max?: Maybe<RatingMaxAggregate>;
  _min?: Maybe<RatingMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  rating: Scalars['String']['output'];
  sport: Sport;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type RatingMaxAggregate = {
  __typename?: 'RatingMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Sport>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type RatingMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
};

export type RatingMinAggregate = {
  __typename?: 'RatingMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Sport>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type RatingMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
};

export type RatingOrderByWithAggregationInput = {
  _count?: InputMaybe<RatingCountOrderByAggregateInput>;
  _max?: InputMaybe<RatingMaxOrderByAggregateInput>;
  _min?: InputMaybe<RatingMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
};

export type RatingOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
  userRating?: InputMaybe<UserRatingOrderByRelationAggregateInput>;
};

export type RatingRelationFilter = {
  is?: InputMaybe<RatingWhereInput>;
  isNot?: InputMaybe<RatingWhereInput>;
};

export enum RatingScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Rating = 'rating',
  Sport = 'sport',
  UpdatedAt = 'updatedAt',
}

export type RatingScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RatingScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RatingScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RatingScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  rating?: InputMaybe<StringWithAggregatesFilter>;
  sport?: InputMaybe<EnumSportWithAggregatesFilter>;
};

export type RatingUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating?: InputMaybe<StringFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutRatingNestedInput>;
};

export type RatingUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating?: InputMaybe<StringFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
};

export type RatingUpdateOneRequiredWithoutUserRatingNestedInput = {
  connect?: InputMaybe<RatingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RatingCreateOrConnectWithoutUserRatingInput>;
  create?: InputMaybe<RatingCreateWithoutUserRatingInput>;
  update?: InputMaybe<RatingUpdateToOneWithWhereWithoutUserRatingInput>;
  upsert?: InputMaybe<RatingUpsertWithoutUserRatingInput>;
};

export type RatingUpdateToOneWithWhereWithoutUserRatingInput = {
  data: RatingUpdateWithoutUserRatingInput;
  where?: InputMaybe<RatingWhereInput>;
};

export type RatingUpdateWithoutUserRatingInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating?: InputMaybe<StringFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
};

export type RatingUpsertWithoutUserRatingInput = {
  create: RatingCreateWithoutUserRatingInput;
  update: RatingUpdateWithoutUserRatingInput;
  where?: InputMaybe<RatingWhereInput>;
};

export type RatingWhereInput = {
  AND?: InputMaybe<Array<RatingWhereInput>>;
  NOT?: InputMaybe<Array<RatingWhereInput>>;
  OR?: InputMaybe<Array<RatingWhereInput>>;
  id?: InputMaybe<StringFilter>;
  rating?: InputMaybe<StringFilter>;
  sport?: InputMaybe<EnumSportFilter>;
  userRating?: InputMaybe<UserRatingListRelationFilter>;
};

export type RatingWhereUniqueInput = {
  AND?: InputMaybe<Array<RatingWhereInput>>;
  NOT?: InputMaybe<Array<RatingWhereInput>>;
  OR?: InputMaybe<Array<RatingWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<StringFilter>;
  sport?: InputMaybe<EnumSportFilter>;
  userRating?: InputMaybe<UserRatingListRelationFilter>;
};

export type Round = {
  __typename?: 'Round';
  _count?: Maybe<RoundCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  match: Match;
  matchId: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  scores: Array<Score>;
  updatedAt: Scalars['DateTimeISO']['output'];
  winningTeam?: Maybe<Team>;
  winningTeamId?: Maybe<Scalars['String']['output']>;
};

export type RoundScoresArgs = {
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};

export type RoundWinningTeamArgs = {
  where?: InputMaybe<TeamWhereInput>;
};

export type RoundAvgAggregate = {
  __typename?: 'RoundAvgAggregate';
  order?: Maybe<Scalars['Float']['output']>;
};

export type RoundAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type RoundCount = {
  __typename?: 'RoundCount';
  scores: Scalars['Int']['output'];
};

export type RoundCountScoresArgs = {
  where?: InputMaybe<ScoreWhereInput>;
};

export type RoundCountAggregate = {
  __typename?: 'RoundCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  matchId: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  winningTeamId: Scalars['Int']['output'];
};

export type RoundCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  winningTeamId?: InputMaybe<SortOrder>;
};

export type RoundCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  match: MatchCreateNestedOneWithoutRoundsInput;
  order: Scalars['Int']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutRoundInput>;
  winningTeam?: InputMaybe<TeamCreateNestedOneWithoutWonRoundsInput>;
};

export type RoundCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  matchId: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  winningTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type RoundCreateManyMatchInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  winningTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type RoundCreateManyMatchInputEnvelope = {
  data: Array<RoundCreateManyMatchInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoundCreateManyWinningTeamInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  matchId: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type RoundCreateManyWinningTeamInputEnvelope = {
  data: Array<RoundCreateManyWinningTeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoundCreateNestedManyWithoutMatchInput = {
  connect?: InputMaybe<Array<RoundWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoundCreateOrConnectWithoutMatchInput>>;
  create?: InputMaybe<Array<RoundCreateWithoutMatchInput>>;
  createMany?: InputMaybe<RoundCreateManyMatchInputEnvelope>;
};

export type RoundCreateNestedManyWithoutWinningTeamInput = {
  connect?: InputMaybe<Array<RoundWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RoundCreateOrConnectWithoutWinningTeamInput>
  >;
  create?: InputMaybe<Array<RoundCreateWithoutWinningTeamInput>>;
  createMany?: InputMaybe<RoundCreateManyWinningTeamInputEnvelope>;
};

export type RoundCreateNestedOneWithoutScoresInput = {
  connect?: InputMaybe<RoundWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoundCreateOrConnectWithoutScoresInput>;
  create?: InputMaybe<RoundCreateWithoutScoresInput>;
};

export type RoundCreateOrConnectWithoutMatchInput = {
  create: RoundCreateWithoutMatchInput;
  where: RoundWhereUniqueInput;
};

export type RoundCreateOrConnectWithoutScoresInput = {
  create: RoundCreateWithoutScoresInput;
  where: RoundWhereUniqueInput;
};

export type RoundCreateOrConnectWithoutWinningTeamInput = {
  create: RoundCreateWithoutWinningTeamInput;
  where: RoundWhereUniqueInput;
};

export type RoundCreateWithoutMatchInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutRoundInput>;
  winningTeam?: InputMaybe<TeamCreateNestedOneWithoutWonRoundsInput>;
};

export type RoundCreateWithoutScoresInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  match: MatchCreateNestedOneWithoutRoundsInput;
  order: Scalars['Int']['input'];
  winningTeam?: InputMaybe<TeamCreateNestedOneWithoutWonRoundsInput>;
};

export type RoundCreateWithoutWinningTeamInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  match: MatchCreateNestedOneWithoutRoundsInput;
  order: Scalars['Int']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutRoundInput>;
};

export type RoundGroupBy = {
  __typename?: 'RoundGroupBy';
  _avg?: Maybe<RoundAvgAggregate>;
  _count?: Maybe<RoundCountAggregate>;
  _max?: Maybe<RoundMaxAggregate>;
  _min?: Maybe<RoundMinAggregate>;
  _sum?: Maybe<RoundSumAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  matchId: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  winningTeamId?: Maybe<Scalars['String']['output']>;
};

export type RoundListRelationFilter = {
  every?: InputMaybe<RoundWhereInput>;
  none?: InputMaybe<RoundWhereInput>;
  some?: InputMaybe<RoundWhereInput>;
};

export type RoundMatchIdOrderCompoundUniqueInput = {
  matchId: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type RoundMaxAggregate = {
  __typename?: 'RoundMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  matchId?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  winningTeamId?: Maybe<Scalars['String']['output']>;
};

export type RoundMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  winningTeamId?: InputMaybe<SortOrder>;
};

export type RoundMinAggregate = {
  __typename?: 'RoundMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  matchId?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  winningTeamId?: Maybe<Scalars['String']['output']>;
};

export type RoundMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  winningTeamId?: InputMaybe<SortOrder>;
};

export type RoundOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RoundOrderByWithAggregationInput = {
  _avg?: InputMaybe<RoundAvgOrderByAggregateInput>;
  _count?: InputMaybe<RoundCountOrderByAggregateInput>;
  _max?: InputMaybe<RoundMaxOrderByAggregateInput>;
  _min?: InputMaybe<RoundMinOrderByAggregateInput>;
  _sum?: InputMaybe<RoundSumOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  winningTeamId?: InputMaybe<SortOrderInput>;
};

export type RoundOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  match?: InputMaybe<MatchOrderByWithRelationInput>;
  matchId?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  scores?: InputMaybe<ScoreOrderByRelationAggregateInput>;
  winningTeam?: InputMaybe<TeamOrderByWithRelationInput>;
  winningTeamId?: InputMaybe<SortOrderInput>;
};

export type RoundRelationFilter = {
  is?: InputMaybe<RoundWhereInput>;
  isNot?: InputMaybe<RoundWhereInput>;
};

export enum RoundScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  MatchId = 'matchId',
  Order = 'order',
  UpdatedAt = 'updatedAt',
  WinningTeamId = 'winningTeamId',
}

export type RoundScalarWhereInput = {
  AND?: InputMaybe<Array<RoundScalarWhereInput>>;
  NOT?: InputMaybe<Array<RoundScalarWhereInput>>;
  OR?: InputMaybe<Array<RoundScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  matchId?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  winningTeamId?: InputMaybe<StringNullableFilter>;
};

export type RoundScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RoundScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RoundScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RoundScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  matchId?: InputMaybe<StringWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  winningTeamId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type RoundSumAggregate = {
  __typename?: 'RoundSumAggregate';
  order?: Maybe<Scalars['Int']['output']>;
};

export type RoundSumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type RoundUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  match?: InputMaybe<MatchUpdateOneRequiredWithoutRoundsNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutRoundNestedInput>;
  winningTeam?: InputMaybe<TeamUpdateOneWithoutWonRoundsNestedInput>;
};

export type RoundUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type RoundUpdateManyWithWhereWithoutMatchInput = {
  data: RoundUpdateManyMutationInput;
  where: RoundScalarWhereInput;
};

export type RoundUpdateManyWithWhereWithoutWinningTeamInput = {
  data: RoundUpdateManyMutationInput;
  where: RoundScalarWhereInput;
};

export type RoundUpdateManyWithoutMatchNestedInput = {
  connect?: InputMaybe<Array<RoundWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoundCreateOrConnectWithoutMatchInput>>;
  create?: InputMaybe<Array<RoundCreateWithoutMatchInput>>;
  createMany?: InputMaybe<RoundCreateManyMatchInputEnvelope>;
  delete?: InputMaybe<Array<RoundWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoundScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoundWhereUniqueInput>>;
  set?: InputMaybe<Array<RoundWhereUniqueInput>>;
  update?: InputMaybe<Array<RoundUpdateWithWhereUniqueWithoutMatchInput>>;
  updateMany?: InputMaybe<Array<RoundUpdateManyWithWhereWithoutMatchInput>>;
  upsert?: InputMaybe<Array<RoundUpsertWithWhereUniqueWithoutMatchInput>>;
};

export type RoundUpdateManyWithoutWinningTeamNestedInput = {
  connect?: InputMaybe<Array<RoundWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RoundCreateOrConnectWithoutWinningTeamInput>
  >;
  create?: InputMaybe<Array<RoundCreateWithoutWinningTeamInput>>;
  createMany?: InputMaybe<RoundCreateManyWinningTeamInputEnvelope>;
  delete?: InputMaybe<Array<RoundWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoundScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoundWhereUniqueInput>>;
  set?: InputMaybe<Array<RoundWhereUniqueInput>>;
  update?: InputMaybe<Array<RoundUpdateWithWhereUniqueWithoutWinningTeamInput>>;
  updateMany?: InputMaybe<
    Array<RoundUpdateManyWithWhereWithoutWinningTeamInput>
  >;
  upsert?: InputMaybe<Array<RoundUpsertWithWhereUniqueWithoutWinningTeamInput>>;
};

export type RoundUpdateOneRequiredWithoutScoresNestedInput = {
  connect?: InputMaybe<RoundWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoundCreateOrConnectWithoutScoresInput>;
  create?: InputMaybe<RoundCreateWithoutScoresInput>;
  update?: InputMaybe<RoundUpdateToOneWithWhereWithoutScoresInput>;
  upsert?: InputMaybe<RoundUpsertWithoutScoresInput>;
};

export type RoundUpdateToOneWithWhereWithoutScoresInput = {
  data: RoundUpdateWithoutScoresInput;
  where?: InputMaybe<RoundWhereInput>;
};

export type RoundUpdateWithWhereUniqueWithoutMatchInput = {
  data: RoundUpdateWithoutMatchInput;
  where: RoundWhereUniqueInput;
};

export type RoundUpdateWithWhereUniqueWithoutWinningTeamInput = {
  data: RoundUpdateWithoutWinningTeamInput;
  where: RoundWhereUniqueInput;
};

export type RoundUpdateWithoutMatchInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutRoundNestedInput>;
  winningTeam?: InputMaybe<TeamUpdateOneWithoutWonRoundsNestedInput>;
};

export type RoundUpdateWithoutScoresInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  match?: InputMaybe<MatchUpdateOneRequiredWithoutRoundsNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  winningTeam?: InputMaybe<TeamUpdateOneWithoutWonRoundsNestedInput>;
};

export type RoundUpdateWithoutWinningTeamInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  match?: InputMaybe<MatchUpdateOneRequiredWithoutRoundsNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutRoundNestedInput>;
};

export type RoundUpsertWithWhereUniqueWithoutMatchInput = {
  create: RoundCreateWithoutMatchInput;
  update: RoundUpdateWithoutMatchInput;
  where: RoundWhereUniqueInput;
};

export type RoundUpsertWithWhereUniqueWithoutWinningTeamInput = {
  create: RoundCreateWithoutWinningTeamInput;
  update: RoundUpdateWithoutWinningTeamInput;
  where: RoundWhereUniqueInput;
};

export type RoundUpsertWithoutScoresInput = {
  create: RoundCreateWithoutScoresInput;
  update: RoundUpdateWithoutScoresInput;
  where?: InputMaybe<RoundWhereInput>;
};

export type RoundWhereInput = {
  AND?: InputMaybe<Array<RoundWhereInput>>;
  NOT?: InputMaybe<Array<RoundWhereInput>>;
  OR?: InputMaybe<Array<RoundWhereInput>>;
  id?: InputMaybe<StringFilter>;
  match?: InputMaybe<MatchRelationFilter>;
  matchId?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  scores?: InputMaybe<ScoreListRelationFilter>;
  winningTeam?: InputMaybe<TeamNullableRelationFilter>;
  winningTeamId?: InputMaybe<StringNullableFilter>;
};

export type RoundWhereUniqueInput = {
  AND?: InputMaybe<Array<RoundWhereInput>>;
  NOT?: InputMaybe<Array<RoundWhereInput>>;
  OR?: InputMaybe<Array<RoundWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  match?: InputMaybe<MatchRelationFilter>;
  matchId?: InputMaybe<StringFilter>;
  matchId_order?: InputMaybe<RoundMatchIdOrderCompoundUniqueInput>;
  order?: InputMaybe<IntFilter>;
  scores?: InputMaybe<ScoreListRelationFilter>;
  winningTeam?: InputMaybe<TeamNullableRelationFilter>;
  winningTeamId?: InputMaybe<StringNullableFilter>;
};

export type Score = {
  __typename?: 'Score';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  round: Round;
  roundId: Scalars['String']['output'];
  team: Team;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  value: Scalars['Int']['output'];
};

export type ScoreAvgAggregate = {
  __typename?: 'ScoreAvgAggregate';
  value?: Maybe<Scalars['Float']['output']>;
};

export type ScoreAvgOrderByAggregateInput = {
  value?: InputMaybe<SortOrder>;
};

export type ScoreCountAggregate = {
  __typename?: 'ScoreCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  roundId: Scalars['Int']['output'];
  teamId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type ScoreCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  roundId?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ScoreCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  round: RoundCreateNestedOneWithoutScoresInput;
  team: TeamCreateNestedOneWithoutScoresInput;
  value: Scalars['Int']['input'];
};

export type ScoreCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  roundId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export type ScoreCreateManyRoundInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export type ScoreCreateManyRoundInputEnvelope = {
  data: Array<ScoreCreateManyRoundInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ScoreCreateManyTeamInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  roundId: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export type ScoreCreateManyTeamInputEnvelope = {
  data: Array<ScoreCreateManyTeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ScoreCreateNestedManyWithoutRoundInput = {
  connect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScoreCreateOrConnectWithoutRoundInput>>;
  create?: InputMaybe<Array<ScoreCreateWithoutRoundInput>>;
  createMany?: InputMaybe<ScoreCreateManyRoundInputEnvelope>;
};

export type ScoreCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScoreCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<ScoreCreateWithoutTeamInput>>;
  createMany?: InputMaybe<ScoreCreateManyTeamInputEnvelope>;
};

export type ScoreCreateOrConnectWithoutRoundInput = {
  create: ScoreCreateWithoutRoundInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreCreateOrConnectWithoutTeamInput = {
  create: ScoreCreateWithoutTeamInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreCreateWithoutRoundInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  team: TeamCreateNestedOneWithoutScoresInput;
  value: Scalars['Int']['input'];
};

export type ScoreCreateWithoutTeamInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  round: RoundCreateNestedOneWithoutScoresInput;
  value: Scalars['Int']['input'];
};

export type ScoreGroupBy = {
  __typename?: 'ScoreGroupBy';
  _avg?: Maybe<ScoreAvgAggregate>;
  _count?: Maybe<ScoreCountAggregate>;
  _max?: Maybe<ScoreMaxAggregate>;
  _min?: Maybe<ScoreMinAggregate>;
  _sum?: Maybe<ScoreSumAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  roundId: Scalars['String']['output'];
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  value: Scalars['Int']['output'];
};

export type ScoreListRelationFilter = {
  every?: InputMaybe<ScoreWhereInput>;
  none?: InputMaybe<ScoreWhereInput>;
  some?: InputMaybe<ScoreWhereInput>;
};

export type ScoreMaxAggregate = {
  __typename?: 'ScoreMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  roundId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type ScoreMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  roundId?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ScoreMinAggregate = {
  __typename?: 'ScoreMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  roundId?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type ScoreMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  roundId?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ScoreOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ScoreOrderByWithAggregationInput = {
  _avg?: InputMaybe<ScoreAvgOrderByAggregateInput>;
  _count?: InputMaybe<ScoreCountOrderByAggregateInput>;
  _max?: InputMaybe<ScoreMaxOrderByAggregateInput>;
  _min?: InputMaybe<ScoreMinOrderByAggregateInput>;
  _sum?: InputMaybe<ScoreSumOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  roundId?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ScoreOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  round?: InputMaybe<RoundOrderByWithRelationInput>;
  roundId?: InputMaybe<SortOrder>;
  team?: InputMaybe<TeamOrderByWithRelationInput>;
  teamId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ScoreRoundIdTeamIdCompoundUniqueInput = {
  roundId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export enum ScoreScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  RoundId = 'roundId',
  TeamId = 'teamId',
  UpdatedAt = 'updatedAt',
  Value = 'value',
}

export type ScoreScalarWhereInput = {
  AND?: InputMaybe<Array<ScoreScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScoreScalarWhereInput>>;
  OR?: InputMaybe<Array<ScoreScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  roundId?: InputMaybe<StringFilter>;
  teamId?: InputMaybe<StringFilter>;
  value?: InputMaybe<IntFilter>;
};

export type ScoreScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ScoreScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ScoreScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ScoreScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  roundId?: InputMaybe<StringWithAggregatesFilter>;
  teamId?: InputMaybe<StringWithAggregatesFilter>;
  value?: InputMaybe<IntWithAggregatesFilter>;
};

export type ScoreSumAggregate = {
  __typename?: 'ScoreSumAggregate';
  value?: Maybe<Scalars['Int']['output']>;
};

export type ScoreSumOrderByAggregateInput = {
  value?: InputMaybe<SortOrder>;
};

export type ScoreUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  round?: InputMaybe<RoundUpdateOneRequiredWithoutScoresNestedInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutScoresNestedInput>;
  value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ScoreUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ScoreUpdateManyWithWhereWithoutRoundInput = {
  data: ScoreUpdateManyMutationInput;
  where: ScoreScalarWhereInput;
};

export type ScoreUpdateManyWithWhereWithoutTeamInput = {
  data: ScoreUpdateManyMutationInput;
  where: ScoreScalarWhereInput;
};

export type ScoreUpdateManyWithoutRoundNestedInput = {
  connect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScoreCreateOrConnectWithoutRoundInput>>;
  create?: InputMaybe<Array<ScoreCreateWithoutRoundInput>>;
  createMany?: InputMaybe<ScoreCreateManyRoundInputEnvelope>;
  delete?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScoreScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  set?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  update?: InputMaybe<Array<ScoreUpdateWithWhereUniqueWithoutRoundInput>>;
  updateMany?: InputMaybe<Array<ScoreUpdateManyWithWhereWithoutRoundInput>>;
  upsert?: InputMaybe<Array<ScoreUpsertWithWhereUniqueWithoutRoundInput>>;
};

export type ScoreUpdateManyWithoutTeamNestedInput = {
  connect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScoreCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<ScoreCreateWithoutTeamInput>>;
  createMany?: InputMaybe<ScoreCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScoreScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  set?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  update?: InputMaybe<Array<ScoreUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<ScoreUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<ScoreUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type ScoreUpdateWithWhereUniqueWithoutRoundInput = {
  data: ScoreUpdateWithoutRoundInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreUpdateWithWhereUniqueWithoutTeamInput = {
  data: ScoreUpdateWithoutTeamInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreUpdateWithoutRoundInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutScoresNestedInput>;
  value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ScoreUpdateWithoutTeamInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  round?: InputMaybe<RoundUpdateOneRequiredWithoutScoresNestedInput>;
  value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ScoreUpsertWithWhereUniqueWithoutRoundInput = {
  create: ScoreCreateWithoutRoundInput;
  update: ScoreUpdateWithoutRoundInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreUpsertWithWhereUniqueWithoutTeamInput = {
  create: ScoreCreateWithoutTeamInput;
  update: ScoreUpdateWithoutTeamInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreWhereInput = {
  AND?: InputMaybe<Array<ScoreWhereInput>>;
  NOT?: InputMaybe<Array<ScoreWhereInput>>;
  OR?: InputMaybe<Array<ScoreWhereInput>>;
  id?: InputMaybe<StringFilter>;
  round?: InputMaybe<RoundRelationFilter>;
  roundId?: InputMaybe<StringFilter>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<StringFilter>;
  value?: InputMaybe<IntFilter>;
};

export type ScoreWhereUniqueInput = {
  AND?: InputMaybe<Array<ScoreWhereInput>>;
  NOT?: InputMaybe<Array<ScoreWhereInput>>;
  OR?: InputMaybe<Array<ScoreWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  round?: InputMaybe<RoundRelationFilter>;
  roundId?: InputMaybe<StringFilter>;
  roundId_teamId?: InputMaybe<ScoreRoundIdTeamIdCompoundUniqueInput>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<StringFilter>;
  value?: InputMaybe<IntFilter>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export enum Sport {
  Basketball = 'BASKETBALL',
  Pickleball = 'PICKLEBALL',
  Soccer = 'SOCCER',
  Spikeball = 'SPIKEBALL',
  TableTennis = 'TABLE_TENNIS',
  Tennis = 'TENNIS',
  VballIndoor = 'VBALL_INDOOR',
  VballSand = 'VBALL_SAND',
}

export type Stage = {
  __typename?: 'Stage';
  _count?: Maybe<StageCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  matches: Array<Match>;
  name?: Maybe<Scalars['String']['output']>;
  type: StageType;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type StageMatchesArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MatchWhereInput>;
};

export type StageCount = {
  __typename?: 'StageCount';
  matches: Scalars['Int']['output'];
};

export type StageCountMatchesArgs = {
  where?: InputMaybe<MatchWhereInput>;
};

export type StageCountAggregate = {
  __typename?: 'StageCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type StageCountOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type StageCreateInput = {
  event: EventCreateNestedOneWithoutStagesInput;
  id?: InputMaybe<Scalars['String']['input']>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutStageInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StageType>;
};

export type StageCreateManyEventInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StageType>;
};

export type StageCreateManyEventInputEnvelope = {
  data: Array<StageCreateManyEventInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StageCreateManyInput = {
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StageType>;
};

export type StageCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<StageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StageCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<StageCreateWithoutEventInput>>;
  createMany?: InputMaybe<StageCreateManyEventInputEnvelope>;
};

export type StageCreateNestedOneWithoutMatchesInput = {
  connect?: InputMaybe<StageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StageCreateOrConnectWithoutMatchesInput>;
  create?: InputMaybe<StageCreateWithoutMatchesInput>;
};

export type StageCreateOrConnectWithoutEventInput = {
  create: StageCreateWithoutEventInput;
  where: StageWhereUniqueInput;
};

export type StageCreateOrConnectWithoutMatchesInput = {
  create: StageCreateWithoutMatchesInput;
  where: StageWhereUniqueInput;
};

export type StageCreateWithoutEventInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutStageInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StageType>;
};

export type StageCreateWithoutMatchesInput = {
  event: EventCreateNestedOneWithoutStagesInput;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StageType>;
};

export type StageGroupBy = {
  __typename?: 'StageGroupBy';
  _count?: Maybe<StageCountAggregate>;
  _max?: Maybe<StageMaxAggregate>;
  _min?: Maybe<StageMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: StageType;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type StageListRelationFilter = {
  every?: InputMaybe<StageWhereInput>;
  none?: InputMaybe<StageWhereInput>;
  some?: InputMaybe<StageWhereInput>;
};

export type StageMaxAggregate = {
  __typename?: 'StageMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<StageType>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type StageMaxOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type StageMinAggregate = {
  __typename?: 'StageMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<StageType>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type StageMinOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type StageNullableRelationFilter = {
  is?: InputMaybe<StageWhereInput>;
  isNot?: InputMaybe<StageWhereInput>;
};

export type StageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StageOrderByWithAggregationInput = {
  _count?: InputMaybe<StageCountOrderByAggregateInput>;
  _max?: InputMaybe<StageMaxOrderByAggregateInput>;
  _min?: InputMaybe<StageMinOrderByAggregateInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
};

export type StageOrderByWithRelationInput = {
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  matches?: InputMaybe<MatchOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
};

export enum StageScalarFieldEnum {
  CreatedAt = 'createdAt',
  EventId = 'eventId',
  Id = 'id',
  Name = 'name',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

export type StageScalarWhereInput = {
  AND?: InputMaybe<Array<StageScalarWhereInput>>;
  NOT?: InputMaybe<Array<StageScalarWhereInput>>;
  OR?: InputMaybe<Array<StageScalarWhereInput>>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumStageTypeFilter>;
};

export type StageScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StageScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StageScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StageScalarWhereWithAggregatesInput>>;
  eventId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumStageTypeWithAggregatesFilter>;
};

export enum StageType {
  Bracket = 'BRACKET',
  PoolPlay = 'POOL_PLAY',
}

export type StageUpdateInput = {
  event?: InputMaybe<EventUpdateOneRequiredWithoutStagesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutStageNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStageTypeFieldUpdateOperationsInput>;
};

export type StageUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStageTypeFieldUpdateOperationsInput>;
};

export type StageUpdateManyWithWhereWithoutEventInput = {
  data: StageUpdateManyMutationInput;
  where: StageScalarWhereInput;
};

export type StageUpdateManyWithoutEventNestedInput = {
  connect?: InputMaybe<Array<StageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StageCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<StageCreateWithoutEventInput>>;
  createMany?: InputMaybe<StageCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<StageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StageWhereUniqueInput>>;
  set?: InputMaybe<Array<StageWhereUniqueInput>>;
  update?: InputMaybe<Array<StageUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<StageUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<StageUpsertWithWhereUniqueWithoutEventInput>>;
};

export type StageUpdateOneWithoutMatchesNestedInput = {
  connect?: InputMaybe<StageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StageCreateOrConnectWithoutMatchesInput>;
  create?: InputMaybe<StageCreateWithoutMatchesInput>;
  delete?: InputMaybe<StageWhereInput>;
  disconnect?: InputMaybe<StageWhereInput>;
  update?: InputMaybe<StageUpdateToOneWithWhereWithoutMatchesInput>;
  upsert?: InputMaybe<StageUpsertWithoutMatchesInput>;
};

export type StageUpdateToOneWithWhereWithoutMatchesInput = {
  data: StageUpdateWithoutMatchesInput;
  where?: InputMaybe<StageWhereInput>;
};

export type StageUpdateWithWhereUniqueWithoutEventInput = {
  data: StageUpdateWithoutEventInput;
  where: StageWhereUniqueInput;
};

export type StageUpdateWithoutEventInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutStageNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStageTypeFieldUpdateOperationsInput>;
};

export type StageUpdateWithoutMatchesInput = {
  event?: InputMaybe<EventUpdateOneRequiredWithoutStagesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStageTypeFieldUpdateOperationsInput>;
};

export type StageUpsertWithWhereUniqueWithoutEventInput = {
  create: StageCreateWithoutEventInput;
  update: StageUpdateWithoutEventInput;
  where: StageWhereUniqueInput;
};

export type StageUpsertWithoutMatchesInput = {
  create: StageCreateWithoutMatchesInput;
  update: StageUpdateWithoutMatchesInput;
  where?: InputMaybe<StageWhereInput>;
};

export type StageWhereInput = {
  AND?: InputMaybe<Array<StageWhereInput>>;
  NOT?: InputMaybe<Array<StageWhereInput>>;
  OR?: InputMaybe<Array<StageWhereInput>>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  matches?: InputMaybe<MatchListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumStageTypeFilter>;
};

export type StageWhereUniqueInput = {
  AND?: InputMaybe<Array<StageWhereInput>>;
  NOT?: InputMaybe<Array<StageWhereInput>>;
  OR?: InputMaybe<Array<StageWhereInput>>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  matches?: InputMaybe<MatchListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumStageTypeFilter>;
};

export enum Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Team = {
  __typename?: 'Team';
  _count?: Maybe<TeamCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invitedUsers: Array<UserTeamInvite>;
  matches: Array<Match>;
  name: Scalars['String']['output'];
  scores: Array<Score>;
  sport: Sport;
  status: Status;
  teamEvents: Array<TeamEvent>;
  teamUsers: Array<UserTeam>;
  updatedAt: Scalars['DateTimeISO']['output'];
  userTeamEvents: Array<UserTeamEvent>;
  wonMatches: Array<Match>;
  wonRounds: Array<Round>;
};

export type TeamCreatedByUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type TeamInvitedUsersArgs = {
  cursor?: InputMaybe<UserTeamInviteWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamInviteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamInviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamInviteWhereInput>;
};

export type TeamMatchesArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MatchWhereInput>;
};

export type TeamScoresArgs = {
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};

export type TeamTeamEventsArgs = {
  cursor?: InputMaybe<TeamEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamEventWhereInput>;
};

export type TeamTeamUsersArgs = {
  cursor?: InputMaybe<UserTeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamWhereInput>;
};

export type TeamUserTeamEventsArgs = {
  cursor?: InputMaybe<UserTeamEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTeamEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTeamEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type TeamWonMatchesArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MatchWhereInput>;
};

export type TeamWonRoundsArgs = {
  cursor?: InputMaybe<RoundWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoundScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoundOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoundWhereInput>;
};

export type TeamCount = {
  __typename?: 'TeamCount';
  invitedUsers: Scalars['Int']['output'];
  matches: Scalars['Int']['output'];
  scores: Scalars['Int']['output'];
  teamEvents: Scalars['Int']['output'];
  teamUsers: Scalars['Int']['output'];
  userTeamEvents: Scalars['Int']['output'];
  wonMatches: Scalars['Int']['output'];
  wonRounds: Scalars['Int']['output'];
};

export type TeamCountInvitedUsersArgs = {
  where?: InputMaybe<UserTeamInviteWhereInput>;
};

export type TeamCountMatchesArgs = {
  where?: InputMaybe<MatchWhereInput>;
};

export type TeamCountScoresArgs = {
  where?: InputMaybe<ScoreWhereInput>;
};

export type TeamCountTeamEventsArgs = {
  where?: InputMaybe<TeamEventWhereInput>;
};

export type TeamCountTeamUsersArgs = {
  where?: InputMaybe<UserTeamWhereInput>;
};

export type TeamCountUserTeamEventsArgs = {
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type TeamCountWonMatchesArgs = {
  where?: InputMaybe<MatchWhereInput>;
};

export type TeamCountWonRoundsArgs = {
  where?: InputMaybe<RoundWhereInput>;
};

export type TeamCountAggregate = {
  __typename?: 'TeamCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  createdByUserId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  sport: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TeamCountOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type TeamCreateInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutTeamInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserTeamInviteCreateNestedManyWithoutTeamInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutTeamsInput>;
  name: Scalars['String']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutTeamInput>;
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutTeamInput>;
  teamUsers?: InputMaybe<UserTeamCreateNestedManyWithoutTeamInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutTeamInput>;
  wonMatches?: InputMaybe<MatchCreateNestedManyWithoutWinningTeamInput>;
  wonRounds?: InputMaybe<RoundCreateNestedManyWithoutWinningTeamInput>;
};

export type TeamCreateManyCreatedByUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
};

export type TeamCreateManyCreatedByUserInputEnvelope = {
  data: Array<TeamCreateManyCreatedByUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TeamCreateManyInput = {
  createdByUserId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
};

export type TeamCreateNestedManyWithoutCreatedByUserInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TeamCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<TeamCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<TeamCreateManyCreatedByUserInputEnvelope>;
};

export type TeamCreateNestedManyWithoutMatchesInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutMatchesInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutMatchesInput>>;
};

export type TeamCreateNestedOneWithoutInvitedUsersInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutInvitedUsersInput>;
  create?: InputMaybe<TeamCreateWithoutInvitedUsersInput>;
};

export type TeamCreateNestedOneWithoutScoresInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutScoresInput>;
  create?: InputMaybe<TeamCreateWithoutScoresInput>;
};

export type TeamCreateNestedOneWithoutTeamEventsInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutTeamEventsInput>;
  create?: InputMaybe<TeamCreateWithoutTeamEventsInput>;
};

export type TeamCreateNestedOneWithoutTeamUsersInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutTeamUsersInput>;
  create?: InputMaybe<TeamCreateWithoutTeamUsersInput>;
};

export type TeamCreateNestedOneWithoutUserTeamEventsInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutUserTeamEventsInput>;
  create?: InputMaybe<TeamCreateWithoutUserTeamEventsInput>;
};

export type TeamCreateNestedOneWithoutWonMatchesInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutWonMatchesInput>;
  create?: InputMaybe<TeamCreateWithoutWonMatchesInput>;
};

export type TeamCreateNestedOneWithoutWonRoundsInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutWonRoundsInput>;
  create?: InputMaybe<TeamCreateWithoutWonRoundsInput>;
};

export type TeamCreateOrConnectWithoutCreatedByUserInput = {
  create: TeamCreateWithoutCreatedByUserInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutInvitedUsersInput = {
  create: TeamCreateWithoutInvitedUsersInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutMatchesInput = {
  create: TeamCreateWithoutMatchesInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutScoresInput = {
  create: TeamCreateWithoutScoresInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutTeamEventsInput = {
  create: TeamCreateWithoutTeamEventsInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutTeamUsersInput = {
  create: TeamCreateWithoutTeamUsersInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutUserTeamEventsInput = {
  create: TeamCreateWithoutUserTeamEventsInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutWonMatchesInput = {
  create: TeamCreateWithoutWonMatchesInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutWonRoundsInput = {
  create: TeamCreateWithoutWonRoundsInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateWithoutCreatedByUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserTeamInviteCreateNestedManyWithoutTeamInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutTeamsInput>;
  name: Scalars['String']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutTeamInput>;
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutTeamInput>;
  teamUsers?: InputMaybe<UserTeamCreateNestedManyWithoutTeamInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutTeamInput>;
  wonMatches?: InputMaybe<MatchCreateNestedManyWithoutWinningTeamInput>;
  wonRounds?: InputMaybe<RoundCreateNestedManyWithoutWinningTeamInput>;
};

export type TeamCreateWithoutInvitedUsersInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutTeamInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutTeamsInput>;
  name: Scalars['String']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutTeamInput>;
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutTeamInput>;
  teamUsers?: InputMaybe<UserTeamCreateNestedManyWithoutTeamInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutTeamInput>;
  wonMatches?: InputMaybe<MatchCreateNestedManyWithoutWinningTeamInput>;
  wonRounds?: InputMaybe<RoundCreateNestedManyWithoutWinningTeamInput>;
};

export type TeamCreateWithoutMatchesInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutTeamInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserTeamInviteCreateNestedManyWithoutTeamInput>;
  name: Scalars['String']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutTeamInput>;
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutTeamInput>;
  teamUsers?: InputMaybe<UserTeamCreateNestedManyWithoutTeamInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutTeamInput>;
  wonMatches?: InputMaybe<MatchCreateNestedManyWithoutWinningTeamInput>;
  wonRounds?: InputMaybe<RoundCreateNestedManyWithoutWinningTeamInput>;
};

export type TeamCreateWithoutScoresInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutTeamInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserTeamInviteCreateNestedManyWithoutTeamInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutTeamsInput>;
  name: Scalars['String']['input'];
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutTeamInput>;
  teamUsers?: InputMaybe<UserTeamCreateNestedManyWithoutTeamInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutTeamInput>;
  wonMatches?: InputMaybe<MatchCreateNestedManyWithoutWinningTeamInput>;
  wonRounds?: InputMaybe<RoundCreateNestedManyWithoutWinningTeamInput>;
};

export type TeamCreateWithoutTeamEventsInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutTeamInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserTeamInviteCreateNestedManyWithoutTeamInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutTeamsInput>;
  name: Scalars['String']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutTeamInput>;
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
  teamUsers?: InputMaybe<UserTeamCreateNestedManyWithoutTeamInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutTeamInput>;
  wonMatches?: InputMaybe<MatchCreateNestedManyWithoutWinningTeamInput>;
  wonRounds?: InputMaybe<RoundCreateNestedManyWithoutWinningTeamInput>;
};

export type TeamCreateWithoutTeamUsersInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutTeamInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserTeamInviteCreateNestedManyWithoutTeamInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutTeamsInput>;
  name: Scalars['String']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutTeamInput>;
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutTeamInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutTeamInput>;
  wonMatches?: InputMaybe<MatchCreateNestedManyWithoutWinningTeamInput>;
  wonRounds?: InputMaybe<RoundCreateNestedManyWithoutWinningTeamInput>;
};

export type TeamCreateWithoutUserTeamEventsInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutTeamInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserTeamInviteCreateNestedManyWithoutTeamInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutTeamsInput>;
  name: Scalars['String']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutTeamInput>;
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutTeamInput>;
  teamUsers?: InputMaybe<UserTeamCreateNestedManyWithoutTeamInput>;
  wonMatches?: InputMaybe<MatchCreateNestedManyWithoutWinningTeamInput>;
  wonRounds?: InputMaybe<RoundCreateNestedManyWithoutWinningTeamInput>;
};

export type TeamCreateWithoutWonMatchesInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutTeamInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserTeamInviteCreateNestedManyWithoutTeamInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutTeamsInput>;
  name: Scalars['String']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutTeamInput>;
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutTeamInput>;
  teamUsers?: InputMaybe<UserTeamCreateNestedManyWithoutTeamInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutTeamInput>;
  wonRounds?: InputMaybe<RoundCreateNestedManyWithoutWinningTeamInput>;
};

export type TeamCreateWithoutWonRoundsInput = {
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutTeamInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserTeamInviteCreateNestedManyWithoutTeamInput>;
  matches?: InputMaybe<MatchCreateNestedManyWithoutTeamsInput>;
  name: Scalars['String']['input'];
  scores?: InputMaybe<ScoreCreateNestedManyWithoutTeamInput>;
  sport?: InputMaybe<Sport>;
  status?: InputMaybe<Status>;
  teamEvents?: InputMaybe<TeamEventCreateNestedManyWithoutTeamInput>;
  teamUsers?: InputMaybe<UserTeamCreateNestedManyWithoutTeamInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutTeamInput>;
  wonMatches?: InputMaybe<MatchCreateNestedManyWithoutWinningTeamInput>;
};

export type TeamEvent = {
  __typename?: 'TeamEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPaid: Scalars['Boolean']['output'];
  placement?: Maybe<Scalars['Int']['output']>;
  pool?: Maybe<Pool>;
  poolId?: Maybe<Scalars['String']['output']>;
  seed?: Maybe<Scalars['Int']['output']>;
  team: Team;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type TeamEventEventArgs = {
  where?: InputMaybe<EventWhereInput>;
};

export type TeamEventPoolArgs = {
  where?: InputMaybe<PoolWhereInput>;
};

export type TeamEventAvgAggregate = {
  __typename?: 'TeamEventAvgAggregate';
  placement?: Maybe<Scalars['Float']['output']>;
  seed?: Maybe<Scalars['Float']['output']>;
};

export type TeamEventAvgOrderByAggregateInput = {
  placement?: InputMaybe<SortOrder>;
  seed?: InputMaybe<SortOrder>;
};

export type TeamEventCountAggregate = {
  __typename?: 'TeamEventCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isPaid: Scalars['Int']['output'];
  placement: Scalars['Int']['output'];
  poolId: Scalars['Int']['output'];
  seed: Scalars['Int']['output'];
  teamId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TeamEventCountOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  placement?: InputMaybe<SortOrder>;
  poolId?: InputMaybe<SortOrder>;
  seed?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
};

export type TeamEventCreateInput = {
  event?: InputMaybe<EventCreateNestedOneWithoutTeamEventsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  placement?: InputMaybe<Scalars['Int']['input']>;
  pool?: InputMaybe<PoolCreateNestedOneWithoutTeamEventInput>;
  seed?: InputMaybe<Scalars['Int']['input']>;
  team: TeamCreateNestedOneWithoutTeamEventsInput;
};

export type TeamEventCreateManyEventInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  placement?: InputMaybe<Scalars['Int']['input']>;
  poolId?: InputMaybe<Scalars['String']['input']>;
  seed?: InputMaybe<Scalars['Int']['input']>;
  teamId: Scalars['String']['input'];
};

export type TeamEventCreateManyEventInputEnvelope = {
  data: Array<TeamEventCreateManyEventInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TeamEventCreateManyInput = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  placement?: InputMaybe<Scalars['Int']['input']>;
  poolId?: InputMaybe<Scalars['String']['input']>;
  seed?: InputMaybe<Scalars['Int']['input']>;
  teamId: Scalars['String']['input'];
};

export type TeamEventCreateManyPoolInput = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  placement?: InputMaybe<Scalars['Int']['input']>;
  seed?: InputMaybe<Scalars['Int']['input']>;
  teamId: Scalars['String']['input'];
};

export type TeamEventCreateManyPoolInputEnvelope = {
  data: Array<TeamEventCreateManyPoolInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TeamEventCreateManyTeamInput = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  placement?: InputMaybe<Scalars['Int']['input']>;
  poolId?: InputMaybe<Scalars['String']['input']>;
  seed?: InputMaybe<Scalars['Int']['input']>;
};

export type TeamEventCreateManyTeamInputEnvelope = {
  data: Array<TeamEventCreateManyTeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TeamEventCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TeamEventCreateOrConnectWithoutEventInput>
  >;
  create?: InputMaybe<Array<TeamEventCreateWithoutEventInput>>;
  createMany?: InputMaybe<TeamEventCreateManyEventInputEnvelope>;
};

export type TeamEventCreateNestedManyWithoutPoolInput = {
  connect?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamEventCreateOrConnectWithoutPoolInput>>;
  create?: InputMaybe<Array<TeamEventCreateWithoutPoolInput>>;
  createMany?: InputMaybe<TeamEventCreateManyPoolInputEnvelope>;
};

export type TeamEventCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamEventCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<TeamEventCreateWithoutTeamInput>>;
  createMany?: InputMaybe<TeamEventCreateManyTeamInputEnvelope>;
};

export type TeamEventCreateOrConnectWithoutEventInput = {
  create: TeamEventCreateWithoutEventInput;
  where: TeamEventWhereUniqueInput;
};

export type TeamEventCreateOrConnectWithoutPoolInput = {
  create: TeamEventCreateWithoutPoolInput;
  where: TeamEventWhereUniqueInput;
};

export type TeamEventCreateOrConnectWithoutTeamInput = {
  create: TeamEventCreateWithoutTeamInput;
  where: TeamEventWhereUniqueInput;
};

export type TeamEventCreateWithoutEventInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  placement?: InputMaybe<Scalars['Int']['input']>;
  pool?: InputMaybe<PoolCreateNestedOneWithoutTeamEventInput>;
  seed?: InputMaybe<Scalars['Int']['input']>;
  team: TeamCreateNestedOneWithoutTeamEventsInput;
};

export type TeamEventCreateWithoutPoolInput = {
  event?: InputMaybe<EventCreateNestedOneWithoutTeamEventsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  placement?: InputMaybe<Scalars['Int']['input']>;
  seed?: InputMaybe<Scalars['Int']['input']>;
  team: TeamCreateNestedOneWithoutTeamEventsInput;
};

export type TeamEventCreateWithoutTeamInput = {
  event?: InputMaybe<EventCreateNestedOneWithoutTeamEventsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  placement?: InputMaybe<Scalars['Int']['input']>;
  pool?: InputMaybe<PoolCreateNestedOneWithoutTeamEventInput>;
  seed?: InputMaybe<Scalars['Int']['input']>;
};

export type TeamEventGroupBy = {
  __typename?: 'TeamEventGroupBy';
  _avg?: Maybe<TeamEventAvgAggregate>;
  _count?: Maybe<TeamEventCountAggregate>;
  _max?: Maybe<TeamEventMaxAggregate>;
  _min?: Maybe<TeamEventMinAggregate>;
  _sum?: Maybe<TeamEventSumAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  eventId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPaid: Scalars['Boolean']['output'];
  placement?: Maybe<Scalars['Int']['output']>;
  poolId?: Maybe<Scalars['String']['output']>;
  seed?: Maybe<Scalars['Int']['output']>;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type TeamEventListRelationFilter = {
  every?: InputMaybe<TeamEventWhereInput>;
  none?: InputMaybe<TeamEventWhereInput>;
  some?: InputMaybe<TeamEventWhereInput>;
};

export type TeamEventMaxAggregate = {
  __typename?: 'TeamEventMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  placement?: Maybe<Scalars['Int']['output']>;
  poolId?: Maybe<Scalars['String']['output']>;
  seed?: Maybe<Scalars['Int']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TeamEventMaxOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  placement?: InputMaybe<SortOrder>;
  poolId?: InputMaybe<SortOrder>;
  seed?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
};

export type TeamEventMinAggregate = {
  __typename?: 'TeamEventMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  placement?: Maybe<Scalars['Int']['output']>;
  poolId?: Maybe<Scalars['String']['output']>;
  seed?: Maybe<Scalars['Int']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TeamEventMinOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  placement?: InputMaybe<SortOrder>;
  poolId?: InputMaybe<SortOrder>;
  seed?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
};

export type TeamEventOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TeamEventOrderByWithAggregationInput = {
  _avg?: InputMaybe<TeamEventAvgOrderByAggregateInput>;
  _count?: InputMaybe<TeamEventCountOrderByAggregateInput>;
  _max?: InputMaybe<TeamEventMaxOrderByAggregateInput>;
  _min?: InputMaybe<TeamEventMinOrderByAggregateInput>;
  _sum?: InputMaybe<TeamEventSumOrderByAggregateInput>;
  eventId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  placement?: InputMaybe<SortOrderInput>;
  poolId?: InputMaybe<SortOrderInput>;
  seed?: InputMaybe<SortOrderInput>;
  teamId?: InputMaybe<SortOrder>;
};

export type TeamEventOrderByWithRelationInput = {
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isPaid?: InputMaybe<SortOrder>;
  placement?: InputMaybe<SortOrderInput>;
  pool?: InputMaybe<PoolOrderByWithRelationInput>;
  poolId?: InputMaybe<SortOrderInput>;
  seed?: InputMaybe<SortOrderInput>;
  team?: InputMaybe<TeamOrderByWithRelationInput>;
  teamId?: InputMaybe<SortOrder>;
};

export enum TeamEventScalarFieldEnum {
  CreatedAt = 'createdAt',
  EventId = 'eventId',
  Id = 'id',
  IsPaid = 'isPaid',
  Placement = 'placement',
  PoolId = 'poolId',
  Seed = 'seed',
  TeamId = 'teamId',
  UpdatedAt = 'updatedAt',
}

export type TeamEventScalarWhereInput = {
  AND?: InputMaybe<Array<TeamEventScalarWhereInput>>;
  NOT?: InputMaybe<Array<TeamEventScalarWhereInput>>;
  OR?: InputMaybe<Array<TeamEventScalarWhereInput>>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isPaid?: InputMaybe<BoolFilter>;
  placement?: InputMaybe<IntNullableFilter>;
  poolId?: InputMaybe<StringNullableFilter>;
  seed?: InputMaybe<IntNullableFilter>;
  teamId?: InputMaybe<StringFilter>;
};

export type TeamEventScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TeamEventScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TeamEventScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TeamEventScalarWhereWithAggregatesInput>>;
  eventId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPaid?: InputMaybe<BoolWithAggregatesFilter>;
  placement?: InputMaybe<IntNullableWithAggregatesFilter>;
  poolId?: InputMaybe<StringNullableWithAggregatesFilter>;
  seed?: InputMaybe<IntNullableWithAggregatesFilter>;
  teamId?: InputMaybe<StringWithAggregatesFilter>;
};

export type TeamEventSumAggregate = {
  __typename?: 'TeamEventSumAggregate';
  placement?: Maybe<Scalars['Int']['output']>;
  seed?: Maybe<Scalars['Int']['output']>;
};

export type TeamEventSumOrderByAggregateInput = {
  placement?: InputMaybe<SortOrder>;
  seed?: InputMaybe<SortOrder>;
};

export type TeamEventTeamIdEventIdCompoundUniqueInput = {
  eventId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type TeamEventUpdateInput = {
  event?: InputMaybe<EventUpdateOneWithoutTeamEventsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  placement?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pool?: InputMaybe<PoolUpdateOneWithoutTeamEventNestedInput>;
  seed?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutTeamEventsNestedInput>;
};

export type TeamEventUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  placement?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  seed?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type TeamEventUpdateManyWithWhereWithoutEventInput = {
  data: TeamEventUpdateManyMutationInput;
  where: TeamEventScalarWhereInput;
};

export type TeamEventUpdateManyWithWhereWithoutPoolInput = {
  data: TeamEventUpdateManyMutationInput;
  where: TeamEventScalarWhereInput;
};

export type TeamEventUpdateManyWithWhereWithoutTeamInput = {
  data: TeamEventUpdateManyMutationInput;
  where: TeamEventScalarWhereInput;
};

export type TeamEventUpdateManyWithoutEventNestedInput = {
  connect?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TeamEventCreateOrConnectWithoutEventInput>
  >;
  create?: InputMaybe<Array<TeamEventCreateWithoutEventInput>>;
  createMany?: InputMaybe<TeamEventCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamEventUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<TeamEventUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<TeamEventUpsertWithWhereUniqueWithoutEventInput>>;
};

export type TeamEventUpdateManyWithoutPoolNestedInput = {
  connect?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamEventCreateOrConnectWithoutPoolInput>>;
  create?: InputMaybe<Array<TeamEventCreateWithoutPoolInput>>;
  createMany?: InputMaybe<TeamEventCreateManyPoolInputEnvelope>;
  delete?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamEventUpdateWithWhereUniqueWithoutPoolInput>>;
  updateMany?: InputMaybe<Array<TeamEventUpdateManyWithWhereWithoutPoolInput>>;
  upsert?: InputMaybe<Array<TeamEventUpsertWithWhereUniqueWithoutPoolInput>>;
};

export type TeamEventUpdateManyWithoutTeamNestedInput = {
  connect?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamEventCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<TeamEventCreateWithoutTeamInput>>;
  createMany?: InputMaybe<TeamEventCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamEventWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamEventUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<TeamEventUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<TeamEventUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type TeamEventUpdateWithWhereUniqueWithoutEventInput = {
  data: TeamEventUpdateWithoutEventInput;
  where: TeamEventWhereUniqueInput;
};

export type TeamEventUpdateWithWhereUniqueWithoutPoolInput = {
  data: TeamEventUpdateWithoutPoolInput;
  where: TeamEventWhereUniqueInput;
};

export type TeamEventUpdateWithWhereUniqueWithoutTeamInput = {
  data: TeamEventUpdateWithoutTeamInput;
  where: TeamEventWhereUniqueInput;
};

export type TeamEventUpdateWithoutEventInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  placement?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pool?: InputMaybe<PoolUpdateOneWithoutTeamEventNestedInput>;
  seed?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutTeamEventsNestedInput>;
};

export type TeamEventUpdateWithoutPoolInput = {
  event?: InputMaybe<EventUpdateOneWithoutTeamEventsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  placement?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  seed?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutTeamEventsNestedInput>;
};

export type TeamEventUpdateWithoutTeamInput = {
  event?: InputMaybe<EventUpdateOneWithoutTeamEventsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPaid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  placement?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pool?: InputMaybe<PoolUpdateOneWithoutTeamEventNestedInput>;
  seed?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type TeamEventUpsertWithWhereUniqueWithoutEventInput = {
  create: TeamEventCreateWithoutEventInput;
  update: TeamEventUpdateWithoutEventInput;
  where: TeamEventWhereUniqueInput;
};

export type TeamEventUpsertWithWhereUniqueWithoutPoolInput = {
  create: TeamEventCreateWithoutPoolInput;
  update: TeamEventUpdateWithoutPoolInput;
  where: TeamEventWhereUniqueInput;
};

export type TeamEventUpsertWithWhereUniqueWithoutTeamInput = {
  create: TeamEventCreateWithoutTeamInput;
  update: TeamEventUpdateWithoutTeamInput;
  where: TeamEventWhereUniqueInput;
};

export type TeamEventWhereInput = {
  AND?: InputMaybe<Array<TeamEventWhereInput>>;
  NOT?: InputMaybe<Array<TeamEventWhereInput>>;
  OR?: InputMaybe<Array<TeamEventWhereInput>>;
  event?: InputMaybe<EventNullableRelationFilter>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isPaid?: InputMaybe<BoolFilter>;
  placement?: InputMaybe<IntNullableFilter>;
  pool?: InputMaybe<PoolNullableRelationFilter>;
  poolId?: InputMaybe<StringNullableFilter>;
  seed?: InputMaybe<IntNullableFilter>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<StringFilter>;
};

export type TeamEventWhereUniqueInput = {
  AND?: InputMaybe<Array<TeamEventWhereInput>>;
  NOT?: InputMaybe<Array<TeamEventWhereInput>>;
  OR?: InputMaybe<Array<TeamEventWhereInput>>;
  event?: InputMaybe<EventNullableRelationFilter>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPaid?: InputMaybe<BoolFilter>;
  placement?: InputMaybe<IntNullableFilter>;
  pool?: InputMaybe<PoolNullableRelationFilter>;
  poolId?: InputMaybe<StringNullableFilter>;
  seed?: InputMaybe<IntNullableFilter>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<StringFilter>;
  teamId_eventId?: InputMaybe<TeamEventTeamIdEventIdCompoundUniqueInput>;
};

export type TeamGroupBy = {
  __typename?: 'TeamGroupBy';
  _count?: Maybe<TeamCountAggregate>;
  _max?: Maybe<TeamMaxAggregate>;
  _min?: Maybe<TeamMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sport: Sport;
  status: Status;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type TeamListRelationFilter = {
  every?: InputMaybe<TeamWhereInput>;
  none?: InputMaybe<TeamWhereInput>;
  some?: InputMaybe<TeamWhereInput>;
};

export type TeamMaxAggregate = {
  __typename?: 'TeamMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Sport>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TeamMaxOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type TeamMinAggregate = {
  __typename?: 'TeamMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Sport>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TeamMinOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type TeamNullableRelationFilter = {
  is?: InputMaybe<TeamWhereInput>;
  isNot?: InputMaybe<TeamWhereInput>;
};

export type TeamOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TeamOrderByWithAggregationInput = {
  _count?: InputMaybe<TeamCountOrderByAggregateInput>;
  _max?: InputMaybe<TeamMaxOrderByAggregateInput>;
  _min?: InputMaybe<TeamMinOrderByAggregateInput>;
  createdByUserId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type TeamOrderByWithRelationInput = {
  createdByUser?: InputMaybe<UserOrderByWithRelationInput>;
  createdByUserId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  invitedUsers?: InputMaybe<UserTeamInviteOrderByRelationAggregateInput>;
  matches?: InputMaybe<MatchOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  scores?: InputMaybe<ScoreOrderByRelationAggregateInput>;
  sport?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamEvents?: InputMaybe<TeamEventOrderByRelationAggregateInput>;
  teamUsers?: InputMaybe<UserTeamOrderByRelationAggregateInput>;
  userTeamEvents?: InputMaybe<UserTeamEventOrderByRelationAggregateInput>;
  wonMatches?: InputMaybe<MatchOrderByRelationAggregateInput>;
  wonRounds?: InputMaybe<RoundOrderByRelationAggregateInput>;
};

export type TeamRelationFilter = {
  is?: InputMaybe<TeamWhereInput>;
  isNot?: InputMaybe<TeamWhereInput>;
};

export enum TeamScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  Id = 'id',
  Name = 'name',
  Sport = 'sport',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export type TeamScalarWhereInput = {
  AND?: InputMaybe<Array<TeamScalarWhereInput>>;
  NOT?: InputMaybe<Array<TeamScalarWhereInput>>;
  OR?: InputMaybe<Array<TeamScalarWhereInput>>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  sport?: InputMaybe<EnumSportFilter>;
  status?: InputMaybe<EnumStatusFilter>;
};

export type TeamScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TeamScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TeamScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TeamScalarWhereWithAggregatesInput>>;
  createdByUserId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  sport?: InputMaybe<EnumSportWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
};

export type TeamUpdateInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutTeamNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserTeamInviteUpdateManyWithoutTeamNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutTeamsNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutTeamNestedInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutTeamNestedInput>;
  teamUsers?: InputMaybe<UserTeamUpdateManyWithoutTeamNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutTeamNestedInput>;
  wonMatches?: InputMaybe<MatchUpdateManyWithoutWinningTeamNestedInput>;
  wonRounds?: InputMaybe<RoundUpdateManyWithoutWinningTeamNestedInput>;
};

export type TeamUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
};

export type TeamUpdateManyWithWhereWithoutCreatedByUserInput = {
  data: TeamUpdateManyMutationInput;
  where: TeamScalarWhereInput;
};

export type TeamUpdateManyWithWhereWithoutMatchesInput = {
  data: TeamUpdateManyMutationInput;
  where: TeamScalarWhereInput;
};

export type TeamUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TeamCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<TeamCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<TeamCreateManyCreatedByUserInputEnvelope>;
  delete?: InputMaybe<Array<TeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TeamUpdateWithWhereUniqueWithoutCreatedByUserInput>
  >;
  updateMany?: InputMaybe<
    Array<TeamUpdateManyWithWhereWithoutCreatedByUserInput>
  >;
  upsert?: InputMaybe<
    Array<TeamUpsertWithWhereUniqueWithoutCreatedByUserInput>
  >;
};

export type TeamUpdateManyWithoutMatchesNestedInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutMatchesInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutMatchesInput>>;
  delete?: InputMaybe<Array<TeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamUpdateWithWhereUniqueWithoutMatchesInput>>;
  updateMany?: InputMaybe<Array<TeamUpdateManyWithWhereWithoutMatchesInput>>;
  upsert?: InputMaybe<Array<TeamUpsertWithWhereUniqueWithoutMatchesInput>>;
};

export type TeamUpdateOneRequiredWithoutInvitedUsersNestedInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutInvitedUsersInput>;
  create?: InputMaybe<TeamCreateWithoutInvitedUsersInput>;
  update?: InputMaybe<TeamUpdateToOneWithWhereWithoutInvitedUsersInput>;
  upsert?: InputMaybe<TeamUpsertWithoutInvitedUsersInput>;
};

export type TeamUpdateOneRequiredWithoutScoresNestedInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutScoresInput>;
  create?: InputMaybe<TeamCreateWithoutScoresInput>;
  update?: InputMaybe<TeamUpdateToOneWithWhereWithoutScoresInput>;
  upsert?: InputMaybe<TeamUpsertWithoutScoresInput>;
};

export type TeamUpdateOneRequiredWithoutTeamEventsNestedInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutTeamEventsInput>;
  create?: InputMaybe<TeamCreateWithoutTeamEventsInput>;
  update?: InputMaybe<TeamUpdateToOneWithWhereWithoutTeamEventsInput>;
  upsert?: InputMaybe<TeamUpsertWithoutTeamEventsInput>;
};

export type TeamUpdateOneRequiredWithoutTeamUsersNestedInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutTeamUsersInput>;
  create?: InputMaybe<TeamCreateWithoutTeamUsersInput>;
  update?: InputMaybe<TeamUpdateToOneWithWhereWithoutTeamUsersInput>;
  upsert?: InputMaybe<TeamUpsertWithoutTeamUsersInput>;
};

export type TeamUpdateOneRequiredWithoutUserTeamEventsNestedInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutUserTeamEventsInput>;
  create?: InputMaybe<TeamCreateWithoutUserTeamEventsInput>;
  update?: InputMaybe<TeamUpdateToOneWithWhereWithoutUserTeamEventsInput>;
  upsert?: InputMaybe<TeamUpsertWithoutUserTeamEventsInput>;
};

export type TeamUpdateOneWithoutWonMatchesNestedInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutWonMatchesInput>;
  create?: InputMaybe<TeamCreateWithoutWonMatchesInput>;
  delete?: InputMaybe<TeamWhereInput>;
  disconnect?: InputMaybe<TeamWhereInput>;
  update?: InputMaybe<TeamUpdateToOneWithWhereWithoutWonMatchesInput>;
  upsert?: InputMaybe<TeamUpsertWithoutWonMatchesInput>;
};

export type TeamUpdateOneWithoutWonRoundsNestedInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutWonRoundsInput>;
  create?: InputMaybe<TeamCreateWithoutWonRoundsInput>;
  delete?: InputMaybe<TeamWhereInput>;
  disconnect?: InputMaybe<TeamWhereInput>;
  update?: InputMaybe<TeamUpdateToOneWithWhereWithoutWonRoundsInput>;
  upsert?: InputMaybe<TeamUpsertWithoutWonRoundsInput>;
};

export type TeamUpdateToOneWithWhereWithoutInvitedUsersInput = {
  data: TeamUpdateWithoutInvitedUsersInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpdateToOneWithWhereWithoutScoresInput = {
  data: TeamUpdateWithoutScoresInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpdateToOneWithWhereWithoutTeamEventsInput = {
  data: TeamUpdateWithoutTeamEventsInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpdateToOneWithWhereWithoutTeamUsersInput = {
  data: TeamUpdateWithoutTeamUsersInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpdateToOneWithWhereWithoutUserTeamEventsInput = {
  data: TeamUpdateWithoutUserTeamEventsInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpdateToOneWithWhereWithoutWonMatchesInput = {
  data: TeamUpdateWithoutWonMatchesInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpdateToOneWithWhereWithoutWonRoundsInput = {
  data: TeamUpdateWithoutWonRoundsInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  data: TeamUpdateWithoutCreatedByUserInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpdateWithWhereUniqueWithoutMatchesInput = {
  data: TeamUpdateWithoutMatchesInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpdateWithoutCreatedByUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserTeamInviteUpdateManyWithoutTeamNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutTeamsNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutTeamNestedInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutTeamNestedInput>;
  teamUsers?: InputMaybe<UserTeamUpdateManyWithoutTeamNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutTeamNestedInput>;
  wonMatches?: InputMaybe<MatchUpdateManyWithoutWinningTeamNestedInput>;
  wonRounds?: InputMaybe<RoundUpdateManyWithoutWinningTeamNestedInput>;
};

export type TeamUpdateWithoutInvitedUsersInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutTeamNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutTeamsNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutTeamNestedInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutTeamNestedInput>;
  teamUsers?: InputMaybe<UserTeamUpdateManyWithoutTeamNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutTeamNestedInput>;
  wonMatches?: InputMaybe<MatchUpdateManyWithoutWinningTeamNestedInput>;
  wonRounds?: InputMaybe<RoundUpdateManyWithoutWinningTeamNestedInput>;
};

export type TeamUpdateWithoutMatchesInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutTeamNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserTeamInviteUpdateManyWithoutTeamNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutTeamNestedInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutTeamNestedInput>;
  teamUsers?: InputMaybe<UserTeamUpdateManyWithoutTeamNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutTeamNestedInput>;
  wonMatches?: InputMaybe<MatchUpdateManyWithoutWinningTeamNestedInput>;
  wonRounds?: InputMaybe<RoundUpdateManyWithoutWinningTeamNestedInput>;
};

export type TeamUpdateWithoutScoresInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutTeamNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserTeamInviteUpdateManyWithoutTeamNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutTeamsNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutTeamNestedInput>;
  teamUsers?: InputMaybe<UserTeamUpdateManyWithoutTeamNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutTeamNestedInput>;
  wonMatches?: InputMaybe<MatchUpdateManyWithoutWinningTeamNestedInput>;
  wonRounds?: InputMaybe<RoundUpdateManyWithoutWinningTeamNestedInput>;
};

export type TeamUpdateWithoutTeamEventsInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutTeamNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserTeamInviteUpdateManyWithoutTeamNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutTeamsNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutTeamNestedInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  teamUsers?: InputMaybe<UserTeamUpdateManyWithoutTeamNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutTeamNestedInput>;
  wonMatches?: InputMaybe<MatchUpdateManyWithoutWinningTeamNestedInput>;
  wonRounds?: InputMaybe<RoundUpdateManyWithoutWinningTeamNestedInput>;
};

export type TeamUpdateWithoutTeamUsersInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutTeamNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserTeamInviteUpdateManyWithoutTeamNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutTeamsNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutTeamNestedInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutTeamNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutTeamNestedInput>;
  wonMatches?: InputMaybe<MatchUpdateManyWithoutWinningTeamNestedInput>;
  wonRounds?: InputMaybe<RoundUpdateManyWithoutWinningTeamNestedInput>;
};

export type TeamUpdateWithoutUserTeamEventsInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutTeamNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserTeamInviteUpdateManyWithoutTeamNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutTeamsNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutTeamNestedInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutTeamNestedInput>;
  teamUsers?: InputMaybe<UserTeamUpdateManyWithoutTeamNestedInput>;
  wonMatches?: InputMaybe<MatchUpdateManyWithoutWinningTeamNestedInput>;
  wonRounds?: InputMaybe<RoundUpdateManyWithoutWinningTeamNestedInput>;
};

export type TeamUpdateWithoutWonMatchesInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutTeamNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserTeamInviteUpdateManyWithoutTeamNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutTeamsNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutTeamNestedInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutTeamNestedInput>;
  teamUsers?: InputMaybe<UserTeamUpdateManyWithoutTeamNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutTeamNestedInput>;
  wonRounds?: InputMaybe<RoundUpdateManyWithoutWinningTeamNestedInput>;
};

export type TeamUpdateWithoutWonRoundsInput = {
  createdByUser?: InputMaybe<UserUpdateOneWithoutTeamNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserTeamInviteUpdateManyWithoutTeamNestedInput>;
  matches?: InputMaybe<MatchUpdateManyWithoutTeamsNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutTeamNestedInput>;
  sport?: InputMaybe<EnumSportFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  teamEvents?: InputMaybe<TeamEventUpdateManyWithoutTeamNestedInput>;
  teamUsers?: InputMaybe<UserTeamUpdateManyWithoutTeamNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutTeamNestedInput>;
  wonMatches?: InputMaybe<MatchUpdateManyWithoutWinningTeamNestedInput>;
};

export type TeamUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  create: TeamCreateWithoutCreatedByUserInput;
  update: TeamUpdateWithoutCreatedByUserInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpsertWithWhereUniqueWithoutMatchesInput = {
  create: TeamCreateWithoutMatchesInput;
  update: TeamUpdateWithoutMatchesInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpsertWithoutInvitedUsersInput = {
  create: TeamCreateWithoutInvitedUsersInput;
  update: TeamUpdateWithoutInvitedUsersInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpsertWithoutScoresInput = {
  create: TeamCreateWithoutScoresInput;
  update: TeamUpdateWithoutScoresInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpsertWithoutTeamEventsInput = {
  create: TeamCreateWithoutTeamEventsInput;
  update: TeamUpdateWithoutTeamEventsInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpsertWithoutTeamUsersInput = {
  create: TeamCreateWithoutTeamUsersInput;
  update: TeamUpdateWithoutTeamUsersInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpsertWithoutUserTeamEventsInput = {
  create: TeamCreateWithoutUserTeamEventsInput;
  update: TeamUpdateWithoutUserTeamEventsInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpsertWithoutWonMatchesInput = {
  create: TeamCreateWithoutWonMatchesInput;
  update: TeamUpdateWithoutWonMatchesInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpsertWithoutWonRoundsInput = {
  create: TeamCreateWithoutWonRoundsInput;
  update: TeamUpdateWithoutWonRoundsInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamWhereInput = {
  AND?: InputMaybe<Array<TeamWhereInput>>;
  NOT?: InputMaybe<Array<TeamWhereInput>>;
  OR?: InputMaybe<Array<TeamWhereInput>>;
  createdByUser?: InputMaybe<UserNullableRelationFilter>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invitedUsers?: InputMaybe<UserTeamInviteListRelationFilter>;
  matches?: InputMaybe<MatchListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  scores?: InputMaybe<ScoreListRelationFilter>;
  sport?: InputMaybe<EnumSportFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  teamEvents?: InputMaybe<TeamEventListRelationFilter>;
  teamUsers?: InputMaybe<UserTeamListRelationFilter>;
  userTeamEvents?: InputMaybe<UserTeamEventListRelationFilter>;
  wonMatches?: InputMaybe<MatchListRelationFilter>;
  wonRounds?: InputMaybe<RoundListRelationFilter>;
};

export type TeamWhereUniqueInput = {
  AND?: InputMaybe<Array<TeamWhereInput>>;
  NOT?: InputMaybe<Array<TeamWhereInput>>;
  OR?: InputMaybe<Array<TeamWhereInput>>;
  createdByUser?: InputMaybe<UserNullableRelationFilter>;
  createdByUserId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitedUsers?: InputMaybe<UserTeamInviteListRelationFilter>;
  matches?: InputMaybe<MatchListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  scores?: InputMaybe<ScoreListRelationFilter>;
  sport?: InputMaybe<EnumSportFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  teamEvents?: InputMaybe<TeamEventListRelationFilter>;
  teamUsers?: InputMaybe<UserTeamListRelationFilter>;
  userTeamEvents?: InputMaybe<UserTeamEventListRelationFilter>;
  wonMatches?: InputMaybe<MatchListRelationFilter>;
  wonRounds?: InputMaybe<RoundListRelationFilter>;
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  favoriteSports: Array<Sport>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isPseudo: Scalars['Boolean']['output'];
  lastViewedFeed?: Maybe<Scalars['DateTimeISO']['output']>;
  name: Scalars['String']['output'];
  optIn: Scalars['Boolean']['output'];
  /** @deprecated use favoriteSports */
  sport?: Maybe<Sport>;
  startDate: Scalars['DateTimeISO']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type UserCount = {
  __typename?: 'UserCount';
  Court: Scalars['Int']['output'];
  Location: Scalars['Int']['output'];
  Team: Scalars['Int']['output'];
  createdEvents: Scalars['Int']['output'];
  inviteeEvents: Scalars['Int']['output'];
  inviteeTeams: Scalars['Int']['output'];
  inviterEvents: Scalars['Int']['output'];
  inviterTeams: Scalars['Int']['output'];
  userEvents: Scalars['Int']['output'];
  userNotificationPreferences: Scalars['Int']['output'];
  userRating: Scalars['Int']['output'];
  userTeamEvents: Scalars['Int']['output'];
  userTeams: Scalars['Int']['output'];
};

export type UserCountCourtArgs = {
  where?: InputMaybe<CourtWhereInput>;
};

export type UserCountLocationArgs = {
  where?: InputMaybe<LocationWhereInput>;
};

export type UserCountTeamArgs = {
  where?: InputMaybe<TeamWhereInput>;
};

export type UserCountCreatedEventsArgs = {
  where?: InputMaybe<EventWhereInput>;
};

export type UserCountInviteeEventsArgs = {
  where?: InputMaybe<UserEventInviteWhereInput>;
};

export type UserCountInviteeTeamsArgs = {
  where?: InputMaybe<UserTeamInviteWhereInput>;
};

export type UserCountInviterEventsArgs = {
  where?: InputMaybe<UserEventInviteWhereInput>;
};

export type UserCountInviterTeamsArgs = {
  where?: InputMaybe<UserTeamInviteWhereInput>;
};

export type UserCountUserEventsArgs = {
  where?: InputMaybe<UserEventWhereInput>;
};

export type UserCountUserNotificationPreferencesArgs = {
  where?: InputMaybe<UserNotificationPreferencesWhereInput>;
};

export type UserCountUserRatingArgs = {
  where?: InputMaybe<UserRatingWhereInput>;
};

export type UserCountUserTeamEventsArgs = {
  where?: InputMaybe<UserTeamEventWhereInput>;
};

export type UserCountUserTeamsArgs = {
  where?: InputMaybe<UserTeamWhereInput>;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  favoriteSports: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Int']['output'];
  isPseudo: Scalars['Int']['output'];
  lastViewedFeed: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  optIn: Scalars['Int']['output'];
  sport: Scalars['Int']['output'];
  startDate: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type UserCountOrderByAggregateInput = {
  email?: InputMaybe<SortOrder>;
  favoriteSports?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  isPseudo?: InputMaybe<SortOrder>;
  lastViewedFeed?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  optIn?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateManyInput = {
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
};

export type UserCreateNestedOneWithoutCourtInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCourtInput>;
  create?: InputMaybe<UserCreateWithoutCourtInput>;
};

export type UserCreateNestedOneWithoutCreatedEventsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedEventsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedEventsInput>;
};

export type UserCreateNestedOneWithoutInviteeEventsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInviteeEventsInput>;
  create?: InputMaybe<UserCreateWithoutInviteeEventsInput>;
};

export type UserCreateNestedOneWithoutInviteeTeamsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInviteeTeamsInput>;
  create?: InputMaybe<UserCreateWithoutInviteeTeamsInput>;
};

export type UserCreateNestedOneWithoutInviterEventsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInviterEventsInput>;
  create?: InputMaybe<UserCreateWithoutInviterEventsInput>;
};

export type UserCreateNestedOneWithoutInviterTeamsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInviterTeamsInput>;
  create?: InputMaybe<UserCreateWithoutInviterTeamsInput>;
};

export type UserCreateNestedOneWithoutLocationInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLocationInput>;
  create?: InputMaybe<UserCreateWithoutLocationInput>;
};

export type UserCreateNestedOneWithoutTeamInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTeamInput>;
  create?: InputMaybe<UserCreateWithoutTeamInput>;
};

export type UserCreateNestedOneWithoutUserEventsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserEventsInput>;
  create?: InputMaybe<UserCreateWithoutUserEventsInput>;
};

export type UserCreateNestedOneWithoutUserNotificationPreferencesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserNotificationPreferencesInput>;
  create?: InputMaybe<UserCreateWithoutUserNotificationPreferencesInput>;
};

export type UserCreateNestedOneWithoutUserRatingInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserRatingInput>;
  create?: InputMaybe<UserCreateWithoutUserRatingInput>;
};

export type UserCreateNestedOneWithoutUserTeamEventsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserTeamEventsInput>;
  create?: InputMaybe<UserCreateWithoutUserTeamEventsInput>;
};

export type UserCreateNestedOneWithoutUserTeamsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserTeamsInput>;
  create?: InputMaybe<UserCreateWithoutUserTeamsInput>;
};

export type UserCreateOrConnectWithoutCourtInput = {
  create: UserCreateWithoutCourtInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreatedEventsInput = {
  create: UserCreateWithoutCreatedEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInviteeEventsInput = {
  create: UserCreateWithoutInviteeEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInviteeTeamsInput = {
  create: UserCreateWithoutInviteeTeamsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInviterEventsInput = {
  create: UserCreateWithoutInviterEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInviterTeamsInput = {
  create: UserCreateWithoutInviterTeamsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLocationInput = {
  create: UserCreateWithoutLocationInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTeamInput = {
  create: UserCreateWithoutTeamInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserEventsInput = {
  create: UserCreateWithoutUserEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserNotificationPreferencesInput = {
  create: UserCreateWithoutUserNotificationPreferencesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserRatingInput = {
  create: UserCreateWithoutUserRatingInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserTeamEventsInput = {
  create: UserCreateWithoutUserTeamEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserTeamsInput = {
  create: UserCreateWithoutUserTeamsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCourtInput = {
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutCreatedEventsInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutInviteeEventsInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutInviteeTeamsInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutInviterEventsInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutInviterTeamsInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutLocationInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutTeamInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserEventsInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserNotificationPreferencesInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserRatingInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserTeamEventsInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeams?: InputMaybe<UserTeamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserTeamsInput = {
  Court?: InputMaybe<CourtCreateNestedManyWithoutCreatedByUserInput>;
  Location?: InputMaybe<LocationCreateNestedManyWithoutCreatedByUserInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutCreatedByUserInput>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatedByUserInput>;
  email: Scalars['String']['input'];
  favoriteSports?: InputMaybe<UserCreatefavoriteSportsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutUserInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutUserInput>;
  inviterEvents?: InputMaybe<UserEventInviteCreateNestedManyWithoutCreatedByUserInput>;
  inviterTeams?: InputMaybe<UserTeamInviteCreateNestedManyWithoutCreatedByUserInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPseudo?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedFeed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  sport?: InputMaybe<Sport>;
  startDate: Scalars['DateTimeISO']['input'];
  userEvents?: InputMaybe<UserEventCreateNestedManyWithoutUserInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesCreateNestedManyWithoutUserInput>;
  userRating?: InputMaybe<UserRatingCreateNestedManyWithoutUserInput>;
  userTeamEvents?: InputMaybe<UserTeamEventCreateNestedManyWithoutUserInput>;
};

export type UserCreatefavoriteSportsInput = {
  set: Array<Sport>;
};

export type UserEvent = {
  __typename?: 'UserEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  eventPosition?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  status: Status;
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserEventAvgAggregate = {
  __typename?: 'UserEventAvgAggregate';
  eventPosition?: Maybe<Scalars['Float']['output']>;
};

export type UserEventAvgOrderByAggregateInput = {
  eventPosition?: InputMaybe<SortOrder>;
};

export type UserEventCountAggregate = {
  __typename?: 'UserEventCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  eventId: Scalars['Int']['output'];
  eventPosition: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserEventCountOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  eventPosition?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserEventCreateInput = {
  event: EventCreateNestedOneWithoutEventUsersInput;
  eventPosition?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  user: UserCreateNestedOneWithoutUserEventsInput;
};

export type UserEventCreateManyEventInput = {
  eventPosition?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  userId: Scalars['String']['input'];
};

export type UserEventCreateManyEventInputEnvelope = {
  data: Array<UserEventCreateManyEventInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserEventCreateManyInput = {
  eventId: Scalars['String']['input'];
  eventPosition?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  userId: Scalars['String']['input'];
};

export type UserEventCreateManyUserInput = {
  eventId: Scalars['String']['input'];
  eventPosition?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
};

export type UserEventCreateManyUserInputEnvelope = {
  data: Array<UserEventCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserEventCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<UserEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserEventCreateOrConnectWithoutEventInput>
  >;
  create?: InputMaybe<Array<UserEventCreateWithoutEventInput>>;
  createMany?: InputMaybe<UserEventCreateManyEventInputEnvelope>;
};

export type UserEventCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserEventCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserEventCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserEventCreateManyUserInputEnvelope>;
};

export type UserEventCreateOrConnectWithoutEventInput = {
  create: UserEventCreateWithoutEventInput;
  where: UserEventWhereUniqueInput;
};

export type UserEventCreateOrConnectWithoutUserInput = {
  create: UserEventCreateWithoutUserInput;
  where: UserEventWhereUniqueInput;
};

export type UserEventCreateWithoutEventInput = {
  eventPosition?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  user: UserCreateNestedOneWithoutUserEventsInput;
};

export type UserEventCreateWithoutUserInput = {
  event: EventCreateNestedOneWithoutEventUsersInput;
  eventPosition?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
};

export type UserEventGroupBy = {
  __typename?: 'UserEventGroupBy';
  _avg?: Maybe<UserEventAvgAggregate>;
  _count?: Maybe<UserEventCountAggregate>;
  _max?: Maybe<UserEventMaxAggregate>;
  _min?: Maybe<UserEventMinAggregate>;
  _sum?: Maybe<UserEventSumAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  eventId: Scalars['String']['output'];
  eventPosition?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  status: Status;
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type UserEventInvite = {
  __typename?: 'UserEventInvite';
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser: User;
  createdByUserId: Scalars['String']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: InviteStatus;
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserEventInviteCountAggregate = {
  __typename?: 'UserEventInviteCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  createdByUserId: Scalars['Int']['output'];
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserEventInviteCountOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserEventInviteCreateInput = {
  createdByUser: UserCreateNestedOneWithoutInviterEventsInput;
  event: EventCreateNestedOneWithoutInvitedUsersInput;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  user: UserCreateNestedOneWithoutInviteeEventsInput;
};

export type UserEventInviteCreateManyCreatedByUserInput = {
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  userId: Scalars['String']['input'];
};

export type UserEventInviteCreateManyCreatedByUserInputEnvelope = {
  data: Array<UserEventInviteCreateManyCreatedByUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserEventInviteCreateManyEventInput = {
  createdByUserId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  userId: Scalars['String']['input'];
};

export type UserEventInviteCreateManyEventInputEnvelope = {
  data: Array<UserEventInviteCreateManyEventInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserEventInviteCreateManyInput = {
  createdByUserId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  userId: Scalars['String']['input'];
};

export type UserEventInviteCreateManyUserInput = {
  createdByUserId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
};

export type UserEventInviteCreateManyUserInputEnvelope = {
  data: Array<UserEventInviteCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserEventInviteCreateNestedManyWithoutCreatedByUserInput = {
  connect?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserEventInviteCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<UserEventInviteCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<UserEventInviteCreateManyCreatedByUserInputEnvelope>;
};

export type UserEventInviteCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserEventInviteCreateOrConnectWithoutEventInput>
  >;
  create?: InputMaybe<Array<UserEventInviteCreateWithoutEventInput>>;
  createMany?: InputMaybe<UserEventInviteCreateManyEventInputEnvelope>;
};

export type UserEventInviteCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserEventInviteCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserEventInviteCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserEventInviteCreateManyUserInputEnvelope>;
};

export type UserEventInviteCreateOrConnectWithoutCreatedByUserInput = {
  create: UserEventInviteCreateWithoutCreatedByUserInput;
  where: UserEventInviteWhereUniqueInput;
};

export type UserEventInviteCreateOrConnectWithoutEventInput = {
  create: UserEventInviteCreateWithoutEventInput;
  where: UserEventInviteWhereUniqueInput;
};

export type UserEventInviteCreateOrConnectWithoutUserInput = {
  create: UserEventInviteCreateWithoutUserInput;
  where: UserEventInviteWhereUniqueInput;
};

export type UserEventInviteCreateWithoutCreatedByUserInput = {
  event: EventCreateNestedOneWithoutInvitedUsersInput;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  user: UserCreateNestedOneWithoutInviteeEventsInput;
};

export type UserEventInviteCreateWithoutEventInput = {
  createdByUser: UserCreateNestedOneWithoutInviterEventsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  user: UserCreateNestedOneWithoutInviteeEventsInput;
};

export type UserEventInviteCreateWithoutUserInput = {
  createdByUser: UserCreateNestedOneWithoutInviterEventsInput;
  event: EventCreateNestedOneWithoutInvitedUsersInput;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
};

export type UserEventInviteGroupBy = {
  __typename?: 'UserEventInviteGroupBy';
  _count?: Maybe<UserEventInviteCountAggregate>;
  _max?: Maybe<UserEventInviteMaxAggregate>;
  _min?: Maybe<UserEventInviteMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUserId: Scalars['String']['output'];
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: InviteStatus;
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type UserEventInviteListRelationFilter = {
  every?: InputMaybe<UserEventInviteWhereInput>;
  none?: InputMaybe<UserEventInviteWhereInput>;
  some?: InputMaybe<UserEventInviteWhereInput>;
};

export type UserEventInviteMaxAggregate = {
  __typename?: 'UserEventInviteMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<InviteStatus>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserEventInviteMaxOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserEventInviteMinAggregate = {
  __typename?: 'UserEventInviteMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<InviteStatus>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserEventInviteMinOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserEventInviteOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserEventInviteOrderByWithAggregationInput = {
  _count?: InputMaybe<UserEventInviteCountOrderByAggregateInput>;
  _max?: InputMaybe<UserEventInviteMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserEventInviteMinOrderByAggregateInput>;
  createdByUserId?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserEventInviteOrderByWithRelationInput = {
  createdByUser?: InputMaybe<UserOrderByWithRelationInput>;
  createdByUserId?: InputMaybe<SortOrder>;
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserEventInviteScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  EventId = 'eventId',
  Id = 'id',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserEventInviteScalarWhereInput = {
  AND?: InputMaybe<Array<UserEventInviteScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserEventInviteScalarWhereInput>>;
  OR?: InputMaybe<Array<UserEventInviteScalarWhereInput>>;
  createdByUserId?: InputMaybe<StringFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumInviteStatusFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserEventInviteScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserEventInviteScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserEventInviteScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserEventInviteScalarWhereWithAggregatesInput>>;
  createdByUserId?: InputMaybe<StringWithAggregatesFilter>;
  eventId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumInviteStatusWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserEventInviteUpdateInput = {
  createdByUser?: InputMaybe<UserUpdateOneRequiredWithoutInviterEventsNestedInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutInvitedUsersNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInviteStatusFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutInviteeEventsNestedInput>;
};

export type UserEventInviteUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInviteStatusFieldUpdateOperationsInput>;
};

export type UserEventInviteUpdateManyWithWhereWithoutCreatedByUserInput = {
  data: UserEventInviteUpdateManyMutationInput;
  where: UserEventInviteScalarWhereInput;
};

export type UserEventInviteUpdateManyWithWhereWithoutEventInput = {
  data: UserEventInviteUpdateManyMutationInput;
  where: UserEventInviteScalarWhereInput;
};

export type UserEventInviteUpdateManyWithWhereWithoutUserInput = {
  data: UserEventInviteUpdateManyMutationInput;
  where: UserEventInviteScalarWhereInput;
};

export type UserEventInviteUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserEventInviteCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<UserEventInviteCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<UserEventInviteCreateManyCreatedByUserInputEnvelope>;
  delete?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserEventInviteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  set?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserEventInviteUpdateWithWhereUniqueWithoutCreatedByUserInput>
  >;
  updateMany?: InputMaybe<
    Array<UserEventInviteUpdateManyWithWhereWithoutCreatedByUserInput>
  >;
  upsert?: InputMaybe<
    Array<UserEventInviteUpsertWithWhereUniqueWithoutCreatedByUserInput>
  >;
};

export type UserEventInviteUpdateManyWithoutEventNestedInput = {
  connect?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserEventInviteCreateOrConnectWithoutEventInput>
  >;
  create?: InputMaybe<Array<UserEventInviteCreateWithoutEventInput>>;
  createMany?: InputMaybe<UserEventInviteCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserEventInviteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  set?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserEventInviteUpdateWithWhereUniqueWithoutEventInput>
  >;
  updateMany?: InputMaybe<
    Array<UserEventInviteUpdateManyWithWhereWithoutEventInput>
  >;
  upsert?: InputMaybe<
    Array<UserEventInviteUpsertWithWhereUniqueWithoutEventInput>
  >;
};

export type UserEventInviteUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserEventInviteCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserEventInviteCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserEventInviteCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserEventInviteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  set?: InputMaybe<Array<UserEventInviteWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserEventInviteUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<UserEventInviteUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<UserEventInviteUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type UserEventInviteUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  data: UserEventInviteUpdateWithoutCreatedByUserInput;
  where: UserEventInviteWhereUniqueInput;
};

export type UserEventInviteUpdateWithWhereUniqueWithoutEventInput = {
  data: UserEventInviteUpdateWithoutEventInput;
  where: UserEventInviteWhereUniqueInput;
};

export type UserEventInviteUpdateWithWhereUniqueWithoutUserInput = {
  data: UserEventInviteUpdateWithoutUserInput;
  where: UserEventInviteWhereUniqueInput;
};

export type UserEventInviteUpdateWithoutCreatedByUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutInvitedUsersNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInviteStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutInviteeEventsNestedInput>;
};

export type UserEventInviteUpdateWithoutEventInput = {
  createdByUser?: InputMaybe<UserUpdateOneRequiredWithoutInviterEventsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInviteStatusFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutInviteeEventsNestedInput>;
};

export type UserEventInviteUpdateWithoutUserInput = {
  createdByUser?: InputMaybe<UserUpdateOneRequiredWithoutInviterEventsNestedInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutInvitedUsersNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInviteStatusFieldUpdateOperationsInput>;
};

export type UserEventInviteUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  create: UserEventInviteCreateWithoutCreatedByUserInput;
  update: UserEventInviteUpdateWithoutCreatedByUserInput;
  where: UserEventInviteWhereUniqueInput;
};

export type UserEventInviteUpsertWithWhereUniqueWithoutEventInput = {
  create: UserEventInviteCreateWithoutEventInput;
  update: UserEventInviteUpdateWithoutEventInput;
  where: UserEventInviteWhereUniqueInput;
};

export type UserEventInviteUpsertWithWhereUniqueWithoutUserInput = {
  create: UserEventInviteCreateWithoutUserInput;
  update: UserEventInviteUpdateWithoutUserInput;
  where: UserEventInviteWhereUniqueInput;
};

export type UserEventInviteUserIdEventIdCompoundUniqueInput = {
  eventId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserEventInviteWhereInput = {
  AND?: InputMaybe<Array<UserEventInviteWhereInput>>;
  NOT?: InputMaybe<Array<UserEventInviteWhereInput>>;
  OR?: InputMaybe<Array<UserEventInviteWhereInput>>;
  createdByUser?: InputMaybe<UserRelationFilter>;
  createdByUserId?: InputMaybe<StringFilter>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumInviteStatusFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserEventInviteWhereUniqueInput = {
  AND?: InputMaybe<Array<UserEventInviteWhereInput>>;
  NOT?: InputMaybe<Array<UserEventInviteWhereInput>>;
  OR?: InputMaybe<Array<UserEventInviteWhereInput>>;
  createdByUser?: InputMaybe<UserRelationFilter>;
  createdByUserId?: InputMaybe<StringFilter>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EnumInviteStatusFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  userId_eventId?: InputMaybe<UserEventInviteUserIdEventIdCompoundUniqueInput>;
};

export type UserEventListRelationFilter = {
  every?: InputMaybe<UserEventWhereInput>;
  none?: InputMaybe<UserEventWhereInput>;
  some?: InputMaybe<UserEventWhereInput>;
};

export type UserEventMaxAggregate = {
  __typename?: 'UserEventMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  eventPosition?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserEventMaxOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  eventPosition?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserEventMinAggregate = {
  __typename?: 'UserEventMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  eventPosition?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserEventMinOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  eventPosition?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserEventOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserEventOrderByWithAggregationInput = {
  _avg?: InputMaybe<UserEventAvgOrderByAggregateInput>;
  _count?: InputMaybe<UserEventCountOrderByAggregateInput>;
  _max?: InputMaybe<UserEventMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserEventMinOrderByAggregateInput>;
  _sum?: InputMaybe<UserEventSumOrderByAggregateInput>;
  eventId?: InputMaybe<SortOrder>;
  eventPosition?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserEventOrderByWithRelationInput = {
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrder>;
  eventPosition?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserEventScalarFieldEnum {
  CreatedAt = 'createdAt',
  EventId = 'eventId',
  EventPosition = 'eventPosition',
  Id = 'id',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserEventScalarWhereInput = {
  AND?: InputMaybe<Array<UserEventScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserEventScalarWhereInput>>;
  OR?: InputMaybe<Array<UserEventScalarWhereInput>>;
  eventId?: InputMaybe<StringFilter>;
  eventPosition?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserEventScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserEventScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserEventScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserEventScalarWhereWithAggregatesInput>>;
  eventId?: InputMaybe<StringWithAggregatesFilter>;
  eventPosition?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserEventSumAggregate = {
  __typename?: 'UserEventSumAggregate';
  eventPosition?: Maybe<Scalars['Int']['output']>;
};

export type UserEventSumOrderByAggregateInput = {
  eventPosition?: InputMaybe<SortOrder>;
};

export type UserEventUpdateInput = {
  event?: InputMaybe<EventUpdateOneRequiredWithoutEventUsersNestedInput>;
  eventPosition?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserEventsNestedInput>;
};

export type UserEventUpdateManyMutationInput = {
  eventPosition?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
};

export type UserEventUpdateManyWithWhereWithoutEventInput = {
  data: UserEventUpdateManyMutationInput;
  where: UserEventScalarWhereInput;
};

export type UserEventUpdateManyWithWhereWithoutUserInput = {
  data: UserEventUpdateManyMutationInput;
  where: UserEventScalarWhereInput;
};

export type UserEventUpdateManyWithoutEventNestedInput = {
  connect?: InputMaybe<Array<UserEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserEventCreateOrConnectWithoutEventInput>
  >;
  create?: InputMaybe<Array<UserEventCreateWithoutEventInput>>;
  createMany?: InputMaybe<UserEventCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<UserEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserEventWhereUniqueInput>>;
  set?: InputMaybe<Array<UserEventWhereUniqueInput>>;
  update?: InputMaybe<Array<UserEventUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<UserEventUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<UserEventUpsertWithWhereUniqueWithoutEventInput>>;
};

export type UserEventUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserEventCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserEventCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserEventCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserEventWhereUniqueInput>>;
  set?: InputMaybe<Array<UserEventWhereUniqueInput>>;
  update?: InputMaybe<Array<UserEventUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserEventUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserEventUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserEventUpdateWithWhereUniqueWithoutEventInput = {
  data: UserEventUpdateWithoutEventInput;
  where: UserEventWhereUniqueInput;
};

export type UserEventUpdateWithWhereUniqueWithoutUserInput = {
  data: UserEventUpdateWithoutUserInput;
  where: UserEventWhereUniqueInput;
};

export type UserEventUpdateWithoutEventInput = {
  eventPosition?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserEventsNestedInput>;
};

export type UserEventUpdateWithoutUserInput = {
  event?: InputMaybe<EventUpdateOneRequiredWithoutEventUsersNestedInput>;
  eventPosition?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
};

export type UserEventUpsertWithWhereUniqueWithoutEventInput = {
  create: UserEventCreateWithoutEventInput;
  update: UserEventUpdateWithoutEventInput;
  where: UserEventWhereUniqueInput;
};

export type UserEventUpsertWithWhereUniqueWithoutUserInput = {
  create: UserEventCreateWithoutUserInput;
  update: UserEventUpdateWithoutUserInput;
  where: UserEventWhereUniqueInput;
};

export type UserEventUserIdEventIdCompoundUniqueInput = {
  eventId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserEventWhereInput = {
  AND?: InputMaybe<Array<UserEventWhereInput>>;
  NOT?: InputMaybe<Array<UserEventWhereInput>>;
  OR?: InputMaybe<Array<UserEventWhereInput>>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  eventPosition?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserEventWhereUniqueInput = {
  AND?: InputMaybe<Array<UserEventWhereInput>>;
  NOT?: InputMaybe<Array<UserEventWhereInput>>;
  OR?: InputMaybe<Array<UserEventWhereInput>>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  eventPosition?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EnumStatusFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  userId_eventId?: InputMaybe<UserEventUserIdEventIdCompoundUniqueInput>;
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  favoriteSports?: Maybe<Array<Sport>>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isPseudo: Scalars['Boolean']['output'];
  lastViewedFeed?: Maybe<Scalars['DateTimeISO']['output']>;
  name: Scalars['String']['output'];
  optIn: Scalars['Boolean']['output'];
  sport?: Maybe<Sport>;
  startDate: Scalars['DateTimeISO']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPseudo?: Maybe<Scalars['Boolean']['output']>;
  lastViewedFeed?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  optIn?: Maybe<Scalars['Boolean']['output']>;
  sport?: Maybe<Sport>;
  startDate?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type UserMaxOrderByAggregateInput = {
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  isPseudo?: InputMaybe<SortOrder>;
  lastViewedFeed?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  optIn?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPseudo?: Maybe<Scalars['Boolean']['output']>;
  lastViewedFeed?: Maybe<Scalars['DateTimeISO']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  optIn?: Maybe<Scalars['Boolean']['output']>;
  sport?: Maybe<Sport>;
  startDate?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type UserMinOrderByAggregateInput = {
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  isPseudo?: InputMaybe<SortOrder>;
  lastViewedFeed?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  optIn?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type UserNotificationPreferences = {
  __typename?: 'UserNotificationPreferences';
  createdAt: Scalars['DateTimeISO']['output'];
  deviceId: Scalars['String']['output'];
  deviceName: Scalars['String']['output'];
  emailNotify: Scalars['Boolean']['output'];
  firebaseToken: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  pushNotify: Scalars['Boolean']['output'];
  smsNotify: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type UserNotificationPreferencesAvgAggregate = {
  __typename?: 'UserNotificationPreferencesAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type UserNotificationPreferencesAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type UserNotificationPreferencesCountAggregate = {
  __typename?: 'UserNotificationPreferencesCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  deviceId: Scalars['Int']['output'];
  deviceName: Scalars['Int']['output'];
  emailNotify: Scalars['Int']['output'];
  firebaseToken: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  pushNotify: Scalars['Int']['output'];
  smsNotify: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserNotificationPreferencesCountOrderByAggregateInput = {
  deviceId?: InputMaybe<SortOrder>;
  deviceName?: InputMaybe<SortOrder>;
  emailNotify?: InputMaybe<SortOrder>;
  firebaseToken?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pushNotify?: InputMaybe<SortOrder>;
  smsNotify?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserNotificationPreferencesCreateInput = {
  deviceId: Scalars['String']['input'];
  deviceName: Scalars['String']['input'];
  emailNotify?: InputMaybe<Scalars['Boolean']['input']>;
  firebaseToken: Scalars['String']['input'];
  pushNotify?: InputMaybe<Scalars['Boolean']['input']>;
  smsNotify?: InputMaybe<Scalars['Boolean']['input']>;
  user: UserCreateNestedOneWithoutUserNotificationPreferencesInput;
};

export type UserNotificationPreferencesCreateManyInput = {
  deviceId: Scalars['String']['input'];
  deviceName: Scalars['String']['input'];
  emailNotify?: InputMaybe<Scalars['Boolean']['input']>;
  firebaseToken: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  pushNotify?: InputMaybe<Scalars['Boolean']['input']>;
  smsNotify?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
};

export type UserNotificationPreferencesCreateManyUserInput = {
  deviceId: Scalars['String']['input'];
  deviceName: Scalars['String']['input'];
  emailNotify?: InputMaybe<Scalars['Boolean']['input']>;
  firebaseToken: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  pushNotify?: InputMaybe<Scalars['Boolean']['input']>;
  smsNotify?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserNotificationPreferencesCreateManyUserInputEnvelope = {
  data: Array<UserNotificationPreferencesCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserNotificationPreferencesCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserNotificationPreferencesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserNotificationPreferencesCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserNotificationPreferencesCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserNotificationPreferencesCreateManyUserInputEnvelope>;
};

export type UserNotificationPreferencesCreateOrConnectWithoutUserInput = {
  create: UserNotificationPreferencesCreateWithoutUserInput;
  where: UserNotificationPreferencesWhereUniqueInput;
};

export type UserNotificationPreferencesCreateWithoutUserInput = {
  deviceId: Scalars['String']['input'];
  deviceName: Scalars['String']['input'];
  emailNotify?: InputMaybe<Scalars['Boolean']['input']>;
  firebaseToken: Scalars['String']['input'];
  pushNotify?: InputMaybe<Scalars['Boolean']['input']>;
  smsNotify?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserNotificationPreferencesGroupBy = {
  __typename?: 'UserNotificationPreferencesGroupBy';
  _avg?: Maybe<UserNotificationPreferencesAvgAggregate>;
  _count?: Maybe<UserNotificationPreferencesCountAggregate>;
  _max?: Maybe<UserNotificationPreferencesMaxAggregate>;
  _min?: Maybe<UserNotificationPreferencesMinAggregate>;
  _sum?: Maybe<UserNotificationPreferencesSumAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  deviceId: Scalars['String']['output'];
  deviceName: Scalars['String']['output'];
  emailNotify: Scalars['Boolean']['output'];
  firebaseToken: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  pushNotify: Scalars['Boolean']['output'];
  smsNotify: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type UserNotificationPreferencesListRelationFilter = {
  every?: InputMaybe<UserNotificationPreferencesWhereInput>;
  none?: InputMaybe<UserNotificationPreferencesWhereInput>;
  some?: InputMaybe<UserNotificationPreferencesWhereInput>;
};

export type UserNotificationPreferencesMaxAggregate = {
  __typename?: 'UserNotificationPreferencesMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceName?: Maybe<Scalars['String']['output']>;
  emailNotify?: Maybe<Scalars['Boolean']['output']>;
  firebaseToken?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  pushNotify?: Maybe<Scalars['Boolean']['output']>;
  smsNotify?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserNotificationPreferencesMaxOrderByAggregateInput = {
  deviceId?: InputMaybe<SortOrder>;
  deviceName?: InputMaybe<SortOrder>;
  emailNotify?: InputMaybe<SortOrder>;
  firebaseToken?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pushNotify?: InputMaybe<SortOrder>;
  smsNotify?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserNotificationPreferencesMinAggregate = {
  __typename?: 'UserNotificationPreferencesMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceName?: Maybe<Scalars['String']['output']>;
  emailNotify?: Maybe<Scalars['Boolean']['output']>;
  firebaseToken?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  pushNotify?: Maybe<Scalars['Boolean']['output']>;
  smsNotify?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserNotificationPreferencesMinOrderByAggregateInput = {
  deviceId?: InputMaybe<SortOrder>;
  deviceName?: InputMaybe<SortOrder>;
  emailNotify?: InputMaybe<SortOrder>;
  firebaseToken?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pushNotify?: InputMaybe<SortOrder>;
  smsNotify?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserNotificationPreferencesOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserNotificationPreferencesOrderByWithAggregationInput = {
  _avg?: InputMaybe<UserNotificationPreferencesAvgOrderByAggregateInput>;
  _count?: InputMaybe<UserNotificationPreferencesCountOrderByAggregateInput>;
  _max?: InputMaybe<UserNotificationPreferencesMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserNotificationPreferencesMinOrderByAggregateInput>;
  _sum?: InputMaybe<UserNotificationPreferencesSumOrderByAggregateInput>;
  deviceId?: InputMaybe<SortOrder>;
  deviceName?: InputMaybe<SortOrder>;
  emailNotify?: InputMaybe<SortOrder>;
  firebaseToken?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pushNotify?: InputMaybe<SortOrder>;
  smsNotify?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserNotificationPreferencesOrderByWithRelationInput = {
  deviceId?: InputMaybe<SortOrder>;
  deviceName?: InputMaybe<SortOrder>;
  emailNotify?: InputMaybe<SortOrder>;
  firebaseToken?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pushNotify?: InputMaybe<SortOrder>;
  smsNotify?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserNotificationPreferencesScalarFieldEnum {
  CreatedAt = 'createdAt',
  DeviceId = 'deviceId',
  DeviceName = 'deviceName',
  EmailNotify = 'emailNotify',
  FirebaseToken = 'firebaseToken',
  Id = 'id',
  PushNotify = 'pushNotify',
  SmsNotify = 'smsNotify',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserNotificationPreferencesScalarWhereInput = {
  AND?: InputMaybe<Array<UserNotificationPreferencesScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserNotificationPreferencesScalarWhereInput>>;
  OR?: InputMaybe<Array<UserNotificationPreferencesScalarWhereInput>>;
  deviceId?: InputMaybe<StringFilter>;
  deviceName?: InputMaybe<StringFilter>;
  emailNotify?: InputMaybe<BoolFilter>;
  firebaseToken?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  pushNotify?: InputMaybe<BoolFilter>;
  smsNotify?: InputMaybe<BoolFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserNotificationPreferencesScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<
    Array<UserNotificationPreferencesScalarWhereWithAggregatesInput>
  >;
  NOT?: InputMaybe<
    Array<UserNotificationPreferencesScalarWhereWithAggregatesInput>
  >;
  OR?: InputMaybe<
    Array<UserNotificationPreferencesScalarWhereWithAggregatesInput>
  >;
  deviceId?: InputMaybe<StringWithAggregatesFilter>;
  deviceName?: InputMaybe<StringWithAggregatesFilter>;
  emailNotify?: InputMaybe<BoolWithAggregatesFilter>;
  firebaseToken?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  pushNotify?: InputMaybe<BoolWithAggregatesFilter>;
  smsNotify?: InputMaybe<BoolWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserNotificationPreferencesSumAggregate = {
  __typename?: 'UserNotificationPreferencesSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type UserNotificationPreferencesSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type UserNotificationPreferencesUpdateInput = {
  deviceId?: InputMaybe<StringFieldUpdateOperationsInput>;
  deviceName?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailNotify?: InputMaybe<BoolFieldUpdateOperationsInput>;
  firebaseToken?: InputMaybe<StringFieldUpdateOperationsInput>;
  pushNotify?: InputMaybe<BoolFieldUpdateOperationsInput>;
  smsNotify?: InputMaybe<BoolFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserNotificationPreferencesNestedInput>;
};

export type UserNotificationPreferencesUpdateManyMutationInput = {
  deviceId?: InputMaybe<StringFieldUpdateOperationsInput>;
  deviceName?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailNotify?: InputMaybe<BoolFieldUpdateOperationsInput>;
  firebaseToken?: InputMaybe<StringFieldUpdateOperationsInput>;
  pushNotify?: InputMaybe<BoolFieldUpdateOperationsInput>;
  smsNotify?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type UserNotificationPreferencesUpdateManyWithWhereWithoutUserInput = {
  data: UserNotificationPreferencesUpdateManyMutationInput;
  where: UserNotificationPreferencesScalarWhereInput;
};

export type UserNotificationPreferencesUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserNotificationPreferencesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserNotificationPreferencesCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserNotificationPreferencesCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserNotificationPreferencesCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserNotificationPreferencesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserNotificationPreferencesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserNotificationPreferencesWhereUniqueInput>>;
  set?: InputMaybe<Array<UserNotificationPreferencesWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserNotificationPreferencesUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<UserNotificationPreferencesUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<UserNotificationPreferencesUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type UserNotificationPreferencesUpdateWithWhereUniqueWithoutUserInput = {
  data: UserNotificationPreferencesUpdateWithoutUserInput;
  where: UserNotificationPreferencesWhereUniqueInput;
};

export type UserNotificationPreferencesUpdateWithoutUserInput = {
  deviceId?: InputMaybe<StringFieldUpdateOperationsInput>;
  deviceName?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailNotify?: InputMaybe<BoolFieldUpdateOperationsInput>;
  firebaseToken?: InputMaybe<StringFieldUpdateOperationsInput>;
  pushNotify?: InputMaybe<BoolFieldUpdateOperationsInput>;
  smsNotify?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type UserNotificationPreferencesUpsertWithWhereUniqueWithoutUserInput = {
  create: UserNotificationPreferencesCreateWithoutUserInput;
  update: UserNotificationPreferencesUpdateWithoutUserInput;
  where: UserNotificationPreferencesWhereUniqueInput;
};

export type UserNotificationPreferencesUserIdDeviceIdCompoundUniqueInput = {
  deviceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserNotificationPreferencesWhereInput = {
  AND?: InputMaybe<Array<UserNotificationPreferencesWhereInput>>;
  NOT?: InputMaybe<Array<UserNotificationPreferencesWhereInput>>;
  OR?: InputMaybe<Array<UserNotificationPreferencesWhereInput>>;
  deviceId?: InputMaybe<StringFilter>;
  deviceName?: InputMaybe<StringFilter>;
  emailNotify?: InputMaybe<BoolFilter>;
  firebaseToken?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  pushNotify?: InputMaybe<BoolFilter>;
  smsNotify?: InputMaybe<BoolFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserNotificationPreferencesWhereUniqueInput = {
  AND?: InputMaybe<Array<UserNotificationPreferencesWhereInput>>;
  NOT?: InputMaybe<Array<UserNotificationPreferencesWhereInput>>;
  OR?: InputMaybe<Array<UserNotificationPreferencesWhereInput>>;
  deviceId?: InputMaybe<StringFilter>;
  deviceName?: InputMaybe<StringFilter>;
  emailNotify?: InputMaybe<BoolFilter>;
  firebaseToken?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  pushNotify?: InputMaybe<BoolFilter>;
  smsNotify?: InputMaybe<BoolFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  userId_deviceId?: InputMaybe<UserNotificationPreferencesUserIdDeviceIdCompoundUniqueInput>;
};

export type UserNullableRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  email?: InputMaybe<SortOrder>;
  favoriteSports?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  isPseudo?: InputMaybe<SortOrder>;
  lastViewedFeed?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  optIn?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrderInput>;
  startDate?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  Court?: InputMaybe<CourtOrderByRelationAggregateInput>;
  Location?: InputMaybe<LocationOrderByRelationAggregateInput>;
  Team?: InputMaybe<TeamOrderByRelationAggregateInput>;
  createdEvents?: InputMaybe<EventOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  favoriteSports?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inviteeEvents?: InputMaybe<UserEventInviteOrderByRelationAggregateInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteOrderByRelationAggregateInput>;
  inviterEvents?: InputMaybe<UserEventInviteOrderByRelationAggregateInput>;
  inviterTeams?: InputMaybe<UserTeamInviteOrderByRelationAggregateInput>;
  isActive?: InputMaybe<SortOrder>;
  isPseudo?: InputMaybe<SortOrder>;
  lastViewedFeed?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  optIn?: InputMaybe<SortOrder>;
  sport?: InputMaybe<SortOrderInput>;
  startDate?: InputMaybe<SortOrder>;
  userEvents?: InputMaybe<UserEventOrderByRelationAggregateInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesOrderByRelationAggregateInput>;
  userRating?: InputMaybe<UserRatingOrderByRelationAggregateInput>;
  userTeamEvents?: InputMaybe<UserTeamEventOrderByRelationAggregateInput>;
  userTeams?: InputMaybe<UserTeamOrderByRelationAggregateInput>;
};

export type UserRating = {
  __typename?: 'UserRating';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  rating: Rating;
  ratingId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserRatingCountAggregate = {
  __typename?: 'UserRatingCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  ratingId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserRatingCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  ratingId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserRatingCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  rating: RatingCreateNestedOneWithoutUserRatingInput;
  user: UserCreateNestedOneWithoutUserRatingInput;
};

export type UserRatingCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  ratingId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserRatingCreateManyRatingInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserRatingCreateManyRatingInputEnvelope = {
  data: Array<UserRatingCreateManyRatingInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserRatingCreateManyUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  ratingId: Scalars['String']['input'];
};

export type UserRatingCreateManyUserInputEnvelope = {
  data: Array<UserRatingCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserRatingCreateNestedManyWithoutRatingInput = {
  connect?: InputMaybe<Array<UserRatingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserRatingCreateOrConnectWithoutRatingInput>
  >;
  create?: InputMaybe<Array<UserRatingCreateWithoutRatingInput>>;
  createMany?: InputMaybe<UserRatingCreateManyRatingInputEnvelope>;
};

export type UserRatingCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserRatingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserRatingCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserRatingCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserRatingCreateManyUserInputEnvelope>;
};

export type UserRatingCreateOrConnectWithoutRatingInput = {
  create: UserRatingCreateWithoutRatingInput;
  where: UserRatingWhereUniqueInput;
};

export type UserRatingCreateOrConnectWithoutUserInput = {
  create: UserRatingCreateWithoutUserInput;
  where: UserRatingWhereUniqueInput;
};

export type UserRatingCreateWithoutRatingInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  user: UserCreateNestedOneWithoutUserRatingInput;
};

export type UserRatingCreateWithoutUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  rating: RatingCreateNestedOneWithoutUserRatingInput;
};

export type UserRatingGroupBy = {
  __typename?: 'UserRatingGroupBy';
  _count?: Maybe<UserRatingCountAggregate>;
  _max?: Maybe<UserRatingMaxAggregate>;
  _min?: Maybe<UserRatingMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  ratingId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type UserRatingListRelationFilter = {
  every?: InputMaybe<UserRatingWhereInput>;
  none?: InputMaybe<UserRatingWhereInput>;
  some?: InputMaybe<UserRatingWhereInput>;
};

export type UserRatingMaxAggregate = {
  __typename?: 'UserRatingMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ratingId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserRatingMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  ratingId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserRatingMinAggregate = {
  __typename?: 'UserRatingMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ratingId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserRatingMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  ratingId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserRatingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserRatingOrderByWithAggregationInput = {
  _count?: InputMaybe<UserRatingCountOrderByAggregateInput>;
  _max?: InputMaybe<UserRatingMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserRatingMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  ratingId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserRatingOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<RatingOrderByWithRelationInput>;
  ratingId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserRatingScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  RatingId = 'ratingId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserRatingScalarWhereInput = {
  AND?: InputMaybe<Array<UserRatingScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserRatingScalarWhereInput>>;
  OR?: InputMaybe<Array<UserRatingScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  ratingId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserRatingScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserRatingScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserRatingScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserRatingScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  ratingId?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserRatingUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating?: InputMaybe<RatingUpdateOneRequiredWithoutUserRatingNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserRatingNestedInput>;
};

export type UserRatingUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserRatingUpdateManyWithWhereWithoutRatingInput = {
  data: UserRatingUpdateManyMutationInput;
  where: UserRatingScalarWhereInput;
};

export type UserRatingUpdateManyWithWhereWithoutUserInput = {
  data: UserRatingUpdateManyMutationInput;
  where: UserRatingScalarWhereInput;
};

export type UserRatingUpdateManyWithoutRatingNestedInput = {
  connect?: InputMaybe<Array<UserRatingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserRatingCreateOrConnectWithoutRatingInput>
  >;
  create?: InputMaybe<Array<UserRatingCreateWithoutRatingInput>>;
  createMany?: InputMaybe<UserRatingCreateManyRatingInputEnvelope>;
  delete?: InputMaybe<Array<UserRatingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserRatingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserRatingWhereUniqueInput>>;
  set?: InputMaybe<Array<UserRatingWhereUniqueInput>>;
  update?: InputMaybe<Array<UserRatingUpdateWithWhereUniqueWithoutRatingInput>>;
  updateMany?: InputMaybe<
    Array<UserRatingUpdateManyWithWhereWithoutRatingInput>
  >;
  upsert?: InputMaybe<Array<UserRatingUpsertWithWhereUniqueWithoutRatingInput>>;
};

export type UserRatingUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserRatingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserRatingCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserRatingCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserRatingCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserRatingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserRatingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserRatingWhereUniqueInput>>;
  set?: InputMaybe<Array<UserRatingWhereUniqueInput>>;
  update?: InputMaybe<Array<UserRatingUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserRatingUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserRatingUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserRatingUpdateWithWhereUniqueWithoutRatingInput = {
  data: UserRatingUpdateWithoutRatingInput;
  where: UserRatingWhereUniqueInput;
};

export type UserRatingUpdateWithWhereUniqueWithoutUserInput = {
  data: UserRatingUpdateWithoutUserInput;
  where: UserRatingWhereUniqueInput;
};

export type UserRatingUpdateWithoutRatingInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserRatingNestedInput>;
};

export type UserRatingUpdateWithoutUserInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating?: InputMaybe<RatingUpdateOneRequiredWithoutUserRatingNestedInput>;
};

export type UserRatingUpsertWithWhereUniqueWithoutRatingInput = {
  create: UserRatingCreateWithoutRatingInput;
  update: UserRatingUpdateWithoutRatingInput;
  where: UserRatingWhereUniqueInput;
};

export type UserRatingUpsertWithWhereUniqueWithoutUserInput = {
  create: UserRatingCreateWithoutUserInput;
  update: UserRatingUpdateWithoutUserInput;
  where: UserRatingWhereUniqueInput;
};

export type UserRatingUserIdRatingIdCompoundUniqueInput = {
  ratingId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserRatingWhereInput = {
  AND?: InputMaybe<Array<UserRatingWhereInput>>;
  NOT?: InputMaybe<Array<UserRatingWhereInput>>;
  OR?: InputMaybe<Array<UserRatingWhereInput>>;
  id?: InputMaybe<StringFilter>;
  rating?: InputMaybe<RatingRelationFilter>;
  ratingId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserRatingWhereUniqueInput = {
  AND?: InputMaybe<Array<UserRatingWhereInput>>;
  NOT?: InputMaybe<Array<UserRatingWhereInput>>;
  OR?: InputMaybe<Array<UserRatingWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<RatingRelationFilter>;
  ratingId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  userId_ratingId?: InputMaybe<UserRatingUserIdRatingIdCompoundUniqueInput>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  FavoriteSports = 'favoriteSports',
  Id = 'id',
  IsActive = 'isActive',
  IsPseudo = 'isPseudo',
  LastViewedFeed = 'lastViewedFeed',
  Name = 'name',
  OptIn = 'optIn',
  Sport = 'sport',
  StartDate = 'startDate',
  UpdatedAt = 'updatedAt',
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  favoriteSports?: InputMaybe<EnumSportNullableListFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isActive?: InputMaybe<BoolWithAggregatesFilter>;
  isPseudo?: InputMaybe<BoolWithAggregatesFilter>;
  lastViewedFeed?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  optIn?: InputMaybe<BoolWithAggregatesFilter>;
  sport?: InputMaybe<EnumSportNullableWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type UserTeam = {
  __typename?: 'UserTeam';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  status: Status;
  team: Team;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserTeamCountAggregate = {
  __typename?: 'UserTeamCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  teamId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserTeamCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  team: TeamCreateNestedOneWithoutTeamUsersInput;
  user: UserCreateNestedOneWithoutUserTeamsInput;
};

export type UserTeamCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserTeamCreateManyTeamInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  userId: Scalars['String']['input'];
};

export type UserTeamCreateManyTeamInputEnvelope = {
  data: Array<UserTeamCreateManyTeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserTeamCreateManyUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  teamId: Scalars['String']['input'];
};

export type UserTeamCreateManyUserInputEnvelope = {
  data: Array<UserTeamCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserTeamCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<UserTeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserTeamCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<UserTeamCreateWithoutTeamInput>>;
  createMany?: InputMaybe<UserTeamCreateManyTeamInputEnvelope>;
};

export type UserTeamCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserTeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserTeamCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserTeamCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserTeamCreateManyUserInputEnvelope>;
};

export type UserTeamCreateOrConnectWithoutTeamInput = {
  create: UserTeamCreateWithoutTeamInput;
  where: UserTeamWhereUniqueInput;
};

export type UserTeamCreateOrConnectWithoutUserInput = {
  create: UserTeamCreateWithoutUserInput;
  where: UserTeamWhereUniqueInput;
};

export type UserTeamCreateWithoutTeamInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  user: UserCreateNestedOneWithoutUserTeamsInput;
};

export type UserTeamCreateWithoutUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  team: TeamCreateNestedOneWithoutTeamUsersInput;
};

export type UserTeamEvent = {
  __typename?: 'UserTeamEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Status;
  team: Team;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserTeamEventCountAggregate = {
  __typename?: 'UserTeamEventCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  teamId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserTeamEventCountOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamEventCreateInput = {
  event: EventCreateNestedOneWithoutUserTeamEventsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  team: TeamCreateNestedOneWithoutUserTeamEventsInput;
  user: UserCreateNestedOneWithoutUserTeamEventsInput;
};

export type UserTeamEventCreateManyEventInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserTeamEventCreateManyEventInputEnvelope = {
  data: Array<UserTeamEventCreateManyEventInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserTeamEventCreateManyInput = {
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserTeamEventCreateManyTeamInput = {
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  userId: Scalars['String']['input'];
};

export type UserTeamEventCreateManyTeamInputEnvelope = {
  data: Array<UserTeamEventCreateManyTeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserTeamEventCreateManyUserInput = {
  eventId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  teamId: Scalars['String']['input'];
};

export type UserTeamEventCreateManyUserInputEnvelope = {
  data: Array<UserTeamEventCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserTeamEventCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamEventCreateOrConnectWithoutEventInput>
  >;
  create?: InputMaybe<Array<UserTeamEventCreateWithoutEventInput>>;
  createMany?: InputMaybe<UserTeamEventCreateManyEventInputEnvelope>;
};

export type UserTeamEventCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamEventCreateOrConnectWithoutTeamInput>
  >;
  create?: InputMaybe<Array<UserTeamEventCreateWithoutTeamInput>>;
  createMany?: InputMaybe<UserTeamEventCreateManyTeamInputEnvelope>;
};

export type UserTeamEventCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamEventCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserTeamEventCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserTeamEventCreateManyUserInputEnvelope>;
};

export type UserTeamEventCreateOrConnectWithoutEventInput = {
  create: UserTeamEventCreateWithoutEventInput;
  where: UserTeamEventWhereUniqueInput;
};

export type UserTeamEventCreateOrConnectWithoutTeamInput = {
  create: UserTeamEventCreateWithoutTeamInput;
  where: UserTeamEventWhereUniqueInput;
};

export type UserTeamEventCreateOrConnectWithoutUserInput = {
  create: UserTeamEventCreateWithoutUserInput;
  where: UserTeamEventWhereUniqueInput;
};

export type UserTeamEventCreateWithoutEventInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  team: TeamCreateNestedOneWithoutUserTeamEventsInput;
  user: UserCreateNestedOneWithoutUserTeamEventsInput;
};

export type UserTeamEventCreateWithoutTeamInput = {
  event: EventCreateNestedOneWithoutUserTeamEventsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  user: UserCreateNestedOneWithoutUserTeamEventsInput;
};

export type UserTeamEventCreateWithoutUserInput = {
  event: EventCreateNestedOneWithoutUserTeamEventsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  team: TeamCreateNestedOneWithoutUserTeamEventsInput;
};

export type UserTeamEventGroupBy = {
  __typename?: 'UserTeamEventGroupBy';
  _count?: Maybe<UserTeamEventCountAggregate>;
  _max?: Maybe<UserTeamEventMaxAggregate>;
  _min?: Maybe<UserTeamEventMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Status;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type UserTeamEventListRelationFilter = {
  every?: InputMaybe<UserTeamEventWhereInput>;
  none?: InputMaybe<UserTeamEventWhereInput>;
  some?: InputMaybe<UserTeamEventWhereInput>;
};

export type UserTeamEventMaxAggregate = {
  __typename?: 'UserTeamEventMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserTeamEventMaxOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamEventMinAggregate = {
  __typename?: 'UserTeamEventMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserTeamEventMinOrderByAggregateInput = {
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamEventOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserTeamEventOrderByWithAggregationInput = {
  _count?: InputMaybe<UserTeamEventCountOrderByAggregateInput>;
  _max?: InputMaybe<UserTeamEventMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserTeamEventMinOrderByAggregateInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamEventOrderByWithRelationInput = {
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  team?: InputMaybe<TeamOrderByWithRelationInput>;
  teamId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserTeamEventScalarFieldEnum {
  CreatedAt = 'createdAt',
  EventId = 'eventId',
  Id = 'id',
  Status = 'status',
  TeamId = 'teamId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserTeamEventScalarWhereInput = {
  AND?: InputMaybe<Array<UserTeamEventScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserTeamEventScalarWhereInput>>;
  OR?: InputMaybe<Array<UserTeamEventScalarWhereInput>>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  teamId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTeamEventScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserTeamEventScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserTeamEventScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserTeamEventScalarWhereWithAggregatesInput>>;
  eventId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
  teamId?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserTeamEventUpdateInput = {
  event?: InputMaybe<EventUpdateOneRequiredWithoutUserTeamEventsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutUserTeamEventsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserTeamEventsNestedInput>;
};

export type UserTeamEventUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
};

export type UserTeamEventUpdateManyWithWhereWithoutEventInput = {
  data: UserTeamEventUpdateManyMutationInput;
  where: UserTeamEventScalarWhereInput;
};

export type UserTeamEventUpdateManyWithWhereWithoutTeamInput = {
  data: UserTeamEventUpdateManyMutationInput;
  where: UserTeamEventScalarWhereInput;
};

export type UserTeamEventUpdateManyWithWhereWithoutUserInput = {
  data: UserTeamEventUpdateManyMutationInput;
  where: UserTeamEventScalarWhereInput;
};

export type UserTeamEventUpdateManyWithoutEventNestedInput = {
  connect?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamEventCreateOrConnectWithoutEventInput>
  >;
  create?: InputMaybe<Array<UserTeamEventCreateWithoutEventInput>>;
  createMany?: InputMaybe<UserTeamEventCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserTeamEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  set?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserTeamEventUpdateWithWhereUniqueWithoutEventInput>
  >;
  updateMany?: InputMaybe<
    Array<UserTeamEventUpdateManyWithWhereWithoutEventInput>
  >;
  upsert?: InputMaybe<
    Array<UserTeamEventUpsertWithWhereUniqueWithoutEventInput>
  >;
};

export type UserTeamEventUpdateManyWithoutTeamNestedInput = {
  connect?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamEventCreateOrConnectWithoutTeamInput>
  >;
  create?: InputMaybe<Array<UserTeamEventCreateWithoutTeamInput>>;
  createMany?: InputMaybe<UserTeamEventCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserTeamEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  set?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserTeamEventUpdateWithWhereUniqueWithoutTeamInput>
  >;
  updateMany?: InputMaybe<
    Array<UserTeamEventUpdateManyWithWhereWithoutTeamInput>
  >;
  upsert?: InputMaybe<
    Array<UserTeamEventUpsertWithWhereUniqueWithoutTeamInput>
  >;
};

export type UserTeamEventUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamEventCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserTeamEventCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserTeamEventCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserTeamEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  set?: InputMaybe<Array<UserTeamEventWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserTeamEventUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<UserTeamEventUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<UserTeamEventUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type UserTeamEventUpdateWithWhereUniqueWithoutEventInput = {
  data: UserTeamEventUpdateWithoutEventInput;
  where: UserTeamEventWhereUniqueInput;
};

export type UserTeamEventUpdateWithWhereUniqueWithoutTeamInput = {
  data: UserTeamEventUpdateWithoutTeamInput;
  where: UserTeamEventWhereUniqueInput;
};

export type UserTeamEventUpdateWithWhereUniqueWithoutUserInput = {
  data: UserTeamEventUpdateWithoutUserInput;
  where: UserTeamEventWhereUniqueInput;
};

export type UserTeamEventUpdateWithoutEventInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutUserTeamEventsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserTeamEventsNestedInput>;
};

export type UserTeamEventUpdateWithoutTeamInput = {
  event?: InputMaybe<EventUpdateOneRequiredWithoutUserTeamEventsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserTeamEventsNestedInput>;
};

export type UserTeamEventUpdateWithoutUserInput = {
  event?: InputMaybe<EventUpdateOneRequiredWithoutUserTeamEventsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutUserTeamEventsNestedInput>;
};

export type UserTeamEventUpsertWithWhereUniqueWithoutEventInput = {
  create: UserTeamEventCreateWithoutEventInput;
  update: UserTeamEventUpdateWithoutEventInput;
  where: UserTeamEventWhereUniqueInput;
};

export type UserTeamEventUpsertWithWhereUniqueWithoutTeamInput = {
  create: UserTeamEventCreateWithoutTeamInput;
  update: UserTeamEventUpdateWithoutTeamInput;
  where: UserTeamEventWhereUniqueInput;
};

export type UserTeamEventUpsertWithWhereUniqueWithoutUserInput = {
  create: UserTeamEventCreateWithoutUserInput;
  update: UserTeamEventUpdateWithoutUserInput;
  where: UserTeamEventWhereUniqueInput;
};

export type UserTeamEventUserIdTeamIdEventIdCompoundUniqueInput = {
  eventId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserTeamEventWhereInput = {
  AND?: InputMaybe<Array<UserTeamEventWhereInput>>;
  NOT?: InputMaybe<Array<UserTeamEventWhereInput>>;
  OR?: InputMaybe<Array<UserTeamEventWhereInput>>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTeamEventWhereUniqueInput = {
  AND?: InputMaybe<Array<UserTeamEventWhereInput>>;
  NOT?: InputMaybe<Array<UserTeamEventWhereInput>>;
  OR?: InputMaybe<Array<UserTeamEventWhereInput>>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EnumStatusFilter>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  userId_teamId_eventId?: InputMaybe<UserTeamEventUserIdTeamIdEventIdCompoundUniqueInput>;
};

export type UserTeamGroupBy = {
  __typename?: 'UserTeamGroupBy';
  _count?: Maybe<UserTeamCountAggregate>;
  _max?: Maybe<UserTeamMaxAggregate>;
  _min?: Maybe<UserTeamMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  status: Status;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type UserTeamInvite = {
  __typename?: 'UserTeamInvite';
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser: User;
  createdByUserId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: InviteStatus;
  team: Team;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserTeamInviteCountAggregate = {
  __typename?: 'UserTeamInviteCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  createdByUserId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  teamId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserTeamInviteCountOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamInviteCreateInput = {
  createdByUser: UserCreateNestedOneWithoutInviterTeamsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  team: TeamCreateNestedOneWithoutInvitedUsersInput;
  user: UserCreateNestedOneWithoutInviteeTeamsInput;
};

export type UserTeamInviteCreateManyCreatedByUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserTeamInviteCreateManyCreatedByUserInputEnvelope = {
  data: Array<UserTeamInviteCreateManyCreatedByUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserTeamInviteCreateManyInput = {
  createdByUserId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserTeamInviteCreateManyTeamInput = {
  createdByUserId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  userId: Scalars['String']['input'];
};

export type UserTeamInviteCreateManyTeamInputEnvelope = {
  data: Array<UserTeamInviteCreateManyTeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserTeamInviteCreateManyUserInput = {
  createdByUserId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  teamId: Scalars['String']['input'];
};

export type UserTeamInviteCreateManyUserInputEnvelope = {
  data: Array<UserTeamInviteCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserTeamInviteCreateNestedManyWithoutCreatedByUserInput = {
  connect?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamInviteCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<UserTeamInviteCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<UserTeamInviteCreateManyCreatedByUserInputEnvelope>;
};

export type UserTeamInviteCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamInviteCreateOrConnectWithoutTeamInput>
  >;
  create?: InputMaybe<Array<UserTeamInviteCreateWithoutTeamInput>>;
  createMany?: InputMaybe<UserTeamInviteCreateManyTeamInputEnvelope>;
};

export type UserTeamInviteCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamInviteCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserTeamInviteCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserTeamInviteCreateManyUserInputEnvelope>;
};

export type UserTeamInviteCreateOrConnectWithoutCreatedByUserInput = {
  create: UserTeamInviteCreateWithoutCreatedByUserInput;
  where: UserTeamInviteWhereUniqueInput;
};

export type UserTeamInviteCreateOrConnectWithoutTeamInput = {
  create: UserTeamInviteCreateWithoutTeamInput;
  where: UserTeamInviteWhereUniqueInput;
};

export type UserTeamInviteCreateOrConnectWithoutUserInput = {
  create: UserTeamInviteCreateWithoutUserInput;
  where: UserTeamInviteWhereUniqueInput;
};

export type UserTeamInviteCreateWithoutCreatedByUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  team: TeamCreateNestedOneWithoutInvitedUsersInput;
  user: UserCreateNestedOneWithoutInviteeTeamsInput;
};

export type UserTeamInviteCreateWithoutTeamInput = {
  createdByUser: UserCreateNestedOneWithoutInviterTeamsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  user: UserCreateNestedOneWithoutInviteeTeamsInput;
};

export type UserTeamInviteCreateWithoutUserInput = {
  createdByUser: UserCreateNestedOneWithoutInviterTeamsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InviteStatus>;
  team: TeamCreateNestedOneWithoutInvitedUsersInput;
};

export type UserTeamInviteGroupBy = {
  __typename?: 'UserTeamInviteGroupBy';
  _count?: Maybe<UserTeamInviteCountAggregate>;
  _max?: Maybe<UserTeamInviteMaxAggregate>;
  _min?: Maybe<UserTeamInviteMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUserId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: InviteStatus;
  teamId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type UserTeamInviteListRelationFilter = {
  every?: InputMaybe<UserTeamInviteWhereInput>;
  none?: InputMaybe<UserTeamInviteWhereInput>;
  some?: InputMaybe<UserTeamInviteWhereInput>;
};

export type UserTeamInviteMaxAggregate = {
  __typename?: 'UserTeamInviteMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<InviteStatus>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserTeamInviteMaxOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamInviteMinAggregate = {
  __typename?: 'UserTeamInviteMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<InviteStatus>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserTeamInviteMinOrderByAggregateInput = {
  createdByUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamInviteOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserTeamInviteOrderByWithAggregationInput = {
  _count?: InputMaybe<UserTeamInviteCountOrderByAggregateInput>;
  _max?: InputMaybe<UserTeamInviteMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserTeamInviteMinOrderByAggregateInput>;
  createdByUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamInviteOrderByWithRelationInput = {
  createdByUser?: InputMaybe<UserOrderByWithRelationInput>;
  createdByUserId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  team?: InputMaybe<TeamOrderByWithRelationInput>;
  teamId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserTeamInviteScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  Id = 'id',
  Status = 'status',
  TeamId = 'teamId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserTeamInviteScalarWhereInput = {
  AND?: InputMaybe<Array<UserTeamInviteScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserTeamInviteScalarWhereInput>>;
  OR?: InputMaybe<Array<UserTeamInviteScalarWhereInput>>;
  createdByUserId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumInviteStatusFilter>;
  teamId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTeamInviteScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserTeamInviteScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserTeamInviteScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserTeamInviteScalarWhereWithAggregatesInput>>;
  createdByUserId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumInviteStatusWithAggregatesFilter>;
  teamId?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserTeamInviteTeamIdUserIdCompoundUniqueInput = {
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserTeamInviteUpdateInput = {
  createdByUser?: InputMaybe<UserUpdateOneRequiredWithoutInviterTeamsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInviteStatusFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutInvitedUsersNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutInviteeTeamsNestedInput>;
};

export type UserTeamInviteUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInviteStatusFieldUpdateOperationsInput>;
};

export type UserTeamInviteUpdateManyWithWhereWithoutCreatedByUserInput = {
  data: UserTeamInviteUpdateManyMutationInput;
  where: UserTeamInviteScalarWhereInput;
};

export type UserTeamInviteUpdateManyWithWhereWithoutTeamInput = {
  data: UserTeamInviteUpdateManyMutationInput;
  where: UserTeamInviteScalarWhereInput;
};

export type UserTeamInviteUpdateManyWithWhereWithoutUserInput = {
  data: UserTeamInviteUpdateManyMutationInput;
  where: UserTeamInviteScalarWhereInput;
};

export type UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamInviteCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<UserTeamInviteCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<UserTeamInviteCreateManyCreatedByUserInputEnvelope>;
  delete?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserTeamInviteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  set?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserTeamInviteUpdateWithWhereUniqueWithoutCreatedByUserInput>
  >;
  updateMany?: InputMaybe<
    Array<UserTeamInviteUpdateManyWithWhereWithoutCreatedByUserInput>
  >;
  upsert?: InputMaybe<
    Array<UserTeamInviteUpsertWithWhereUniqueWithoutCreatedByUserInput>
  >;
};

export type UserTeamInviteUpdateManyWithoutTeamNestedInput = {
  connect?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamInviteCreateOrConnectWithoutTeamInput>
  >;
  create?: InputMaybe<Array<UserTeamInviteCreateWithoutTeamInput>>;
  createMany?: InputMaybe<UserTeamInviteCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserTeamInviteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  set?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserTeamInviteUpdateWithWhereUniqueWithoutTeamInput>
  >;
  updateMany?: InputMaybe<
    Array<UserTeamInviteUpdateManyWithWhereWithoutTeamInput>
  >;
  upsert?: InputMaybe<
    Array<UserTeamInviteUpsertWithWhereUniqueWithoutTeamInput>
  >;
};

export type UserTeamInviteUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTeamInviteCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserTeamInviteCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserTeamInviteCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserTeamInviteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  set?: InputMaybe<Array<UserTeamInviteWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserTeamInviteUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<UserTeamInviteUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<UserTeamInviteUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type UserTeamInviteUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  data: UserTeamInviteUpdateWithoutCreatedByUserInput;
  where: UserTeamInviteWhereUniqueInput;
};

export type UserTeamInviteUpdateWithWhereUniqueWithoutTeamInput = {
  data: UserTeamInviteUpdateWithoutTeamInput;
  where: UserTeamInviteWhereUniqueInput;
};

export type UserTeamInviteUpdateWithWhereUniqueWithoutUserInput = {
  data: UserTeamInviteUpdateWithoutUserInput;
  where: UserTeamInviteWhereUniqueInput;
};

export type UserTeamInviteUpdateWithoutCreatedByUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInviteStatusFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutInvitedUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutInviteeTeamsNestedInput>;
};

export type UserTeamInviteUpdateWithoutTeamInput = {
  createdByUser?: InputMaybe<UserUpdateOneRequiredWithoutInviterTeamsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInviteStatusFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutInviteeTeamsNestedInput>;
};

export type UserTeamInviteUpdateWithoutUserInput = {
  createdByUser?: InputMaybe<UserUpdateOneRequiredWithoutInviterTeamsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInviteStatusFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutInvitedUsersNestedInput>;
};

export type UserTeamInviteUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  create: UserTeamInviteCreateWithoutCreatedByUserInput;
  update: UserTeamInviteUpdateWithoutCreatedByUserInput;
  where: UserTeamInviteWhereUniqueInput;
};

export type UserTeamInviteUpsertWithWhereUniqueWithoutTeamInput = {
  create: UserTeamInviteCreateWithoutTeamInput;
  update: UserTeamInviteUpdateWithoutTeamInput;
  where: UserTeamInviteWhereUniqueInput;
};

export type UserTeamInviteUpsertWithWhereUniqueWithoutUserInput = {
  create: UserTeamInviteCreateWithoutUserInput;
  update: UserTeamInviteUpdateWithoutUserInput;
  where: UserTeamInviteWhereUniqueInput;
};

export type UserTeamInviteWhereInput = {
  AND?: InputMaybe<Array<UserTeamInviteWhereInput>>;
  NOT?: InputMaybe<Array<UserTeamInviteWhereInput>>;
  OR?: InputMaybe<Array<UserTeamInviteWhereInput>>;
  createdByUser?: InputMaybe<UserRelationFilter>;
  createdByUserId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumInviteStatusFilter>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTeamInviteWhereUniqueInput = {
  AND?: InputMaybe<Array<UserTeamInviteWhereInput>>;
  NOT?: InputMaybe<Array<UserTeamInviteWhereInput>>;
  OR?: InputMaybe<Array<UserTeamInviteWhereInput>>;
  createdByUser?: InputMaybe<UserRelationFilter>;
  createdByUserId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EnumInviteStatusFilter>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<StringFilter>;
  teamId_userId?: InputMaybe<UserTeamInviteTeamIdUserIdCompoundUniqueInput>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTeamListRelationFilter = {
  every?: InputMaybe<UserTeamWhereInput>;
  none?: InputMaybe<UserTeamWhereInput>;
  some?: InputMaybe<UserTeamWhereInput>;
};

export type UserTeamMaxAggregate = {
  __typename?: 'UserTeamMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserTeamMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamMinAggregate = {
  __typename?: 'UserTeamMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserTeamMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserTeamOrderByWithAggregationInput = {
  _count?: InputMaybe<UserTeamCountOrderByAggregateInput>;
  _max?: InputMaybe<UserTeamMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserTeamMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTeamOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  team?: InputMaybe<TeamOrderByWithRelationInput>;
  teamId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserTeamScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Status = 'status',
  TeamId = 'teamId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserTeamScalarWhereInput = {
  AND?: InputMaybe<Array<UserTeamScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserTeamScalarWhereInput>>;
  OR?: InputMaybe<Array<UserTeamScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  teamId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTeamScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserTeamScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserTeamScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserTeamScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
  teamId?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserTeamUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutTeamUsersNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserTeamsNestedInput>;
};

export type UserTeamUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
};

export type UserTeamUpdateManyWithWhereWithoutTeamInput = {
  data: UserTeamUpdateManyMutationInput;
  where: UserTeamScalarWhereInput;
};

export type UserTeamUpdateManyWithWhereWithoutUserInput = {
  data: UserTeamUpdateManyMutationInput;
  where: UserTeamScalarWhereInput;
};

export type UserTeamUpdateManyWithoutTeamNestedInput = {
  connect?: InputMaybe<Array<UserTeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserTeamCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<UserTeamCreateWithoutTeamInput>>;
  createMany?: InputMaybe<UserTeamCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<UserTeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserTeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserTeamWhereUniqueInput>>;
  set?: InputMaybe<Array<UserTeamWhereUniqueInput>>;
  update?: InputMaybe<Array<UserTeamUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<UserTeamUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<UserTeamUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type UserTeamUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserTeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserTeamCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserTeamCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserTeamCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserTeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserTeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserTeamWhereUniqueInput>>;
  set?: InputMaybe<Array<UserTeamWhereUniqueInput>>;
  update?: InputMaybe<Array<UserTeamUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserTeamUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserTeamUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserTeamUpdateWithWhereUniqueWithoutTeamInput = {
  data: UserTeamUpdateWithoutTeamInput;
  where: UserTeamWhereUniqueInput;
};

export type UserTeamUpdateWithWhereUniqueWithoutUserInput = {
  data: UserTeamUpdateWithoutUserInput;
  where: UserTeamWhereUniqueInput;
};

export type UserTeamUpdateWithoutTeamInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserTeamsNestedInput>;
};

export type UserTeamUpdateWithoutUserInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutTeamUsersNestedInput>;
};

export type UserTeamUpsertWithWhereUniqueWithoutTeamInput = {
  create: UserTeamCreateWithoutTeamInput;
  update: UserTeamUpdateWithoutTeamInput;
  where: UserTeamWhereUniqueInput;
};

export type UserTeamUpsertWithWhereUniqueWithoutUserInput = {
  create: UserTeamCreateWithoutUserInput;
  update: UserTeamUpdateWithoutUserInput;
  where: UserTeamWhereUniqueInput;
};

export type UserTeamUserIdTeamIdCompoundUniqueInput = {
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserTeamWhereInput = {
  AND?: InputMaybe<Array<UserTeamWhereInput>>;
  NOT?: InputMaybe<Array<UserTeamWhereInput>>;
  OR?: InputMaybe<Array<UserTeamWhereInput>>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTeamWhereUniqueInput = {
  AND?: InputMaybe<Array<UserTeamWhereInput>>;
  NOT?: InputMaybe<Array<UserTeamWhereInput>>;
  OR?: InputMaybe<Array<UserTeamWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EnumStatusFilter>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  userId_teamId?: InputMaybe<UserTeamUserIdTeamIdCompoundUniqueInput>;
};

export type UserUpdateInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateManyMutationInput = {
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutInviteeEventsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInviteeEventsInput>;
  create?: InputMaybe<UserCreateWithoutInviteeEventsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutInviteeEventsInput>;
  upsert?: InputMaybe<UserUpsertWithoutInviteeEventsInput>;
};

export type UserUpdateOneRequiredWithoutInviteeTeamsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInviteeTeamsInput>;
  create?: InputMaybe<UserCreateWithoutInviteeTeamsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutInviteeTeamsInput>;
  upsert?: InputMaybe<UserUpsertWithoutInviteeTeamsInput>;
};

export type UserUpdateOneRequiredWithoutInviterEventsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInviterEventsInput>;
  create?: InputMaybe<UserCreateWithoutInviterEventsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutInviterEventsInput>;
  upsert?: InputMaybe<UserUpsertWithoutInviterEventsInput>;
};

export type UserUpdateOneRequiredWithoutInviterTeamsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInviterTeamsInput>;
  create?: InputMaybe<UserCreateWithoutInviterTeamsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutInviterTeamsInput>;
  upsert?: InputMaybe<UserUpsertWithoutInviterTeamsInput>;
};

export type UserUpdateOneRequiredWithoutUserEventsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserEventsInput>;
  create?: InputMaybe<UserCreateWithoutUserEventsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutUserEventsInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserEventsInput>;
};

export type UserUpdateOneRequiredWithoutUserNotificationPreferencesNestedInput =
  {
    connect?: InputMaybe<UserWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserNotificationPreferencesInput>;
    create?: InputMaybe<UserCreateWithoutUserNotificationPreferencesInput>;
    update?: InputMaybe<UserUpdateToOneWithWhereWithoutUserNotificationPreferencesInput>;
    upsert?: InputMaybe<UserUpsertWithoutUserNotificationPreferencesInput>;
  };

export type UserUpdateOneRequiredWithoutUserRatingNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserRatingInput>;
  create?: InputMaybe<UserCreateWithoutUserRatingInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutUserRatingInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserRatingInput>;
};

export type UserUpdateOneRequiredWithoutUserTeamEventsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserTeamEventsInput>;
  create?: InputMaybe<UserCreateWithoutUserTeamEventsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutUserTeamEventsInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserTeamEventsInput>;
};

export type UserUpdateOneRequiredWithoutUserTeamsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserTeamsInput>;
  create?: InputMaybe<UserCreateWithoutUserTeamsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutUserTeamsInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserTeamsInput>;
};

export type UserUpdateOneWithoutCourtNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCourtInput>;
  create?: InputMaybe<UserCreateWithoutCourtInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutCourtInput>;
  upsert?: InputMaybe<UserUpsertWithoutCourtInput>;
};

export type UserUpdateOneWithoutCreatedEventsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedEventsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedEventsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutCreatedEventsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreatedEventsInput>;
};

export type UserUpdateOneWithoutLocationNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLocationInput>;
  create?: InputMaybe<UserCreateWithoutLocationInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutLocationInput>;
  upsert?: InputMaybe<UserUpsertWithoutLocationInput>;
};

export type UserUpdateOneWithoutTeamNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTeamInput>;
  create?: InputMaybe<UserCreateWithoutTeamInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutTeamInput>;
  upsert?: InputMaybe<UserUpsertWithoutTeamInput>;
};

export type UserUpdateToOneWithWhereWithoutCourtInput = {
  data: UserUpdateWithoutCourtInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutCreatedEventsInput = {
  data: UserUpdateWithoutCreatedEventsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutInviteeEventsInput = {
  data: UserUpdateWithoutInviteeEventsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutInviteeTeamsInput = {
  data: UserUpdateWithoutInviteeTeamsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutInviterEventsInput = {
  data: UserUpdateWithoutInviterEventsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutInviterTeamsInput = {
  data: UserUpdateWithoutInviterTeamsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutLocationInput = {
  data: UserUpdateWithoutLocationInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutTeamInput = {
  data: UserUpdateWithoutTeamInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutUserEventsInput = {
  data: UserUpdateWithoutUserEventsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutUserNotificationPreferencesInput = {
  data: UserUpdateWithoutUserNotificationPreferencesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutUserRatingInput = {
  data: UserUpdateWithoutUserRatingInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutUserTeamEventsInput = {
  data: UserUpdateWithoutUserTeamEventsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutUserTeamsInput = {
  data: UserUpdateWithoutUserTeamsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateWithoutCourtInput = {
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutCreatedEventsInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutInviteeEventsInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutInviteeTeamsInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutInviterEventsInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutInviterTeamsInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutLocationInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutTeamInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserEventsInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserNotificationPreferencesInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserRatingInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserTeamEventsInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeams?: InputMaybe<UserTeamUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserTeamsInput = {
  Court?: InputMaybe<CourtUpdateManyWithoutCreatedByUserNestedInput>;
  Location?: InputMaybe<LocationUpdateManyWithoutCreatedByUserNestedInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutCreatedByUserNestedInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatedByUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favoriteSports?: InputMaybe<UserUpdatefavoriteSportsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteeEvents?: InputMaybe<UserEventInviteUpdateManyWithoutUserNestedInput>;
  inviteeTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutUserNestedInput>;
  inviterEvents?: InputMaybe<UserEventInviteUpdateManyWithoutCreatedByUserNestedInput>;
  inviterTeams?: InputMaybe<UserTeamInviteUpdateManyWithoutCreatedByUserNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPseudo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastViewedFeed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  optIn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sport?: InputMaybe<NullableEnumSportFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEvents?: InputMaybe<UserEventUpdateManyWithoutUserNestedInput>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesUpdateManyWithoutUserNestedInput>;
  userRating?: InputMaybe<UserRatingUpdateManyWithoutUserNestedInput>;
  userTeamEvents?: InputMaybe<UserTeamEventUpdateManyWithoutUserNestedInput>;
};

export type UserUpdatefavoriteSportsInput = {
  push?: InputMaybe<Array<Sport>>;
  set?: InputMaybe<Array<Sport>>;
};

export type UserUpsertWithoutCourtInput = {
  create: UserCreateWithoutCourtInput;
  update: UserUpdateWithoutCourtInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutCreatedEventsInput = {
  create: UserCreateWithoutCreatedEventsInput;
  update: UserUpdateWithoutCreatedEventsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutInviteeEventsInput = {
  create: UserCreateWithoutInviteeEventsInput;
  update: UserUpdateWithoutInviteeEventsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutInviteeTeamsInput = {
  create: UserCreateWithoutInviteeTeamsInput;
  update: UserUpdateWithoutInviteeTeamsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutInviterEventsInput = {
  create: UserCreateWithoutInviterEventsInput;
  update: UserUpdateWithoutInviterEventsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutInviterTeamsInput = {
  create: UserCreateWithoutInviterTeamsInput;
  update: UserUpdateWithoutInviterTeamsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutLocationInput = {
  create: UserCreateWithoutLocationInput;
  update: UserUpdateWithoutLocationInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutTeamInput = {
  create: UserCreateWithoutTeamInput;
  update: UserUpdateWithoutTeamInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutUserEventsInput = {
  create: UserCreateWithoutUserEventsInput;
  update: UserUpdateWithoutUserEventsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutUserNotificationPreferencesInput = {
  create: UserCreateWithoutUserNotificationPreferencesInput;
  update: UserUpdateWithoutUserNotificationPreferencesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutUserRatingInput = {
  create: UserCreateWithoutUserRatingInput;
  update: UserUpdateWithoutUserRatingInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutUserTeamEventsInput = {
  create: UserCreateWithoutUserTeamEventsInput;
  update: UserUpdateWithoutUserTeamEventsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutUserTeamsInput = {
  create: UserCreateWithoutUserTeamsInput;
  update: UserUpdateWithoutUserTeamsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  Court?: InputMaybe<CourtListRelationFilter>;
  Location?: InputMaybe<LocationListRelationFilter>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  Team?: InputMaybe<TeamListRelationFilter>;
  createdEvents?: InputMaybe<EventListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  favoriteSports?: InputMaybe<EnumSportNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  inviteeEvents?: InputMaybe<UserEventInviteListRelationFilter>;
  inviteeTeams?: InputMaybe<UserTeamInviteListRelationFilter>;
  inviterEvents?: InputMaybe<UserEventInviteListRelationFilter>;
  inviterTeams?: InputMaybe<UserTeamInviteListRelationFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isPseudo?: InputMaybe<BoolFilter>;
  lastViewedFeed?: InputMaybe<DateTimeNullableFilter>;
  name?: InputMaybe<StringFilter>;
  optIn?: InputMaybe<BoolFilter>;
  sport?: InputMaybe<EnumSportNullableFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  userEvents?: InputMaybe<UserEventListRelationFilter>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesListRelationFilter>;
  userRating?: InputMaybe<UserRatingListRelationFilter>;
  userTeamEvents?: InputMaybe<UserTeamEventListRelationFilter>;
  userTeams?: InputMaybe<UserTeamListRelationFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  Court?: InputMaybe<CourtListRelationFilter>;
  Location?: InputMaybe<LocationListRelationFilter>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  Team?: InputMaybe<TeamListRelationFilter>;
  createdEvents?: InputMaybe<EventListRelationFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  favoriteSports?: InputMaybe<EnumSportNullableListFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  inviteeEvents?: InputMaybe<UserEventInviteListRelationFilter>;
  inviteeTeams?: InputMaybe<UserTeamInviteListRelationFilter>;
  inviterEvents?: InputMaybe<UserEventInviteListRelationFilter>;
  inviterTeams?: InputMaybe<UserTeamInviteListRelationFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isPseudo?: InputMaybe<BoolFilter>;
  lastViewedFeed?: InputMaybe<DateTimeNullableFilter>;
  name?: InputMaybe<StringFilter>;
  optIn?: InputMaybe<BoolFilter>;
  sport?: InputMaybe<EnumSportNullableFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  userEvents?: InputMaybe<UserEventListRelationFilter>;
  userNotificationPreferences?: InputMaybe<UserNotificationPreferencesListRelationFilter>;
  userRating?: InputMaybe<UserRatingListRelationFilter>;
  userTeamEvents?: InputMaybe<UserTeamEventListRelationFilter>;
  userTeams?: InputMaybe<UserTeamListRelationFilter>;
};

export type DeleteCourtMutationVariables = Exact<{
  where: CourtWhereUniqueInput;
}>;

export type DeleteCourtMutation = {
  __typename?: 'Mutation';
  deleteOneCourt?: { __typename?: 'Court'; id: string } | null;
};

export type CreateCourtMutationVariables = Exact<{
  data: CourtCreateInput;
}>;

export type CreateCourtMutation = {
  __typename?: 'Mutation';
  createOneCourt: { __typename?: 'Court'; id: string };
};

export type UpdateCourtMutationVariables = Exact<{
  data: CourtUpdateInput;
  where: CourtWhereUniqueInput;
}>;

export type UpdateCourtMutation = {
  __typename?: 'Mutation';
  updateOneCourt?: { __typename?: 'Court'; id: string } | null;
};

export type GetCourtsQueryVariables = Exact<{
  where?: InputMaybe<CourtWhereInput>;
}>;

export type GetCourtsQuery = {
  __typename?: 'Query';
  courts: Array<{
    __typename?: 'Court';
    id: string;
    name: string;
    sport: Sport;
    locationId: string;
  }>;
};

export type CreateUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createOneUser: {
    __typename?: 'User';
    name: string;
    id: string;
    email: string;
  };
};

export type CreateOneEventMutationVariables = Exact<{
  data: EventCreateInput;
}>;

export type CreateOneEventMutation = {
  __typename?: 'Mutation';
  createOneEvent: { __typename?: 'Event'; id: string; name: string };
};

export type UpdateOneEventMutationVariables = Exact<{
  data: EventUpdateInput;
  where: EventWhereUniqueInput;
}>;

export type UpdateOneEventMutation = {
  __typename?: 'Mutation';
  updateOneEvent?: { __typename?: 'Event'; id: string; name: string } | null;
};

export type DeleteEventMutationVariables = Exact<{
  where: EventWhereUniqueInput;
}>;

export type DeleteEventMutation = {
  __typename?: 'Mutation';
  deleteOneEvent?: { __typename?: 'Event'; id: string } | null;
};

export type UpdateScoreMutationVariables = Exact<{
  data: ScoreUpdateInput;
  where: ScoreWhereUniqueInput;
}>;

export type UpdateScoreMutation = {
  __typename?: 'Mutation';
  updateOneScore?: { __typename?: 'Score'; id: string; value: number } | null;
};

export type UpdateRoundMutationVariables = Exact<{
  data: RoundUpdateInput;
  where: RoundWhereUniqueInput;
}>;

export type UpdateRoundMutation = {
  __typename?: 'Mutation';
  updateOneRound?: {
    __typename?: 'Round';
    id: string;
    winningTeamId?: string | null;
  } | null;
};

export type UpdateMatchMutationVariables = Exact<{
  data: MatchUpdateInput;
  where: MatchWhereUniqueInput;
}>;

export type UpdateMatchMutation = {
  __typename?: 'Mutation';
  updateOneMatch?: {
    __typename?: 'Match';
    id: string;
    winningTeamId?: string | null;
    nextMatchId?: string | null;
  } | null;
};

export type DeleteMatchMutationVariables = Exact<{
  where: MatchWhereUniqueInput;
}>;

export type DeleteMatchMutation = {
  __typename?: 'Mutation';
  deleteOneMatch?: {
    __typename?: 'Match';
    _count?: { __typename?: 'MatchCount'; rounds: number } | null;
  } | null;
};

export type CreateManyUserEventInviteMutationVariables = Exact<{
  data: Array<UserEventInviteCreateManyInput> | UserEventInviteCreateManyInput;
}>;

export type CreateManyUserEventInviteMutation = {
  __typename?: 'Mutation';
  createManyUserEventInvite: {
    __typename?: 'AffectedRowsOutput';
    count: number;
  };
};

export type CreateUserEventInviteMutationVariables = Exact<{
  data: UserEventInviteCreateInput;
}>;

export type CreateUserEventInviteMutation = {
  __typename?: 'Mutation';
  createOneUserEventInvite: { __typename?: 'UserEventInvite'; id: string };
};

export type GetEventInvitesQueryVariables = Exact<{
  where?: InputMaybe<UserEventInviteWhereInput>;
}>;

export type GetEventInvitesQuery = {
  __typename?: 'Query';
  userEventInvites: Array<{
    __typename?: 'UserEventInvite';
    id: string;
    status: InviteStatus;
    createdByUser: { __typename?: 'User'; id: string; name: string };
    event: {
      __typename?: 'Event';
      id: string;
      name: string;
      url?: string | null;
      description: string;
      startDate: any;
      endDate: any;
      type: EventType;
      gender: Gender;
      privacy?: EventPrivacy | null;
      createdByUserId?: string | null;
      maxUsersGoing?: number | null;
      _count?: { __typename?: 'EventCount'; invitedUsers: number } | null;
    };
  }>;
};

export type GetTournamentQueryVariables = Exact<{
  where: EventWhereUniqueInput;
  matchesWhere?: InputMaybe<MatchWhereInput>;
  stagesWhere?: InputMaybe<StageWhereInput>;
  eventUsersWhere?: InputMaybe<UserEventWhereInput>;
  orderMatchesBy?: InputMaybe<
    Array<MatchOrderByWithRelationInput> | MatchOrderByWithRelationInput
  >;
  orderTeamEventsBy?: InputMaybe<
    Array<TeamEventOrderByWithRelationInput> | TeamEventOrderByWithRelationInput
  >;
  orderStagesBy?: InputMaybe<
    Array<StageOrderByWithRelationInput> | StageOrderByWithRelationInput
  >;
  orderTeamUsersBy?: InputMaybe<
    | Array<UserEventInviteOrderByWithRelationInput>
    | UserEventInviteOrderByWithRelationInput
  >;
}>;

export type GetTournamentQuery = {
  __typename?: 'Query';
  event?: {
    __typename?: 'Event';
    id: string;
    sport: Sport;
    name: string;
    startDate: any;
    endDate: any;
    description: string;
    privacy?: EventPrivacy | null;
    type: EventType;
    gender: Gender;
    teamSize?: number | null;
    url?: string | null;
    createdByUserId?: string | null;
    maxUsersGoing?: number | null;
    stages: Array<{
      __typename?: 'Stage';
      id: string;
      name?: string | null;
      type: StageType;
      _count?: { __typename?: 'StageCount'; matches: number } | null;
      matches: Array<{
        __typename?: 'Match';
        id: string;
        order: number;
        playoffRound?: number | null;
        startDate?: any | null;
        winningTeamId?: string | null;
        court?: { __typename?: 'Court'; id: string; name: string } | null;
        teams: Array<{ __typename?: 'Team'; id: string; name: string }>;
        rounds: Array<{
          __typename?: 'Round';
          id: string;
          order: number;
          winningTeamId?: string | null;
          scores: Array<{
            __typename?: 'Score';
            id: string;
            value: number;
            team: { __typename?: 'Team'; id: string; name: string };
          }>;
        }>;
      }>;
    }>;
    location?: {
      __typename?: 'Location';
      name: string;
      court: Array<{ __typename?: 'Court'; id: string; name: string }>;
    } | null;
    org?: { __typename?: 'Org'; name: string; url: string } | null;
    teamEvents: Array<{
      __typename?: 'TeamEvent';
      id: string;
      seed?: number | null;
      team: { __typename?: 'Team'; id: string; name: string };
    }>;
    invitedUsers: Array<{
      __typename?: 'UserEventInvite';
      id: string;
      status: InviteStatus;
      user: {
        __typename?: 'User';
        id: string;
        name: string;
        isPseudo: boolean;
        email: string;
      };
    }>;
    eventUsers: Array<{
      __typename?: 'UserEvent';
      id: string;
      status: Status;
      user: {
        __typename?: 'User';
        id: string;
        name: string;
        isPseudo: boolean;
        email: string;
      };
    }>;
    userTeamEvents: Array<{
      __typename?: 'UserTeamEvent';
      id: string;
      team: { __typename?: 'Team'; id: string; name: string };
      user: { __typename?: 'User'; id: string; name: string };
    }>;
  } | null;
};

export type GetPickupEventQueryVariables = Exact<{
  where: EventWhereUniqueInput;
  matchesWhere?: InputMaybe<MatchWhereInput>;
  teamsOrderBy?: InputMaybe<
    Array<TeamOrderByWithRelationInput> | TeamOrderByWithRelationInput
  >;
  teamUsersOrderBy?: InputMaybe<
    Array<UserTeamOrderByWithRelationInput> | UserTeamOrderByWithRelationInput
  >;
}>;

export type GetPickupEventQuery = {
  __typename?: 'Query';
  event?: {
    __typename?: 'Event';
    sport: Sport;
    teamSize?: number | null;
    matches: Array<{
      __typename?: 'Match';
      id: string;
      rounds: Array<{
        __typename?: 'Round';
        id: string;
        scores: Array<{ __typename?: 'Score'; teamId: string; value: number }>;
      }>;
      teams: Array<{
        __typename?: 'Team';
        id: string;
        teamUsers: Array<{
          __typename?: 'UserTeam';
          user: { __typename?: 'User'; id: string; name: string };
        }>;
      }>;
    }>;
  } | null;
};

export type GetEventCardQueryVariables = Exact<{
  where: EventWhereUniqueInput;
}>;

export type GetEventCardQuery = {
  __typename?: 'Query';
  event?: {
    __typename?: 'Event';
    id: string;
    sport: Sport;
    name: string;
    startDate: any;
    endDate: any;
    description: string;
    privacy?: EventPrivacy | null;
    type: EventType;
    gender: Gender;
    teamSize?: number | null;
    url?: string | null;
    createdByUserId?: string | null;
    maxUsersGoing?: number | null;
    org?: { __typename?: 'Org'; name: string; url: string } | null;
  } | null;
};

export type GetEventInvitedUsersQueryVariables = Exact<{
  where: EventWhereUniqueInput;
  orderTeamUsersBy?: InputMaybe<
    | Array<UserEventInviteOrderByWithRelationInput>
    | UserEventInviteOrderByWithRelationInput
  >;
}>;

export type GetEventInvitedUsersQuery = {
  __typename?: 'Query';
  event?: {
    __typename?: 'Event';
    id: string;
    name: string;
    startDate: any;
    invitedUsers: Array<{
      __typename?: 'UserEventInvite';
      id: string;
      status: InviteStatus;
      user: {
        __typename?: 'User';
        id: string;
        name: string;
        isPseudo: boolean;
        email: string;
      };
    }>;
  } | null;
};

export type GetEditEventDetailsQueryVariables = Exact<{
  where: EventWhereUniqueInput;
}>;

export type GetEditEventDetailsQuery = {
  __typename?: 'Query';
  event?: {
    __typename?: 'Event';
    id: string;
    sport: Sport;
    name: string;
    startDate: any;
    endDate: any;
    description: string;
    privacy?: EventPrivacy | null;
    type: EventType;
    gender: Gender;
    teamSize?: number | null;
    url?: string | null;
    minTeams?: number | null;
    maxTeams?: number | null;
    orgId?: string | null;
    locationId?: string | null;
    maxUsersGoing?: number | null;
    createdByUserId?: string | null;
    cost?: number | null;
  } | null;
};

export type GetMatchDetailsQueryVariables = Exact<{
  where: MatchWhereUniqueInput;
  roundsOrderBy?: InputMaybe<
    Array<RoundOrderByWithRelationInput> | RoundOrderByWithRelationInput
  >;
  scoresOrderBy?: InputMaybe<
    Array<ScoreOrderByWithRelationInput> | ScoreOrderByWithRelationInput
  >;
}>;

export type GetMatchDetailsQuery = {
  __typename?: 'Query';
  match?: {
    __typename?: 'Match';
    id: string;
    order: number;
    startDate?: any | null;
    winningTeamId?: string | null;
    stage?: {
      __typename?: 'Stage';
      event: {
        __typename?: 'Event';
        id: string;
        name: string;
        createdByUserId?: string | null;
      };
    } | null;
    teams: Array<{ __typename?: 'Team'; id: string; name: string }>;
    court?: { __typename?: 'Court'; id: string; name: string } | null;
    rounds: Array<{
      __typename?: 'Round';
      id: string;
      order: number;
      winningTeamId?: string | null;
      scores: Array<{
        __typename?: 'Score';
        id: string;
        value: number;
        team: {
          __typename?: 'Team';
          id: string;
          name: string;
          userTeamEvents: Array<{
            __typename?: 'UserTeamEvent';
            user: { __typename?: 'User'; name: string };
          }>;
        };
      }>;
    }>;
  } | null;
};

export type GetUserEventInviteQueryVariables = Exact<{
  where: UserEventInviteWhereUniqueInput;
}>;

export type GetUserEventInviteQuery = {
  __typename?: 'Query';
  userEventInvite?: {
    __typename?: 'UserEventInvite';
    id: string;
    status: InviteStatus;
  } | null;
};

export type GetUserEventInvitesQueryVariables = Exact<{
  where?: InputMaybe<UserEventInviteWhereInput>;
}>;

export type GetUserEventInvitesQuery = {
  __typename?: 'Query';
  userEventInvites: Array<{
    __typename?: 'UserEventInvite';
    id: string;
    status: InviteStatus;
    user: { __typename?: 'User'; name: string };
  }>;
};

export type GetEventInvitesByEventQueryVariables = Exact<{
  where?: InputMaybe<EventWhereInput>;
  orderBy?: InputMaybe<
    Array<EventOrderByWithRelationInput> | EventOrderByWithRelationInput
  >;
}>;

export type GetEventInvitesByEventQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    name: string;
    url?: string | null;
    description: string;
    startDate: any;
    endDate: any;
    type: EventType;
    gender: Gender;
    createdByUserId?: string | null;
    privacy?: EventPrivacy | null;
    maxUsersGoing?: number | null;
  }>;
};

export type GetEventsForHomeFeedQueryVariables = Exact<{
  where?: InputMaybe<EventWhereInput>;
  orderBy?: InputMaybe<
    Array<EventOrderByWithRelationInput> | EventOrderByWithRelationInput
  >;
}>;

export type GetEventsForHomeFeedQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    name: string;
    url?: string | null;
    description: string;
    startDate: any;
    endDate: any;
    type: EventType;
    gender: Gender;
    createdByUserId?: string | null;
    privacy?: EventPrivacy | null;
    maxUsersGoing?: number | null;
    createdAt: any;
    createdByUser?: { __typename?: 'User'; id: string; name: string } | null;
    org?: { __typename?: 'Org'; name: string; url: string } | null;
  }>;
};

export type UpdateUserEventInviteMutationVariables = Exact<{
  data: UserEventInviteUpdateInput;
  where: UserEventInviteWhereUniqueInput;
}>;

export type UpdateUserEventInviteMutation = {
  __typename?: 'Mutation';
  updateOneUserEventInvite?: {
    __typename?: 'UserEventInvite';
    id: string;
  } | null;
};

export type DeleteOneUserEventInviteMutationVariables = Exact<{
  where: UserEventInviteWhereUniqueInput;
}>;

export type DeleteOneUserEventInviteMutation = {
  __typename?: 'Mutation';
  deleteOneUserEventInvite?: {
    __typename?: 'UserEventInvite';
    id: string;
  } | null;
};

export type CreateRoundMutationVariables = Exact<{
  data: RoundCreateInput;
}>;

export type CreateRoundMutation = {
  __typename?: 'Mutation';
  createOneRound: { __typename?: 'Round'; id: string };
};

export type CreateMatchMutationVariables = Exact<{
  data: MatchCreateInput;
}>;

export type CreateMatchMutation = {
  __typename?: 'Mutation';
  createOneMatch: { __typename?: 'Match'; id: string };
};

export type DeleteManyTeamsMutationVariables = Exact<{
  where?: InputMaybe<TeamWhereInput>;
}>;

export type DeleteManyTeamsMutation = {
  __typename?: 'Mutation';
  deleteManyTeam: { __typename?: 'AffectedRowsOutput'; count: number };
};

export type CreateOneTeamEventMutationVariables = Exact<{
  data: TeamEventCreateInput;
}>;

export type CreateOneTeamEventMutation = {
  __typename?: 'Mutation';
  createOneTeamEvent: { __typename?: 'TeamEvent'; id: string };
};

export type CreateOneStageMutationVariables = Exact<{
  data: StageCreateInput;
}>;

export type CreateOneStageMutation = {
  __typename?: 'Mutation';
  createOneStage: { __typename?: 'Stage'; id: string };
};

export type CreateOnePoolMutationVariables = Exact<{
  data: PoolCreateInput;
}>;

export type CreateOnePoolMutation = {
  __typename?: 'Mutation';
  createOnePool: { __typename?: 'Pool'; id: string };
};

export type GetTeamPlacementQueryVariables = Exact<{
  teamEventsWhere?: InputMaybe<TeamEventWhereInput>;
  matchWhere?: InputMaybe<MatchWhereInput>;
  roundWhere?: InputMaybe<RoundWhereInput>;
  orderBy?: InputMaybe<
    Array<TeamEventOrderByWithRelationInput> | TeamEventOrderByWithRelationInput
  >;
}>;

export type GetTeamPlacementQuery = {
  __typename?: 'Query';
  teamEvents: Array<{
    __typename?: 'TeamEvent';
    placement?: number | null;
    seed?: number | null;
    pool?: { __typename?: 'Pool'; name: string } | null;
    team: {
      __typename?: 'Team';
      id: string;
      name: string;
      matches: Array<{
        __typename?: 'Match';
        winningTeamId?: string | null;
        rounds: Array<{ __typename?: 'Round'; winningTeamId?: string | null }>;
      }>;
    };
  }>;
};

export type GenerateSeedsMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;

export type GenerateSeedsMutation = {
  __typename?: 'Mutation';
  generateSeeds: boolean;
};

export type UpdateSeedsMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
  teams: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type UpdateSeedsMutation = {
  __typename?: 'Mutation';
  updateSeeds: boolean;
};

export type GetPlayoffRoundsQueryVariables = Exact<{
  distinct?: InputMaybe<Array<MatchScalarFieldEnum> | MatchScalarFieldEnum>;
  where?: InputMaybe<MatchWhereInput>;
  orderBy?: InputMaybe<
    Array<MatchOrderByWithRelationInput> | MatchOrderByWithRelationInput
  >;
}>;

export type GetPlayoffRoundsQuery = {
  __typename?: 'Query';
  matches: Array<{ __typename?: 'Match'; playoffRound?: number | null }>;
};

export type GetPoolsQueryVariables = Exact<{
  where?: InputMaybe<PoolWhereInput>;
  orderBy?: InputMaybe<
    Array<PoolOrderByWithRelationInput> | PoolOrderByWithRelationInput
  >;
}>;

export type GetPoolsQuery = {
  __typename?: 'Query';
  pools: Array<{ __typename?: 'Pool'; id: string; name: string }>;
};

export type CreatePlayoffMatchesMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;

export type CreatePlayoffMatchesMutation = {
  __typename?: 'Mutation';
  createPlayoffMatches: number;
};

export type GetUserEventQueryVariables = Exact<{
  where: UserEventWhereUniqueInput;
}>;

export type GetUserEventQuery = {
  __typename?: 'Query';
  getUserEvent?: {
    __typename?: 'UserEvent';
    id: string;
    status: Status;
  } | null;
};

export type GetUsersInQueueQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;

export type GetUsersInQueueQuery = {
  __typename?: 'Query';
  getUsersInQueue: Array<{
    __typename?: 'UserEvent';
    eventPosition?: number | null;
    user: { __typename?: 'User'; id: string; name: string };
  }>;
};

export type MoveUserIdsToEndofQueueMutationVariables = Exact<{
  userIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  eventId: Scalars['String']['input'];
}>;

export type MoveUserIdsToEndofQueueMutation = {
  __typename?: 'Mutation';
  moveUserIdsToEndofQueue: boolean;
};

export type MoveUserIndexesInQueueMutationVariables = Exact<{
  index1: Scalars['Float']['input'];
  index2: Scalars['Float']['input'];
  eventId: Scalars['String']['input'];
}>;

export type MoveUserIndexesInQueueMutation = {
  __typename?: 'Mutation';
  moveUserIndexesInQueue: boolean;
};

export type AggregateUserEventQueryVariables = Exact<{
  where?: InputMaybe<UserEventWhereInput>;
}>;

export type AggregateUserEventQuery = {
  __typename?: 'Query';
  aggregateUserEvent: {
    __typename?: 'AggregateUserEvent';
    _count?: { __typename?: 'UserEventCountAggregate'; _all: number } | null;
  };
};

export type GetPickupLastWinnerQueryVariables = Exact<{
  where: EventWhereUniqueInput;
  orderBy?: InputMaybe<
    Array<MatchOrderByWithRelationInput> | MatchOrderByWithRelationInput
  >;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetPickupLastWinnerQuery = {
  __typename?: 'Query';
  getEvent?: {
    __typename?: 'Event';
    matches: Array<{
      __typename?: 'Match';
      rounds: Array<{
        __typename?: 'Round';
        winningTeam?: {
          __typename?: 'Team';
          teamUsers: Array<{
            __typename?: 'UserTeam';
            userId: string;
            user: { __typename?: 'User'; name: string };
          }>;
        } | null;
      }>;
    }>;
  } | null;
};

export type EmailInviteMutationVariables = Exact<{
  emails: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  invitedBy: Scalars['String']['input'];
  invitedByID: Scalars['String']['input'];
  name: Scalars['String']['input'];
  eventStartDate: Scalars['String']['input'];
  inviteType: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type EmailInviteMutation = {
  __typename?: 'Mutation';
  emailInvite: boolean;
};

export type GetLocationsWithEventsQueryVariables = Exact<{
  locationsWhere?: InputMaybe<LocationWhereInput>;
  eventsWhere?: InputMaybe<EventWhereInput>;
  eventsOrderBy?: InputMaybe<
    Array<EventOrderByWithRelationInput> | EventOrderByWithRelationInput
  >;
}>;

export type GetLocationsWithEventsQuery = {
  __typename?: 'Query';
  locations: Array<{
    __typename?: 'Location';
    id: string;
    name: string;
    url?: string | null;
    description?: string | null;
    latitude: any;
    longitude: any;
    events: Array<{
      __typename?: 'Event';
      id: string;
      name: string;
      url?: string | null;
      description: string;
      startDate: any;
      endDate: any;
      type: EventType;
      gender: Gender;
      teamSize?: number | null;
      privacy?: EventPrivacy | null;
      createdByUserId?: string | null;
      maxUsersGoing?: number | null;
      org?: { __typename?: 'Org'; name: string; url: string } | null;
    }>;
  }>;
};

export type GetLocationsQueryVariables = Exact<{
  where?: InputMaybe<LocationWhereInput>;
  orderBy?: InputMaybe<
    Array<LocationOrderByWithRelationInput> | LocationOrderByWithRelationInput
  >;
}>;

export type GetLocationsQuery = {
  __typename?: 'Query';
  locations: Array<{
    __typename?: 'Location';
    id: string;
    name: string;
    address?: string | null;
    url?: string | null;
    description?: string | null;
    latitude: any;
    longitude: any;
  }>;
};

export type GetLocationQueryVariables = Exact<{
  where: LocationWhereUniqueInput;
}>;

export type GetLocationQuery = {
  __typename?: 'Query';
  getLocation?: {
    __typename?: 'Location';
    description?: string | null;
    isVerified?: boolean | null;
    latitude: any;
    longitude: any;
    name: string;
    url?: string | null;
    address?: string | null;
  } | null;
};

export type UpdateOneLocationMutationVariables = Exact<{
  data: LocationUpdateInput;
  where: LocationWhereUniqueInput;
}>;

export type UpdateOneLocationMutation = {
  __typename?: 'Mutation';
  updateOneLocation?: { __typename?: 'Location'; id: string } | null;
};

export type CreateOneLocationMutationVariables = Exact<{
  data: LocationCreateInput;
}>;

export type CreateOneLocationMutation = {
  __typename?: 'Mutation';
  createOneLocation: {
    __typename?: 'Location';
    id: string;
    name: string;
    latitude: any;
    longitude: any;
  };
};

export type GetOrgsQueryVariables = Exact<{
  orderBy?: InputMaybe<
    Array<OrgOrderByWithRelationInput> | OrgOrderByWithRelationInput
  >;
}>;

export type GetOrgsQuery = {
  __typename?: 'Query';
  orgs: Array<{
    __typename?: 'Org';
    id: string;
    name: string;
    description?: string | null;
    url: string;
  }>;
};

export type GetOrgQueryVariables = Exact<{
  where: OrgWhereUniqueInput;
}>;

export type GetOrgQuery = {
  __typename?: 'Query';
  getOrg?: {
    __typename?: 'Org';
    id: string;
    name: string;
    description?: string | null;
    url: string;
    scrapeUrls: Array<string>;
    OrgLocations: Array<{ __typename?: 'OrgLocation'; locationId: string }>;
  } | null;
};

export type UpdateOneOrgMutationVariables = Exact<{
  data: OrgUpdateInput;
  where: OrgWhereUniqueInput;
}>;

export type UpdateOneOrgMutation = {
  __typename?: 'Mutation';
  updateOneOrg?: { __typename?: 'Org'; id: string } | null;
};

export type CreateOneOrgMutationVariables = Exact<{
  data: OrgCreateInput;
}>;

export type CreateOneOrgMutation = {
  __typename?: 'Mutation';
  createOneOrg: { __typename?: 'Org'; id: string };
};

export type GetRatingsQueryVariables = Exact<{
  where?: InputMaybe<RatingWhereInput>;
  orderBy?: InputMaybe<
    Array<RatingOrderByWithRelationInput> | RatingOrderByWithRelationInput
  >;
}>;

export type GetRatingsQuery = {
  __typename?: 'Query';
  ratings: Array<{
    __typename?: 'Rating';
    id: string;
    rating: string;
    sport: Sport;
  }>;
};

export type DeleteManyUserRatingsMutationVariables = Exact<{
  where?: InputMaybe<UserRatingWhereInput>;
}>;

export type DeleteManyUserRatingsMutation = {
  __typename?: 'Mutation';
  deleteManyUserRating: { __typename?: 'AffectedRowsOutput'; count: number };
};

export type DeleteOneUserRatingMutationVariables = Exact<{
  where: UserRatingWhereUniqueInput;
}>;

export type DeleteOneUserRatingMutation = {
  __typename?: 'Mutation';
  deleteOneUserRating?: { __typename?: 'UserRating'; id: string } | null;
};

export type GetUserRatingsQueryVariables = Exact<{
  where?: InputMaybe<UserRatingWhereInput>;
}>;

export type GetUserRatingsQuery = {
  __typename?: 'Query';
  userRatings: Array<{
    __typename?: 'UserRating';
    id: string;
    rating: { __typename?: 'Rating'; id: string; sport: Sport; rating: string };
  }>;
};

export type CreateOneUserRatingMutationVariables = Exact<{
  data: UserRatingCreateInput;
}>;

export type CreateOneUserRatingMutation = {
  __typename?: 'Mutation';
  createOneUserRating: {
    __typename?: 'UserRating';
    ratingId: string;
    userId: string;
  };
};

export type UpsertOneUserRatingMutationVariables = Exact<{
  where: UserRatingWhereUniqueInput;
  create: UserRatingCreateInput;
  update: UserRatingUpdateInput;
}>;

export type UpsertOneUserRatingMutation = {
  __typename?: 'Mutation';
  upsertOneUserRating: { __typename?: 'UserRating'; id: string };
};

export type GetTotalMatchesQueryVariables = Exact<{
  where?: InputMaybe<MatchWhereInput>;
}>;

export type GetTotalMatchesQuery = {
  __typename?: 'Query';
  aggregateMatch: {
    __typename?: 'AggregateMatch';
    _count?: { __typename?: 'MatchCountAggregate'; _all: number } | null;
  };
};

export type GetTotalWinsQueryVariables = Exact<{
  where?: InputMaybe<MatchWhereInput>;
}>;

export type GetTotalWinsQuery = {
  __typename?: 'Query';
  aggregateMatch: {
    __typename?: 'AggregateMatch';
    _count?: {
      __typename?: 'MatchCountAggregate';
      winningTeamId: number;
    } | null;
  };
};

export type GetAllSportsQueryVariables = Exact<{
  where?: InputMaybe<MatchWhereInput>;
}>;

export type GetAllSportsQuery = {
  __typename?: 'Query';
  aggregateMatch: {
    __typename?: 'AggregateMatch';
    _count?: { __typename?: 'MatchCountAggregate'; _all: number } | null;
  };
};

export type GetUserTeamsQueryVariables = Exact<{
  where?: InputMaybe<UserTeamWhereInput>;
}>;

export type GetUserTeamsQuery = {
  __typename?: 'Query';
  userTeams: Array<{
    __typename?: 'UserTeam';
    team: { __typename?: 'Team'; id: string; name: string; sport: Sport };
  }>;
};

export type GetTeamQueryVariables = Exact<{
  where: TeamWhereUniqueInput;
  teamUsersWhere?: InputMaybe<UserTeamWhereInput>;
  orderTeamUsersBy?: InputMaybe<
    Array<UserTeamOrderByWithRelationInput> | UserTeamOrderByWithRelationInput
  >;
  orderUserTeamInviteBy?: InputMaybe<
    | Array<UserTeamInviteOrderByWithRelationInput>
    | UserTeamInviteOrderByWithRelationInput
  >;
}>;

export type GetTeamQuery = {
  __typename?: 'Query';
  getTeam?: {
    __typename?: 'Team';
    id: string;
    name: string;
    createdByUserId?: string | null;
    invitedUsers: Array<{
      __typename?: 'UserTeamInvite';
      status: InviteStatus;
      user: {
        __typename?: 'User';
        id: string;
        name: string;
        isPseudo: boolean;
        email: string;
      };
    }>;
    teamUsers: Array<{
      __typename?: 'UserTeam';
      user: { __typename?: 'User'; id: string; name: string };
    }>;
  } | null;
};

export type GetTeamForTeamCardQueryVariables = Exact<{
  where: TeamWhereUniqueInput;
  teamUsersWhere?: InputMaybe<UserTeamWhereInput>;
  orderTeamUsersBy?: InputMaybe<
    Array<UserTeamOrderByWithRelationInput> | UserTeamOrderByWithRelationInput
  >;
}>;

export type GetTeamForTeamCardQuery = {
  __typename?: 'Query';
  getTeam?: {
    __typename?: 'Team';
    id: string;
    name: string;
    teamUsers: Array<{
      __typename?: 'UserTeam';
      user: { __typename?: 'User'; id: string; name: string };
    }>;
  } | null;
};

export type InviteUsersToTeamMutationVariables = Exact<{
  data: Array<UserTeamInviteCreateManyInput> | UserTeamInviteCreateManyInput;
}>;

export type InviteUsersToTeamMutation = {
  __typename?: 'Mutation';
  createManyUserTeamInvite: {
    __typename?: 'AffectedRowsOutput';
    count: number;
  };
};

export type RemoveUserFromTeamMutationVariables = Exact<{
  where: UserTeamWhereUniqueInput;
}>;

export type RemoveUserFromTeamMutation = {
  __typename?: 'Mutation';
  deleteOneUserTeam?: { __typename?: 'UserTeam'; id: string } | null;
};

export type CreateTeamMutationVariables = Exact<{
  data: TeamCreateInput;
}>;

export type CreateTeamMutation = {
  __typename?: 'Mutation';
  createOneTeam: {
    __typename?: 'Team';
    id: string;
    name: string;
    teamUsers: Array<{
      __typename?: 'UserTeam';
      user: { __typename?: 'User'; id: string; name: string };
    }>;
  };
};

export type GetUserTeamInviteQueryVariables = Exact<{
  where: UserTeamInviteWhereUniqueInput;
}>;

export type GetUserTeamInviteQuery = {
  __typename?: 'Query';
  getUserTeamInvite?: {
    __typename?: 'UserTeamInvite';
    status: InviteStatus;
    id: string;
  } | null;
};

export type GetUserTeamInvitesQueryVariables = Exact<{
  where?: InputMaybe<UserTeamInviteWhereInput>;
}>;

export type GetUserTeamInvitesQuery = {
  __typename?: 'Query';
  userTeamInvites: Array<{
    __typename?: 'UserTeamInvite';
    id: string;
    createdByUser: { __typename?: 'User'; name: string };
    team: { __typename?: 'Team'; id: string; name: string };
  }>;
};

export type UpdateOneUserTeamInviteMutationVariables = Exact<{
  data: UserTeamInviteUpdateInput;
  where: UserTeamInviteWhereUniqueInput;
}>;

export type UpdateOneUserTeamInviteMutation = {
  __typename?: 'Mutation';
  updateOneUserTeamInvite?: {
    __typename?: 'UserTeamInvite';
    id: string;
    status: InviteStatus;
  } | null;
};

export type CreateOneUserTeamMutationVariables = Exact<{
  data: UserTeamCreateInput;
}>;

export type CreateOneUserTeamMutation = {
  __typename?: 'Mutation';
  createOneUserTeam: { __typename?: 'UserTeam'; id: string };
};

export type UpdateOneUserTeamEventMutationVariables = Exact<{
  data: UserTeamEventUpdateInput;
  where: UserTeamEventWhereUniqueInput;
}>;

export type UpdateOneUserTeamEventMutation = {
  __typename?: 'Mutation';
  updateOneUserTeamEvent?: { __typename?: 'UserTeamEvent'; id: string } | null;
};

export type UpdateOneUserTeamMutationVariables = Exact<{
  data: UserTeamUpdateInput;
  where: UserTeamWhereUniqueInput;
}>;

export type UpdateOneUserTeamMutation = {
  __typename?: 'Mutation';
  updateOneUserTeam?: { __typename?: 'UserTeam'; id: string } | null;
};

export type GetTeamEventQueryVariables = Exact<{
  where: TeamEventWhereUniqueInput;
}>;

export type GetTeamEventQuery = {
  __typename?: 'Query';
  getTeamEvent?: {
    __typename?: 'TeamEvent';
    eventId?: string | null;
    seed?: number | null;
    team: {
      __typename?: 'Team';
      name: string;
      id: string;
      userTeamEvents: Array<{
        __typename?: 'UserTeamEvent';
        user: { __typename?: 'User'; id: string; name: string };
      }>;
    };
  } | null;
};

export type CreateUserTeamInviteMutationVariables = Exact<{
  data: UserTeamInviteCreateInput;
}>;

export type CreateUserTeamInviteMutation = {
  __typename?: 'Mutation';
  createOneUserTeamInvite: { __typename?: 'UserTeamInvite'; id: string };
};

export type UpdateOneUserEventMutationVariables = Exact<{
  data: UserEventUpdateInput;
  where: UserEventWhereUniqueInput;
}>;

export type UpdateOneUserEventMutation = {
  __typename?: 'Mutation';
  updateOneUserEvent?: { __typename?: 'UserEvent'; id: string } | null;
};

export type UpdateOneUserMutationVariables = Exact<{
  data: UserUpdateInput;
  updateOneUserWhere: UserWhereUniqueInput;
}>;

export type UpdateOneUserMutation = {
  __typename?: 'Mutation';
  updateOneUser?: {
    __typename?: 'User';
    email: string;
    name: string;
    id: string;
    optIn: boolean;
    favoriteSports: Array<Sport>;
  } | null;
};

export type UpsertOneUserNotificationPreferencesMutationVariables = Exact<{
  where: UserNotificationPreferencesWhereUniqueInput;
  create: UserNotificationPreferencesCreateInput;
  update: UserNotificationPreferencesUpdateInput;
}>;

export type UpsertOneUserNotificationPreferencesMutation = {
  __typename?: 'Mutation';
  upsertOneUserNotificationPreferences: {
    __typename?: 'UserNotificationPreferences';
    id: number;
  };
};

export type UpsertOneUserEventMutationVariables = Exact<{
  where: UserEventWhereUniqueInput;
  create: UserEventCreateInput;
  update: UserEventUpdateInput;
}>;

export type UpsertOneUserEventMutation = {
  __typename?: 'Mutation';
  upsertOneUserEvent: { __typename?: 'UserEvent'; id: string };
};

export type GetUsersQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
  }>;
};

export type GetUserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser?: {
    __typename?: 'User';
    id: string;
    email: string;
    name: string;
    optIn: boolean;
    favoriteSports: Array<Sport>;
    lastViewedFeed?: any | null;
  } | null;
};

export type DeleteAuth0UserMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteAuth0UserMutation = {
  __typename?: 'Mutation';
  deleteAuth0User: boolean;
};

export const DeleteCourtDocument = gql`
  mutation deleteCourt($where: CourtWhereUniqueInput!) {
    deleteOneCourt(where: $where) {
      id
    }
  }
`;
export const CreateCourtDocument = gql`
  mutation createCourt($data: CourtCreateInput!) {
    createOneCourt(data: $data) {
      id
    }
  }
`;
export const UpdateCourtDocument = gql`
  mutation updateCourt(
    $data: CourtUpdateInput!
    $where: CourtWhereUniqueInput!
  ) {
    updateOneCourt(data: $data, where: $where) {
      id
    }
  }
`;
export const GetCourtsDocument = gql`
  query getCourts($where: CourtWhereInput) {
    courts(where: $where) {
      id
      name
      sport
      locationId
    }
  }
`;
export const CreateUserDocument = gql`
  mutation createUser($data: UserCreateInput!) {
    createOneUser(data: $data) {
      name
      id
      email
    }
  }
`;
export const CreateOneEventDocument = gql`
  mutation createOneEvent($data: EventCreateInput!) {
    createOneEvent(data: $data) {
      id
      name
    }
  }
`;
export const UpdateOneEventDocument = gql`
  mutation updateOneEvent(
    $data: EventUpdateInput!
    $where: EventWhereUniqueInput!
  ) {
    updateOneEvent(data: $data, where: $where) {
      id
      name
    }
  }
`;
export const DeleteEventDocument = gql`
  mutation deleteEvent($where: EventWhereUniqueInput!) {
    deleteOneEvent(where: $where) {
      id
    }
  }
`;
export const UpdateScoreDocument = gql`
  mutation updateScore(
    $data: ScoreUpdateInput!
    $where: ScoreWhereUniqueInput!
  ) {
    updateOneScore(data: $data, where: $where) {
      id
      value
    }
  }
`;
export const UpdateRoundDocument = gql`
  mutation updateRound(
    $data: RoundUpdateInput!
    $where: RoundWhereUniqueInput!
  ) {
    updateOneRound(data: $data, where: $where) {
      id
      winningTeamId
    }
  }
`;
export const UpdateMatchDocument = gql`
  mutation updateMatch(
    $data: MatchUpdateInput!
    $where: MatchWhereUniqueInput!
  ) {
    updateOneMatch(data: $data, where: $where) {
      id
      winningTeamId
      nextMatchId
    }
  }
`;
export const DeleteMatchDocument = gql`
  mutation deleteMatch($where: MatchWhereUniqueInput!) {
    deleteOneMatch(where: $where) {
      _count {
        rounds
      }
    }
  }
`;
export const CreateManyUserEventInviteDocument = gql`
  mutation createManyUserEventInvite(
    $data: [UserEventInviteCreateManyInput!]!
  ) {
    createManyUserEventInvite(data: $data) {
      count
    }
  }
`;
export const CreateUserEventInviteDocument = gql`
  mutation createUserEventInvite($data: UserEventInviteCreateInput!) {
    createOneUserEventInvite(data: $data) {
      id
    }
  }
`;
export const GetEventInvitesDocument = gql`
  query getEventInvites($where: UserEventInviteWhereInput) {
    userEventInvites(where: $where) {
      id
      status
      createdByUser {
        id
        name
      }
      event {
        _count {
          invitedUsers
        }
        id
        name
        url
        description
        startDate
        endDate
        type
        gender
        privacy
        createdByUserId
        maxUsersGoing
      }
    }
  }
`;
export const GetTournamentDocument = gql`
  query getTournament(
    $where: EventWhereUniqueInput!
    $matchesWhere: MatchWhereInput
    $stagesWhere: StageWhereInput
    $eventUsersWhere: UserEventWhereInput
    $orderMatchesBy: [MatchOrderByWithRelationInput!]
    $orderTeamEventsBy: [TeamEventOrderByWithRelationInput!]
    $orderStagesBy: [StageOrderByWithRelationInput!]
    $orderTeamUsersBy: [UserEventInviteOrderByWithRelationInput!]
  ) {
    event(where: $where) {
      id
      sport
      name
      startDate
      endDate
      description
      privacy
      type
      gender
      teamSize
      url
      createdByUserId
      maxUsersGoing
      stages(orderBy: $orderStagesBy, where: $stagesWhere) {
        _count {
          matches
        }
        id
        name
        type
        matches(orderBy: $orderMatchesBy, where: $matchesWhere) {
          id
          order
          court {
            id
            name
          }
          playoffRound
          startDate
          winningTeamId
          teams {
            id
            name
          }
          rounds {
            id
            order
            winningTeamId
            scores {
              id
              team {
                id
                name
              }
              value
            }
          }
        }
      }
      location {
        name
        court {
          id
          name
        }
      }
      org {
        name
        url
      }
      teamEvents(orderBy: $orderTeamEventsBy) {
        team {
          id
          name
        }
        id
        seed
      }
      invitedUsers(orderBy: $orderTeamUsersBy) {
        id
        status
        user {
          id
          name
          isPseudo
          email
        }
      }
      eventUsers(where: $eventUsersWhere) {
        id
        status
        user {
          id
          name
          isPseudo
          email
        }
      }
      userTeamEvents {
        id
        team {
          id
          name
        }
        user {
          id
          name
        }
      }
    }
  }
`;
export const GetPickupEventDocument = gql`
  query getPickupEvent(
    $where: EventWhereUniqueInput!
    $matchesWhere: MatchWhereInput
    $teamsOrderBy: [TeamOrderByWithRelationInput!]
    $teamUsersOrderBy: [UserTeamOrderByWithRelationInput!]
  ) {
    event(where: $where) {
      sport
      teamSize
      matches(where: $matchesWhere) {
        id
        rounds {
          id
          scores {
            teamId
            value
          }
        }
        teams(orderBy: $teamsOrderBy) {
          id
          teamUsers(orderBy: $teamUsersOrderBy) {
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export const GetEventCardDocument = gql`
  query getEventCard($where: EventWhereUniqueInput!) {
    event(where: $where) {
      id
      sport
      name
      startDate
      endDate
      description
      privacy
      type
      gender
      teamSize
      url
      createdByUserId
      maxUsersGoing
      org {
        name
        url
      }
    }
  }
`;
export const GetEventInvitedUsersDocument = gql`
  query getEventInvitedUsers(
    $where: EventWhereUniqueInput!
    $orderTeamUsersBy: [UserEventInviteOrderByWithRelationInput!]
  ) {
    event(where: $where) {
      id
      name
      startDate
      invitedUsers(orderBy: $orderTeamUsersBy) {
        id
        status
        user {
          id
          name
          isPseudo
          email
        }
      }
    }
  }
`;
export const GetEditEventDetailsDocument = gql`
  query getEditEventDetails($where: EventWhereUniqueInput!) {
    event(where: $where) {
      id
      sport
      name
      startDate
      endDate
      description
      privacy
      type
      gender
      teamSize
      url
      minTeams
      maxTeams
      orgId
      locationId
      maxUsersGoing
      createdByUserId
      cost
    }
  }
`;
export const GetMatchDetailsDocument = gql`
  query getMatchDetails(
    $where: MatchWhereUniqueInput!
    $roundsOrderBy: [RoundOrderByWithRelationInput!]
    $scoresOrderBy: [ScoreOrderByWithRelationInput!]
  ) {
    match(where: $where) {
      id
      order
      startDate
      winningTeamId
      stage {
        event {
          id
          name
        }
      }
      teams {
        id
        name
      }
      court {
        id
        name
      }
      stage {
        event {
          createdByUserId
        }
      }
      rounds(orderBy: $roundsOrderBy) {
        id
        order
        winningTeamId
        scores(orderBy: $scoresOrderBy) {
          id
          team {
            id
            name
            userTeamEvents {
              user {
                name
              }
            }
          }
          value
        }
      }
    }
  }
`;
export const GetUserEventInviteDocument = gql`
  query getUserEventInvite($where: UserEventInviteWhereUniqueInput!) {
    userEventInvite(where: $where) {
      id
      status
    }
  }
`;
export const GetUserEventInvitesDocument = gql`
  query getUserEventInvites($where: UserEventInviteWhereInput) {
    userEventInvites(where: $where) {
      id
      status
      user {
        name
      }
    }
  }
`;
export const GetEventInvitesByEventDocument = gql`
  query getEventInvitesByEvent(
    $where: EventWhereInput
    $orderBy: [EventOrderByWithRelationInput!]
  ) {
    events(where: $where, orderBy: $orderBy) {
      id
      name
      url
      description
      startDate
      endDate
      type
      gender
      createdByUserId
      privacy
      maxUsersGoing
    }
  }
`;
export const GetEventsForHomeFeedDocument = gql`
  query getEventsForHomeFeed(
    $where: EventWhereInput
    $orderBy: [EventOrderByWithRelationInput!]
  ) {
    events(where: $where, orderBy: $orderBy) {
      id
      name
      url
      description
      startDate
      endDate
      type
      gender
      createdByUserId
      privacy
      maxUsersGoing
      createdAt
      createdByUser {
        id
        name
      }
      org {
        name
        url
      }
    }
  }
`;
export const UpdateUserEventInviteDocument = gql`
  mutation updateUserEventInvite(
    $data: UserEventInviteUpdateInput!
    $where: UserEventInviteWhereUniqueInput!
  ) {
    updateOneUserEventInvite(data: $data, where: $where) {
      id
    }
  }
`;
export const DeleteOneUserEventInviteDocument = gql`
  mutation deleteOneUserEventInvite($where: UserEventInviteWhereUniqueInput!) {
    deleteOneUserEventInvite(where: $where) {
      id
    }
  }
`;
export const CreateRoundDocument = gql`
  mutation createRound($data: RoundCreateInput!) {
    createOneRound(data: $data) {
      id
    }
  }
`;
export const CreateMatchDocument = gql`
  mutation createMatch($data: MatchCreateInput!) {
    createOneMatch(data: $data) {
      id
    }
  }
`;
export const DeleteManyTeamsDocument = gql`
  mutation deleteManyTeams($where: TeamWhereInput) {
    deleteManyTeam(where: $where) {
      count
    }
  }
`;
export const CreateOneTeamEventDocument = gql`
  mutation createOneTeamEvent($data: TeamEventCreateInput!) {
    createOneTeamEvent(data: $data) {
      id
    }
  }
`;
export const CreateOneStageDocument = gql`
  mutation createOneStage($data: StageCreateInput!) {
    createOneStage(data: $data) {
      id
    }
  }
`;
export const CreateOnePoolDocument = gql`
  mutation createOnePool($data: PoolCreateInput!) {
    createOnePool(data: $data) {
      id
    }
  }
`;
export const GetTeamPlacementDocument = gql`
  query getTeamPlacement(
    $teamEventsWhere: TeamEventWhereInput
    $matchWhere: MatchWhereInput
    $roundWhere: RoundWhereInput
    $orderBy: [TeamEventOrderByWithRelationInput!]
  ) {
    teamEvents(where: $teamEventsWhere, orderBy: $orderBy) {
      placement
      pool {
        name
      }
      seed
      team {
        id
        name
        matches(where: $matchWhere) {
          winningTeamId
          rounds(where: $roundWhere) {
            winningTeamId
          }
        }
      }
    }
  }
`;
export const GenerateSeedsDocument = gql`
  mutation generateSeeds($eventId: String!) {
    generateSeeds(eventId: $eventId)
  }
`;
export const UpdateSeedsDocument = gql`
  mutation updateSeeds($eventId: String!, $teams: [ID!]!) {
    updateSeeds(eventId: $eventId, teams: $teams)
  }
`;
export const GetPlayoffRoundsDocument = gql`
  query getPlayoffRounds(
    $distinct: [MatchScalarFieldEnum!]
    $where: MatchWhereInput
    $orderBy: [MatchOrderByWithRelationInput!]
  ) {
    matches(distinct: $distinct, where: $where, orderBy: $orderBy) {
      playoffRound
    }
  }
`;
export const GetPoolsDocument = gql`
  query getPools(
    $where: PoolWhereInput
    $orderBy: [PoolOrderByWithRelationInput!]
  ) {
    pools(where: $where, orderBy: $orderBy) {
      id
      name
    }
  }
`;
export const CreatePlayoffMatchesDocument = gql`
  mutation createPlayoffMatches($eventId: String!) {
    createPlayoffMatches(eventId: $eventId)
  }
`;
export const GetUserEventDocument = gql`
  query getUserEvent($where: UserEventWhereUniqueInput!) {
    getUserEvent(where: $where) {
      id
      status
    }
  }
`;
export const GetUsersInQueueDocument = gql`
  query getUsersInQueue($eventId: String!) {
    getUsersInQueue(eventId: $eventId) {
      user {
        id
        name
      }
      eventPosition
    }
  }
`;
export const MoveUserIdsToEndofQueueDocument = gql`
  mutation moveUserIdsToEndofQueue($userIds: [ID!]!, $eventId: String!) {
    moveUserIdsToEndofQueue(userIds: $userIds, eventId: $eventId)
  }
`;
export const MoveUserIndexesInQueueDocument = gql`
  mutation moveUserIndexesInQueue(
    $index1: Float!
    $index2: Float!
    $eventId: String!
  ) {
    moveUserIndexesInQueue(index1: $index1, index2: $index2, eventId: $eventId)
  }
`;
export const AggregateUserEventDocument = gql`
  query aggregateUserEvent($where: UserEventWhereInput) {
    aggregateUserEvent(where: $where) {
      _count {
        _all
      }
    }
  }
`;
export const GetPickupLastWinnerDocument = gql`
  query getPickupLastWinner(
    $where: EventWhereUniqueInput!
    $orderBy: [MatchOrderByWithRelationInput!]
    $take: Int
  ) {
    getEvent(where: $where) {
      matches(orderBy: $orderBy, take: $take) {
        rounds {
          winningTeam {
            teamUsers {
              userId
              user {
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const EmailInviteDocument = gql`
  mutation emailInvite(
    $emails: [ID!]!
    $invitedBy: String!
    $invitedByID: String!
    $name: String!
    $eventStartDate: String!
    $inviteType: String!
    $id: String!
  ) {
    emailInvite(
      emails: $emails
      invitedBy: $invitedBy
      invitedByID: $invitedByID
      name: $name
      eventStartDate: $eventStartDate
      inviteType: $inviteType
      id: $id
    )
  }
`;
export const GetLocationsWithEventsDocument = gql`
  query getLocationsWithEvents(
    $locationsWhere: LocationWhereInput
    $eventsWhere: EventWhereInput
    $eventsOrderBy: [EventOrderByWithRelationInput!]
  ) {
    locations(where: $locationsWhere) {
      id
      name
      url
      description
      latitude
      longitude
      events(where: $eventsWhere, orderBy: $eventsOrderBy) {
        id
        name
        url
        description
        startDate
        endDate
        type
        gender
        teamSize
        privacy
        createdByUserId
        maxUsersGoing
        org {
          name
          url
        }
      }
    }
  }
`;
export const GetLocationsDocument = gql`
  query getLocations(
    $where: LocationWhereInput
    $orderBy: [LocationOrderByWithRelationInput!]
  ) {
    locations(where: $where, orderBy: $orderBy) {
      id
      name
      address
      url
      description
      latitude
      longitude
    }
  }
`;
export const GetLocationDocument = gql`
  query getLocation($where: LocationWhereUniqueInput!) {
    getLocation(where: $where) {
      description
      isVerified
      latitude
      longitude
      name
      url
      address
    }
  }
`;
export const UpdateOneLocationDocument = gql`
  mutation updateOneLocation(
    $data: LocationUpdateInput!
    $where: LocationWhereUniqueInput!
  ) {
    updateOneLocation(data: $data, where: $where) {
      id
    }
  }
`;
export const CreateOneLocationDocument = gql`
  mutation createOneLocation($data: LocationCreateInput!) {
    createOneLocation(data: $data) {
      id
      name
      latitude
      longitude
    }
  }
`;
export const GetOrgsDocument = gql`
  query getOrgs($orderBy: [OrgOrderByWithRelationInput!]) {
    orgs(orderBy: $orderBy) {
      id
      name
      description
      url
    }
  }
`;
export const GetOrgDocument = gql`
  query getOrg($where: OrgWhereUniqueInput!) {
    getOrg(where: $where) {
      id
      name
      description
      url
      scrapeUrls
      OrgLocations {
        locationId
      }
    }
  }
`;
export const UpdateOneOrgDocument = gql`
  mutation updateOneOrg($data: OrgUpdateInput!, $where: OrgWhereUniqueInput!) {
    updateOneOrg(data: $data, where: $where) {
      id
    }
  }
`;
export const CreateOneOrgDocument = gql`
  mutation createOneOrg($data: OrgCreateInput!) {
    createOneOrg(data: $data) {
      id
    }
  }
`;
export const GetRatingsDocument = gql`
  query getRatings(
    $where: RatingWhereInput
    $orderBy: [RatingOrderByWithRelationInput!]
  ) {
    ratings(where: $where, orderBy: $orderBy) {
      id
      rating
      sport
    }
  }
`;
export const DeleteManyUserRatingsDocument = gql`
  mutation deleteManyUserRatings($where: UserRatingWhereInput) {
    deleteManyUserRating(where: $where) {
      count
    }
  }
`;
export const DeleteOneUserRatingDocument = gql`
  mutation deleteOneUserRating($where: UserRatingWhereUniqueInput!) {
    deleteOneUserRating(where: $where) {
      id
    }
  }
`;
export const GetUserRatingsDocument = gql`
  query getUserRatings($where: UserRatingWhereInput) {
    userRatings(where: $where) {
      id
      rating {
        id
        sport
        rating
      }
    }
  }
`;
export const CreateOneUserRatingDocument = gql`
  mutation createOneUserRating($data: UserRatingCreateInput!) {
    createOneUserRating(data: $data) {
      ratingId
      userId
    }
  }
`;
export const UpsertOneUserRatingDocument = gql`
  mutation upsertOneUserRating(
    $where: UserRatingWhereUniqueInput!
    $create: UserRatingCreateInput!
    $update: UserRatingUpdateInput!
  ) {
    upsertOneUserRating(where: $where, create: $create, update: $update) {
      id
    }
  }
`;
export const GetTotalMatchesDocument = gql`
  query getTotalMatches($where: MatchWhereInput) {
    aggregateMatch(where: $where) {
      _count {
        _all
      }
    }
  }
`;
export const GetTotalWinsDocument = gql`
  query getTotalWins($where: MatchWhereInput) {
    aggregateMatch(where: $where) {
      _count {
        winningTeamId
      }
    }
  }
`;
export const GetAllSportsDocument = gql`
  query getAllSports($where: MatchWhereInput) {
    aggregateMatch(where: $where) {
      _count {
        _all
      }
    }
  }
`;
export const GetUserTeamsDocument = gql`
  query getUserTeams($where: UserTeamWhereInput) {
    userTeams(where: $where) {
      team {
        id
        name
        sport
      }
    }
  }
`;
export const GetTeamDocument = gql`
  query getTeam(
    $where: TeamWhereUniqueInput!
    $teamUsersWhere: UserTeamWhereInput
    $orderTeamUsersBy: [UserTeamOrderByWithRelationInput!]
    $orderUserTeamInviteBy: [UserTeamInviteOrderByWithRelationInput!]
  ) {
    getTeam(where: $where) {
      id
      name
      invitedUsers(orderBy: $orderUserTeamInviteBy) {
        user {
          id
          name
          isPseudo
          email
        }
        status
      }
      teamUsers(where: $teamUsersWhere, orderBy: $orderTeamUsersBy) {
        user {
          id
          name
        }
      }
      createdByUserId
    }
  }
`;
export const GetTeamForTeamCardDocument = gql`
  query getTeamForTeamCard(
    $where: TeamWhereUniqueInput!
    $teamUsersWhere: UserTeamWhereInput
    $orderTeamUsersBy: [UserTeamOrderByWithRelationInput!]
  ) {
    getTeam(where: $where) {
      id
      name
      teamUsers(where: $teamUsersWhere, orderBy: $orderTeamUsersBy) {
        user {
          id
          name
        }
      }
    }
  }
`;
export const InviteUsersToTeamDocument = gql`
  mutation inviteUsersToTeam($data: [UserTeamInviteCreateManyInput!]!) {
    createManyUserTeamInvite(data: $data) {
      count
    }
  }
`;
export const RemoveUserFromTeamDocument = gql`
  mutation removeUserFromTeam($where: UserTeamWhereUniqueInput!) {
    deleteOneUserTeam(where: $where) {
      id
    }
  }
`;
export const CreateTeamDocument = gql`
  mutation createTeam($data: TeamCreateInput!) {
    createOneTeam(data: $data) {
      id
      name
      teamUsers {
        user {
          id
          name
        }
      }
    }
  }
`;
export const GetUserTeamInviteDocument = gql`
  query getUserTeamInvite($where: UserTeamInviteWhereUniqueInput!) {
    getUserTeamInvite(where: $where) {
      status
      id
    }
  }
`;
export const GetUserTeamInvitesDocument = gql`
  query getUserTeamInvites($where: UserTeamInviteWhereInput) {
    userTeamInvites(where: $where) {
      id
      createdByUser {
        name
      }
      team {
        id
        name
      }
    }
  }
`;
export const UpdateOneUserTeamInviteDocument = gql`
  mutation updateOneUserTeamInvite(
    $data: UserTeamInviteUpdateInput!
    $where: UserTeamInviteWhereUniqueInput!
  ) {
    updateOneUserTeamInvite(data: $data, where: $where) {
      id
      status
    }
  }
`;
export const CreateOneUserTeamDocument = gql`
  mutation createOneUserTeam($data: UserTeamCreateInput!) {
    createOneUserTeam(data: $data) {
      id
    }
  }
`;
export const UpdateOneUserTeamEventDocument = gql`
  mutation updateOneUserTeamEvent(
    $data: UserTeamEventUpdateInput!
    $where: UserTeamEventWhereUniqueInput!
  ) {
    updateOneUserTeamEvent(data: $data, where: $where) {
      id
    }
  }
`;
export const UpdateOneUserTeamDocument = gql`
  mutation updateOneUserTeam(
    $data: UserTeamUpdateInput!
    $where: UserTeamWhereUniqueInput!
  ) {
    updateOneUserTeam(data: $data, where: $where) {
      id
    }
  }
`;
export const GetTeamEventDocument = gql`
  query getTeamEvent($where: TeamEventWhereUniqueInput!) {
    getTeamEvent(where: $where) {
      eventId
      seed
      team {
        name
        id
        userTeamEvents {
          user {
            id
            name
          }
        }
      }
    }
  }
`;
export const CreateUserTeamInviteDocument = gql`
  mutation createUserTeamInvite($data: UserTeamInviteCreateInput!) {
    createOneUserTeamInvite(data: $data) {
      id
    }
  }
`;
export const UpdateOneUserEventDocument = gql`
  mutation updateOneUserEvent(
    $data: UserEventUpdateInput!
    $where: UserEventWhereUniqueInput!
  ) {
    updateOneUserEvent(data: $data, where: $where) {
      id
    }
  }
`;
export const UpdateOneUserDocument = gql`
  mutation updateOneUser(
    $data: UserUpdateInput!
    $updateOneUserWhere: UserWhereUniqueInput!
  ) {
    updateOneUser(data: $data, where: $updateOneUserWhere) {
      email
      name
      id
      optIn
      favoriteSports
    }
  }
`;
export const UpsertOneUserNotificationPreferencesDocument = gql`
  mutation upsertOneUserNotificationPreferences(
    $where: UserNotificationPreferencesWhereUniqueInput!
    $create: UserNotificationPreferencesCreateInput!
    $update: UserNotificationPreferencesUpdateInput!
  ) {
    upsertOneUserNotificationPreferences(
      where: $where
      create: $create
      update: $update
    ) {
      id
    }
  }
`;
export const UpsertOneUserEventDocument = gql`
  mutation upsertOneUserEvent(
    $where: UserEventWhereUniqueInput!
    $create: UserEventCreateInput!
    $update: UserEventUpdateInput!
  ) {
    upsertOneUserEvent(where: $where, create: $create, update: $update) {
      id
    }
  }
`;
export const GetUsersDocument = gql`
  query getUsers($where: UserWhereInput) {
    users(where: $where) {
      id
      name
      email
    }
  }
`;
export const GetUserDocument = gql`
  query getUser($where: UserWhereUniqueInput!) {
    getUser(where: $where) {
      id
      email
      name
      optIn
      favoriteSports
      lastViewedFeed
    }
  }
`;
export const DeleteAuth0UserDocument = gql`
  mutation deleteAuth0User {
    deleteAuth0User
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
  _variables
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    deleteCourt(
      variables: DeleteCourtMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteCourtMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteCourtMutation>(DeleteCourtDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteCourt',
        'mutation',
        variables
      );
    },
    createCourt(
      variables: CreateCourtMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateCourtMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateCourtMutation>(CreateCourtDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'createCourt',
        'mutation',
        variables
      );
    },
    updateCourt(
      variables: UpdateCourtMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateCourtMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateCourtMutation>(UpdateCourtDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateCourt',
        'mutation',
        variables
      );
    },
    getCourts(
      variables?: GetCourtsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetCourtsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCourtsQuery>(GetCourtsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getCourts',
        'query',
        variables
      );
    },
    createUser(
      variables: CreateUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateUserMutation>(CreateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'createUser',
        'mutation',
        variables
      );
    },
    createOneEvent(
      variables: CreateOneEventMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateOneEventMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateOneEventMutation>(
            CreateOneEventDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createOneEvent',
        'mutation',
        variables
      );
    },
    updateOneEvent(
      variables: UpdateOneEventMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateOneEventMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneEventMutation>(
            UpdateOneEventDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneEvent',
        'mutation',
        variables
      );
    },
    deleteEvent(
      variables: DeleteEventMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteEventMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteEventMutation>(DeleteEventDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteEvent',
        'mutation',
        variables
      );
    },
    updateScore(
      variables: UpdateScoreMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateScoreMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateScoreMutation>(UpdateScoreDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateScore',
        'mutation',
        variables
      );
    },
    updateRound(
      variables: UpdateRoundMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateRoundMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateRoundMutation>(UpdateRoundDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateRound',
        'mutation',
        variables
      );
    },
    updateMatch(
      variables: UpdateMatchMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateMatchMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateMatchMutation>(UpdateMatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateMatch',
        'mutation',
        variables
      );
    },
    deleteMatch(
      variables: DeleteMatchMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteMatchMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteMatchMutation>(DeleteMatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteMatch',
        'mutation',
        variables
      );
    },
    createManyUserEventInvite(
      variables: CreateManyUserEventInviteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateManyUserEventInviteMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateManyUserEventInviteMutation>(
            CreateManyUserEventInviteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createManyUserEventInvite',
        'mutation',
        variables
      );
    },
    createUserEventInvite(
      variables: CreateUserEventInviteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateUserEventInviteMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateUserEventInviteMutation>(
            CreateUserEventInviteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createUserEventInvite',
        'mutation',
        variables
      );
    },
    getEventInvites(
      variables?: GetEventInvitesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetEventInvitesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEventInvitesQuery>(
            GetEventInvitesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getEventInvites',
        'query',
        variables
      );
    },
    getTournament(
      variables: GetTournamentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetTournamentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTournamentQuery>(GetTournamentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getTournament',
        'query',
        variables
      );
    },
    getPickupEvent(
      variables: GetPickupEventQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetPickupEventQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetPickupEventQuery>(
            GetPickupEventDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getPickupEvent',
        'query',
        variables
      );
    },
    getEventCard(
      variables: GetEventCardQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetEventCardQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEventCardQuery>(GetEventCardDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getEventCard',
        'query',
        variables
      );
    },
    getEventInvitedUsers(
      variables: GetEventInvitedUsersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetEventInvitedUsersQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEventInvitedUsersQuery>(
            GetEventInvitedUsersDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getEventInvitedUsers',
        'query',
        variables
      );
    },
    getEditEventDetails(
      variables: GetEditEventDetailsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetEditEventDetailsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEditEventDetailsQuery>(
            GetEditEventDetailsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getEditEventDetails',
        'query',
        variables
      );
    },
    getMatchDetails(
      variables: GetMatchDetailsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetMatchDetailsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetMatchDetailsQuery>(
            GetMatchDetailsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getMatchDetails',
        'query',
        variables
      );
    },
    getUserEventInvite(
      variables: GetUserEventInviteQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserEventInviteQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUserEventInviteQuery>(
            GetUserEventInviteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getUserEventInvite',
        'query',
        variables
      );
    },
    getUserEventInvites(
      variables?: GetUserEventInvitesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserEventInvitesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUserEventInvitesQuery>(
            GetUserEventInvitesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getUserEventInvites',
        'query',
        variables
      );
    },
    getEventInvitesByEvent(
      variables?: GetEventInvitesByEventQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetEventInvitesByEventQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEventInvitesByEventQuery>(
            GetEventInvitesByEventDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getEventInvitesByEvent',
        'query',
        variables
      );
    },
    getEventsForHomeFeed(
      variables?: GetEventsForHomeFeedQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetEventsForHomeFeedQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEventsForHomeFeedQuery>(
            GetEventsForHomeFeedDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getEventsForHomeFeed',
        'query',
        variables
      );
    },
    updateUserEventInvite(
      variables: UpdateUserEventInviteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateUserEventInviteMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateUserEventInviteMutation>(
            UpdateUserEventInviteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateUserEventInvite',
        'mutation',
        variables
      );
    },
    deleteOneUserEventInvite(
      variables: DeleteOneUserEventInviteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteOneUserEventInviteMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteOneUserEventInviteMutation>(
            DeleteOneUserEventInviteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteOneUserEventInvite',
        'mutation',
        variables
      );
    },
    createRound(
      variables: CreateRoundMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateRoundMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateRoundMutation>(CreateRoundDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'createRound',
        'mutation',
        variables
      );
    },
    createMatch(
      variables: CreateMatchMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateMatchMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateMatchMutation>(CreateMatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'createMatch',
        'mutation',
        variables
      );
    },
    deleteManyTeams(
      variables?: DeleteManyTeamsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteManyTeamsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteManyTeamsMutation>(
            DeleteManyTeamsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteManyTeams',
        'mutation',
        variables
      );
    },
    createOneTeamEvent(
      variables: CreateOneTeamEventMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateOneTeamEventMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateOneTeamEventMutation>(
            CreateOneTeamEventDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createOneTeamEvent',
        'mutation',
        variables
      );
    },
    createOneStage(
      variables: CreateOneStageMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateOneStageMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateOneStageMutation>(
            CreateOneStageDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createOneStage',
        'mutation',
        variables
      );
    },
    createOnePool(
      variables: CreateOnePoolMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateOnePoolMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateOnePoolMutation>(
            CreateOnePoolDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createOnePool',
        'mutation',
        variables
      );
    },
    getTeamPlacement(
      variables?: GetTeamPlacementQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetTeamPlacementQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTeamPlacementQuery>(
            GetTeamPlacementDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getTeamPlacement',
        'query',
        variables
      );
    },
    generateSeeds(
      variables: GenerateSeedsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GenerateSeedsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GenerateSeedsMutation>(
            GenerateSeedsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'generateSeeds',
        'mutation',
        variables
      );
    },
    updateSeeds(
      variables: UpdateSeedsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateSeedsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateSeedsMutation>(UpdateSeedsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateSeeds',
        'mutation',
        variables
      );
    },
    getPlayoffRounds(
      variables?: GetPlayoffRoundsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetPlayoffRoundsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetPlayoffRoundsQuery>(
            GetPlayoffRoundsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getPlayoffRounds',
        'query',
        variables
      );
    },
    getPools(
      variables?: GetPoolsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetPoolsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetPoolsQuery>(GetPoolsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getPools',
        'query',
        variables
      );
    },
    createPlayoffMatches(
      variables: CreatePlayoffMatchesMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreatePlayoffMatchesMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreatePlayoffMatchesMutation>(
            CreatePlayoffMatchesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createPlayoffMatches',
        'mutation',
        variables
      );
    },
    getUserEvent(
      variables: GetUserEventQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserEventQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUserEventQuery>(GetUserEventDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getUserEvent',
        'query',
        variables
      );
    },
    getUsersInQueue(
      variables: GetUsersInQueueQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUsersInQueueQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUsersInQueueQuery>(
            GetUsersInQueueDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getUsersInQueue',
        'query',
        variables
      );
    },
    moveUserIdsToEndofQueue(
      variables: MoveUserIdsToEndofQueueMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<MoveUserIdsToEndofQueueMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<MoveUserIdsToEndofQueueMutation>(
            MoveUserIdsToEndofQueueDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'moveUserIdsToEndofQueue',
        'mutation',
        variables
      );
    },
    moveUserIndexesInQueue(
      variables: MoveUserIndexesInQueueMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<MoveUserIndexesInQueueMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<MoveUserIndexesInQueueMutation>(
            MoveUserIndexesInQueueDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'moveUserIndexesInQueue',
        'mutation',
        variables
      );
    },
    aggregateUserEvent(
      variables?: AggregateUserEventQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<AggregateUserEventQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AggregateUserEventQuery>(
            AggregateUserEventDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'aggregateUserEvent',
        'query',
        variables
      );
    },
    getPickupLastWinner(
      variables: GetPickupLastWinnerQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetPickupLastWinnerQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetPickupLastWinnerQuery>(
            GetPickupLastWinnerDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getPickupLastWinner',
        'query',
        variables
      );
    },
    emailInvite(
      variables: EmailInviteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<EmailInviteMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<EmailInviteMutation>(EmailInviteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'emailInvite',
        'mutation',
        variables
      );
    },
    getLocationsWithEvents(
      variables?: GetLocationsWithEventsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetLocationsWithEventsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetLocationsWithEventsQuery>(
            GetLocationsWithEventsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getLocationsWithEvents',
        'query',
        variables
      );
    },
    getLocations(
      variables?: GetLocationsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetLocationsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetLocationsQuery>(GetLocationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getLocations',
        'query',
        variables
      );
    },
    getLocation(
      variables: GetLocationQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetLocationQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetLocationQuery>(GetLocationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getLocation',
        'query',
        variables
      );
    },
    updateOneLocation(
      variables: UpdateOneLocationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateOneLocationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneLocationMutation>(
            UpdateOneLocationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneLocation',
        'mutation',
        variables
      );
    },
    createOneLocation(
      variables: CreateOneLocationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateOneLocationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateOneLocationMutation>(
            CreateOneLocationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createOneLocation',
        'mutation',
        variables
      );
    },
    getOrgs(
      variables?: GetOrgsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetOrgsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetOrgsQuery>(GetOrgsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getOrgs',
        'query',
        variables
      );
    },
    getOrg(
      variables: GetOrgQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetOrgQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetOrgQuery>(GetOrgDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getOrg',
        'query',
        variables
      );
    },
    updateOneOrg(
      variables: UpdateOneOrgMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateOneOrgMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneOrgMutation>(
            UpdateOneOrgDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneOrg',
        'mutation',
        variables
      );
    },
    createOneOrg(
      variables: CreateOneOrgMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateOneOrgMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateOneOrgMutation>(
            CreateOneOrgDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createOneOrg',
        'mutation',
        variables
      );
    },
    getRatings(
      variables?: GetRatingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetRatingsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRatingsQuery>(GetRatingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getRatings',
        'query',
        variables
      );
    },
    deleteManyUserRatings(
      variables?: DeleteManyUserRatingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteManyUserRatingsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteManyUserRatingsMutation>(
            DeleteManyUserRatingsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteManyUserRatings',
        'mutation',
        variables
      );
    },
    deleteOneUserRating(
      variables: DeleteOneUserRatingMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteOneUserRatingMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteOneUserRatingMutation>(
            DeleteOneUserRatingDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteOneUserRating',
        'mutation',
        variables
      );
    },
    getUserRatings(
      variables?: GetUserRatingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserRatingsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUserRatingsQuery>(
            GetUserRatingsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getUserRatings',
        'query',
        variables
      );
    },
    createOneUserRating(
      variables: CreateOneUserRatingMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateOneUserRatingMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateOneUserRatingMutation>(
            CreateOneUserRatingDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createOneUserRating',
        'mutation',
        variables
      );
    },
    upsertOneUserRating(
      variables: UpsertOneUserRatingMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpsertOneUserRatingMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertOneUserRatingMutation>(
            UpsertOneUserRatingDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'upsertOneUserRating',
        'mutation',
        variables
      );
    },
    getTotalMatches(
      variables?: GetTotalMatchesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetTotalMatchesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTotalMatchesQuery>(
            GetTotalMatchesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getTotalMatches',
        'query',
        variables
      );
    },
    getTotalWins(
      variables?: GetTotalWinsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetTotalWinsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTotalWinsQuery>(GetTotalWinsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getTotalWins',
        'query',
        variables
      );
    },
    getAllSports(
      variables?: GetAllSportsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetAllSportsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAllSportsQuery>(GetAllSportsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getAllSports',
        'query',
        variables
      );
    },
    getUserTeams(
      variables?: GetUserTeamsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserTeamsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUserTeamsQuery>(GetUserTeamsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getUserTeams',
        'query',
        variables
      );
    },
    getTeam(
      variables: GetTeamQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetTeamQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTeamQuery>(GetTeamDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getTeam',
        'query',
        variables
      );
    },
    getTeamForTeamCard(
      variables: GetTeamForTeamCardQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetTeamForTeamCardQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTeamForTeamCardQuery>(
            GetTeamForTeamCardDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getTeamForTeamCard',
        'query',
        variables
      );
    },
    inviteUsersToTeam(
      variables: InviteUsersToTeamMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<InviteUsersToTeamMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InviteUsersToTeamMutation>(
            InviteUsersToTeamDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'inviteUsersToTeam',
        'mutation',
        variables
      );
    },
    removeUserFromTeam(
      variables: RemoveUserFromTeamMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<RemoveUserFromTeamMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<RemoveUserFromTeamMutation>(
            RemoveUserFromTeamDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'removeUserFromTeam',
        'mutation',
        variables
      );
    },
    createTeam(
      variables: CreateTeamMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateTeamMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateTeamMutation>(CreateTeamDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'createTeam',
        'mutation',
        variables
      );
    },
    getUserTeamInvite(
      variables: GetUserTeamInviteQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserTeamInviteQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUserTeamInviteQuery>(
            GetUserTeamInviteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getUserTeamInvite',
        'query',
        variables
      );
    },
    getUserTeamInvites(
      variables?: GetUserTeamInvitesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserTeamInvitesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUserTeamInvitesQuery>(
            GetUserTeamInvitesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getUserTeamInvites',
        'query',
        variables
      );
    },
    updateOneUserTeamInvite(
      variables: UpdateOneUserTeamInviteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateOneUserTeamInviteMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneUserTeamInviteMutation>(
            UpdateOneUserTeamInviteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneUserTeamInvite',
        'mutation',
        variables
      );
    },
    createOneUserTeam(
      variables: CreateOneUserTeamMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateOneUserTeamMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateOneUserTeamMutation>(
            CreateOneUserTeamDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createOneUserTeam',
        'mutation',
        variables
      );
    },
    updateOneUserTeamEvent(
      variables: UpdateOneUserTeamEventMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateOneUserTeamEventMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneUserTeamEventMutation>(
            UpdateOneUserTeamEventDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneUserTeamEvent',
        'mutation',
        variables
      );
    },
    updateOneUserTeam(
      variables: UpdateOneUserTeamMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateOneUserTeamMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneUserTeamMutation>(
            UpdateOneUserTeamDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneUserTeam',
        'mutation',
        variables
      );
    },
    getTeamEvent(
      variables: GetTeamEventQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetTeamEventQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTeamEventQuery>(GetTeamEventDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getTeamEvent',
        'query',
        variables
      );
    },
    createUserTeamInvite(
      variables: CreateUserTeamInviteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateUserTeamInviteMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateUserTeamInviteMutation>(
            CreateUserTeamInviteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createUserTeamInvite',
        'mutation',
        variables
      );
    },
    updateOneUserEvent(
      variables: UpdateOneUserEventMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateOneUserEventMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneUserEventMutation>(
            UpdateOneUserEventDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneUserEvent',
        'mutation',
        variables
      );
    },
    updateOneUser(
      variables: UpdateOneUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateOneUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateOneUserMutation>(
            UpdateOneUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateOneUser',
        'mutation',
        variables
      );
    },
    upsertOneUserNotificationPreferences(
      variables: UpsertOneUserNotificationPreferencesMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpsertOneUserNotificationPreferencesMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertOneUserNotificationPreferencesMutation>(
            UpsertOneUserNotificationPreferencesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'upsertOneUserNotificationPreferences',
        'mutation',
        variables
      );
    },
    upsertOneUserEvent(
      variables: UpsertOneUserEventMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpsertOneUserEventMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertOneUserEventMutation>(
            UpsertOneUserEventDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'upsertOneUserEvent',
        'mutation',
        variables
      );
    },
    getUsers(
      variables?: GetUsersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUsersQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUsersQuery>(GetUsersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getUsers',
        'query',
        variables
      );
    },
    getUser(
      variables: GetUserQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUserQuery>(GetUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getUser',
        'query',
        variables
      );
    },
    deleteAuth0User(
      variables?: DeleteAuth0UserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<DeleteAuth0UserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteAuth0UserMutation>(
            DeleteAuth0UserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteAuth0User',
        'mutation',
        variables
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
